import type { AxiosInstance, AxiosResponse } from 'axios';
import urlJoin from 'url-join';
import { AlbatoInvalidResponseError } from './errors/albato.invalid.response.error';

type AlbatoResponse = {
  token: string,
  validUntil: Date
}

export class Albato {

    http: AxiosInstance;
    baseUrl: string = '';
    embedUrl: string = '';

    constructor(config: { http: AxiosInstance, baseUrl: string, embedUrl: string }) {
        this.baseUrl = config.baseUrl;
        this.embedUrl = config.embedUrl;
        this.http = config.http;
    }

    async getAlbatoToken(guid: string): Promise<AlbatoResponse | undefined> {
        try {
            const albato_session_url = `${this.baseUrl}token/${guid}`;
            const result: AxiosResponse<AlbatoResponse> = await this.http.post(`${albato_session_url}`, null, { headers: { Company: guid } });

            if (result.data) {
                return result.data;
            }

            return undefined;
        } catch (e: unknown) {
            if (e instanceof Error) {
                throw new AlbatoInvalidResponseError(e);
            }

            throw e;
        }
    }

    getAlbatoUrl(token: string): string {
        const url = new URL(urlJoin(this.embedUrl, 'user/auth/token'));

        if (!token) {
            throw Error('No token provided');
        }

        url.searchParams.set('session_token', token);

        // This redirect the Albato frame to add the (urlencoded) view id, this adds 'Solutions' to the Albato menu.
        url.searchParams.set('r', '/bundle?marketplace_id=184&view_id=3');

        return url.toString();
    }

}
