<template>
    <OtModal
        ref="modalRef"
        :width="800"
        :title="title"
        :subtitle="subtitle"
    >
        <ErrorView v-if="error" />

        <CollapseUpdateForm
            v-else
            :form="collapseForm"
        />

        <template #footer-action>
            <OtButton
                icon="check"
                :title="$t('dashboard.common.action.save.title')"
                :disabled="!collapseForm.hasLocalChanges"
                @click="save"
            >
                {{ $t('dashboard.common.action.save.text') }}
            </OtButton>
        </template>
    </OtModal>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue';
import { validate } from '@openticket/lib-crud';
import type { ManagementClient, Collapse } from '@openticket/lib-management';
import type { ShopDataCollapse } from '../../../../services/shop';
import { injectOrFail } from '../../../../services/util';
import ErrorView from '../../../../components/ErrorView.vue';
import { useGenericErrorHandling } from '../../../../composables';
import { useUpdateForm } from '../../../../composables/forms';
import CollapseUpdateForm from '../forms/CollapseUpdateForm.vue';

type Props = {
    title: string,
    subtitle?: string,
}

type Emits = {
    (e: 'saved', data: { updatedCollapse?: ShopDataCollapse, eventIndex?: number, isManagementModel: boolean }): void
}

const management = injectOrFail<ManagementClient>('management');

defineProps<Props>();
const emit = defineEmits<Emits>();

const { handleError, error } = useGenericErrorHandling();

// TODO revert to Vue components types
const modalRef = ref<{ open(): void, close(): void } | undefined >();

const collapseForm = reactive(useUpdateForm<Collapse<ManagementClient>, ManagementClient>(
    management.collapses.new(),
    management.collapses,
));
const eventIndex = ref<number>();
const originalCollapseModel = ref<ShopDataCollapse | null>(null);

function close() {
    if (modalRef.value) {
        cleanup();
        modalRef.value.close();
    }
}

async function open(collapse: ShopDataCollapse, _eventIndex: number) {
    if (!modalRef.value) {
        return;
    }

    if (collapse.concept) {
        eventIndex.value = _eventIndex;
        originalCollapseModel.value = collapse;

        // Update the model data in the form composable with the data from the provided collapse
        Object.keys(collapse).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(collapseForm.model.$data, key)) {
                const setCollapseModelDataValue = <K extends keyof typeof collapseForm.model.$data>(
                    dataKey: K,
                    dataValue: (typeof collapseForm.model.$data)[K],
                ) => {
                    collapseForm.model.$data[dataKey] = dataValue;
                };

                // Casting needed as the hasOwnProperty doesn't correctly infere type for key
                const k = key as keyof (typeof collapseForm.model.$data | typeof collapse);
                setCollapseModelDataValue(k, collapse[k]);
            }
        });

        // Reset falsely triggered local changes
        collapseForm.hasLocalChanges = false;
    } else {
        collapseForm.init(await management.collapses.find(collapse.guid), management.collapses);
    }

    modalRef.value.open();
}

async function save() {
    try {
        if (originalCollapseModel.value) {
            const validation = validate(collapseForm.model.$raw, collapseForm.rules);

            if (!validation.valid) {
                collapseForm.errors = validation.result.messages;
                return;
            }

            emit('saved', {
                isManagementModel: false,
                updatedCollapse: {
                    ...originalCollapseModel.value,
                    ...collapseForm.model.$data,
                },
                eventIndex: eventIndex.value,
            });
            close();
        } else {
            const { success } = await collapseForm.submit();

            if (success) {
                emit('saved', { isManagementModel: true });
                close();
            }
        }
    } catch (e: unknown) {
        void handleError(e);
    }
}

function cleanup() {
    eventIndex.value = undefined;
    error.value = null;
    collapseForm.errors = {};
    collapseForm.hasLocalChanges = false;
    collapseForm.init(management.collapses.new(), management.collapses);
}

defineExpose({
    open,
});
</script>

<style scoped lang="scss">
.update-collapse-modal {
    &__content {
        max-height: 50vh;
        overflow-y: auto;
    }
}
</style>
