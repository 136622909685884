<template>
    <div>
        <OtButtonDropdown
            v-if="roles.isWhitelabelAdmin"
            class="order-admin-actions"
            :buttons="buttons"
        >
            <template #trigger>
                <OtButton
                    icon="lock"
                    size="small"
                    variant="outline"
                    style="
                        --ot-ui-color-accent-secondary: var(--ot-ui-color-intent-success-primary);
                        --ot-ui-color-foreground-secondary: var(--ot-ui-color-intent-success-primary);
                    "
                >
                    {{ $t('dashboard.orders.admin_actions.label') }}
                </OtButton>
            </template>
        </OtButtonDropdown>
    </div>
</template>

<script setup lang="ts">
import type { OrderExtended, ManagementClient } from '@openticket/lib-management';
import { computed, reactive, type UnwrapNestedRefs } from 'vue';
import type { OtButtonDropdownType } from '@openticket/vue-ui';
import { useLocalization, useNotifications } from '@openticket/vue-ui';
import { injectOrFail } from '../../../services/util';
import type { Roles } from '../../../plugins/types';
import type { AdminActions } from '../../../services/admin-actions/adminActions.service';
import { useGenericErrorHandling } from '../../../composables';

type AdminAction = Omit<OtButtonDropdownType, 'onClick'> & {
    cb: () => void | Promise<void>;
    successLabel: string;
}

type Props = {
    order: OrderExtended<ManagementClient>,
}

const props = defineProps<Props>();

const adminActions = injectOrFail<AdminActions>('adminActions');
const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');

const { handleError } = useGenericErrorHandling();
const notifications = useNotifications();
const { t } = useLocalization();

const actions = reactive<Record<string, AdminAction>>({
    rerenderTickets: {
        cb: async () => {
            if (!props.order.id) {
                return;
            }
            await adminActions.rerenderOrderTickets(props.order.id);
        },
        icon: 'ticket',
        label: t('dashboard.orders.admin_actions.rerender_tickets.label'),
        loading: false,
        successLabel: t('dashboard.orders.admin_actions.rerender_tickets.success'),
    },
    resendConfirmationEmail: {
        cb: async () => {
            if (!props.order.id) {
                return;
            }
            await adminActions.resendOrderConfirmationEmail(props.order.id);
        },
        icon: 'mail',
        label: t('dashboard.orders.admin_actions.resend_confirmation_email.label'),
        loading: false,
        successLabel: t('dashboard.orders.admin_actions.resend_confirmation_email.success'),
    },
});

const buttons = computed<OtButtonDropdownType[]>(() => Object.values(actions).map((item) => ({
    ...item,
    onClick: () => performAdminAction(item),
})));

async function performAdminAction(action: AdminAction): Promise<void> {
    action.loading = true;

    try {
        await action.cb();

        notifications.success(action.successLabel);
    } catch (e) {
        handleError(e);
    }

    action.loading = false;
}
</script>
