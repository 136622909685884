export const urlIncludesString = (url: string, searchString: string): boolean => (!!url.includes(searchString));

export const splitUrl = (fullUrl: string): [url: string, searchParams: string] => {
    const [ url, searchParams ] = fullUrl.split('?', 2);
    return [ url, searchParams ];
};

export const getPathFromUrl = (fullUrl: string): string => splitUrl(fullUrl)[0];
export const getParamsFromUrl = (fullUrl: string): string => splitUrl(fullUrl)[1];

export const getHostnameFromUrl = (fullUrl: string): string => {
    const parts = getPathFromUrl(fullUrl).split('://');
    return parts.length <= 1 ? parts[0] : parts[1].split('/')[0];
};

export function mapRelevantParams(fullUrl: string, relevantQueries: Readonly<string[]>): Map<string, string> {
    const searchParams = getParamsFromUrl(fullUrl);
    const queries = new Map<string, string>();

    if (!searchParams) {
        return queries;
    }

    searchParams.split('&')
        .forEach((q) => {
            const [ key, value ] = q.split('=', 2);
            if (relevantQueries.includes(key)) {
                queries.set(key, value);
            }
        });
    return queries;
}
