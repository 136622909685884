<template>
    <OtCard
        :no-padding="true"
        class="tfa-code-setup"
    >
        <ModalHeader
            :hide-logo="tfaSetupProvider.hideLogo"
            :hide-logout="tfaSetupProvider.hideLogout"
            text-centered
        >
            <template #title>
                <h1 class="ot-ui-text-heading-1">
                    {{ $t('dashboard.user.tfa.setup.code.title') }}
                </h1>
            </template>

            <template
                v-if="error.incorrectTFAInput || error.incorrectPassword"
                #extra
            >
                <h5 class="tfa-code-setup__warning warning ot-ui-text-heading-5">
                    {{ $t('dashboard.user.tfa.setup.warning') }}
                </h5>
            </template>
        </ModalHeader>

        <OtCardContent>
            <div>
                <OtInputField
                    :label="$t('dashboard.user.tfa.setup.password')"
                    :error="$t(error.incorrectPassword || '', { attribute: $t('dashboard.user.tfa.setup.attributes.password') })"
                >
                    <OtPasswordInput
                        v-model="password"
                        :label="$t('dashboard.user.tfa.setup.password')"
                        name="password"
                        :invalid="!!error.incorrectPassword"
                    />
                </OtInputField>
            </div>
        </OtCardContent>

        <OtCardFooter class="tfa-code-setup__footer">
            <template #left>
                <OtButton
                    icon="arrow-left"
                    :title="$t('dashboard.common.action.back.title')"
                    variant="secondary"
                    @click="close()"
                >
                    {{ $t('dashboard.common.action.back.text') }}
                </OtButton>
            </template>
            <template #right>
                <OtButton
                    :disabled="!formCompleted || submitting"
                    icon="check"
                    :loading="submitting"
                    :title="$t('dashboard.user.tfa.action.validate.title')"
                    @click="submit()"
                >
                    {{ $t('dashboard.user.tfa.action.validate.text') }}
                </OtButton>
            </template>
        </OtCardFooter>
    </OtCard>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import type { OtTFAError } from '@openticket/vue-ui';
import ModalHeader from '../../modal/ModalHeader.vue';
import { injectOrFail } from '../../../services/util';
import type { TfaSetupProvider } from '../types';

type Props = {
    submitting: boolean,
    error: OtTFAError
}

type Emits = {
    (e: 'close'): void,
    (e: 'submit', path: string, data: {password: string | null}): void
}

const props = withDefaults(
    defineProps<Props>(),
    {
        submitting: false,
        error: () => ({
            incorrectPassword: null,
            incorrectTFAInput: null,
        }),
    },
);
const emit = defineEmits<Emits>();

const tfaSetupProvider = injectOrFail<TfaSetupProvider>('TfaSetupProvider');

const password = ref<string | null>(null);
const formCompleted = computed(() => !!password.value);

const close = (): void => {
    emit('close');
};

const submit = (): void => {
    if (props.submitting) {
        return;
    }

    // TODO - @openticket/lib-auth
    emit(
        'submit',
        'twofactor/code/initialise',
        { password: password.value },
    );
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins.scss";

.tfa-code-setup {
    &__warning {
        margin-top: var(--ot-ui-spacing-sm);
        color: var(--ot-ui-color-intent-warning-secondary);
    }
}
</style>
