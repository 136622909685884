<template>
    <OtCard
        class="tfa-totp-setup"
        no-padding
    >
        <ModalHeader
            :hide-logo="tfaSetupProvider.hideLogo"
            :hide-logout="tfaSetupProvider.hideLogout"
            text-centered
        >
            <template #title>
                <h1 class="ot-ui-text-heading-1">
                    {{ $t('dashboard.user.tfa.setup.totp.title') }}
                </h1>
            </template>

            <template
                v-if="!prepared"
                #subtitle
            >
                <span class="ot-ui-text-body-intro">
                    {{ $t('dashboard.user.tfa.setup.totp.explanation') }}
                </span>
            </template>

            <template
                v-if="prepared && (error.incorrectTFAInput || error.incorrectPassword)"
                #extra
            >
                <h5 class="warning ot-ui-text-heading-5">
                    {{ $t('dashboard.user.tfa.setup.warning') }}
                </h5>
            </template>
        </ModalHeader>

        <form @submit.prevent="submit()">
            <OtCardContent>
                <img
                    v-if="!setup"
                    class="tfa-totp-setup__loading"
                    :src="$whitelabel.resources.spinner"
                    alt="loading..."
                >

                <TfaTotpPrepare
                    v-else-if="!prepared"
                    :totp="setup.data.totp"
                />

                <TfaTotpConfirm
                    v-else
                    v-model="formData"
                    :error="error"
                />
            </OtCardContent>

            <OtCardFooter class="tfa-totp-setup__footer">
                <template
                    v-if="!tfaSetupProvider.hideBackButton || prepared"
                    #left
                >
                    <OtButton
                        icon="arrow-left"
                        :title="$t('dashboard.common.action.back.title')"
                        variant="secondary"
                        @click="back"
                    >
                        {{ $t('dashboard.common.action.back.text') }}
                    </OtButton>
                </template>

                <template #right>
                    <OtButton
                        v-if="setup && prepared"
                        :disabled="!formCompleted || submitting"
                        icon="check"
                        :loading="submitting"
                        type="submit"
                        :title="$t('dashboard.user.tfa.action.validate_and_enable.title')"
                    >
                        {{ $t('dashboard.user.tfa.action.validate_and_enable.text') }}
                    </OtButton>

                    <OtButton
                        v-else
                        :disabled="!setup"
                        icon-right="arrow-right"
                        :title="$t('dashboard.common.action.next.title')"
                        @click="next"
                    >
                        {{ $t('dashboard.common.action.next.text') }}
                    </OtButton>
                </template>
            </OtCardFooter>
        </form>
    </OtCard>
</template>

<script setup lang="ts">
import type { AuthClient } from '@openticket/lib-auth';
import urlJoin from 'url-join';
import { computed, ref } from 'vue';
import type { OtTFAError } from '@openticket/vue-ui';
import type { PluginsManager } from '../../../plugins';
import ModalHeader from '../../modal/ModalHeader.vue';
import TfaTotpConfirm from './TfaTotpConfirm.vue';
import TfaTotpPrepare from './TfaTotpPrepare.vue';
import type { TfaSetupProvider, TfaTotpData, TotpSetup } from '../types';
import { injectOrFail } from '../../../services/util';

interface TotpSetupData {
    data: {
        totp: TotpSetup;
    }
}

type Props = {
    submitting: boolean,
    error: OtTFAError,
}

type Emits = {
    (e: 'close'): void,
    (e: 'submit', path: string, data: {password: string, input: string, config: string}): void
}

withDefaults(
    defineProps<Props>(),
    {
        submitting: false,
        error: () => ({
            incorrectPassword: null,
            incorrectTFAInput: null,
        }),
    },
);

const emit = defineEmits<Emits>();

const plugins = injectOrFail<PluginsManager>('plugins');
const tfaSetupProvider = injectOrFail<TfaSetupProvider>('TfaSetupProvider');

const setup = ref<TotpSetupData | null>(null);
const prepared = ref<boolean>(false);

const formData = ref<TfaTotpData>({
    password: null,
    input: null,
});

const formCompleted = computed(() => !!formData.value.password && !!formData.value.input);

void (async () => {
    const auth: AuthClient = await plugins.auth.loading;
    // TODO - @openticket/lib-auth
    setup.value = await auth.$http.get(urlJoin(auth.$path, 'twofactor', 'totp', 'prepare'));
})();

const back = (): void => {
    if (prepared.value) {
        prepared.value = false;
        formData.value.password = null;
        formData.value.input = null;
    } else {
        emit('close');
    }
};

const next = (): void => {
    if (!prepared.value) {
        prepared.value = true;
    }
};

const submit = (): void => {
    if (setup.value && formData.value.password && formData.value.input) {
        emit(
            'submit',
            '/twofactor/totp/confirm',
            {
                password: formData.value.password,
                input: formData.value.input,
                config: setup.value.data.totp.config,
            },
        );
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins.scss";

.tfa-totp-setup {
    .warning {
        color: var(--ot-ui-color-intent-warning-secondary);
    }
}
</style>
