import type { RouteRecordRaw } from 'vue-router';
import FixForcedTfaView from './views/FixForcedTfaView.vue';
import FixForgotPasswordView from './views/FixForgotPasswordView.vue';
import FixProfileFinalizationView from './views/FixProfileFinalizationView.vue';
import FixZeroCompaniesView from './views/FixZeroCompaniesView.vue';
import FixWrapper from '../../layouts/FixWrapper.vue';

export function fixRoutes(): RouteRecordRaw[] {
    return [
        {
            path: '/fix',
            component: FixWrapper,
            meta: {
                disableAuth: true,
            },
            children: [
                {
                    path: 'enable-2fa',
                    name: 'fix.tfa',
                    component: FixForcedTfaView,
                    meta: {
                        disableAuth: true,
                        title: 'dashboard.document_title.fix.tfa',
                        titleFallback: 'Two factor',
                    },
                },
                {
                    path: 'forgot-password/:token',
                    name: 'fix.forgot_password',
                    component: FixForgotPasswordView,
                    meta: {
                        disableSessionValidation: true,
                        disableAuth: true,
                        title: 'dashboard.document_title.fix.forgot_password',
                        titleFallback: 'Forgot password',
                    },
                },
                {
                    path: 'finalize-profile/:token',
                    name: 'fix.profile',
                    component: FixProfileFinalizationView,
                    meta: {
                        disableSessionValidation: true,
                        disableAuth: true,
                        title: 'dashboard.document_title.fix.profile',
                        titleFallback: 'Profile finalization',
                    },
                },
                {
                    path: 'zero-companies',
                    name: 'fix.invitations',
                    component: FixZeroCompaniesView,
                    meta: {
                        disableAuth: true,
                        title: 'dashboard.document_title.fix.invitations',
                        titleFallback: 'Invitations',
                    },
                },
            ],
        },
    ];
}
