import { OtError } from '@openticket/lib-log';

export class InvalidShopDataIdError extends OtError {

    constructor() {
        super('InvalidShopDataIdError', 'dashboard.error.service.shop.parser.invalid.shop.data.id', 'Failed to parse raw shop data. Invalid shop ID.');

        this.captureStackTrace(InvalidShopDataIdError);
    }

}
