import { OtError } from '@openticket/lib-log';

export class ContextTicketError extends OtError {

    constructor() {
        super('ContextTicketError', 'dashboard.errors.plugins.context.ticket', 'Failed to set ticket context.');

        this.captureStackTrace(ContextTicketError);
    }

}
