import { OtError } from '@openticket/lib-log';

export class InvalidShopDataUniqueError extends OtError {

    readonly key: string;
    readonly property: string;
    readonly value: string;

    constructor(key: string, property: string, value: string) {
        super('InvalidShopDataUniqueError', 'dashboard.error.service.shop.parser.invalid.shop.data.unique', 'Failed to parse raw shop data. Duplicate data value.');

        this.key = key;
        this.addContext('key', key);

        this.property = property;
        this.addContext('property', property);

        this.value = value;
        this.addContext('value', value);

        this.captureStackTrace(InvalidShopDataUniqueError);
    }

}
