import type { App } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { AuthClient } from '@openticket/lib-auth';
import { KnoxClient } from '@openticket/lib-knox';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import type { UploaderOptions } from './types';

/**
 * Plugin for uploading files.
 */
export class UploaderPlugin extends Plugin<KnoxClient> {

    async install(plugins: PluginsManager, app: App): Promise<void> {
        const options: UploaderOptions = plugins.options.uploader || {};

        const whitelabel: Whitelabel = await plugins.whitelabel.loading;

        const baseUrl: string = options.url
            || whitelabel.dashboard.uploader_url
            || 'https://knox.openticket.tech/';

        const auth: AuthClient = await plugins.auth.loading;

        const uploader = new KnoxClient({
            http: auth.$http,
            baseUrl,
        });

        app.provide('uploader', uploader);

        this.resolve(uploader);
    }

}
