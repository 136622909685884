<template>
    <OtCard
        class="tfa-codes"
        no-padding
    >
        <ModalHeader
            :hide-logo="tfaSetupProvider.hideLogo"
            :hide-logout="tfaSetupProvider.hideLogout"
            text-centered
        >
            <template #title>
                <h1 class="ot-ui-text-heading-1">
                    {{ $t('dashboard.user.tfa.setup.code.title') }}
                </h1>
            </template>

            <template #extra>
                <h5 class="ot-ui-text-heading-5">
                    {{ $t(tfaSetupProvider.successMessage) }}
                </h5>
            </template>
        </ModalHeader>

        <OtCardContent>
            <div class="tfa-codes__explanation-label">
                {{ $t('dashboard.user.tfa.setup.code.explanation') }}
            </div>

            <div class="tfa-codes__codes">
                <h3
                    v-for="(code, key) in codes"
                    :key="key"
                    class="tfa-codes__codes__code ot-ui-text-heading-3"
                >
                    {{ code }}
                </h3>
            </div>

            <OtAside
                :title="$t('dashboard.user.tfa.setup.code.explanation_warning.title')"
                :description="$t('dashboard.user.tfa.setup.code.explanation_warning.description')"
                type="danger"
                icon="alert"
            />

            <div class="tfa-codes__download">
                <OtButton
                    icon="download"
                    :title="$t('dashboard.user.tfa.setup.code.download.title')"
                    variant="outline"
                    @click="saveContent"
                >
                    {{ $t('dashboard.user.tfa.setup.code.download.text') }}
                </OtButton>

                <OtButton
                    :icon="copied ? 'check' : 'copy'"
                    :title="$t('dashboard.user.tfa.setup.code.copy.title')"
                    variant="outline"
                    @click="copyContent"
                >
                    {{ copied
                        ? $t('dashboard.user.tfa.setup.code.copy.success')
                        : $t('dashboard.user.tfa.setup.code.copy.text') }}
                </OtButton>
            </div>
        </OtCardContent>

        <OtCardFooter class="tfa-codes__footer">
            <template #right>
                <OtButton
                    :title="$t('dashboard.common.action.continue.title')"
                    @click="close"
                >
                    {{ $t('dashboard.common.action.continue.text') }}
                </OtButton>
            </template>
        </OtCardFooter>
    </OtCard>
</template>

<script setup lang="ts">
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { ref } from 'vue';
import ModalHeader from '../modal/ModalHeader.vue';
import { injectOrFail } from '../../services/util';
import type { TfaSetupProvider } from './types';
import { useClipboard } from '../../composables';

type Props = {
    codes: number[],
}

type Emits = {
    (e: 'close'): void
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const tfaSetupProvider = injectOrFail<TfaSetupProvider>('TfaSetupProvider');

const copied = ref<boolean>(false);

const { clipboard } = useClipboard();

// TODO: Check how this should be re-introduced. The `modal` is not provided anymore
// void (() => {
//     if (modal) {
//         modal.registerBeforeClose(async () =>
//             // Only close code modal when user confirm they have saved the recovery codes
//             // eslint-disable-next-line implicit-arrow-linebreak
//             confirmationModal.confirm({
//                 title: t('dashboard.user.tfa.setup.code.confirm_close.title'),
//                 message: t('dashboard.user.tfa.setup.code.confirm_close.description'),
//                 variant: 'default',
//             }));
//     }
// })();

const close = (): void => {
    emit('close');
};

const saveContent = () => {
    const link = document.createElement('a');
    link.download = `${whitelabel.name} - recovery codes.txt`;
    link.href = `data:,${props.codes.join(', ')}`;
    link.click();
};

const copyContent = async () => {
    await clipboard.writeText(props.codes.join(', ')).then(() => {
        copied.value = true;
        setTimeout(() => {
            copied.value = false;
        }, 1500);
    });
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins.scss";

.tfa-codes {
    &__header__success {
        margin-top: var(--ot-ui-spacing-sm);
        color: var(--ot-ui-color-intent-success-secondary);
    }

    &__explanation-label {
        margin-bottom: var(--ot-ui-spacing-md);
        color: var(--ot-ui-color-foreground-secondary);
    }

    &__download {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--ot-ui-spacing-xs);

        @include breakpoint(mob) {
            margin-bottom: 0;
        }

        button {
            @include breakpoint(mob) {
                width: 100%;
            }
            & > div {
                display: inline-flex;

                &.copied_success {
                    color: var(--ot-ui-color-intent-success-secondary);
                }
            }
        }
    }

    &__codes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        margin-bottom: var(--ot-ui-spacing-md);

        &__code {
            margin-bottom: var(--ot-ui-spacing-xs);
        }
    }

    &__footer {
        @include breakpoint(mob) {
            flex-direction: column;
            gap: var(--ot-ui-spacing-xs);
        }

        button {
            @include breakpoint(mob) {
                width: 100%;
            }
        }
    }
}
</style>
