import { ref } from 'vue';

export function useFormModal() {
    const isOpen = ref<boolean>(false);

    function open() {
        isOpen.value = true;
    }

    function close() {
        isOpen.value = false;
    }

    return {
        close,
        isOpen,
        open,
    };
}

export type FormModalComposableResult = ReturnType<typeof useFormModal>;
