import type { App } from 'vue';
import { isOtError, send } from '@openticket/lib-log';
import { Plugin } from './plugin';
import type { PluginsManager } from './manager';
import { Intercom } from '../services/intercom';

export class IntercomPlugin extends Plugin<Intercom> {

    async install(plugins: PluginsManager, app: App): Promise<void> {
        const intercom = new Intercom();

        try {
            const whitelabel = await plugins.whitelabel.loading;

            if (whitelabel.dashboard.intercom_app_id) {
                intercom.init(
                    whitelabel.dashboard.intercom_app_id,
                    'https://api-iam.intercom.io',
                );
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());

                if (isOtError(e)) {
                    send(e);
                }
            }
        } finally {
            app.provide('intercom', intercom);

            this.resolve(intercom);
        }
    }

}
