<template>
    <div class="event-wizard-tickets__list">
        <div class="event-wizard-tickets__list__docs">
            <OtDocsSection
                :label="$t('dashboard.common.read_docs')"
                :variant="'tickets'"
                :url="$whitelabel.dashboard.docs_urls?.tickets?.list"
            >
                <h2 class="ot-ui-text-heading-2">
                    {{ $t('dashboard.events.wizard.steps.tickets.list.title') }}
                </h2>
                <span class="ot-ui-text-body-lg">{{ $t('dashboard.events.wizard.steps.tickets.list.description') }}</span>
            </OtDocsSection>
        </div>

        <OtDataGrid
            v-if="wizardProvide.eventTickets.pagination && !error"
            class="event-wizard-tickets__list__grid"
            type="table"
            :pagination="wizardProvide.eventTickets.pagination"
            :columns="columns"
            show-controls="auto"
            :empty-labels="{
                subtitle: $t('dashboard.tickets.list.empty.message'),
                title: $t('dashboard.tickets.list.empty.title'),
            }"
        >
            <template #column__$data__available_stock="{ value }">
                {{ $l.number(value === 0 ? Infinity : value) }}
            </template>

            <template #column__$data__min_price="{ value }">
                {{ $l.currency(value, wizardProvide.eventForm.model.$data.currency) }}
            </template>

            <template #column__$data__vat_percentage="{ value }">
                {{ value }}%
            </template>

            <template #actions="{ record }">
                <OtButton
                    size="tiny"
                    variant="outline"
                    icon="carrot-right"
                    :title="$t('dashboard.events.wizard.steps.tickets.list.edit.title')"
                    @click="handleTicketEdit(record)"
                >
                    {{ $t('dashboard.events.wizard.steps.tickets.list.edit.text') }}
                </OtButton>
            </template>

            <template #table-head-actions>
                &nbsp;
            </template>
        </OtDataGrid>

        <ErrorView
            v-else-if="error"
            :label="error.message"
        />

        <OtSpinner v-else />
    </div>
</template>

<script lang="ts" setup>
import type { Event, ManagementClient, Ticket } from '@openticket/lib-management';
import type { OtDataGridColumn } from '@openticket/vue-ui';
import type { WizardProvide } from '../types';
import ErrorView from '../../../components/ErrorView.vue';
import { injectOrFail } from '../../../services/util';
import { useGenericErrorHandling } from '../../../composables';

const { error, handleError } = useGenericErrorHandling();

const management = injectOrFail<ManagementClient>('management');
const wizardProvide = injectOrFail<WizardProvide>('wizardProvide');

const columns: OtDataGridColumn[] = [
    {
        key: [ '$data', 'name' ],
        type: 'string',
        label: 'Name',
        tileColumnSize: 'default',
    },
    {
        key: [ '$data', 'available_stock' ],
        type: 'custom',
        label: 'Capacity',
        tileColumnSize: 'default',
    },
    {
        key: [ '$data', 'min_price' ],
        type: 'number',
        label: 'Price',
        tileColumnSize: 'default',
    },
    {
        key: [ '$data', 'vat_percentage' ],
        type: 'number',
        label: 'VAT',
        tileColumnSize: 'default',
    },
];

void (async () => {
    try {
        if (wizardProvide.eventForm.model.id) {
            const event = management.events.new();
            event.$raw = wizardProvide.eventForm.model.$raw;

            const list = event.tickets.list({ deferInitialization: true });
            await list.initialization;

            wizardProvide.eventTickets.update(list);
        }
    } catch (e) {
        void handleError(e);
    }
})();

function handleTicketEdit(data: Ticket<Event<ManagementClient>>) {
    wizardProvide.ticketToEditGuid.value = data.id;
    wizardProvide.showUpsertTicketView.value = true;
}
</script>

<style lang="scss" scoped>

.event-wizard-tickets__list {
    &__docs {
        padding: var(--ot-ui-spacing-xl) 0;
        border-bottom: 1px solid var(--ot-ui-color-accent-tertiary);
        margin-bottom: var(--ot-ui-spacing-xl);

        h1 {
            margin-bottom: var(--ot-ui-spacing-xs);
        }

        span {
            color: var(--ot-ui-color-foreground-secondary);
        }
    }
}
</style>
