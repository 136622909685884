import { DataType } from '@openticket/lib-crud';
import type { DataInterface, ModelConfig } from '@openticket/lib-crud';
import {
    OrderMetaDataExtendedModelConfig,
    OrderModelConfig,
    OrderPaymentExtendedModelConfig,
    OrderTicketExtendedModelConfig,
} from '@openticket/lib-management';
import type { LegacyApiHelperMethods } from '../types';

export function getOrderEndpoints(methods: LegacyApiHelperMethods) {
    /**
     * Retrieve an entire specific Order. This includes, all Tickets, Products, OrderMetadata, Payments, Returns, and Refunds.
     *
     * @param orderId The guid of the Order.
     * @returns
     */
    function find(orderId: string): Promise<DataInterface<typeof config>> {
        // TODO/Note: The below config is incomplete, add other properties when needed
        const config = {
            ...OrderModelConfig,

            metadata: [ DataType.array(DataType.object(OrderMetaDataExtendedModelConfig)) ],

            payments: [ DataType.array(DataType.object(OrderPaymentExtendedModelConfig)) ],

            products: [ DataType.array(DataType.object({
                guid: [ DataType.string ],
                product: [ DataType.object({
                    guid: [ DataType.string ],
                    name: [ DataType.string ],
                }) ],
            })) ],

            refunds: [ DataType.array(DataType.object({
                guid: [ DataType.string ],
                method: [ DataType.object({
                    guid: [ DataType.string ],
                    name: [ DataType.string ],
                }) ],
            })) ],

            shop: [ DataType.object({
                guid: [ DataType.string ],
                name: [ DataType.string ],
            }) ],

            tickets: [ DataType.array(DataType.object(OrderTicketExtendedModelConfig)) ],
        } satisfies ModelConfig;

        return methods.performGet([ 'order', orderId ], config);
    }

    return {
        find,
    };
}
