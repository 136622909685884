import { OtError, UnknownError } from '@openticket/lib-log';

export class RudderstackCallError extends OtError {

    constructor(cause?: Error) {
        super('RudderstackCallError', 'dashboard.errors.plugins.rudderstack.call', 'Failed in rudderstack call.');

        if (cause) {
            this.causedBy(new UnknownError(cause));
        }

        this.captureStackTrace(RudderstackCallError);
    }

}
