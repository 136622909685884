<template>
    <div class="debug-plugins__management">
        <code
            class="debug-plugins__management__status full-width"
        >
            <span>Status:</span>

            <span v-if="loading">Loading</span>
            <span v-else>Ready (available)</span>
        </code>

        <template v-if="client">
            <code
                class="debug-plugins__management__company-id full-width"
            ><span>Company ID:</span><span>{{ companyId }}</span></code>

            <code class="debug-plugins__management__config full-width">
                <span class="row">Config:</span>
                <span class="row"><span>baseUrl:</span><span>{{ client.config.baseUrl }}</span></span>
                <span class="row"><span>shopUrl:</span><span>{{ client.config.shopUrl || 'None' }}</span></span>
                <span class="row"><span>Initial company ID:</span><span>{{ client.config.companyId }}</span></span>
            </code>
        </template>
    </div>
</template>

<script lang="ts" setup>
import {
    computed, onMounted, ref,
} from 'vue';
import type { ManagementClient } from '@openticket/lib-management';
import type { PluginsManager } from '../../../../plugins';
import { injectOrFail } from '../../../../services/util';

const plugins = injectOrFail<PluginsManager>('plugins');
const loading = ref<boolean>(true);
const client = ref<ManagementClient | null>(null);

onMounted(() => {
    void initializePluginDebug();
});

async function initializePluginDebug() {
    try {
        client.value = await plugins.management.loading;
    } finally {
        loading.value = false;
    }
}

const companyId = computed<string>(() => {
    if (!client.value) {
        return '';
    }

    // TODO
    return 'Private field, awaiting new version';
});
</script>

<style lang="scss" scoped>
.debug-plugins__management {
    & > code {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;

        & > span:not(:first-child) {
            padding-left: .5rem;
        }

        &.debug-plugins__management__config {
            flex-direction: column;
            justify-content: flex-start;

            & > .row {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
</style>
