import type { RouteRecordRaw } from 'vue-router';
import ScannersListView from './views/ScannersListView.vue';

export function scannersRoutes(): RouteRecordRaw[] {
    return [
        {
            path: '/scanners',
            name: 'scanners.list',
            component: ScannersListView,
            meta: {
                title: 'dashboard.document_title.scanners.list',
                titleFallback: 'Scanners',
            },
        },
    ];
}
