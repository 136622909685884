<template>
    <div class="access-moments-update__linked-tickets">
        <ErrorView
            v-if="error"
            :label="error.message"
        />

        <OtDataGrid
            v-if="linkedTickets"
            class="access-moments-update__linked-tickets__grid"
            type="table"
            :columns="columns"
            :pagination="linkedTickets"
            hide-header
            :empty-labels="{
                title: $t('dashboard.access_moments.linked_tickets.list.empty.title'),
            }"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="linkedTickets"
                    searchable
                    search-key="name"
                    :search-placeholder="$t('common.placeholder.search')"
                    :title="$t('dashboard.access_moments.linked_tickets.list.header.title')"
                >
                    <template #actions-top>
                        <OtButton
                            :title="$t('dashboard.access_moments.linked_tickets.list.header.link.title')"
                            :disabled="isReadOnly"
                            data-testid="access-moment-link-ticket-btn"
                            icon="plus"
                            @click="linkModalRef?.show(context.accessMoment.model)"
                        >
                            {{ $t('dashboard.access_moments.linked_tickets.list.header.link.text') }}
                        </OtButton>
                    </template>
                </OtListHeader>
            </template>

            <template
                v-if="!Object.keys(linkedTickets.filters?.active ?? {}).length"
                #empty-action
            >
                <OtButton
                    variant="outline"
                    icon="plus"
                    :disabled="isReadOnly"
                    :title="$t('dashboard.access_moments.linked_tickets.list.header.link.title')"
                    data-testid="access-moment-empty-link-tickets-btn"
                    @click="linkModalRef?.show(context.accessMoment.model)"
                >
                    {{ $t('dashboard.access_moments.linked_tickets.list.header.link.text') }}
                </OtButton>
            </template>

            <template #actions="{ record }">
                <OtButton
                    data-testid="access-moment-linked-tickets-unlink"
                    icon="close"
                    size="tiny"
                    variant="inline"
                    class="access-moments-update__delete"
                    :disabled="isReadOnly"
                    @click="unlinkTicket(record.id)"
                />
            </template>
        </OtDataGrid>

        <AccessMomentTicketsLinkModal
            ref="linkModalRef"
            @submit="loadTickets"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, useTemplateRef } from 'vue';
import type { EventDate, ManagementClient, Ticket } from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import { type OtDataGridColumn, useConfirmationModal, useLocalization } from '@openticket/vue-ui';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling, useRoles } from '../../../composables';
import ErrorView from '../../../components/ErrorView.vue';
import AccessMomentTicketsLinkModal from '../components/AccessMomentTicketsLinkModal.vue';

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const dialog = useConfirmationModal();
const { error, handleError } = useGenericErrorHandling();
const { t } = useLocalization();

if (!context.isAccessMomentContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const columns: OtDataGridColumn[] = [
    {
        key: [ '$data', 'name' ],
        type: 'string',
        label: t('Name'),
        tileColumnSize: 'default',
    },
];

// Type adjusted copy of the contexts accessmoment to have tickets relation
const accessMoment = management.eventdates.$factory(context.accessMoment.model.$raw);
const linkedTickets = ref<Pagination<Ticket<EventDate<ManagementClient>>>>();
const linkModalRef = useTemplateRef('linkModalRef');
const { isReadOnly } = useRoles();

void loadTickets();

async function loadTickets() {
    linkedTickets.value = accessMoment.tickets.list({ deferInitialization: true });
    await linkedTickets.value?.initialization;
}

async function unlinkTicket(id: string) {
    if (!context.isAccessMomentContext()) {
        // TODO Properly log error & localise reason.
        throw Error('Invalid context');
    }

    const confirm = await dialog.confirm({
        title: t('dashboard.common.confirm.permanent.title') || '',
        message: t('dashboard.common.confirm.permanent.description') || '',
        variant: 'destructive',
    });

    if (!confirm) {
        return;
    }

    try {
        // Unlink ticket
        const unlinkTicketModel = accessMoment.tickets.$factory({
            ...context.event.model.tickets.new().$data,
            guid: id,
        });
        await accessMoment.tickets.unlink(unlinkTicketModel);

        // Refresh list
        void loadTickets();
    } catch (e) {
        handleError(e);
    }
}
</script>

<style scoped lang="scss">
.access-moments-update__delete {
    &.ot-ui-button {
        color: var(--ot-ui-color-foreground-secondary);

        &:hover {
            color: var(--ot-ui-color-intent-error-primary);
        }
    }
}
</style>
