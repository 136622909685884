<template>
    <div class="company-general">
        <div class="company-general__header">
            <h3 class="ot-ui-text-heading-3">
                {{ $t('dashboard.company.edit.general.page_title') }}
            </h3>
            <p class="company-general__header-description ot-ui-text-body-md">
                {{ $t('dashboard.company.edit.general.page_description') }}
            </p>
        </div>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.company.edit.general.form.name.label')"
                :description="$t('dashboard.company.edit.general.form.name.description')"
                :optional="form.rules.name && !form.rules.name.includes('required')"
                :error="form.errors.name"
            >
                <OtTextInput
                    v-model="form.model.$data.name"
                    :invalid="form.errors.name?.length > 0"
                    class="input"
                    data-testid="company-general-form-name"
                    @input="form.errors.name = []"
                />
            </OtInputField>

            <OtInputField
                :label="$t('dashboard.company.edit.general.form.website.label')"
                :description="$t('dashboard.company.edit.general.form.website.description')"
                :optional="form.rules.website && !form.rules.website.includes('required')"
                :error="form.errors.website"
            >
                <OtTextInput
                    v-model="form.model.$data.website"
                    :invalid="form.errors.website?.length > 0"
                    data-testid="company-general-form-website"
                    @input="form.errors.website = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.company.edit.general.form.currency.label')"
                :description="$t('dashboard.company.edit.general.form.currency.description')"
                :optional="form.rules.currency && !form.rules.currency.includes('required')"
                :error="form.errors.currency"
            >
                <OtSelectInput
                    v-model="form.model.$data.currency"
                    :internal-search="true"
                    :invalid="form.errors.currency?.length > 0"
                    :options="currencies"
                    :searchable="true"
                    data-testid="company-general-form-currency"
                    sort="alphabetically"
                    @input="form.errors.currency = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.company.edit.general.form.locale.label')"
                :description="$t('dashboard.company.edit.general.form.locale.description')"
                :optional="form.rules.locale && !form.rules.locale.includes('required')"
                :error="form.errors.locale"
            >
                <OtSelectInput
                    v-model="form.model.$data.locale"
                    :internal-search="true"
                    :invalid="form.errors.locale?.length > 0"
                    :options="locales"
                    :searchable="true"
                    data-testid="company-general-form-locale"
                    sort="alphabetically"
                    @input="form.errors.locale = []"
                />
            </OtInputField>

            <OtInputField
                :label="$t('dashboard.company.edit.general.form.country.label')"
                :description="$t('dashboard.company.edit.general.form.country.description')"
                :optional="form.rules.country && !form.rules.country.includes('required')"
                :error="form.errors.country"
            >
                <OtSelectInput
                    v-model="form.model.$data.country"
                    :internal-search="true"
                    :invalid="form.errors.country?.length > 0"
                    :options="countries"
                    :searchable="true"
                    data-testid="company-general-form-country"
                    sort="alphabetically"
                    @input="form.errors.country = []"
                />
            </OtInputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue';
import { computed } from 'vue';
import type { AuthClient, Company } from '@openticket/lib-auth';
import { useLocalization } from '@openticket/vue-ui';
import type { ModelFormComposableResult } from '../../../../composables/forms';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Company<AuthClient>, Company<AuthClient>>>
}

defineProps<Props>();

const { localization } = useLocalization();

const countries = computed<Record<string, string>>(() => {
    const map: Record<string, string> = {};
    const { supportedCountries } = localization;

    if (supportedCountries) {
        for (const key of Object.keys(supportedCountries)) {
            map[key] = `${supportedCountries[key].flag} ${supportedCountries[key].territoryDisplayName}`;
        }
    }

    return map;
});

const currencies = computed<Record<string, string>>(() => {
    const map: Record<string, string> = {};
    const { supportedCurrencies } = localization;

    if (supportedCurrencies) {
        for (const key of Object.keys(supportedCurrencies)) {
            map[key] = `${supportedCurrencies[key].currencySymbol} - ${supportedCurrencies[key].currencyDisplayName}`;
        }
    }

    return map;
});

const locales = computed<Record<string, string>>(() => {
    const map: Record<string, string> = {};
    const { supportedLocales } = localization;

    if (supportedLocales) {
        for (const key of Object.keys(supportedLocales)) {
            map[key] = `${supportedLocales[key].flag} ${supportedLocales[key].languageDisplayName}`;
        }
    }

    return map;
});
</script>

<style lang="scss" scoped>
.company-general {
    &__header {
        margin-bottom: var(--ot-ui-spacing-md);
        &-description {
            color: var(--ot-ui-color-foreground-secondary)
        }
    }
}
</style>
