import type { RouteRecordRaw } from 'vue-router';
import AppLibraryView from './views/AppLibraryView.vue';

export function appLibraryRoutes(): RouteRecordRaw[] {
    return [
        {
            path: '/app-library',
            redirect: (to) => ({
                name: 'home',
                params: { ...to.params },
            }),
            children: [
                {
                    path: ':company',
                    name: 'app-library',
                    component: AppLibraryView,
                    meta: {
                        context: 'company',
                        title: 'dashboard.document_title.app_library.title',
                        titleFallback: 'App Library',
                        onContextSelect(id) {
                            if (!id || typeof id === 'symbol') {
                                return {
                                    name: 'companies.list',
                                };
                            }
                            return {
                                params: { company: id },
                            };
                        },
                    },
                },
            ],
        },
    ];
}
