import urljoin from 'url-join';
import type {
    Model,
    ModelConfig,
    Parent,
    RulesMap,
    ToMany,
} from '@openticket/lib-crud';
import {
    ModelConstructorError,
    UnexpectedResponseFactoryMixinError,
} from '@openticket/lib-crud';
import type { AxiosResponse } from 'axios';

export async function CopyParts<
    M extends Model<Ancestor, C, R>,
    C extends ModelConfig,
    Ancestor extends Parent,
    R extends RulesMap = {},
    PivotConfig extends ModelConfig = {},
>(this: ToMany<M, C, Ancestor, R, PivotConfig>, copyFrom: string, copyTo: string, copyAllParts: boolean): Promise<M> {
    let path = urljoin(this.$path, encodeURIComponent(copyFrom), 'copy', encodeURIComponent(copyTo));

    if (copyAllParts) {
        path = urljoin(path, 'all');
    }

    const response: AxiosResponse<M['$raw']> = await this.$http.post(path);

    try {
        return this.$factory(response.data);
    } catch (e) {
        if (e instanceof ModelConstructorError) {
            throw new UnexpectedResponseFactoryMixinError(CopyParts.mixinName, path, e);
        }

        throw e;
    }
}

CopyParts.mixinName = 'CUSTOM-COPY-PARTS' as const;
CopyParts.methodName = 'copyParts' as const;
