<template>
    <div
        class="event-header ot-ui-pb[lg] ot-ui-mb[lg]"
        data-testid="event-header"
    >
        <div class="event-header__left">
            <div class="event-header__left__top">
                <h1 class="event-header__left__top__name ot-ui-text-heading-1">
                    {{ event.$data.name }}
                </h1>

                <div class="event-header__left__top__flairs">
                    <OtFlair
                        v-if="event.$data.retrievable_after"
                        icon="lock"
                        variant="dark"
                        :text="$t('dashboard.events.header.flairs.sealed')"
                    />
                </div>
            </div>
            <div class="event-header__left__bottom ot-ui-text-body-md">
                <div
                    v-if="event.$data.start && event.$data.end"
                    class="event-header__left__bottom-date"
                >
                    {{ $l.dateTimeRangeCollapseLong(event.$data.start, event.$data.end) }}
                </div>
                <div
                    class="event-header__left__bottom__location"
                    :class="{'event-header__left__bottom__location-error': error}"
                >
                    <OtIcon
                        type="location"
                        size="small"
                    />
                    <span>
                        {{ locationInfo }}
                    </span>
                </div>
            </div>
        </div>
        <div class="event-header__right">
            <RevisionModal
                :id="event.id"
                ref="revisionsModalRef"
                type="event"
                :model-name="event.$data.name"
            />

            <OtButton
                icon-right="carrot-right"
                size="small"
                variant="outline"
                @click="goToEventsEdit"
            >
                {{ $t('dashboard.events.header.edit_event') }}
            </OtButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Event, Location, ManagementClient } from '@openticket/lib-management';
import {
    computed, ref, useTemplateRef, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import { useLocalization } from '@openticket/vue-ui';
import { useGenericErrorHandling } from '../composables';
import RevisionModal from './revisions/RevisionModal.vue';

type Props = {
    event: Event<ManagementClient>;
}

const props = defineProps<Props>();

const { t } = useLocalization();
const router = useRouter();

const { error, handleErrorSilently } = useGenericErrorHandling();

const location = ref<Location<Event<ManagementClient>> | null>();
const revisionsModalRef = useTemplateRef('revisionsModalRef');

const locationInfo = computed<string>(() => {
    if (error.value) {
        return t('dashboard.events.header.location.error.failed');
    }

    if (location.value) {
        let str = location.value.$data.name;

        if (location.value.$data.address) {
            str += ` (${location.value.$data.address})`;
        }

        return str;
    }

    return '';
});

watch(() => props.event, async () => {
    await setLocation();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await revisionsModalRef.value?.updateRevisions();
});

void (async () => {
    await setLocation();
})();

async function setLocation() {
    try {
        error.value = null;
        location.value = await props.event.location.first();
    } catch (e) {
        location.value = null;
        void handleErrorSilently(e);
    }
}

function goToEventsEdit(): void {
    if (props.event.id) {
        void router.push({
            name: 'events.edit',
            params: { event: props.event.id },
        });
    }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/mixins.scss';

.event-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--ot-ui-color-accent-tertiary);

    @include breakpoint(mob) {
        flex-direction: column;
        gap: var(--ot-ui-spacing-md);
    }

    &__left {
        display: flex;
        flex-direction: column;
        gap: var(--ot-ui-spacing-xs);

        &__top {
            display: flex;
            align-items: center;
            gap: var(--ot-ui-spacing-sm);

            &__flairs {
                text-transform: uppercase;
            }
        }

        &__bottom {
            display: flex;
            gap: var(--ot-ui-spacing-md);
            align-items: center;

            &::before {
                content: '';
                border: 1px solid var(--ot-ui-color-accent-tertiary);
                align-self: stretch;
            }

            &-date {
                order: -1;
            }

            &__location {
                display: flex;
                align-items: center;
                gap: var(--ot-ui-spacing-2xs);

                &-error {
                    color: var(--ot-ui-color-accent-primary);
                }
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;
        gap: var(--ot-ui-spacing-lg);

        @media (max-width: 40rem) {
            justify-content: space-between;
            width: 100%;
        }

        &-daysago {
            color: var(--ot-ui-color-accent-primary);
            white-space: nowrap;
        }

        button {
            white-space: nowrap;
        }
    }
}
</style>
