import { ClipboardUnavailableError } from './errors';

export function useClipboard() {
    let clipboard: Clipboard;

    try {
        clipboard = navigator.clipboard;
    } catch (e) {
        if (e instanceof Error) {
            throw new ClipboardUnavailableError(e);
        }

        throw e;
    }

    if (!clipboard) {
        throw new ClipboardUnavailableError();
    }

    return { clipboard };
}
