import { OtError } from '@openticket/lib-log';

export class ContextWaitingListError extends OtError {

    constructor() {
        super('ContextWaitingListError', 'dashboard.errors.plugins.context.waiting_list', 'Failed to set waiting list context.');

        this.captureStackTrace(ContextWaitingListError);
    }

}
