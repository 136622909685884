import urljoin from 'url-join';
import type {
    Model,
    ModelConfig,
    Parent,
    RulesMap,
    ToMany,
} from '@openticket/lib-crud';
import type { AxiosResponse } from 'axios';

export async function RenderExample<
    M extends Model<Ancestor, C, R>,
    C extends ModelConfig,
    Ancestor extends Parent,
    R extends RulesMap = {},
    PivotConfig extends ModelConfig = {},
>(this: ToMany<M, C, Ancestor, R, PivotConfig>): Promise<string> {
    const path = urljoin(this.$path, 'example');
    const response: AxiosResponse<string> = await this.$http.get(path, { responseType: 'arraybuffer' });

    return Buffer.from(response.data, 'binary').toString('base64');
}

RenderExample.mixinName = 'CUSTOM-RENDER-EXAMPLE' as const;
RenderExample.methodName = 'renderExample' as const;
