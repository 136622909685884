<template>
    <ActionTile
        :description="$t('dashboard.company.edit.actions.render_receipts.description')"
        :title="$t('dashboard.company.edit.actions.render_receipts.title')"
        class="actions-render-receipts"
        data-testid="actions-render-receipts"
    >
        <OtInputField
            :optional="companyForm.rules.name && !companyForm.rules.name.includes('required')"
            class="actions-render-receipts__toggle"
        >
            <OtToggleInput
                v-model="companyForm.model.$data.invoice_to_visitors"
                :disabled="isDisabled"
                :label="$t('dashboard.company.edit.actions.render_receipts.toggle_label')"
                data-testid="actions-render-receipts-invoice-to-visitors"
            />
        </OtInputField>
        <div
            v-if="isDisabled"
            class="actions-render-receipts__warning"
            data-testid="actions-render-receipts-warning"
        >
            <!-- TODO: replace by warnings from new design system -->
            <div class="actions-render-receipts__warning__icon">
                <OtIcon type="warning" />
            </div>
            <div class="actions-render-receipts__warning__content">
                <div class="actions-render-receipts__warning__content__title">
                    {{ $t('dashboard.company.edit.actions.render_receipts.warning.title') }}
                </div>
                <div
                    class="actions-render-receipts__warning__content__description"
                >
                    <i18n-t
                        tag="span"
                        keypath="dashboard.company.edit.actions.render_receipts.warning.description"
                    >
                        <template #link>
                            <router-link
                                v-if="context.company"
                                :to="{
                                    name: 'companies.edit.registration',
                                    params: { company: context.company.id }
                                }"
                            >
                                <span>{{ $t('dashboard.company.edit.actions.render_receipts.warning.description_link') }}</span>
                            </router-link>
                        </template>
                    </i18n-t>
                </div>
            </div>
        </div>
        <div class="actions-render-receipts__actions">
            <OtButton
                :disabled="isDisabled || !(companyForm.hasLocalChanges || companyForm.model.$data.invoice_to_visitors)"
                class="actions-render-receipts__save-preferences"
                data-testid="actions-render-receipts-save"
                size="small"
                variant="outline"
                @click="props.companyForm.submit()"
            >
                {{ $t('dashboard.company.edit.actions.render_receipts.save_preferences_label') }}
            </OtButton>
            <OtButton
                v-if="modalRef"
                :disabled="!companyForm.model.$data.invoice_to_visitors || isDisabled"
                data-testid="actions-render-receipts-save-and-render"
                size="small"
                @click="saveAndOpenRerenderModal"
            >
                {{ $t('dashboard.company.edit.actions.render_receipts.save_rerender_label') }}
            </OtButton>
        </div>
        <ConfirmWithYesModal
            ref="modalRef"
            :title="$t('dashboard.company.edit.actions.render_receipts.confirm.title')"
            :confirm-label="$t('dashboard.company.edit.actions.render_receipts.confirm.action_label')"
            @confirm="rerender"
        >
            <template #description>
                <i18n-t
                    tag="span"
                    keypath="dashboard.company.edit.actions.render_receipts.confirm.stats_description"
                >
                    <template #count>
                        <span
                            v-if="nrOfReceipts !== undefined"
                            class="confirm-with-yes-modal__highlighted"
                            data-testid="confirm-with-yes-modal-count-render-receipts"
                        >
                            {{ $l.number(nrOfReceipts) }}
                        </span>
                    </template>
                    <template #company>
                        <span class="confirm-with-yes-modal__highlighted">{{ companyName }}</span>
                    </template>
                </i18n-t>
            </template>
        </ConfirmWithYesModal>
    </ActionTile>
</template>

<script setup lang="ts">
import {
    computed, ref, type UnwrapNestedRefs, useTemplateRef,
} from 'vue';
import type { AuthClient, Company } from '@openticket/lib-auth';
import { useLocalization, useNotifications } from '@openticket/vue-ui';
import { type ModelFormComposableResult } from '../../../../composables/forms';
import { injectOrFail } from '../../../../services/util';
import type { Context } from '../../../../services/context';
import ActionTile from './ActionTile.vue';
import ConfirmWithYesModal from './modals/ConfirmWithYesModal.vue';
import type { AdminActions } from '../../../../services/admin-actions/adminActions.service';
import { useGenericErrorHandling } from '../../../../composables';

type Props = {
    companyForm: UnwrapNestedRefs<ModelFormComposableResult<Company<AuthClient>, Company<AuthClient>>>
}
const props = defineProps<Props>();

const { handleError } = useGenericErrorHandling();
const { t } = useLocalization();

const modalRef = useTemplateRef('modalRef');
const context = injectOrFail<Context>('context');
const adminActions = injectOrFail<AdminActions>('adminActions');
const notifications = useNotifications();

const nrOfReceipts = ref<number | undefined>();

const companyName = computed<string>(() => context.company?.name || '');
// TODO: when translation slugs of fields become more generic, rules can be used to fetch the required fields.
const isDisabled = computed<boolean>(() => !props.companyForm.model.$data.vat_number);

async function rerender() {
    if (!context.isCompanyContext()) {
        return;
    }

    try {
        await adminActions.rerenderReceipts(context.company.id);
        notifications.success(t('dashboard.company.edit.actions.render_receipts.success'));
    } catch (e: unknown) {
        handleError(e);
    }
}

async function saveAndOpenRerenderModal() {
    if (!context.isCompanyContext()) {
        return;
    }

    await props.companyForm.submit();
    nrOfReceipts.value = undefined;

    try {
        nrOfReceipts.value = await adminActions.countRerenderReceipts(context.company.id);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalRef.value?.open();
    } catch (e: unknown) {
        handleError(e);
    }
}

</script>

<style lang="scss" scoped>
.actions-render-receipts {
    &__actions {
        gap: var(--ot-ui-spacing-sm);
        display: flex;
        flex-wrap: wrap;
    }

    &__save-preferences {
        margin-right: var(--ot-ui-spacing-sm);
    }

    &__warning {
        display: flex;
        padding: var(--ot-ui-spacing-lg) var(--ot-ui-spacing-xl);
        background-color: var(--ot-ui-color-intent-error-faded);
        border-radius: var(--ot-ui-radius-lg);

        &__icon {
            color: var(--ot-ui-color-intent-error-primary);
            padding-right: var(--ot-ui-spacing-md);
        }

        &__content {
            &__title {
                font-weight: var(--ot-ui-font-weight-medium);
            }

            &__description {
                :deep(a) {
                    font-weight: normal;
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>
