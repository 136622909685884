<template>
    <div class="profile-summary">
        <OtAvatar
            :name="userName"
            size="48"
        />

        <div class="ot-ui-ml[md]">
            <h5 class="ot-ui-text-heading-5 ot-ui-mb[xs]">
                {{ userName }}
            </h5>

            <OtButton
                icon="login"
                size="small"
                variant="inline"
                class="profile-summary__link"
                @on-click="openProfileSettings"
            >
                {{ $t('dashboard.header.profile.user.settings.text') }}
            </OtButton>

            <a
                v-if="!!classicDashboardRoute"
                :href="classicDashboardRoute"
            >
                <OtButton
                    class="profile-summary__link"
                    icon="carrot-left"
                    size="small"
                    variant="inline"
                >
                    {{ $t('dashboard.classic.back_to_classic_dashboard_profile.title') }}
                </OtButton>
            </a>

            <OtButton
                class="profile-summary__link"
                icon="logout"
                size="small"
                variant="inline"
                @on-click="logout"
            >
                {{ $t('dashboard.header.profile.logout.text') }}
            </OtButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { AuthClient } from '@openticket/lib-auth';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useConfirmationModal, useLocalization } from '@openticket/vue-ui';
import { useClassicDashboardRouting } from '../../../../composables';
import { injectOrFail } from '../../../../services/util';

type Emits = {
    (e: 'navigate'): void,
}
const emit = defineEmits<Emits>();

const { goBackToClassicRoute } = useClassicDashboardRouting();
const { confirm } = useConfirmationModal();
const { t } = useLocalization();
const router = useRouter();

const auth = injectOrFail<AuthClient>('auth');

const userName = ref<string | undefined>();

void (async () => {
    const tokenInfo = await auth.$token.$info;
    userName.value = tokenInfo ? tokenInfo.user.name : '?';
})();

const classicDashboardRoute = goBackToClassicRoute.value;

async function openProfileSettings(): Promise<void> {
    if (router.currentRoute.value.name !== 'profile.settings') {
        await router.push({ name: 'profile.settings' });
    }

    emit('navigate');
}

async function logout(): Promise<void> {
    if (await confirm({
        title: t('dashboard.header.profile.logout.confirmation.title'),
        message: t('dashboard.header.profile.logout.confirmation.description'),
        variant: 'destructive',
    })) {
        void router.push({ name: 'auth.logout' });
    }
}
</script>

<style lang="scss" scoped>
.profile-summary {
    display: flex;
    align-items: flex-start;

    &__link {
        color: var(--ot-ui-color-intent-invert-primary) !important;

        &:hover {
            color: var(--ot-ui-color-brand) !important;
        }
    }
}
</style>
