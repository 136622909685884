import type { App } from 'vue';
import type { AuthClient } from '@openticket/lib-auth';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { Plugin } from './plugin';
import type { PluginsManager } from './manager';
import { WaitingListClient } from '../services/waiting-list/client/waitingListClient';
import type { UploaderOptions } from './types';

export class WaitingListPlugin extends Plugin<WaitingListClient> {

    async install(plugins: PluginsManager, app: App): Promise<void> {
        const options: UploaderOptions = plugins.options.waitingList || {};

        const whitelabel: Whitelabel = await plugins.whitelabel.loading;

        const baseUrl: string = options.url || whitelabel.dashboard.waiting_list_url || 'https://waiting-list.openticket.local/';

        const auth: AuthClient = await plugins.auth.loading;

        const waitingListClient = new WaitingListClient({ baseUrl, http: auth.$http });

        app.provide('waitingListClient', waitingListClient);

        this.resolve(waitingListClient);
    }

}
