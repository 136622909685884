<template>
    <div
        v-if="tickets && !!tickets.total && !!tickets.records.length"
        class="module__access-moments__unlinked-tickets__list"
    >
        <OtDataGrid
            class="module__access-moments__unlinked-tickets__list__grid"
            type="table"
            :pagination="tickets"
            :columns="columns"
            hide-header
            :empty-message-active-filter="$t('dashboard.common.filters.not_found')"
            :loading-message="$t('common.loading')"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="tickets"
                    :title="$t('dashboard.access_moments.unlinked_tickets.list.header.title')"
                />
            </template>

            <template #column__$data__name="{ value }">
                <span class="module__access-moments__unlinked-tickets__list__record ot-ui-stack[md]">
                    <OtIcon type="ticket-alt" />
                    <h4 class="ot-ui-heading-4">{{ value }}</h4>
                </span>
            </template>

            <template #actions="{record}">
                <OtButton
                    data-testid="access-moment-unlinked-tickets-list-link-ticket-button"
                    size="small"
                    variant="outline"
                    icon="plus"
                    @click="unlinkedTicketsAccessMomentLinkModalRef?.show(record)"
                >
                    {{ $t('dashboard.access_moments.unlinked_tickets.modal.link.text') }}
                </OtButton>
            </template>
        </OtDataGrid>
        <!-- TODO: replace with generic LinkUnlinkModal -->
        <UnlinkedTicketsAccessMomentLinkModal
            ref="unlinkedTicketsAccessMomentLinkModalRef"
            :access-moments-list="accessMomentsList"
            @submit="$emit('reload')"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, useTemplateRef } from 'vue';
import type {
    Event, EventDate, ManagementClient, Ticket,
} from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import type { OtDataGridColumn } from '@openticket/vue-ui';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';
import UnlinkedTicketsAccessMomentLinkModal from './UnlinkedTicketsAccessMomentLinkModal.vue';

type Props = {
    accessMomentsList: Pagination<EventDate<Event<ManagementClient>>>;
}

type Emits = {
    (e: 'reload'): void;
}

defineProps<Props>();
defineEmits<Emits>();

const context = injectOrFail<Context>('context');

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const columns: OtDataGridColumn[] = [
    {
        key: [ '$data', 'name' ],
        label: 'Name',
        type: 'string',
    },
];

const unlinkedTicketsAccessMomentLinkModalRef = useTemplateRef('unlinkedTicketsAccessMomentLinkModalRef');

const tickets = ref<Pagination<Ticket<Event<ManagementClient>>> | null>(null);

void (async () => {
    tickets.value = context.event.model.tickets.list({
        defaultFilters: { unlinked_event_dates: { scope: '' } },
        deferInitialization: true,
        perPage: 10,
    });

    await tickets.value.initialization;
})();

async function reload(): Promise<void> {
    await tickets.value?.loadPage();
}

defineExpose({
    reload,
});
</script>

<style scoped lang="scss">
.module__access-moments__unlinked-tickets__list__record {
    svg {
        color: var(--ot-ui-color-accent-primary);
    }
}
</style>
