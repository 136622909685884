<template>
    <!-- TODO: Move this component to VueUI -->
    <div class="form-row">
        <slot />
    </div>
</template>

<style lang="scss" scoped>
.form-row {
    display: flex;
    margin: 0 calc(-1 * var(--ot-ui-spacing-xs));
    margin-bottom: var(--ot-ui-spacing-lg);
    align-items: flex-start;

    & > * {
        flex: 1;
        margin: 0 var(--ot-ui-spacing-xs);
    }

    &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 40rem) {
        display: block;
        margin-bottom: 0;

        & > * {
            margin-bottom: var(--ot-ui-spacing-md);
        }
    }
}
</style>
