<template>
    <div class="color-conformation-levels">
        <div
            class="color-conformation-levels__summary"
            role="switch"
            tabindex="0"
            :aria-checked="showDetails"
            :aria-expanded="showDetails"
            @click="showDetails = !showDetails"
            @keydown.space="showDetails = !showDetails"
            @keydown.enter="showDetails = !showDetails"
        >
            <div class="color-conformation-levels__summary__line">
                <div
                    v-if="comparisonSummary"
                    class="color-conformation-levels__summary__line__contrast"
                    :style="{
                        width: `${100*comparisonSummary.minContrast/21}%`,
                        background: summaryRGB,
                    }"
                />
            </div>

            <div class="color-conformation-levels__summary__info ot-ui-text-body-sm-strong">
                {{ $t(comparisonSummaryInfo) }}
            </div>

            <OtIcon
                :type="comparisonSummaryIcon"
                size="small"
            />
        </div>

        <!-- TODO: Replace with OtCollapse when that component supports `v-show` instead of `v-if` -->
        <transition
            name="accordion"
            @enter="startTransition"
            @after-enter="endTransition"
            @before-leave="startTransition"
            @after-leave="endTransition"
        >
            <div
                v-show="showDetails"
                class="color-conformation-levels__details"
            >
                <div class="color-conformation-levels__details__header">
                    <div class="color-conformation-levels__details__header__description ot-ui-text-body-sm">
                        <div>{{ $t('dashboard.components.color_conformation_levels.details.description') }}</div>
                        <br>
                        <div
                            v-for="(description, level) in {
                                'aaa': 'dashboard.components.color_conformation_levels.details.levels.aaa',
                                'aa': 'dashboard.components.color_conformation_levels.details.levels.aa',
                                'a': 'dashboard.components.color_conformation_levels.details.levels.a',
                                '-': 'dashboard.components.color_conformation_levels.details.levels.-',
                            }"
                            :key="level"
                            class="color-conformation-levels__details__header__description__explained"
                        >
                            <div class="color-conformation-levels__details__header__description__explained__level">
                                {{ level }}
                            </div>

                            <div>{{ $t(description) }}</div>
                        </div>
                        <br>
                        <i18n-t keypath="dashboard.components.color_conformation_levels.details.learn_more.text">
                            <template #url>
                                <OtLink
                                    :href="$t('dashboard.components.color_conformation_levels.details.learn_more.url')"
                                    :label="$t('dashboard.components.color_conformation_levels.details.learn_more.url_text')"
                                    size="tiny"
                                />
                            </template>
                        </i18n-t>
                    </div>

                    <OtButton
                        icon="close"
                        variant="inline"
                        @click="showDetails = false"
                    />
                </div>

                <slot />
            </div>
        </transition>

        <OtAside
            v-if="showContrastWarning"
            type="warning"
            icon="warning"
            :title="$t('dashboard.components.color_conformation_levels.warning.contrast')"
        />
    </div>
</template>

<script setup lang="ts">
import { Color, type WCAGConformanceLevel } from '@peterdekok/color-compare';
import {
    computed, provide, reactive, ref, watch,
} from 'vue';
import type { OtIconTypes } from '@openticket/vue-ui';
import type { Comparison } from './types';
import { convertCssHslaToRgb } from './hslToRgb';

type Props = {
    target: string | null,
}

const props = withDefaults(defineProps<Props>(), { target: null });

const comparisons = reactive<{ [key: string]: Comparison }>({});

const comparisonSummary = ref<{ level: WCAGConformanceLevel; minContrast: number } | null>(null);
const showDetails = ref<boolean>(false);

const comparisonSummaryInfo = computed<string>(() => {
    if (!comparisonSummary.value) {
        return 'dashboard.components.color_conformation_levels.summary.info.undetermined';
    }

    switch (comparisonSummary.value.level) {
        case 'aaa':
            return 'dashboard.components.color_conformation_levels.summary.info.strong';
        case 'aa':
            return 'dashboard.components.color_conformation_levels.summary.info.good';
        case 'a':
            return 'dashboard.components.color_conformation_levels.summary.info.poor';
        default:
            return 'dashboard.components.color_conformation_levels.summary.info.weak';
    }
});

const comparisonSummaryIcon = computed<OtIconTypes>(() => {
    if (comparisonSummary.value) {
        return showDetails.value ? 'info-active' : 'info';
    }

    return showDetails.value ? 'warning-active' : 'warning';
});

const showContrastWarning = computed<boolean>(() => !!comparisonSummary.value && !comparisonSummary.value.level);

const targetColor = computed<Color | null>(() => {
    if (!props.target) {
        return null;
    }

    try {
        return new Color(convertCssHslaToRgb(props.target));
    } catch (e) {
        return null;
    }
});

const summaryRGB = computed<string>(() => {
    if (!comparisonSummary.value) {
        return 'var(--ot-ui-color-accent-primary)';
    }

    return contrastRatioToRGBRating(comparisonSummary.value.minContrast, 2.5, 4, 7);
});

watch(() => props.target, () => {
    for (const key in comparisons) {
        comparisons[key].target = targetColor.value;

        const { related } = comparisons[key];

        if (targetColor.value && related) {
            comparisons[key].result = targetColor.value.compare(related);
        } else {
            comparisons[key].result = null;
        }
    }

    updateComparisonSummary();
});

provide('color-comparison-registration', comparisonRegistration);

function contrastRatioToRGBRating(ratio: number, lower: number, middle: number, upper: number) {
    const red = (1 - ((ratio - middle) / (upper - middle))) * 255;
    const green = 255 * ((ratio - lower) / (middle - lower));

    return `rgb(${red}, ${green}, 0)`;
}

function updateComparisonSummary(): void {
    if (!Object.keys(comparisons).length) {
        comparisonSummary.value = null;

        return;
    }

    comparisonSummary.value = Object.values(comparisons)
        .reduce((carry: { level: WCAGConformanceLevel, minContrast: number } | null, comparison: Comparison) => {
            if (!carry || !comparison.result) {
                return null;
            }

            if (comparison.result.rawPerceivableValues.contrast < carry.minContrast) {
                return {
                    level: comparison.result.wcagConformance,
                    minContrast: comparison.result.rawPerceivableValues.contrast,
                };
            }

            return carry;
        }, {
            level: 'aaa',
            minContrast: 21,
        });
}

function comparisonRegistration(key: string, related: Color | null): Comparison {
    if (!comparisons[key]) {
        comparisons[key] = {
            key,
            target: targetColor.value,
            related: null,
            result: null,
            deregister: () => deregister(key),
        };
    }

    comparisons[key].related = related;

    if (targetColor.value && related) {
        comparisons[key].result = targetColor.value.compare(related);
    } else {
        comparisons[key].result = null;
    }

    updateComparisonSummary();

    return comparisons[key];
}

function deregister(key: string): void {
    delete comparisons[key];
    updateComparisonSummary();
}

function startTransition(el: HTMLElement): void {
    el.style.height = `${el.scrollHeight}px`;
}

function endTransition(el: HTMLElement): void {
    el.style.height = '';
}
</script>

<style lang="scss" scoped>
.color-conformation-levels {
    &__summary {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--ot-ui-color-foreground-secondary);

        &:not(:last-child) {
            margin-bottom: var(--ot-ui-spacing-md);
        }

        &__line {
            flex-grow: 1;

            height: .375rem;

            border-radius: .375rem;
            overflow: hidden;
            background: var(--ot-ui-color-background-secondary);

            &__contrast {
                width: 0;
                height: 100%;
                border-radius: .375rem;
            }
        }

        &__info {
            padding: 0 .375rem;
            flex-grow: 0;
        }
    }

    &__details {
        padding: var(--ot-ui-spacing-sm);
        background: var(--ot-ui-color-accent-tertiary);
        color: var(--ot-ui-color-foreground-secondary);
        border-radius: var(--ot-ui-radius-lg);

        &:not(:last-child) {
            margin-bottom: var(--ot-ui-spacing-md);
        }

        &__header {
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: var(--ot-ui-spacing-lg);
            }

            &__description {
                flex-grow: 1;

                &__explained {
                    display: flex;

                    & > * {
                        display: inline-block;
                    }

                    &__level {
                        flex-shrink: 0;
                        width: 3em;
                        text-transform: uppercase;
                    }
                }
            }
        }

        &.accordion-enter-active,
        &.accordion-leave-active {
            will-change: height, opacity;
            transition:
                height var(--ot-ui-transition-default-duration) var(--ot-ui-transition-default-timing-function),
                opacity var(--ot-ui-transition-default-duration) var(--ot-ui-transition-default-timing-function);
            overflow: hidden;
        }

        &.accordion-enter-from,
        &.accordion-leave-to {
            height: 0 !important;
            opacity: 0;
        }
    }
}
</style>
