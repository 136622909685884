<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <OtSpinner v-else-if="!shops" />

    <div
        v-else
        class="modules__shops__list"
    >
        <OtDataGrid
            class="module__shops__list__grid"
            type="tiles"
            :pagination="shops"
            :columns="columns"
            show-switcher
            show-controls="always"
            :empty-labels="{
                subtitle: $t('dashboard.shops.list.empty.message'),
                title: $t('dashboard.shops.list.empty.title'),
            }"
            :loading-text="$t('dashboard.shops.list.header.loading')"
        >
            <template #list-header="{ typeSwitcher }">
                <OtListHeader
                    :pagination="shops"
                    searchable
                    :search-key="'name'"
                    :search-placeholder="$t('dashboard.shops.list.header.search.placeholder')"
                    :title="$t('dashboard.document_title.shops.list')"
                >
                    <template #filters>
                        <ShopsFilters :shops="shops" />
                    </template>

                    <template #actions-top>
                        <OtButton
                            :title="$t('dashboard.shops.list.new.title')"
                            :disabled="isReadOnly"
                            data-testid="shop-list-new-button"
                            icon="plus"
                            @onClick="companySelector?.open()"
                        >
                            {{ $t('dashboard.shops.list.new.text') }}
                        </OtButton>
                    </template>

                    <template #actions-bottom>
                        <component :is="typeSwitcher" />
                    </template>
                </OtListHeader>
            </template>

            <template #column__$data__company_id="{ record, value }">
                <OtButton
                    class="module__shops__list-record__company-name"
                    variant="ghost"
                    @onClick="goToCompanyContext(record.$data.company_id)"
                >
                    {{ companyName(value) }}
                </OtButton>
            </template>

            <template #tile="{ record }">
                <ShopsListRecord
                    :record="record"
                    @context-switch="goToCompanyContext"
                />
            </template>

            <template #column__url="{ record }">
                <ShopUrl :record="record" />
            </template>

            <template #actions="{ record }">
                <OtTileActions
                    :buttons="
                        [{
                            title: $t('dashboard.shops.list.edit.title', { shop: record.$data.name }),
                            text: $t('dashboard.shops.list.edit.text', { shop: record.$data.name }),
                            iconRight: 'carrot-right',
                            size: 'small',
                            variant: 'outline',
                            clickHandler: () => goToShopPage(record.$data.guid),
                        }]"
                />
            </template>
        </OtDataGrid>

        <!--<div class="shop-list__content" v-if="shops && !error">-->
        <!--    <div class="shop-list__filters">-->
        <!--        <div class="shop-list__filters__search">-->
        <!--            <ot-input-field :label="$t('dashboard.shop.search.label')">-->
        <!--                <ot-input-->
        <!--                    type="search"-->
        <!--                    :placeholder="$t('dashboard.shop.search.placeholder')"-->
        <!--                    v-model="searchString"-->
        <!--                ></ot-input>-->
        <!--            </ot-input-field>-->
        <!--        </div>-->
        <!--    </div>-->

        <CompanySelector
            ref="companySelector"
            :create-model-label="$t('dashboard.shops.model_name').toString().toLocaleLowerCase()"
            data-testid="shop-list-company-selector"
            :update-context-on-select="false"
            @selected="openCreateShopModal"
        />

        <ShopCreateModal
            v-if="formModal.isOpen && shopCreateCompanyGuid"
            :company-guid="shopCreateCompanyGuid"
            :modal="formModal"
            @saved="onNewShopSaved"
        />
    </div>
</template>

<script lang="ts" setup>
import type { ManagementClient } from '@openticket/lib-management';
import {
    reactive, ref, useTemplateRef, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import type { AuthClient, CompanyRaw } from '@openticket/lib-auth';
import { type OtDataGridColumn, useLocalization } from '@openticket/vue-ui';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';
import ErrorView from '../../../components/ErrorView.vue';
import ShopsListRecord from '../components/ShopsListRecord.vue';
import ShopUrl from '../components/ShopUrl.vue';
import { useGenericErrorHandling, useRoles, useRouterPagination } from '../../../composables';
import { useFormModal } from '../../../composables/forms';
import ShopCreateModal from '../components/ShopCreateModal.vue';
import CompanySelector from '../../../components/CompanySelector.vue';
import ShopsFilters from '../components/ShopsFilters.vue';

const { error } = useGenericErrorHandling();
const router = useRouter();

const formModal = reactive(useFormModal());

const auth = injectOrFail<AuthClient>('auth');
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const { t } = useLocalization();
const { isReadOnly } = useRoles();

const companies = ref<CompanyRaw[] | null>(null);
const companySelector = useTemplateRef('companySelector');
const shopCreateCompanyGuid = ref<string | null>(null);

const { list: shops, setPagination } = useRouterPagination(management.shops, { perPage: 10 });

const columns: OtDataGridColumn[] = [
    {
        key: [ '$data', 'name' ],
        type: 'string',
        label: t('models.shop.attributes.name.label'),
        hide: {
            tile: true,
        },
    },
    {
        key: [ '$data', 'company_id' ],
        type: 'custom',
        label: t('models.company.name.single'),
    },
    {
        key: [ 'url' ],
        type: 'string',
        label: t('dashboard.shops.list.column.url'),
        tileColumnSize: 'large',
    },
];

watch(() => context.company, (newValue, oldValue) => {
    if (!context.isShopContext() && newValue?.id !== oldValue?.id) {
        void setPagination(management.shops);
    }
});

void (async () => {
    // Retrieve companies from the token, to prevent fetching the companies
    // list. This assumes the user is only able to access companies
    // that are saved within the token.
    const token = await auth.$token.$info;

    if (token?.companies) {
        companies.value = token.companies;
    }
})();

function goToShopPage(shop: string): void {
    void router.push({
        name: 'shops.home',
        params: { shop },
    });
}
async function goToCompanyContext(companyId: string) {
    await context.updateContext('company', { company: companyId });
}

function companyName(companyId: string): string | null {
    const company = companies?.value?.find((singleCompany) => singleCompany.guid === companyId);

    return company!.name;
}

function onNewShopSaved(modelGuid: string | null) {
    if (modelGuid) {
        void router.push({
            name: 'shops.home',
            params: { shop: modelGuid },
        });
    }
}

function openCreateShopModal(companyGuid: string): void {
    shopCreateCompanyGuid.value = companyGuid;
    formModal.open();
}

//    searchString: string | null = null;
//    shopImg: string = shopImg;
//    // @Watch('searchString')
//    // private onSearch(): void {
//    //     const query = { ...this.$route.query || {} };
//    //
//    //     if (this.searchString && this.searchString.length) {
//    //         void this.shops.addFilter('name', this.searchString);
//    //         query.name = this.searchString;
//    //     } else {
//    //         void this.shops.removeFilter('name');
//    //         delete query.name;
//    //     }
//    //
//    //     void this.$router.replace({
//    //         path: this.$route.path,
//    //         query,
//    //     });
//    // }
</script>

<style lang="scss" scoped>
.module__shops__list {
    padding-top: var(--ot-ui-spacing-md);

    &__filters {
        display: flex;
        flex-direction: row;
        gap: var(--ot-ui-spacing-md);

        &__select {
            flex: 1;
            margin-right: var(--ot-ui-spacing-xs);
        }

        &__search {
            flex: 1;
            max-width: 50%;
        }
    }
}
</style>
