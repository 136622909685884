import { OtError } from '@openticket/lib-log';

export class ContextInvalidError extends OtError {

    readonly contextType: string;

    constructor(contextType: string) {
        super('ContextInvalidError', 'dashboard.errors.plugins.context.invalid', 'Failed to set context. Invalid context type.');

        this.contextType = contextType;
        this.addContext('context_type', contextType);

        this.captureStackTrace(ContextInvalidError);
    }

}
