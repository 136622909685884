// First two functions below imported from https://stackoverflow.com/questions/2353211/hsl-to-rgb-color-conversion/9493060#9493060\

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from https://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   h       The hue
 * @param   s       The saturation
 * @param   l       The lightness
 * @return          The RGB representation
 */
function hslToRgb(h: number, s: number, l: number): [number, number, number] {
    let r;
    let g;
    let b;

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hueToRgb(p, q, h + 1 / 3);
        g = hueToRgb(p, q, h);
        b = hueToRgb(p, q, h - 1 / 3);
    }

    return [ Math.round(r * 255), Math.round(g * 255), Math.round(b * 255) ];
}

function hueToRgb(p: number, q: number, t: number) {
    if (t < 0) {
        t += 1;
    }
    if (t > 1) {
        t -= 1;
    }
    if (t < 1 / 6) {
        return p + (q - p) * 6 * t;
    }
    if (t < 1 / 2) {
        return q;
    }
    if (t < 2 / 3) {
        return p + (q - p) * (2 / 3 - t) * 6;
    }
    return p;
}

/**
 *
 * @param hsla Should follow the CSS HSLA structure i.e. `hsl(215deg 100% 50% / 100%)`
 */
export function convertCssHslaToRgb(hsla: string): string {
    const hslRegex = /hsl\((\d+)deg (\d+)% (\d+)% \/ (\d+)%\)/;

    const hslProperties = hsla.match(hslRegex);

    if (hslProperties) {
        const [ , hue, saturation, lightness, alpha ] = hslProperties;

        const rgb = hslToRgb(
            Number(hue) / 360,
            Number(saturation) / 100,
            Number(lightness) / 100,
        );

        // Following the format `rgba(R, G, B, A)`
        // Note: the syntax `rgb(R G B / A)` does not work
        return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${Number(alpha) / 100})`;
    }

    return hsla;
}
