<template>
    <OtDropdown
        ref="profileDropdown"
        class="profile-dropdown"
        alignment="right"
    >
        <template #trigger="{ visible }">
            <!-- /* TODO add keylisteners and a11y */ -->
            <div
                class="profile-dropdown__trigger"
                :class="{ 'opened': visible, 'show-marker': showMarker }"
            >
                <OtAvatar
                    class="profile-dropdown__trigger__avatar"
                    :class="{ 'opened': visible }"
                    :name="userName"
                    size="40"
                />
            </div>
        </template>

        <template #content>
            <OtCard
                class="profile-dropdown__content ot-ui-p[md]!"
                no-padding
            >
                <ProfileSummary @navigate="profileDropdown?.close()" />

                <OtLocaleSwitch
                    class="ot-ui-mt[md]"
                    show-trigger
                />

                <OtSeparator class="ot-ui-my[md]!" />

                <ProfileInvitations
                    :class="{ 'show-marker': showMarker }"
                    @has-invitations="hasInvitations"
                />
            </OtCard>
        </template>
    </OtDropdown>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import type { AuthClient } from '@openticket/lib-auth';
import { injectOrFail } from '../../../../services/util';
import ProfileInvitations from './ProfileInvitations.vue';
import ProfileSummary from './ProfileSummary.vue';

const auth = injectOrFail<AuthClient>('auth');

const showMarker = ref<boolean>(false);
const profileDropdown = ref();
const userName = ref<string | undefined>();

void (async () => {
    const tokenInfo = await auth.$token.$info;
    userName.value = tokenInfo ? tokenInfo.user.name : '?';
})();

function hasInvitations(value: boolean): void {
    showMarker.value = !!value;
}

</script>

<style lang="scss" scoped>
.profile-dropdown {
    &__trigger {
        position: relative;
        cursor: pointer;

        &__avatar {
            &:hover, &.opened, &:focus, &:focus-within {
                outline: var(--ot-ui-color-brand);
                outline-width: 2px;
                outline-style: solid;
            }

            &.opened {
                outline-offset: 2px;
            }
        }
    }

    &__content {
        width: 24rem;
        border: 1px solid var(--ot-ui-color-accent-tertiary);

        & > * {
            position: relative;
        }

        :deep(.ot-ui-locale-switch) {
            box-shadow: none;
            border: 1px solid var(--ot-ui-color-accent-secondary);

            .ot-ui-locale-switch__trigger {
                padding: var(--ot-ui-input-padding);
            }
        }
    }

    .show-marker::after {
        content: '';
        position: absolute;
        display: block;
        border-radius: var(--ot-ui-radius-full);
        width: .75rem;
        height: .75rem;
        background-color: var(--ot-ui-color-intent-error-primary);
        top: calc(0.146 * 2.5rem - .375rem); /* i.e. h * (1-(sqrt(2)/2))/2 - r/2 */
        right: calc(0.146 * 2.5rem - .375rem); /* i.e. w * (1-(sqrt(2)/2))/2 - r/2 */
    }
}
</style>
