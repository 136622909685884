import { inject } from 'vue';
import type { InjectionKey } from 'vue';
import { InjectionError } from './injection.error';

export function injectOrFail<T>(key: InjectionKey<T> | string): T {
    const injection: T | undefined = inject<T>(key);

    if (!injection) {
        throw new InjectionError(key.toString());
    }

    return injection;
}
