<template>
    <OtTileSimple
        :title="record.$data.name"
        :record="record"
        class="companies-members-list__record"
    >
        <template #asset>
            <OtAvatar
                :name="record.$data.name"
                size="64"
            />
        </template>

        <template #column__$data__manager="{ value }">
            {{ $t(value ? 'dashboard.members.role.manager' : 'dashboard.members.role.view_only') }}
        </template>

        <template #actions>
            <OtButton
                v-if="roles.isManager"
                :title="$t('dashboard.company.members.list.manage.title', { user: record.$data.name })"
                icon-right="carrot-right"
                size="small"
                variant="outline"
                @click="goToMembersEditPage()"
                @keydown.space="goToMembersEditPage()"
                @keydown.enter="goToMembersEditPage()"
            >
                {{ $t('dashboard.company.members.list.manage.text') }}
            </OtButton>
        </template>
    </OtTileSimple>
</template>

<script setup lang="ts">
import type { AuthClient, User } from '@openticket/lib-auth';
import { type UnwrapNestedRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { injectOrFail } from '../../../../services/util';
import type { Roles } from '../../../../plugins/types';

type Props = {
    record: User<AuthClient>;
}

const props = defineProps<Props>();

const route = useRoute();
const router = useRouter();

const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');

function goToMembersEditPage(): void {
    void router.push({
        name: 'companies.edit.members.edit',
        params: { ...route.params, user: props.record.id! },
    });
}
</script>
