import { ref, watch } from 'vue';

export function useLocalStorage<T = unknown>(key: string, defaultValue: T) {
    const storedValue = ref<T>(getStoredValue());

    function getStoredValue(): T {
        const stored = localStorage.getItem(key);
        if (stored) {
            return JSON.parse(stored) as T;
        }

        return defaultValue;
    }

    function setStoredValue(value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    watch(storedValue, (newValue) => {
        setStoredValue(newValue as T);
    });

    return storedValue;
}
