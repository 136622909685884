import type {
    DataInterface, Parent, RawInterface, RulesMap,
} from '@openticket/lib-crud';
import {
    DataType, List, Model, ToMany,
} from '@openticket/lib-crud';
import type { TemplateLinkConfig } from './templateLink';
import { TemplateLink } from './templateLink';

const ModelConfig = {
    company_id: [ DataType.string ],
    created_at: [ DataType.string ],
    deleted_at: [ DataType.string, DataType.null ],
    fallback_locale: [ DataType.string ],
    name: [ DataType.string ],
    public: [ DataType.string ],
    type: [ DataType.string ],
    updated_at: [ DataType.string ],
};

type Config = typeof ModelConfig;

export type {
    Config as TemplateConfig,
};

export type TemplateRaw = RawInterface<Config>;
export type TemplateData = DataInterface<Config>;

export class Template<Ancestor extends Parent> extends Model<Ancestor, Config, RulesMap> {

    static $defaultData(): RawInterface<Config> {
        return {
            company_id: '',
            created_at: '',
            deleted_at: null,
            fallback_locale: '',
            name: '',
            public: '',
            type: '',
            updated_at: '',
        };
    }

    get id(): string | null {
        return null;
    }

    $config(): Config {
        return ModelConfig;
    }

    $modelName(): string {
        return 'Template';
    }

    templateLinks = new ToMany<TemplateLink<typeof this>, TemplateLinkConfig, typeof this>(TemplateLink, this, 'template_link')
        .with(List);

}
