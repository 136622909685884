<template>
    <OtModal
        ref="modalRef"
        :width="540"
        :title="title"
        :subtitle="subtitle"
    >
        <ErrorView
            v-if="error"
            :label="error.message"
        />
        <div v-else>
            <div class="confirm-with-yes-modal__tile-header ot-ui-pb[md]">
                <slot name="description">
                    {{ description }}
                </slot>
                {{ $t('dashboard.company.edit.actions.confirm.for_sure') }}
            </div>
            <div class="confirm-with-yes-modal__tile-explainer ot-ui-text-body-lg-strong ot-ui-mb[xs]">
                {{ $t('dashboard.company.edit.actions.confirm.help_text') }}
            </div>
            <OtInputField>
                <OtTextInput
                    v-model="confirmInput"
                    :placeholder="$t('dashboard.company.edit.actions.confirm.keyword')"
                    data-testid="confirm-with-yes-modal-input"
                />
            </OtInputField>
        </div>

        <template #footer-action>
            <OtButton
                :disabled="!ready"
                data-testid="confirm-with-yes-modal-confirm"
                icon="check"
                @click="confirm"
            >
                {{ confirmLabel }}
            </OtButton>
        </template>
    </OtModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { OtModal, useLocalization } from '@openticket/vue-ui';
import { useGenericErrorHandling } from '../../../../../composables';
import ErrorView from '../../../../../components/ErrorView.vue';

const { error, handleError } = useGenericErrorHandling();
const { t } = useLocalization();

const CONFIRM_STRING = t('dashboard.company.edit.actions.confirm.keyword');

type Props = {
    confirmLabel: string,
    description?: string,
    subtitle?: string,
    title: string,
}

defineProps<Props>();

type Emits = {
    (e: 'confirm'): void,
}

const emit = defineEmits<Emits>();

const modalRef = ref<InstanceType<typeof OtModal> | null>(null);
const confirmInput = ref<string | undefined>();
const ready = computed<boolean>(() => !!confirmInput.value && confirmInput.value.toUpperCase() === CONFIRM_STRING.toUpperCase());

function confirm() {
    emit('confirm');
    close();
}

function close() {
    void modalRef.value?.close();
}

function open() {
    confirmInput.value = undefined;

    try {
        modalRef.value?.open();
    } catch (e) {
        void handleError(e);
    }
}

defineExpose({
    open,
});
</script>

<!-- None-scoped to allow slot content to use this style -->
<style lang="scss">
.confirm-with-yes-modal__highlighted {
    font-weight: var(--ot-ui-font-weight-medium);
}
</style>

<style lang="scss" scoped>
.confirm-with-yes-modal {
    color: var(--ot-ui-color-foreground-secondary);

    &:deep(.header__content label) {
        color: var(--ot-ui-color-brand);
        font-size: var(--ot-ui-font-size);
        margin-bottom: 0;
    }
}
</style>
