<template>
    <ActionsEventSelect
        :get-count="countEmails"
        :do-action="resendEmails"
        slug="resend-emails"
    />
</template>

<script setup lang="ts">
import type { AdminActions } from '../../services/admin-actions/adminActions.service';
import { injectOrFail } from '../../services/util';
import ActionsEventSelect from '../../modules/companies/components/actions/ActionsEventSelect.vue';

const adminActions = injectOrFail<AdminActions>('adminActions');

async function countEmails(eventId: string) {
    return adminActions.countResendEmails(eventId);
}

async function resendEmails(eventId: string) {
    await adminActions.resendEmails(eventId);
}
</script>
