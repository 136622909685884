import type { LocalizationContract } from '@openticket/lib-localization';
import type { Whitelabel } from '@openticket/lib-whitelabels';

export interface OpenTicketGlobal {
    Whitelabel?: Whitelabel;
    Localization?: LocalizationContract;
}

export function getGlobal(): OpenTicketGlobal {
    const OT = globalThis.OpenTicket || {};

    globalThis.OpenTicket = OT;

    return OT;
}

declare global {
    // noinspection ES6ConvertVarToLetConst
    var OpenTicket: OpenTicket | undefined; // eslint-disable-line vars-on-top,no-var

    interface OpenTicket {
        Whitelabel?: Whitelabel;
    }
}
