import { isAxiosError, type AxiosInstance } from 'axios';
import {
    DataType,
    Pagination,
    unmarshal,
} from '@openticket/lib-crud';
import type {
    ModelConfig,
    DataInterface,
    RawInterface,
} from '@openticket/lib-crud';
import urlJoin from 'url-join';
import { isOtError } from '@openticket/lib-log';
import { LegacyApiRequestError, LegacyApiUnmarshalError, LegacyApiUnsetBaseUrlError } from './errors';
import { getOrderEndpoints, getShopEndpoints } from './modules';
import type { LegacyApiHelperMethods } from './types';

type ErrorCallback = (error: unknown) => void;

export class LegacyApiClient {

    private http: AxiosInstance;
    protected baseUrl: string | null;

    private errorCallbacks: ErrorCallback[] = [];
    private methods: LegacyApiHelperMethods;

    orders: ReturnType<typeof getOrderEndpoints>;
    shops: ReturnType<typeof getShopEndpoints>;

    constructor(config: { http: AxiosInstance, baseUrl: string | null }) {
        this.baseUrl = config.baseUrl;
        this.http = config.http;

        // DD-DASHBOARD-2729 - CORS error appears when credential mode is 'include'.
        // TODO: remove after CORS from legacy api gets updated
        this.http.interceptors.request.use((axiosConfig) => {
            delete axiosConfig.headers['X-Authorization-By-Openticket'];
            return axiosConfig;
        });

        this.methods = {
            performGet: this._performGet.bind(this),
            performGetWithArrayReponse: this._performGetWithArrayReponse.bind(this),
        };

        this.orders = getOrderEndpoints(this.methods);
        this.shops = getShopEndpoints(this.methods);
    }

    /**
     * Registers an error callback to be invoked when an error occurs.
     * Returns a function that can be called to unregister the callback.
     *
     * @param callback - The callback function to be executed when an error occurs.
     * @returns A function to remove the registered callback.
     */
    onError(callback: ErrorCallback): () => void {
        this.errorCallbacks.push(callback);

        return () => {
            const i = this.errorCallbacks.findIndex((value: ErrorCallback) => value === callback);

            if (i >= 0) {
                this.errorCallbacks.splice(i, 1);
            }
        };
    }

    private _checkIfBaseUrlSet(): asserts this is { baseUrl: string } {
        if (!this.baseUrl) {
            const error = new LegacyApiUnsetBaseUrlError();

            this._callErrorCallbacks(error);

            throw error;
        }
    }

    private _callErrorCallbacks(error: unknown): void {
        for (const callback of this.errorCallbacks) {
            callback(error);
        }
    }

    /**
     * Constructs a new `Pagination` object from an array of data, making it suitable for
     * `Pagination`-dependent components (such as the OtDataGrid)
     */
    private _paginationFromArray<T>(array: T[]): Pagination<T> {
        return new Pagination(async () => Promise.resolve({
            current_page: null,
            data: array,
            first_page_url: null,
            from: null,
            last_page: null,
            last_page_url: null,
            next_page_url: null,
            per_page: null,
            prev_page_url: null,
            to: null,
            total: null,
        }));
    }

    /**
     * Wrapper around the http client performing the request, ensuring certain restrictions are met.
     */
    private async _performRequest<T>(method: 'get' | 'delete' | 'post' | 'patch', urlPath: string[], data?: unknown) {
        this._checkIfBaseUrlSet();

        const url = urlJoin(this.baseUrl, ...urlPath);

        try {
            if (method === 'get' || method === 'delete') {
                // DD-DASHBOARD-2729 - CORS error appears when credential mode is 'include'.
                // TODO: remove withCredentials when CORS settings have been updated in legacy api.
                return await this.http[method]<T>(url, { withCredentials: false });
            }

            // DD-DASHBOARD-2729 - CORS error appears when credential mode is 'include'.
            // TODO: remove withCredentials when CORS settings have been updated in legacy api.
            return await this.http[method]<T>(url, data, { withCredentials: false });
        } catch (e: unknown) {
            const error = isAxiosError(e) ? new LegacyApiRequestError(url, e) : e;

            this._callErrorCallbacks(error);

            throw e;
        }
    }

    /**
     * Perform a get request to the provided `urlPath` prefixed with the set base url.
     * The expected response from the call is an object.
     */
    private async _performGet<C extends ModelConfig>(
        urlPath: string[],
        expectedConfig: C,
    ): Promise<DataInterface<C>> {
        const response = await this._performRequest<RawInterface<C>>('get', urlPath);

        try {
            return unmarshal(expectedConfig, response.data);
        } catch (e) {
            const error = isOtError(e) ? new LegacyApiUnmarshalError(e) : e;

            this._callErrorCallbacks(error);

            throw e;
        }
    }

    /**
     * Perform a get request to the provided `urlPath` prefixed with the set base url.
     * The expected response from the call is an array. This is converted to a `Pagination` instance.
     */
    private async _performGetWithArrayReponse<C extends ModelConfig>(
        urlPath: string[],
        expectedConfig: C,
    ): Promise<Pagination<DataInterface<C>>> {
        const response = await this._performRequest<RawInterface<C>[]>('get', urlPath);

        // Wrap config in object, as response returned will be an array
        const newConfig = {
            data: [ DataType.array(DataType.object(expectedConfig)) ],
        };

        try {
            const unmarshalResponse = unmarshal(newConfig, response);

            const pagination = this._paginationFromArray(unmarshalResponse.data);
            await pagination.initialization;

            return pagination;
        } catch (e) {
            const error = isOtError(e) ? new LegacyApiUnmarshalError(e) : e;

            this._callErrorCallbacks(error);

            throw e;
        }
    }

}
