<template>
    <OtFilters
        class="shops-filters"
        :categories-to-hide="filterCategoriesToHide"
        :categories-order="filterCategoriesOrder"
        :pagination="shops"
        translations-prefix-key="models.shop"
        :view-filter="{ label: companyViewFilterSlug }"
    >
        <template #tag__contains_event="{ filter }">
            <OtFilterTag
                v-if="filter.active.scope !== null"
                closable
                :title="$t('models.shop.scopes.contains_event.label')"
                :values="[ eventNameResolver(filter.active.scope, 'scope') ]"
                @close="() => shops.setFilter(filter.attribute, 'scope', null)"
            />
        </template>

        <OtFilterCategory
            id="contains_event"
            v-slot="{ state }"
            class="shops-filters__custom__contains-event"
            :attributes="[ 'contains_event' ]"
            icon="event"
            :label="$t('models.shop.scopes.contains_event.label')"
        >
            <OtSearchInput
                :placeholder="$t('common.placeholder.search')"
                @input="handleSearchInput"
            />

            <span v-if="events.records.length === 0">
                {{ $t('dashboard.events.list.empty.title') }}
            </span>

            <template v-else>
                <OtRadioGroupInput
                    :model-value="state.contains_event.scope"
                    :options="containsEventOptions"
                    @input="(val) => handleRadioGroupInput(state, val)"
                />

                <OtPaginationControls
                    v-if="containsEventLastPage > 1"
                    :pagination="events"
                    centered
                    hide-per-page
                    simple
                />
            </template>
        </OtFilterCategory>;
    </OtFilters>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import type { Pagination } from '@openticket/lib-crud';
import type { Event as ManagementEvent, ManagementClient, Shop } from '@openticket/lib-management';
import { useLocalization } from '@openticket/vue-ui';
import type { OtFiltersState, OtFilterTagValue } from '@openticket/vue-ui';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling } from '../../../composables';

type Props = {
    shops: Pagination<Shop<ManagementClient>>;
}

defineProps<Props>();

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const { handleError } = useGenericErrorHandling();
const { t } = useLocalization();

const filterCategoriesToHide = [
    'company_terms',
    'created_at',
    'custom_redirect',
    'event_selection',
    'facebook_auto_attend',
    'facebook_page_url',
    'from_email',
    'from_name',
    'google_tag',
    'greedy_date_selection',
    'updated_at',
];

const filterCategoriesOrder = [
    'name',
    'boolean_filters',
    'contains_event',
];

const events = ref<Pagination<ManagementEvent<ManagementClient>>>(management.events.list({
    defaultSort: [ [ 'name', 'asc' ] ],
    perPage: 8,
    deferInitialization: true,
}));

const companyViewFilterSlug = computed<string>(() => (context.isCompanyContext()
    ? context.company.name
    : t('dashboard.sidebar.context_filter.company.all_companies')
));

const containsEventOptions = computed<Record<string, { label: string }>>(() => events.value.records
    .reduce<Record<string, { label: string }>>((previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.id || '']: { label: currentValue.$data.name },
    }), {}));

const containsEventLastPage = computed<number>(() => events.value.lastPage || 1);

void events.value.initialization;

function eventNameResolver(guid: string, filtertype: OtFilterTagValue['filtertype']) {
    const value = reactive({
        filtertype,
        label: '',
    });

    try {
        void management.events.find(guid).then((model) => {
            value.label = model.$data.name;
        });
    } catch (e) {
        void handleError(e);
    }

    return value;
}

function handleRadioGroupInput(state: OtFiltersState, value: string | Event): void {
    const val = (value instanceof Event && value.target instanceof HTMLInputElement) ? value.target.value : value;

    if (typeof val === 'string') {
        state.contains_event.scope = val;
    }
}

function handleSearchInput(value: string | Event): void {
    const val = (value instanceof Event && value.target instanceof HTMLInputElement) ? value.target.value : value;

    if (typeof val === 'string') {
        void events.value.setFilter('name', 'contains', val || null);
    }
}
</script>

<style lang="scss" scoped>
.shops-filters {
    &__custom__contains-event {
        white-space: wrap;
        display: flex;
        flex-direction: column;
        gap: var(--ot-ui-spacing-md);

        :deep(.ot-ui-radio-input__content) {
            min-height: 1.5rem !important;
        }
    }
}
</style>
