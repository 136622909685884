<template>
    <div class="ticket-addon-products">
        <div class="ticket-addon-products__header">
            <h2
                class="ticket-addon-products__header__title ot-ui-text-heading-2"
                data-testid="ticket-addon-products-title"
            >
                {{ $t('dashboard.tickets.addon_products.header.title') }}
            </h2>
            <i18n-t
                keypath="dashboard.tickets.addon_products.header.description"
                tag="p"
                class="ticket-addon-products__header__description ot-ui-text-body-large"
            >
                <template #ticket>
                    <router-link
                        class="ticket-addon-products__header__ticket"
                        :to="{
                            name: 'tickets.edit.details',
                        }"
                    >
                        <OtButton
                            variant="inline"
                            size="small"
                        >
                            {{ context.ticket?.name }}
                        </OtButton>
                    </router-link>
                </template>
            </i18n-t>
        </div>
        <div class="ticket-addon-products__content">
            <ErrorView
                v-if="error"
                :label="error.message"
            />
            <OtSpinner v-else-if="!draggableProductGroups" />
            <div
                v-else-if="draggableProductGroups.length === 0"
                class="ticket-addon-products__empty"
                data-testid="ticket-addon-products-empty"
            >
                <h3 class="ticket-addon-products__empty__title ot-ui-text-heading-3">
                    {{ $t('dashboard.tickets.addon_products.empty.title') }}
                </h3>
                <p class="ticket-addon-products__empty__description ot-ui-text-body-md">
                    {{ $t('dashboard.tickets.addon_products.empty.description') }}
                </p>
                <OtButton
                    size="small"
                    variant="outline"
                    icon="plus"
                    data-testid="ticket-addon-products-empty-add"
                    @on-click="addAddonProductsOnEmpty"
                >
                    {{ $t('dashboard.tickets.addon_products.empty.add') }}
                </OtButton>
            </div>
            <div v-else>
                <DragAndDrop
                    :disallow-children-in-root="true"
                    :draggables="draggableProductGroups"
                    icon="shops"
                    item-name="product"
                    group-name="Product group"
                    :has-next="hasNext"
                    @link="linkItem"
                    @unlink="onUnlink"
                    @move:group="reorderGroup"
                    @move:item="reorderItem"
                    @remove:item="onRemoveItem"
                    @remove:group="deleteProductGroup"
                    @open-add:item="openAddItem"
                    @open-edit:group="openEditGroup"
                    @paginate:group="loadProductPage"
                    @paginate:draggables="loadProductGroups"
                >
                    <template #sub-text="{ group }">
                        <div
                            v-if="isProductGroupForced(group)"
                            class="ticket-addon-products__forced ot-ui-text-body-xs"
                        >
                            <OtFlair
                                variant="danger"
                                class="ticket-addon-products__forced__flair ot-ui-text-xs"
                                :text="$t('dashboard.tickets.addon_products.groups.forced.flair')"
                            />
                        </div>
                    </template>
                    <template #footer="{ group }">
                        <div
                            v-if="isProductGroupForced(group)"
                            class="ticket-addon-products__info ot-ui-text-body-xs"
                        >
                            <OtIcon
                                type="info"
                                size="small"
                            />
                            <div class="ticket-addon-products__info__text">
                                {{ $t('dashboard.tickets.addon_products.groups.forced.info') }}
                            </div>
                        </div>
                    </template>
                </DragAndDrop>
                <div class="ot-ui-mt[lg]">
                    <OtButton
                        variant="outline"
                        size="small"
                        icon="plus"
                        :title="$t('dashboard.tickets.addon_products.groups.create_button.title')"
                        data-testid="ticket-addon-products-create-new-group"
                        @on-click="formModalCreateGroup.open()"
                    >
                        {{ $t('dashboard.tickets.addon_products.groups.create_button.text') }}
                    </OtButton>
                </div>
            </div>
        </div>
        <AddonProductGroupCreateModal
            v-if="formModalCreateGroup.isOpen"
            :modal="formModalCreateGroup"
            @submit="() => loadProductGroups(true)"
        />
        <AddonProductGroupEditModal
            v-if="productGroupModal.isOpen && editedProductGroup"
            :modal="productGroupModal"
            :product-group="editedProductGroup"
            :products-pagination="productPaginations[editedProductGroup?.id || '']"
            @submit="onProductGroupSubmit"
        />
        <LinkUnlinkModal
            v-if="context.event?.model.products"
            ref="linkAddonProductModal"
            :title="t('dashboard.addon_products.link_product.title')"
            :subtitle="t('dashboard.addon_products.link_product.subtitle')"
            :input-description="t('dashboard.addon_products.link_product.input_description')"
            :items-label="t('dashboard.addon_products.link_product.items_label')"
            :input-label="t('dashboard.addon_products.link_product.input_label')"
            :save-text="t('dashboard.addon_products.link_product.save_text')"
            :pagination-relation="context.event.model.products"
            :link-relation="editedProductGroup?.products"
            :create-modal="AddonProductCreateModal"
            @saved="onSavedLinkUnlink"
            @submit="createMagicProductGroup"
        />
    </div>
</template>

<script lang="ts" setup>
import {
    computed, reactive, ref, useTemplateRef,
} from 'vue';
import type { Pagination } from '@openticket/lib-crud';
import type {
    ManagementClient, Product, ProductGroup, Ticket,
} from '@openticket/lib-management';
import { useConfirmationModal, useLocalization, useNotifications } from '@openticket/vue-ui';
import type { DragGroup, DragItem } from '../../../components/drag-and-drop/types';
import DragAndDrop from '../../../components/drag-and-drop/DragAndDrop.vue';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import type { LinkEvent, SortEvent } from '../../../composables/draggable-interpreter/types';
import { useFormModal } from '../../../composables/forms';
import AddonProductGroupEditModal from './addon-products/AddonProductGroupEditModal.vue';
import LinkUnlinkModal from '../../../components/modal/link-unlink/LinkUnlinkModal.vue';
import type { DragProductGroup } from './types';
import AddonProductGroupCreateModal from './addon-products/AddonProductGroupCreateModal.vue';
import AddonProductCreateModal from '../../addon-products/components/AddonProductCreateModal.vue';
import { useGenericErrorHandling } from '../../../composables';
import ErrorView from '../../../components/ErrorView.vue';

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');
const dialog = useConfirmationModal();
const notifications = useNotifications();

const { t } = useLocalization();
const { handleError, error } = useGenericErrorHandling();

const productGroupsPagination = ref<Pagination<ProductGroup<Ticket<ManagementClient>>> | null>();
const productPaginations = ref<{ [groupId: string]: Pagination<Product<ProductGroup<ManagementClient>>> }>({});
const draggableProductGroups = ref<DragProductGroup[] | null>();
const hasNext = computed<boolean>(() => productGroupsPagination.value?.nextPageUrl !== null);
const productGroupModal = reactive(useFormModal());
const editedProductGroup = ref<ProductGroup<ManagementClient> | ProductGroup<Ticket<ManagementClient>> | null>(null);
const linkAddonProductModal = useTemplateRef('linkAddonProductModal');
const formModalCreateGroup = reactive(useFormModal());

void loadProductGroups();

async function loadProductGroups(firstPage?: boolean) {
    if (!context.isTicketContext()) {
        return;
    }

    try {
        if (!productGroupsPagination.value) {
            productGroupsPagination.value = context.ticket.model.productGroups.list({ deferInitialization: true, perPage: 4 });
            await productGroupsPagination.value?.initialization;
        } else {
            const page = firstPage ? 0 : (productGroupsPagination.value.currentPage ?? 0) + 1;
            await productGroupsPagination.value.loadPage(page);
        }
    } catch (e) {
        handleError(e);
        return;
    }

    const groups: DragProductGroup[] = draggableProductGroups.value && !firstPage ? [ ...draggableProductGroups.value ] : [];

    for (const group of productGroupsPagination.value?.records ?? []) {
        if (!group.id) {
            continue;
        }

        const productGroup = management.productGroups.$factory({
            ...management.productGroups.new().$raw,
            guid: group.id,
        });

        const products = productGroup.products.list({ deferInitialization: true, perPage: 5 });

        try {
            await products.initialization;
        } catch (e) {
            handleError(e);
            return;
        }

        productPaginations.value[group.id] = products;

        const items: DragItem[] | undefined = products.records.map((product) => ({
            guid: String(product.id),
            name: product.$data.name,
            type: 'item',
        }));

        groups.push({
            type: 'group',
            items: items || [],
            hasNext: products.nextPageUrl !== null,
            nrOfProducts: products.total ?? 0,
            ...group.$data,
            guid: String(group.id),
            name: group.$data.name ?? '',
        });
    }
    draggableProductGroups.value = groups;
}

async function loadProductPage(groupId: string, firstPage?: boolean) {
    const products = productPaginations.value[groupId];
    const page = await products.loadPage(firstPage ? 0 : (products.currentPage ?? 0) + 1);

    const draggableGroup = draggableProductGroups.value?.find((group) => group.guid === groupId);

    if (!draggableGroup) {
        return;
    }

    if (firstPage) {
        // Splicing the array to remove all items without losing reactivity.
        draggableGroup.items.splice(0, draggableGroup.items.length);
    }

    for (const product of page.data) {
        const sameProduct = draggableGroup.items.filter((item) => item.guid !== String(product.id));
        draggableGroup.items.splice(0, draggableGroup.items.length, ...sameProduct);

        draggableGroup.items.push({
            guid: String(product.id),
            name: product.$data.name,
            type: 'item',
        });
    }

    draggableGroup.nrOfProducts = products.total ?? 0;
    draggableGroup.hasNext = products.nextPageUrl !== null;
}

async function createMagicProductGroup(productGuids: string[]) {
    if (!context.isTicketContext()) {
        return;
    }

    try {
        const productGroup = await context.ticket.model.productGroups.create(context.ticket.model.productGroups.$factory({
            ...management.productGroups.new().$raw,
        }));
        const newProductGroup = management.productGroups.$factory(productGroup.$raw);

        await newProductGroup.products.linkMulti(...productGuids);

        notifications.success(t('dashboard.tickets.addon_products.notifications.product_group_created'));
        await loadProductGroups(true);
    } catch (e) {
        handleError(e);
    }
}

async function onSavedLinkUnlink() {
    if (!editedProductGroup.value || !editedProductGroup.value.id) {
        return;
    }

    await loadProductPage(editedProductGroup.value.id, true);
    notifications.success(t('dashboard.common.notification.save.success'));
}

async function unlinkProductFromProductGroup(productId: string, groupId: string) {
    const productGroup = management.productGroups.$factory({
        ...management.productGroups.new().$raw,
        guid: groupId,
    });

    const product = productGroup.products.$factory({
        ...management.products.new().$raw,
        guid: productId,
        event_id: context.event?.model.id ?? '',
    });

    try {
        await productGroup.products.unlink(product);
        await loadProductPage(groupId, true);
    } catch (e) {
        handleError(e);
    }
}

async function onRemoveItem(itemGuid: string, parentGuid?: string) {
    if (parentGuid === undefined) {
        return;
    }

    const confirm = await dialog?.confirm({
        title: t('dashboard.tickets.addon_products.groups.remove_item.title') || '',
        message: t('dashboard.tickets.addon_products.groups.remove_item.description') || '',
        variant: 'destructive',
    });

    if (!confirm) {
        return;
    }

    await unlinkProductFromProductGroup(itemGuid, parentGuid);
    notifications.success(t('dashboard.tickets.addon_products.notifications.product_removed'));
}

async function onUnlink(unlinkEvent: LinkEvent) {
    await unlinkProductFromProductGroup(unlinkEvent.itemGuid, unlinkEvent.parentGuid);
}

async function deleteProductGroup(groupId: string) {
    const confirm = await dialog?.confirm({
        title: t('dashboard.tickets.addon_products.groups.remove_group.title') || '',
        message: t('dashboard.tickets.addon_products.groups.remove_group.description') || '',
        variant: 'destructive',
    });

    if (!confirm) {
        return;
    }

    const productGroup = management.productGroups.$factory({
        ...management.productGroups.new().$raw,
        guid: groupId,
    });

    try {
        await management.productGroups.delete(productGroup);
        notifications.success(t('dashboard.tickets.addon_products.notifications.product_group_removed'));
        await loadProductGroups(true);
    } catch (e) {
        handleError(e);
    }
}

async function openEditGroup(group: DragGroup) {
    if (!context.isTicketContext() || !group.guid) {
        return;
    }

    editedProductGroup.value = await context.ticket.model.productGroups.find(group.guid);
    productGroupModal.open();
}

function onProductGroupSubmit(productGroup: ProductGroup<ManagementClient>) {
    const draggableGroup = draggableProductGroups.value?.find((group) => group.guid === productGroup.id);
    if (draggableGroup !== undefined) {
        Object.assign(draggableGroup, productGroup.$data);
    }
}

function isProductGroupForced(group: DragProductGroup): boolean {
    return (group.min_bound === group.max_bound
        && group.max_bound === group.nrOfProducts
        && group.uniqueness === 1);
}

function addAddonProductsOnEmpty() {
    editedProductGroup.value = null;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    linkAddonProductModal.value?.open();
}

// For opening the modal that adds an item to the group
async function openAddItem(groupId: string) {
    editedProductGroup.value = await management.productGroups.find(groupId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    linkAddonProductModal.value?.open();
}

async function linkItem(mutation: LinkEvent) {
    if (mutation.newIndex === undefined) {
        return;
    }

    const productGroup = management.productGroups.$factory({
        ...management.productGroups.new().$raw,
        guid: mutation.parentGuid,
    });

    const product = productGroup.products.$factory({
        ...management.products.new().$raw,
        guid: mutation.itemGuid,
        event_id: context.event?.model.id ?? '',
    });

    try {
        await productGroup.products.link(product, {});
    } catch (e) {
        handleError(e);
        return;
    }

    const draggableGroup = draggableProductGroups.value?.find((group) => group.guid === mutation.parentGuid);
    const direction = mutation.newIndex !== 0 ? 'down' : 'up';
    const anchorGuid = direction === 'down'
        ? draggableGroup?.items[mutation.newIndex - 1]?.guid
        : draggableGroup?.items[1]?.guid;

    // There is no other item inside the group, so no need to reorder.
    if (anchorGuid === undefined) {
        notifications.success(t('dashboard.tickets.addon_products.notifications.product_reordered'));
        return;
    }

    await reorderItem({
        parentGuid: mutation.parentGuid,
        itemGuid: mutation.itemGuid,
        anchorGuid,
        direction,
    });

    await loadProductPage(mutation.parentGuid, true);
}

async function reorderItem(mutation: SortEvent) {
    if (mutation.parentGuid === undefined) {
        return;
    }
    const productGroup = management.productGroups.$factory({
        ...management.productGroups.new().$raw,
        guid: mutation.parentGuid,
    });
    const ordering = mutation.direction === 'down' ? 'after' : 'before';
    await productGroup.products.reorderByIds(mutation.itemGuid, ordering, mutation.anchorGuid);
    notifications.success(t('dashboard.tickets.addon_products.notifications.product_reordered'));
}

async function reorderGroup(mutation: SortEvent) {
    if (!context.isTicketContext()) {
        return;
    }

    const ordering = mutation.direction === 'down' ? 'after' : 'before';
    await context.ticket.model.productGroups.reorderByIds(mutation.itemGuid, ordering, mutation.anchorGuid);
    notifications.success(t('dashboard.tickets.addon_products.notifications.product_group_reordered'));
}
</script>

<style scoped lang="scss">
.ticket-addon-products {

    &__forced {
        align-content: center;

        &__flair {
            text-transform: uppercase;
        }
    }

    &__info {
        margin-top: var(--ot-ui-spacing-xs);
        color: var(--ot-ui-color-foreground-secondary);
        display: flex;
        flex-direction: row;
        gap: var(--ot-ui-spacing-xs);
        align-items: center;
    }

    &__header {
        margin-bottom: var(--ot-ui-spacing-3xl);

        &__ticket {
            display: inline-flex;
        }

        &__title {
            margin-bottom: var(--ot-ui-spacing-2xs)
        }
    }

    &__empty {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 20rem;
        flex: 1;
        text-align: center;

        &__title {
            margin-bottom: var(--ot-ui-spacing-3xs);
            flex: 0;
        }

         &__description {
             margin-bottom: var(--ot-ui-spacing-md);
             max-width: 26rem;
         }
    }
}
</style>
