<template>
    <div class="ticket-wizard-tickets">
        <template v-if="ticketsCreated">
            <p>Create Tickets</p>
        </template>
        <template v-else>
            <p>Tickets Overview</p>
        </template>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

// TODO Replace this with the correct creation logic.
const ticketsCreated = ref(false);
</script>
