<template>
    <div class="tfa-totp-prepare">
        <OtAside
            v-if="tfaSetupProvider.showForcedWarning"
            class="tfa-totp-prepare__forced"
            icon="alert"
            type="warning"
            :title="$t('dashboard.user.tfa.setup.forced.title')"
        >
            <!--            TODO uncomment when we know where this links -->
            <!--            <template #description>-->
            <!--                <a class="tfa-totp-prepare__forced__learn-more ot-clickable">-->
            <!--                    {{ $t('dashboard.user.tfa.setup.forced.learn_more') }}-->
            <!--                    <i class="oti oti-carrot-right" />-->
            <!--                </a>-->
            <!--            </template>-->
        </OtAside>

        <div class="tfa-totp-prepare__content">
            <div
                v-if="!manual"
                class="tfa-totp-prepare__content__qr"
            >
                <img
                    :src="totp.values.qr"
                    alt="TOTP qr code"
                    class="tfa-totp-prepare__content__qr__image"
                >

                <div class="tfa-totp-prepare__content__button">
                    <a
                        :href="totp.values.url"
                        target="_blank"
                    >
                        <OtButton
                            icon-right="link"
                            variant="outline"
                        >
                            {{ $t('dashboard.user.tfa.setup.totp.follow_url') }}
                        </OtButton>
                    </a>

                    <OtButton
                        icon="copy"
                        variant="outline"
                        @click.prevent="manualSetup"
                    >
                        {{ $t('dashboard.user.tfa.setup.totp.manual_setup') }}
                    </OtButton>
                </div>
            </div>

            <div
                v-if="manual"
                class="tfa-totp-prepare__content__manual"
            >
                <div class="tfa-totp-prepare__content__manual__items">
                    <div class="tfa-totp-prepare__content__manual__items__element">
                        {{ $t('dashboard.user.tfa.setup.totp.manual.issuer') }}
                        <span>{{ totp.values.manual.issuer }}</span>
                    </div>

                    <div class="tfa-totp-prepare__content__manual__items__element">
                        {{ $t('dashboard.user.tfa.setup.totp.manual.label') }}
                        <span>{{ totp.values.manual.label }}</span>
                    </div>

                    <div class="tfa-totp-prepare__content__manual__items__element">
                        {{ $t('dashboard.user.tfa.setup.totp.manual.secret') }}
                        <span> {{ totp.values.manual.secret }}</span>
                    </div>

                    <div class="tfa-totp-prepare__content__manual__items__element">
                        {{ $t('dashboard.user.tfa.setup.totp.manual.period') }}
                        <span>{{ totp.values.manual.period }}</span>
                    </div>

                    <div class="tfa-totp-prepare__content__manual__items__element">
                        {{ $t('dashboard.user.tfa.setup.totp.manual.algorithm') }}
                        <span> {{ totp.values.manual.algorithm }}</span>
                    </div>

                    <div class="tfa-totp-prepare__content__manual__items__element">
                        {{ $t('dashboard.user.tfa.setup.totp.manual.digits') }}
                        <span> {{ totp.values.manual.digits }}</span>
                    </div>
                </div>

                <div class="tfa-totp-prepare__content__button info-button-hack">
                    <OtButton
                        icon="qr"
                        variant="outline"
                        @click.prevent="qrSetup"
                    >
                        {{ $t('dashboard.user.tfa.setup.totp.qr_code') }}
                    </OtButton>
                </div>
            </div>

            <div class="tfa-totp-prepare__content__separator" />

            <div class="tfa-totp-prepare__content__apps">
                <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
                <h5
                    class="tfa-totp-prepare__content__apps__title ot-ui-text-heading-5"
                    @click="downloadOpen = !downloadOpen"
                    @keydown.space="downloadOpen = !downloadOpen"
                    @keydown.enter="downloadOpen = !downloadOpen"
                >
                    {{ $t('dashboard.user.tfa.setup.totp.get_app') }}
                    <OtIcon
                        type="drop-down"
                        size="small"
                        class="tfa-totp-prepare__content__apps__title__icon"
                        :class="{open: downloadOpen}"
                    />
                </h5>
                <div
                    class="tfa-totp-prepare__content__apps__authy"
                    :class="{open: downloadOpen}"
                >
                    <a
                        target="_blank"
                        aria-label="Google Authenticator App Store badge"
                        href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                    >
                        <img
                            src="../../../assets/images/app_store_badge.svg"
                            aria-hidden="true"
                            alt=""
                        >
                    </a>

                    <a
                        target="_blank"
                        aria-label="Google Authenticator Google Play store badge"
                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                    >
                        <img
                            src="../../../assets/images/google_play_badge.svg"
                            aria-hidden="true"
                            alt=""
                        >
                    </a>
                </div>
                <!--            TODO uncomment when we know where this links -->
                <!--                <div class="tfa-totp-prepare__content__button info-button-hack">-->
                <!--                    <button class="ot-button is-text" type="button">-->
                <!--                        <i class="oti oti-alert ot-button-icon&#45;&#45;left" />-->
                <!--                        {{ $t('dashboard.user.tfa.setup.totp.need_help') }}-->
                <!--                    </button>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { TfaSetupProvider, TotpSetup } from '../types';
import { injectOrFail } from '../../../services/util';

type Props = {
    totp: TotpSetup
}

defineProps<Props>();

const manual = ref<boolean>(false);
const downloadOpen = ref<boolean>(false);

const tfaSetupProvider = injectOrFail<TfaSetupProvider>('TfaSetupProvider');

const manualSetup = (): void => {
    manual.value = true;
};

const qrSetup = (): void => {
    manual.value = false;
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins.scss";

.tfa-totp-prepare {
    &__forced {
        &__learn-more {
            text-decoration: underline;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: var(--ot-ui-spacing-xs);

        @include breakpoint(mob) {
            gap: var(--ot-ui-spacing-2xl);
            flex-direction: column;
            margin-bottom: 0;
        }

        &__qr, &__manual {
            display: flex;
            flex-direction: column;
            width: 100%;

            @include breakpoint(tab) {
                width: 50%;
            }

            @include breakpoint(desk) {
                width: 50%;
            }
        }

        &__qr {
            align-items: center;

            &__image {
                margin-bottom: var(--ot-ui-spacing-lg);
            }
        }

        &__manual {
            text-align: left;
            margin-top: var(--ot-ui-spacing-md);

            &__items {
                margin-bottom: var(--ot-ui-spacing-md);
                margin-right: var(--ot-ui-spacing-lg);
                margin-left: var(--ot-ui-spacing-lg);

                &__element {
                    color: var(--ot-ui-color-foreground-secondary);
                    margin-top: var(--ot-ui-spacing-xs);
                    margin-right: var(--ot-ui-spacing-xs);

                    span {
                        color: var(--ot-ui-color-foreground-primary);
                        margin-left: var(--ot-ui-spacing-2xs);
                    }
                }
            }
        }

        &__apps {
            flex-grow: 1;
            align-items: center;

            &__title {
                font-family: var(--ot-text-font-family);
                display: flex;
                justify-content: center;
                align-items: center;
                gap: var(--ot-spacing-lg);

                @include breakpoint(mob) {
                    color: var(--ot-color-core-brand);
                }

                &__icon {
                    display: none;

                    @include breakpoint(mob) {
                        display: block;
                        transform: rotate(0deg);
                        transition: transform 250ms ease-in-out;

                        &.open {
                            transform: rotate(-180deg);
                        }
                    }
                }
            }

            &__authy {
                display: flex;
                flex-direction: column;
                gap: var(--ot-ui-spacing-lg);
                padding-top: var(--ot-ui-spacing-lg);

                @include breakpoint(mob) {
                    max-height: 0;
                    padding-top: 0;
                    opacity: 0;
                    overflow: hidden;
                    transition: padding-top 500ms ease-in-out, max-height 500ms ease-in-out, opacity 125ms 250ms ease-in-out;
                }

                &.open {
                    opacity: 1;
                    padding-top: var(--ot-ui-spacing-lg);
                    max-height: 500px;
                    overflow: initial;
                }

                & > * {
                    line-height: 0;

                    img {
                        width: 100%;
                        max-width: calc(var(--ot-ui-spacing-md) * 12);
                    }
                }
            }
        }

        &__button {
            text-align: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: var(--ot-ui-spacing-sm);

            & > * {
                width: 100%;
                font-weight: var(--ot-ui-font-weight-medium);
            }
        }

        &__separator {
            border-right: 2px solid var(--ot-ui-color-accent-secondary);
            display: block;

            @include breakpoint(mob) {
                display: none;
            }
        }
    }
}
</style>
