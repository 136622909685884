<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <OtSpinner v-else-if="!shopModel || shopModelLoading" />

    <div
        v-else
        class="shop-tickets"
    >
        <div class="shop-tickets__content">
            <div
                v-if="shopData && !shopDataLoading"
                ref="contentRef"
                class="shop-tickets__content__groups"
            >
                <div
                    v-if="shopData.events.length === 0"
                    class="shop-tickets__content__groups-empty ot-ui-my[3xl]"
                >
                    <h2 class="ot-ui-text-heading-2">
                        {{ $t('dashboard.shop_tickets.empty_events.title') }}
                    </h2>
                    <p class="ot-ui-text-body-lg">
                        {{ $t('dashboard.shop_tickets.empty_events.description') }}
                    </p>
                    <OtButton
                        :title="$t('dashboard.shop_tickets.add_event.title')"
                        :disabled="isReadOnly"
                        data-testid="shop-tickets-add-event-empty"
                        variant="outline"
                        size="small"
                        icon="plus"
                        @click="addEventToShopRef?.open()"
                    >
                        {{ $t('dashboard.shop_tickets.add_event.text') }}
                    </OtButton>
                </div>
                <div v-else>
                    <TicketsPerEvent
                        :model-value="shopData.events"
                        @create:collapse="handleCreateNewCollapseWithTickets"
                        @create:conceptCollapse="handleNewConceptCollapse"
                        @remove:conceptCollapse="handleRemoveConceptCollapse"
                        @remove:ticketFromShop="removeTicketFromShop"
                        @add:ticketsToShop="addTicketsToShop"
                        @refresh="handleRefreshShopData"
                        @move:up="onMoveUp"
                        @move:down="onMoveDown"
                        @link="onLink"
                        @unlink="onUnlink"
                        @open-modal:updateCollapse="openUpdateCollapseModal"
                        @open-modal:addTickets="openAddTicketsToEventModal"
                    />
                    <OtSeparator />
                    <div class="ot-ui-mt[lg]">
                        <OtButton
                            :title="$t('dashboard.shop_tickets.add_event.title')"
                            :disabled="isReadOnly"
                            data-testid="shop-tickets-add-event"
                            icon="plus"
                            variant="outline"
                            size="small"
                            @click="addEventToShopRef?.open()"
                        >
                            {{ $t('dashboard.shop_tickets.add_event.text') }}
                        </OtButton>
                    </div>
                </div>
            </div>

            <!-- eslint-disable vue/no-v-html -->
            <div
                v-else
                class="shop-tickets__content__groups disabled"
                v-html="innerHTMLGroups"
            />
            <!-- eslint-enable -->

            <div class="shop-tickets__content__preview">
                <div class="shop-tickets__content__preview-container">
                    <OtPreview
                        ref="previewRef"
                        :header-label="$t('dashboard.tickets.ticket_design.preview.header')"
                        :src="shopUrl"
                        type="iframe"
                    />
                </div>
            </div>
        </div>

        <AddEventToShopModal
            ref="addEventToShopRef"
            @saved="handleAddEventToShop"
        />
        <UpdateCollapseModal
            ref="updateCollapseModalRef"
            :title="$t('dashboard.shop_tickets.edit_collapse.text')"
            @saved="onEditCollapseSave"
        />
        <AddTicketsToEventModal
            ref="addTicketsToEventModal"
            :title="$t('dashboard.shop_tickets.add_tickets.text')"
            @saved="addTicketsToShop"
        />
    </div>
</template>

<script lang="ts" setup>
import { Log } from '@openticket/lib-log';
import { type CollapseRaw, type ManagementClient, type Shop } from '@openticket/lib-management';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import urlJoin from 'url-join';
import {
    computed, nextTick, ref, useTemplateRef, watch,
} from 'vue';
import { OtPreview, useLocalization } from '@openticket/vue-ui';
import type { ShopData, ShopDataCollapse } from '../../../services/shop';
import { ShopDataManager } from '../../../services/shop';
import TicketsPerEvent from '../components/drag-and-drop/TicketsPerEvent.vue';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';
import ErrorView from '../../../components/ErrorView.vue';
import type { LinkEvent, SortEvent } from '../../../services/shop/types';
import { useGenericErrorHandling, useRoles } from '../../../composables';
import { FailedLoadShopData } from '../services/failed.load.shop.data';
import UpdateCollapseModal from '../components/drag-and-drop/UpdateCollapseModal.vue';
import AddTicketsToEventModal from '../components/drag-and-drop/AddTicketsToEventModal.vue';
import AddEventToShopModal from '../components/drag-and-drop/AddEventToShopModal.vue';

const { error, handleError } = useGenericErrorHandling();
const { t } = useLocalization();
const { isReadOnly } = useRoles();

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

if (!whitelabel.shop.api_url) {
    // TODO: Proper error handling + logging
    throw Error('Whitelabel Shop Api Url not defined');
}

const shopDataManager: ShopDataManager = new ShopDataManager(whitelabel.shop.api_url);

const shopModel = ref<Shop<ManagementClient> | null>(null);
const shopData = ref<ShopData | null>(null);

const shopDataLoading = ref(0);
const shopModelLoading = ref(0);

const innerHTMLGroups = ref();

const previewRef = ref<InstanceType<typeof OtPreview> | null>();
const contentRef = ref<HTMLElement | null>();

const addEventToShopRef = useTemplateRef('addEventToShopRef');
const updateCollapseModalRef = useTemplateRef('updateCollapseModalRef');
const addTicketsToEventModal = useTemplateRef('addTicketsToEventModal');

const shopUrl = computed(() => {
    if (!whitelabel || !context.isShopContext()) {
        return null;
    }

    return urlJoin(whitelabel.shop.url, context.shop.id, '?nocache');
});

async function loadShopData(): Promise<void> {
    if (!context.isShopContext() || !whitelabel.shop.api_url) {
        shopData.value = null;
        void handleError(new FailedLoadShopData(context.shop?.id, whitelabel.shop.api_url), Log.Warn);
        return;
    }

    try {
        shopData.value = await shopDataManager.load(context.shop.id);
    } catch (e) {
        void handleError(e, Log.Fatal);
    }
}

async function setShopModel() {
    if (context.isShopContext()) {
        shopModelLoading.value++;

        try {
            shopModel.value = context.shop.model;
            await loadShopData();
        } catch (e) {
            void handleError(e, Log.Error);
        } finally {
            shopModelLoading.value--;
        }
    }
}

watch(() => context.shop, () => {
    void setShopModel();
});

void setShopModel();

async function handleCreateNewCollapseWithTickets(collapse: ShopDataCollapse) {
    try {
        // NextTick is used to guarantee the shadowContent has the moved ticket inside the concept collapse
        await nextTick();
        setShadowLoadingContent();
        shopDataLoading.value++;
        if (!shopModel.value) {
            return;
        }

        // Create new Collapse on management and link ticket after creation.
        const collapseModelRaw: CollapseRaw = {
            title: collapse.title,
            start_open: !!collapse.start_open,
            inner_content: collapse.inner_content,
            inner_title: collapse.inner_title,
            post_content: collapse.post_content,
            pre_content: collapse.pre_content,
            pre_title: collapse.pre_title,
        };

        const collapseModel = shopModel.value.collapses.$factory(collapseModelRaw);
        const createdCollapse = await shopModel.value.collapses.create(collapseModel);

        // TODO Remove hack to use correct relation and api route via createdCollapse
        const collapseAgain = management.collapses.$factory(createdCollapse.$raw);

        // TODO Determine way to remove linking tickets by model
        // Would prefer a linking method by id? Maybe a custom linkId mixin
        const ticket = await management.tickets.find(collapse.items[0].guid);
        await collapseAgain.tickets.link(ticket, {});
        // @todo: await shopModel.value.tickets.reorderByIds(ticket.guid, 'after', previousTicketOrCollapseGuid);

        await handleRefreshShopData();
    } catch (e) {
        void handleError(e);
    } finally {
        shopDataLoading.value--;
    }
}

function handleNewConceptCollapse(eventIndex: number) {
    if (!shopData.value) {
        return;
    }
    const conceptNumber = Math.floor(Math.random() * 1001);
    const conceptCollapse: ShopDataCollapse = {
        _type_: 'collapse',
        guid: `concept-${conceptNumber}`,
        title: t('dashboard.shop_tickets.concept_group.title'),
        index: null,
        items: [],
        concept: true,
        start_open: true,
    };
    shopData.value.events[eventIndex].items.push(conceptCollapse);
}

function handleUpdateConceptCollapseSettings(collapse: ShopDataCollapse, eventIndex: number) {
    if (!shopData.value) {
        return;
    }

    const updatedCollapseIndex = shopData.value.events[eventIndex].items.findIndex((item) => item.guid === collapse.guid);
    if (updatedCollapseIndex !== -1) {
        // replace old collapse with new updated collapse
        const oldCollapse = shopData.value.events[eventIndex].items[updatedCollapseIndex];
        shopData.value.events[eventIndex].items.splice(updatedCollapseIndex, 1, { ...oldCollapse, ...collapse });
    }
}

function handleRemoveConceptCollapse(conceptCollapseId: string, eventIndex: number) {
    if (!shopData.value) {
        return;
    }
    const collapseIndex = shopData.value.events[eventIndex].items.findIndex((item) => item.guid === conceptCollapseId);
    shopData.value.events[eventIndex].items.splice(collapseIndex, 1);
}

function openUpdateCollapseModal(collapse: ShopDataCollapse, eventIndex: number) {
    if (updateCollapseModalRef.value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        updateCollapseModalRef.value.open(collapse, eventIndex);
    }
}

function openAddTicketsToEventModal(eventId: string): void {
    if (addTicketsToEventModal.value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        addTicketsToEventModal.value.open(eventId);
    }
}

function onEditCollapseSave(data: { updatedCollapse?: ShopDataCollapse, eventIndex?: number, isManagementModel: boolean }) {
    if (!data.isManagementModel && typeof data.eventIndex === 'number' && data.updatedCollapse) {
        handleUpdateConceptCollapseSettings(data.updatedCollapse, data.eventIndex);
    } else {
        setShadowLoadingContent();
        void handleRefreshShopData();
    }
}

async function removeTicketFromShop(ticketId: string, collapseId?: string) {
    try {
        setShadowLoadingContent();
        shopDataLoading.value++;
        if (!shopModel.value) {
            return;
        }
        // Fetch ticket from shop
        const ticket = await shopModel.value.tickets.find(ticketId);

        // Unlink from shop
        await shopModel.value.tickets.unlink(ticket);

        // Unlink from collapse if ticket was inside a collapse and delete collapse if it is empty after removing ticket.
        if (collapseId) {
            // Fetch collapse
            const collapse = await management.collapses.find(collapseId);

            // Unlink ticket from collapse
            const ticketConverted = collapse.tickets.$factory(ticket.$raw);
            await collapse.tickets.unlink(ticketConverted);

            // Count/Fetch/Calc remaining tickets inside collapse.
            // TODO Find a way to know when to delete the complete collapse from the backend
        }

        await handleRefreshShopData();
    } catch (e) {
        void handleError(e);
    } finally {
        shopDataLoading.value--;
    }
}

function addTicketsToShop(ticketIds: string[]) {
    void linkTicketsToShopModel(ticketIds);
}

function handleAddEventToShop(ticketIds: string[]) {
    void linkTicketsToShopModel(ticketIds);
}

async function linkTicketsToShopModel(ticketIds: string[]) {
    try {
        setShadowLoadingContent();
        shopDataLoading.value++;
        if (!shopModel.value) {
            return;
        }

        // fetch tickets by ids and push into array
        const ticketModels = [];
        for (let index = 0; index < ticketIds.length; index++) {
            const ticketModel = await management.tickets.find(ticketIds[index]);
            ticketModels.push(ticketModel);
        }

        // linkMulti to currentShop
        await shopModel.value.tickets.linkMulti(...ticketModels);

        await handleRefreshShopData();
    } catch (e) {
        void handleError(e);
    } finally {
        shopDataLoading.value--;
    }
}

async function handleRefreshShopData(): Promise<void> {
    try {
        shopDataLoading.value++;
        if (shopModel.value) {
            await loadShopData();
        }
    } catch (e) {
        void handleError(e);
    } finally {
        shopDataLoading.value--;
        reloadPreview();
    }
}

function reloadPreview() {
    if (previewRef.value) {
        previewRef.value.reloadContent();
    }
}

function setShadowLoadingContent() {
    if (contentRef.value) {
        innerHTMLGroups.value = contentRef.value.innerHTML;
    }
}

async function onMoveUp({ itemGuid, anchorGuid }: SortEvent) {
    await shopModel.value.tickets.reorderByIds(itemGuid, 'before', anchorGuid);
    reloadPreview();
}

async function onMoveDown({ itemGuid, anchorGuid }: SortEvent) {
    await shopModel.value.tickets.reorderByIds(itemGuid, 'after', anchorGuid);
    reloadPreview();
}

async function onLink({ itemGuid, parentGuid }: LinkEvent) {
    const ticket = await management.tickets.find(itemGuid);
    const collapse = await management.collapses.find(parentGuid);
    await collapse.tickets.link(ticket, {});
    reloadPreview();
}

async function onUnlink({ itemGuid, parentGuid }: LinkEvent) {
    const ticket = await management.tickets.find(itemGuid);
    const collapse = await management.collapses.find(parentGuid);
    await collapse.tickets.unlink(ticket);
    reloadPreview();
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/mixins.scss";

.shop-tickets {
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: row;
        gap: var(--ot-ui-spacing-3xl);

        &__groups {
            flex: 4;

            &-empty {
                flex: 1;
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: var(--ot-ui-spacing-md);
            }

            &.disabled {
                cursor: progress;
                opacity: .65;
                filter: blur(1px);
                transition: opacity 0.5s ease-in-out;
                transition: filter 0.5s ease-in-out;

                & > * {
                    pointer-events: none;
                }
            }
        }

        &__preview {
            flex: 2;
            margin-top: 2.5rem;

            @media (max-width: 75rem) {
                display: none;
            }

            &-container {
                position: sticky;
                top: calc(var(--ot-ui-dashboard-layout-header-height) + 1rem);
                right: 0;
                height: calc(90vh - var(--ot-ui-dashboard-layout-header-height) + 1rem);
                min-height: 50vh;
            }
        }
    }

}
</style>
