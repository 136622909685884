import type {
    ContextType, ContextTypeArgs, ContextTypesModelsMap,
} from './types';

interface ModelMap extends ContextTypesModelsMap {
    global: never;
}

type Args<K extends keyof ModelMap> = [ K, ModelMap[K] ];

export function isContextModel<T extends ContextType>(target: ContextTypeArgs<T>[1]): target is Args<T>[1] {
    if (!target || typeof target !== 'object') {
        return false;
    }

    return 'id' in target && typeof target.id === 'string';
}
