import { OtError } from '@openticket/lib-log';

export class ContextCompanyError extends OtError {

    constructor() {
        super('ContextCompanyError', 'dashboard.errors.plugins.context.company', 'Failed to set company context.');

        this.captureStackTrace(ContextCompanyError);
    }

}
