<template>
    <div class="module__events__list-record">
        <div class="module__events__list-record__left">
            <h3 class="ot-ui-text-heading-3">
                {{ record.$data.name }}
            </h3>

            <OtButton
                v-if="company"
                variant="inline"
                class="module__events__list-record__left__company-name"
                @click.stop="goToCompaniesHome(record.$data.company_id)"
                @keydown.space.stop="goToCompaniesHome(record.$data.company_id)"
                @keydown.enter.stop="goToCompaniesHome(record.$data.company_id)"
            >
                {{ company.name }}
            </OtButton>

            <div
                v-if="record.$data.start && record.$data.end"
                class="module__events__list-record__left__date ot-ui-text-body-lg-strong"
            >
                <OtIcon type="calendar" />
                {{ $l.dateTimeRangeCollapseLong(record.$data.start, record.$data.end) }}
            </div>
        </div>

        <div class="module__events__list-record__right">
            <div
                v-if="record.$data.start && hoursUntilStartEvent && hoursUntilStartEvent > 0"
                class="module__events__list-record__right__diff"
            >
                <div
                    class="ot-ui-text-body-intro-strong"
                >
                    {{
                        getRelativeTimeString(
                            new Date(record.$data.start),
                            $localization.locale.language,
                            { numeric: 'always' },
                            { discardPrefix: true }
                        )
                    }}
                </div>
                <div
                    class="module__events__list-record__right__diff__label ot-ui-text-body-md"
                >
                    {{ $t('dashboard.events.list.diff.until') }}
                </div>
            </div>

            <div class="module__events__list-record__right__actions">
                <OtButton
                    :title="$t('dashboard.events.list.view.title', { event: record.$data.name })"
                    variant="outline"
                    icon-right="carrot-right"
                    size="small"
                    @click.stop="goToEventsHome(record.$data.guid)"
                    @keydown.space="goToEventsHome(record.$data.guid)"
                    @keydown.enter="goToEventsHome(record.$data.guid)"
                >
                    {{ $t('dashboard.events.list.view.text') }}
                </OtButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type VueRouter from 'vue-router';
import { useRouter } from 'vue-router';
import type { Event, ManagementClient } from '@openticket/lib-management';
import { computed, ref, type Ref } from 'vue';
import type { AuthClient, CompanyRaw } from '@openticket/lib-auth';
import { getRelativeTimeString, injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';

interface Props {
  record: Event<ManagementClient>;
}

const props = defineProps<Props>();

const router: VueRouter.Router = useRouter();
const companies = ref<CompanyRaw[] | null | undefined>(null) as Ref<CompanyRaw[] | null | undefined>;

const auth = injectOrFail<AuthClient>('auth');
const context = injectOrFail<Context>('context');

void (async () => {
    companies.value = (await auth.$token.$info)?.companies;
})();

async function goToCompaniesHome(companyId: string) {
    await context.updateContext('company', { company: companyId });
}

async function goToEventsHome(eventId: string) {
    await router.push({
        name: 'events.home',
        params: { event: eventId },
    });
}

const hoursUntilStartEvent = computed<number | null>(() => {
    if (!props.record?.$data?.start) {
        return null;
    }

    // Use the browser's current datetime and the datetime of the
    // start of the event. Timezone is included in the start attribute
    // of the record, so this is automatically covered.
    const today = new Date();
    const eventDate = new Date(props.record.$data.start);
    const secondsUntil = (eventDate - today) / 1000;

    // One hour contains 60 * 60 seconds. No need to take care of
    // leap days. Use floor to round down.
    return Math.floor(secondsUntil / (60 * 60));
});

const company = computed<CompanyRaw | null>(() => {
    if (!props.record?.$data?.company_id) {
        return null;
    }

    return (companies.value || []).find((singleCompany) => singleCompany.guid === props.record.$data.company_id) || null;
});
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/mixins.scss';

.module__events__list-record {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @include breakpoint(mob) {
        flex-direction: column;
        gap: var(--ot-ui-spacing-2xl);
    }

    &__left {
        &__company-name.ot-ui-button {
            padding: 0;
        }

        &__company-name.ot-ui-button:deep(span) {
            font-weight: var(--ot-ui-font-weight-medium);
        }

        &__company-name::after {
            display: none;
        }

        &__date .oti {
            width: 1em;
            height: 1em;
            vertical-align: middle;
        }
    }

    &__right {
        display: flex;
        flex-direction: row;
        justify-content: stretch;

        @include breakpoint(mob) {
            justify-content: right;
        }

        & > * {
            display: flex;
            align-items: center;
        }

        &__diff {
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;

            padding-right: 1rem;
            margin-right: 1rem;
            border-right: solid 1px var(--ot-ui-color-accent-tertiary);

            &__label {
                color: var(--ot-ui-color-foreground-secondary);
                white-space: nowrap;
            }
        }
    }
}
</style>
