<template>
    <div class="error-view">
        <OtCard
            class="error-view__card"
            no-padding
        >
            <div class="error-view__card__header">
                <img
                    v-if="tryLogo"
                    :src="tryLogo"
                    alt="logo"
                    tabindex="0"
                    role="button"
                    @click="goHome()"
                    @keydown.space="goHome()"
                    @keydown.enter="goHome()"
                >

                <OtButton
                    icon="logout"
                    size="small"
                    :title="t('dashboard.common.action.logout.title')"
                    @click="tryLogout"
                >
                    {{ t('dashboard.common.action.logout.text') }}
                </OtButton>
            </div>

            <OtCardContent>
                <h1 class="ot-ui-text-heading-1">
                    {{ t('dashboard.common.state.error') }}
                </h1>

                <p class="ot-ui-text-body-intro">
                    {{ t(tryReason) }}
                </p>

                <p
                    v-if="logoutError"
                    class="ot-ui-text-body-intro"
                >
                    {{ logoutError }}
                </p>
            </OtCardContent>

            <OtCardFooter>
                <OtButton
                    full-width
                    :title="t('dashboard.common.action.retry.title')"
                    variant="secondary"
                    @click="goBack()"
                    @keydown.space="goBack()"
                    @keydown.enter="goBack()"
                >
                    {{ t('dashboard.common.action.retry.text') }}
                </OtButton>
            </OtCardFooter>
        </OtCard>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { useRouter } from 'vue-router';
import { useLocalization } from '@openticket/vue-ui';
import type { RudderStack } from '../../../services/rudderstack';
import { useDocumentTitle } from '../../../composables';

type Props = {
    reason: string;
}
type TranslateFunction = (key: unknown, values?: { [key: string]: unknown }) => string;

const props = withDefaults(defineProps<Props>(), { reason: '' });

// Not using injectOrFail, if it's allowed to fail, it could result in a redirect loop to the error page.
const rudderstack = inject<RudderStack | null>('rudderstack', null);
const whitelabel = inject<Whitelabel | null>('whitelabel', null);

let translateFn: TranslateFunction | null = null;

try {
    useDocumentTitle();

    const loc = useLocalization();
    translateFn = loc.t;
} catch (e) {
    // Fail silently
}

const router = useRouter();

const logoutError = ref<string>('');

rudderstack?.page('vue-dashboard', 'error', { reason: props.reason });

const t = computed<TranslateFunction>(() => (key, values) => {
    // Yes this is utterly verbose, but this can not be allowed to fail on the error page.
    // If it's allowed to fail, it could result in a redirect loop to the error page.
    if (typeof key !== 'string') {
        return 'Unknown';
    }

    try {
        if (translateFn) {
            return translateFn(key, values);
        }

        return key;
    } catch (e) {
        return key;
    }
});

const tryLogo = computed<string>(() => {
    try {
        const logo = whitelabel?.resources.logo;

        return logo && typeof logo === 'string' ? logo : '';
    } catch (e) {
        return '';
    }
});

const tryReason = computed<string>(() => {
    try {
        return props.reason && typeof props.reason === 'string' ? props.reason : '';
    } catch (e) {
        return '';
    }
});

async function tryLogout(): Promise<void> {
    logoutError.value = '';

    try {
        await router.push({ name: 'auth.logout' });
    } catch (e) {
        logoutError.value = 'Failed to logout, please try to clear all browser data associated with this site. E.g. cookies, storage, etc.';
    }
}

function goBack(): void {
    try {
        void router.back();
    } catch (e) {
        // Silently ignore
    }
}

function goHome(): void {
    try {
        void router.push({ name: 'home' });
    } catch (e) {
        // Silently ignore
    }
}
</script>

<style lang="scss" scoped>
.error-view {
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 100vh;
    background: var(--ot-ui-color-background-secondary);

    &__card {
        min-width: 40rem;

        @media (max-width: 40rem) {
            min-width: calc(100% - 2 * var(--ot-ui-spacing-md));
        }

        &__header {
            margin: var(--ot-ui-spacing-xl);
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: var(--ot-ui-spacing-md);

            img {
                max-width: 100%;
                max-height: 3rem;
                cursor: pointer;
            }
        }
    }
}
</style>
