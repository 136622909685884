<template>
    <OtModal
        ref="modalRef"
        class="add-event-to-shop-modal"
        :width="600"
        :title="$t('dashboard.shop_tickets.add_event_modal.header.title')"
        :subtitle="$t('dashboard.shop_tickets.add_event_modal.header.subtitle')"
        @close="close"
    >
        <div
            v-if="!loading"
            class="ot-ui-mb[md]"
        >
            <OtFormRow>
                <OtInputField
                    :label="$t('dashboard.shop_tickets.add_event_modal.inputs.event.label')"
                    :description="$t('dashboard.shop_tickets.add_event_modal.inputs.event.description')"
                >
                    <OtPaginatedSelectInput
                        :model-value="selectedEvent"
                        :pagination="eventsPagination"
                        data-primary-key="name"
                        data-testid="shops-tickets-event-picker"
                        :placeholder="eventSelectPlaceholderLabel"
                        @input="handleEventSelect"
                    />
                </OtInputField>
            </OtFormRow>

            <OtFormRow v-if="events && selectedEvent && selectedEventModel">
                <OtInputField
                    :key="selectedEvent.toString()"
                    :label="$t('dashboard.shop_tickets.add_event_modal.inputs.tickets.label')"
                    :description="$t('dashboard.shop_tickets.add_event_modal.inputs.tickets.description')"
                >
                    <div class="add-event-to-shop-modal__tickets-input">
                        <LinkUnlinkPicker
                            ref="linkTicketsToShopRef"
                            :items-label="$t('models.ticket.name.lowercase.plural')"
                            :pagination-relation="selectedEventModel.tickets"
                        />
                    </div>
                </OtInputField>
            </OtFormRow>
        </div>

        <OtSpinner v-else />

        <template #footer-action>
            <OtButton
                icon="check"
                :title="$t('dashboard.common.action.save.title')"
                :disabled="saveDisabled"
                @click="save"
            >
                {{ $t('dashboard.common.action.save.text') }}
            </OtButton>
        </template>
    </OtModal>
</template>

<script setup lang="ts">
import {
    computed, ref, useTemplateRef,
} from 'vue';
import type {
    Event, EventData, ManagementClient,
} from '@openticket/lib-management';
import { useLocalization } from '@openticket/vue-ui';
import { injectOrFail } from '../../../../services/util';
import type { Context } from '../../../../services/context';
import LinkUnlinkPicker from '../../../../components/modal/link-unlink/LinkUnlinkPicker.vue';

type SelectOptions = {
    [key: PropertyKey]: object | string | number | boolean;
};

type Emits = {
    (e: 'saved', ticketGuids: string[]): void
}

const management = injectOrFail<ManagementClient>('management');
const context = injectOrFail<Context>('context');

const emit = defineEmits<Emits>();

const { t } = useLocalization();

const modalRef = useTemplateRef('modalRef');
const linkTicketsToShopRef = useTemplateRef<InstanceType<typeof LinkUnlinkPicker>>('linkTicketsToShopRef');

const events = ref<Record<string, EventData>>({});
const eventsPagination = ref(context.company?.model.events.list({ deferInitialization: true }));
const selectedEvent = ref<(keyof SelectOptions) | (keyof SelectOptions)[] | undefined>();
const selectedEventModel = ref<Event<ManagementClient>>();

const loading = ref(0);
const ticketsLoading = ref(0);

const eventSelectPlaceholderLabel = t(
    'dashboard.components.link_unlink.select.placeholder',
    { items: t('models.event.name.lowercase.plural') },
);

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const saveDisabled = computed(() => !selectedEvent.value
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    || (linkTicketsToShopRef.value && linkTicketsToShopRef.value.diff.toLink.length === 0)
    || Boolean(loading.value)
    || Boolean(ticketsLoading.value));

function save() {
    if (
        linkTicketsToShopRef.value
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        && linkTicketsToShopRef.value.hasLocalChanges
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        && linkTicketsToShopRef.value.diff.toLink.length > 0
    ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        emit('saved', linkTicketsToShopRef.value.diff.toLink);
        close();
    }
}

function open() {
    if (modalRef.value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalRef.value.open();
    }
}

function close() {
    if (modalRef.value) {
        selectedEvent.value = undefined;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        void modalRef.value.close();
    }
}

async function handleEventSelect(guid: string) {
    selectedEvent.value = guid;
    selectedEventModel.value = await management.events.find(guid);
}

void (() => {
    void eventsPagination.value?.initialization;
})();

defineExpose({
    open,
});
</script>

<style lang="scss" scoped>
.add-event-to-shop-modal {
    &__tickets-input {
        flex-grow: 1;
    }
}
</style>
