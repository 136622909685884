import { useLocalization } from '@openticket/vue-ui';
import { inject, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { Context } from '../services/context';

/**
 * The document title composer will listen to route and locale changes and update the document title
 * accordingly so the user knows where they are by reading the documenttitle.
 *
 * The document title can be updated by giving the route a meta prop: title.
 * Each route should also be given a titleFallback as that will be the default title when there is no title given.
 * */
export function useDocumentTitle() {
    const route = useRoute();
    const { t, te, localization } = useLocalization();

    const context = inject<Context>('context');
    const whitelabel = inject<Whitelabel>('whitelabel');

    const baseTitle = ref(t('dashboard.document_title.main', { whitelabel: whitelabel?.name ?? '' }, 'Dashboard').trim());

    watch(route, () => {
        update();
    });

    function update() {
        try {
            const parts: string[] = [ baseTitle.value ];

            if (route) {
                const values = context?.values || {};

                for (const record of route.matched) {
                    const value: string = t(record.meta.title || '', values, record.meta.titleFallback || '');

                    if (value && value !== record.meta.title) {
                        parts.unshift(value);
                    }
                }
            }

            window.document.title = parts.join(' | ');
        } catch (error) {
            console.warn('>> Failed to set document title', error);
            window.document.title = te('dashboard.document_title.main') ? t('dashboard.document_title.main') : 'Dashboard';
        }
    }

    // Updates on locale change
    localization.on('locale-change', () => update());

    // Initial document title update
    update();
}
