import type {
    Model, ModelConfig, Parent, RulesMap,
} from '@openticket/lib-crud';
import { CustomValidatedPost } from '@openticket/lib-auth';
import { useModelForm } from './useModelForm';
import type { ModelFormComposableResult } from './useModelForm';
import type { ModelCustomValidatedPostFormConfig, Relation } from './types';

export function useCustomValidatedPostForm<
    M extends Model<P, ModelConfig, RulesMap>,
    P extends Parent,
>(
    _model: M,
    _relation: Relation<M, P>,
    config?: ModelCustomValidatedPostFormConfig<M, P>,
): ModelFormComposableResult<M, P> {
    return useModelForm(_model, _relation, CustomValidatedPost, config);
}
