<template>
    <!-- TODO: Move this component to VueUI -->
    <div
        class="form-collapse"
        :class="{ open, disabled }"
    >
        <div
            class="form-collapse__header"
            tabindex="0"
            role="button"
            @click="clickHeader()"
            @keydown.enter="clickHeader()"
            @keydown.space="clickHeader()"
        >
            <div
                v-if="headerIcon"
                class="form-collapse__header__icon"
            >
                <OtIcon :type="headerIcon" />
            </div>
            <div class="form-collapse__header__content">
                <strong class="form-collapse__header__title ot-ui-text-body-intro-strong">
                    {{ title }}
                    <span
                        v-if="optionalLabel"
                        class="form-collapse__header__optional ot-ui-text-body-sm ot-ui-ml[xs]"
                    >
                        Optional Label
                        {{ optionalLabel }}
                    </span>
                </strong>
                <p class="form-collapse__header__text ot-ui-text-body-md ot-ui-mt[2xs]">
                    {{ subtitle }}
                </p>
            </div>
            <div class="form-collapse__header__actions">
                <slot name="header-action">
                    <OtButton
                        :disabled="disabled"
                        size="tiny"
                        variant="secondary"
                        :icon="open ? 'drop-up' : 'drop-down'"
                    >
                        {{ open ? buttonCloseLabel : buttonOpenLabel }}
                    </OtButton>
                </slot>
            </div>
        </div>
        <div
            v-show="open"
            v-if="!disabled"
            class="form-collapse__content"
        >
            <slot />
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { OtIconTypes } from '@openticket/vue-ui';
import {
    onMounted, provide, ref, watch,
} from 'vue';

type Props = {
    title: string,
    subtitle?: string,
    headerIcon?: OtIconTypes,
    disabled?: boolean,
    defaultOpen?: boolean,
    opened?: boolean | null,
    optionalLabel?: string,
    buttonOpenLabel?: string,
    buttonCloseLabel?: string,
};

type Emits = {
    (e: 'open-changed', value: boolean): void
}

const props = withDefaults(defineProps<Props>(), {
    buttonCloseLabel: undefined,
    buttonOpenLabel: undefined,
    disabled: false,
    defaultOpen: false,
    headerIcon: undefined,
    opened: null,
    optionalLabel: undefined,
    subtitle: undefined,
});
const emit = defineEmits<Emits>();

provide('inFormCollapse', true);

const open = ref<boolean>(false);

watch(() => props.opened, (newValue) => {
    open.value = newValue ?? false;
});

onMounted(() => {
    open.value = props.opened === null ? props.defaultOpen : props.opened;
});

function clickHeader() {
    open.value = !open.value;

    emit('open-changed', open.value);
}

defineExpose({
    clickHeader,
    open,
});
</script>

<style lang="scss" scoped>
.form-collapse {
    background: var(--ot-ui-color-accent-tertiary);
    padding: var(--ot-ui-spacing-xl) var(--ot-ui-spacing-xl) 0 var(--ot-ui-spacing-xl);

    &__header {
        cursor: pointer;
        border-bottom: 1px solid transparent;
        display: flex;
        align-items: center;
        padding-bottom: var(--ot-ui-spacing-xl);

        &__icon svg {
            margin-right: var(--ot-ui-spacing-xs);
            color: var(--ot-ui-color-foreground-secondary);
        }

        &__title {
            color: var(--ot-ui-color-foreground-secondary);
        }

        &__optional {
            color: var(--ot-ui-color-intent-success-primary);
        }

        &__text {
            color: var(--ot-ui-color-accent-primary);
        }

        &__content {
            flex: 1;
        }
    }

    &__content {
        padding-top: var(--ot-ui-spacing-xl);
    }

    &.open {
        padding: var(--ot-ui-spacing-xl);

        .form-collapse__header {
            border-bottom: 1px solid var(--ot-ui-color-accent-secondary);
        }
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.disabled & {
        &__header {
            pointer-events: none;
        }
    }
}
</style>
