import {
    Create, CrudRootNode, Find, ToMany, Update,
} from '@openticket/lib-crud';
import { isAxiosError } from 'axios';
import { WaitingList } from './models';
import type { WaitingListConfig } from './models';
import type { WaitingListClientConfig } from './types';

export class WaitingListClient extends CrudRootNode {

    private readonly path: string;

    get $path(): string {
        return this.path;
    }

    waitingList = new ToMany<WaitingList<WaitingListClient>, WaitingListConfig, WaitingListClient, {}>(WaitingList, this, 'waiting_list')
        .with(Create)
        .with(Update)
        .with(Find);

    constructor(config: WaitingListClientConfig) {
        super(config.http);
        this.path = config.baseUrl;
    }

    // DD-WAITING-LIST-2713 - Waiting list guids are the same as their event id,
    // since an event is unique to a waiting list
    public async findOrCreate(eventId: string, companyId: string): Promise<WaitingList<WaitingListClient> | undefined> {
        try {
            return await this.waitingList.find(eventId);
        } catch (error) {
            if (isAxiosError(error) && error.response?.status === 404) {
                try {
                    const newWaitingList = new WaitingList<WaitingListClient>(WaitingListClient, {
                        ...WaitingList.$defaultData(),
                        guid: eventId,
                        event_id: eventId,
                        company_id: companyId,
                        type: 'all_tickets_sold_out',
                    });
                    await this.waitingList.create(newWaitingList);
                    return await this.waitingList.find(eventId);
                } catch (createError) {
                    throw Error('Could not create waiting list');
                }
            }
        }
        return undefined;
    }

}
