import type { SubmissionFail, SubmissionValidationFail, SubmissionSuccess } from './types';

export function useSubmission() {
    function asFail(): SubmissionFail {
        return { success: false };
    }

    function asSuccess(): SubmissionSuccess {
        return { success: true };
    }

    function asValidationFail(): SubmissionValidationFail {
        return { success: false, validationError: true };
    }

    return {
        asFail,
        asSuccess,
        asValidationFail,
    };
}
