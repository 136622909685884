<template>
    <AvatarHeader
        class="user-header"
        :name="name"
    >
        <div class="ot-ui-mt[xs]">
            <template v-if="!hideRole">
                <OtFlair
                    v-for="(role, key) in roles"
                    :key="key"
                    :inversed="false"
                    :centered="false"
                    :variant="role.variant"
                    :text="role.label"
                />
            </template>

            <OtFlair
                v-if="twoFactorEnabled"
                icon="check"
                :inversed="false"
                :centered="false"
                variant="success"
                :text="$t('dashboard.members.tag.tfa')"
            />
        </div>
    </AvatarHeader>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization, type OtFlairVariants } from '@openticket/vue-ui';
import AvatarHeader from '../AvatarHeader.vue';

type Props = {
    name: string;
    subtitle?: string | null;
    manager?: boolean;
    twoFactorEnabled?: boolean;
    hideRole?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    subtitle: null,
    manager: false,
    twoFactorEnabled: false,
});

const { t } = useLocalization();

interface RoleFlair {
    label: string;
    variant: OtFlairVariants;
}

const roles = computed((): { [key: string]: RoleFlair } => {
    // This getter is a bit over-engineered.
    // This is done to reduce the template conditional collisions.
    // And this keeps flexibility towards the future, where it is expected
    // to get changes to the way roles are used/done.
    if (props.manager) {
        return {
            manager: {
                label: t('dashboard.members.tag.manager'),
                variant: 'black',
            },
        };
    }

    return {
        view_only: {
            label: t('dashboard.members.tag.view_only'),
            variant: 'light',
        },
    };
});
</script>
