<template>
    <FormModal
        :form="form"
        :modal="modal"
        :title="$t('dashboard.addon_products.list.new.title')"
        :subtitle="$t('dashboard.addon_products.list.new.intro')"
        :submit-button-labels="{
            text: $t('dashboard.addon_products.list.new.submit.text'),
            title: $t('dashboard.addon_products.list.new.submit.title')
        }"
        add-another-enabled
    >
        <AddonProductForm
            hide-description-field
            :form="form"
        />
    </FormModal>
</template>

<script setup lang="ts">
import { reactive, watch, type UnwrapNestedRefs } from 'vue';
import type { Product, ManagementClient, Event } from '@openticket/lib-management';
import FormModal from '../../../components/form/FormModal.vue';
import { useCreateForm, type FormModalComposableResult } from '../../../composables/forms';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import AddonProductForm from './forms/AddonProductForm.vue';

type Props = {
    modal: UnwrapNestedRefs<FormModalComposableResult>,
}

type Emits = {
    (e: 'submit', model: Product<ManagementClient>): void
}

defineProps<Props>();
const emit = defineEmits<Emits>();

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const form = reactive(
    useCreateForm<Product<ManagementClient>, Event<ManagementClient>>(
        context.event.model.products.new(),
        context.event.model.products,
        {
            onSubmit(model) {
                emit('submit', model);
            },
        },
    ),
);

watch(() => form.rules, () => {
    // The event_id properties are marked as required in the rules but not settable as it is readonly.
    // Until CU-86byezdqr is implemented, the below is needed for the validation to succeed
    // TODO: Remove below after the above task is implemented
    delete form.rules.event_id;
});
</script>
