<template>
    <nav class="pagination-controls">
        <ul class="pagination-controls__pages">
            <li
                v-if="lastPage >= 5"
                class="pagination-controls__pages__item"
                :disabled="currentPage === 1"
                role="button"
                tabindex="0"
                @click="goToPage(1)"
                @keydown.space="goToPage(1)"
                @keydown.enter="goToPage(1)"
            >
                <OtIcon type="arrow-left" />
            </li>

            <li
                class="pagination-controls__pages__item"
                :disabled="currentPage < 2 || lastPage < 2"
                role="button"
                tabindex="0"
                @click="shiftPage(-1)"
                @keydown.space="shiftPage(-1)"
                @keydown.enter="shiftPage(-1)"
            >
                <OtIcon
                    type="carrot-left"
                    size="small"
                />
            </li>

            <template v-for="(nr, index) of pageRange">
                <li
                    v-if="nr === 'dots'"
                    :key="`pagination-controls__pages__item-dots-${index}`"
                    class="pagination-controls__pages__item dots"
                >
                    ...
                </li>

                <li
                    v-else
                    :key="`pagination-controls__pages__item-${nr}`"
                    class="pagination-controls__pages__item nr"
                    :class="{ focus: nr === currentPage, dots: nr === -1}"
                    role="button"
                    tabindex="0"
                    @click="goToPage(nr)"
                    @keydown.space="goToPage(nr)"
                    @keydown.enter="goToPage(nr)"
                >
                    {{ nr }}
                </li>
            </template>

            <li
                class="pagination-controls__pages__item pagination-controls__next-page-button"
                :disabled="currentPage >= lastPage || lastPage < 2"
                role="button"
                tabindex="0"
                @keydown.space="shiftPage(1)"
                @click="shiftPage(1)"
                @keydown.enter="shiftPage(1)"
            >
                <OtIcon
                    type="carrot-right"
                    size="small"
                />
            </li>

            <li
                v-if="lastPage >= 5"
                class="pagination-controls__pages__item"
                :disabled="currentPage >= lastPage"
                role="button"
                tabindex="0"
                @click="goToPage(lastPage)"
                @keydown.space="goToPage(lastPage)"
                @keydown.enter="goToPage(lastPage)"
            >
                <OtIcon type="arrow-right" />
            </li>
        </ul>
    </nav>
</template>

<script lang="ts" setup>
import {
    computed,
} from 'vue';
import type { ComputedRef, UnwrapNestedRefs } from 'vue';
import type { Pagination } from '@openticket/lib-crud';
import type { MutationTypes } from '../../services/revisor';
import { useGenericErrorHandling } from '../../composables';

type Props = {
  revisions: UnwrapNestedRefs<Pagination<MutationTypes>>;
}

const props = defineProps<Props>();
const { handleError } = useGenericErrorHandling();

const currentPage: ComputedRef<number> = computed(() => props.revisions.currentPage || 1);
const lastPage: ComputedRef<number> = computed(() => props.revisions.lastPage || 1);

const pageRange: ComputedRef<Array<number | 'dots'>> = computed(() => {
    // x 2 3 ... 11
    // 1 x 3 ... 11
    // 1 2 x 4 ... 11
    // 1 2 3 x 5 ... 11
    // 1 ... 4 x 6 ... 11
    // 1 ... 5 x 7 ... 11
    // 1 ... 6 x 8 ... 11
    // 1 ... 7 x 9 10 11
    // 1 ... 8 x 10 11
    // 1 ... 9 x 11
    // 1 ... 9 10 x

    const n: number = currentPage.value;
    const df: number = Math.max(0, n - 1);
    const l: number = Math.max(n, lastPage.value);
    const dl: number = Math.max(0, l - n);

    const arr: Array<'dots' | number> = [
        n,
    ];

    // Add pages before the current page
    if (df > 0) {
        arr.unshift(n - 1);
    }

    if ((df > 1 && dl === 0) || df === 2 || df === 3) {
        arr.unshift(n - 2);
    }

    if (df > 3) {
        arr.unshift('dots');
    }

    if (df > 2) {
        arr.unshift(1);
    }

    // Add pages after the current page
    if (dl > 0) {
        arr.push(n + 1);
    }

    if ((dl > 1 && df === 0) || dl === 2 || dl === 3) {
        arr.push(n + 2);
    }

    if (dl > 3) {
        arr.push('dots');
    }

    if (dl > 2) {
        arr.push(l);
    }

    return arr;
});

async function goToPage(nr: number): Promise<void> {
    try {
        await props.revisions.loadPage(nr);
    } catch (e) {
        console.error('Go to page failed', { e });
        handleError(e);
    }
}

async function shiftPage(shift: number): Promise<void> {
    try {
        await props.revisions.loadPage(Math.max(1, currentPage.value + shift));
    } catch (e) {
        console.error('Shift page failed', { e });
        handleError(e);
    }
}
</script>

<style lang="scss" scoped>
.pagination-controls {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;

  &__per-page {
      display: inline-flex;
  }

  &__pages {
      display: inline-flex;
      justify-content: center;
      list-style-type: none;

      &__item {
          margin: 0 var(--ot-ui-spacing-sm);
          cursor: pointer;
          font-weight: var(--ot-ui-font-weight-medium);
          color: var(--ot-ui-color-foreground-primary);
          display: flex;
          align-items: center;

          &.nr, &.dots {
            color: var(--ot-ui-color-accent-primary);
          }

          &[disabled=true], &.disabled {
            color: var(--ot-ui-color-accent-primary);
            pointer-events: none;

            &.nr {
              color: var(--ot-ui-color-foreground-primary);
            }
          }

          &.dots {
              pointer-events: none;
          }

          &.focus {
              color: var(--ot-ui-color-foreground-primary);
              pointer-events: none;
          }

          &.disabled {
              opacity: 0.1;
              pointer-events: none;
          }

          &:not(.focus) {
              &.nr, &.dots {
                  @media (max-width: 40rem) {
                      display: none;
                  }
              }
          }
      }
  }
}
</style>
