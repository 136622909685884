<template>
    <OtModal
        ref="accessMomentTicketsLinkModalRef"
        class="access-moment-tickets-link-modal"
        :title="$t('dashboard.access_moments.list.link_tickets.title')"
        :width="600"
        @close="$emit('close')"
    >
        <template #content>
            <OtCardContent>
                <i18n-t
                    tag="span"
                    keypath="dashboard.access_moments.list.link_tickets.description"
                    class="access-moment-tickets-link-modal__card__description ot-ui-text-body-lg ot-ui-mb[md]"
                >
                    <template #moment>
                        <span class="access-moment-tickets-link-modal__card__description__highlighted ot-ui-text-body-lg-strong">
                            {{ accessMomentModel?.$data.name }}
                        </span>
                    </template>
                </i18n-t>

                <OtFormRow>
                    <OtInputField
                        :label="$t('dashboard.access_moments.list.link_tickets.select.label')"
                        :description="$t('dashboard.access_moments.list.link_tickets.select.description')"
                    >
                        <OtPaginatedSelectInput
                            ref="paginationSelect"
                            :pagination="ticketsList"
                            :model-value="linkedTicketsInitial"
                            data-primary-key="name"
                            multiple
                            data-testid="access-moment-tickets-link-modal-select"
                            @input="handleSelectInput"
                        />
                    </OtInputField>
                </OtFormRow>
            </OtCardContent>
        </template>

        <template #footer-action>
            <OtButton
                :title="$t('dashboard.access_moments.list.link_tickets.save')"
                icon="check"
                @click="submit()"
            >
                {{ $t('dashboard.access_moments.list.link_tickets.title') }}
            </OtButton>
        </template>
    </OtModal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type {
    Event, EventDate, ManagementClient, Ticket,
} from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import { OtModal } from '@openticket/vue-ui';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling } from '../../../composables';

type Emits = {
    (e: 'close'): void,
    (e: 'error', error: Error): void
    (e: 'submit', model: EventDate<ManagementClient>): void
}

const emit = defineEmits<Emits>();

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const { handleError } = useGenericErrorHandling();

const accessMomentTicketsLinkModalRef = ref<InstanceType<typeof OtModal> | null>(null);
const linkedTicketsInitial = ref<string[]>([]);
const linkedTicketsNew = ref<string[]>([]);
const accessMomentModel = ref<EventDate<ManagementClient> | null>(null);
const ticketsList = ref<Pagination<Ticket<Event<ManagementClient>>> | null>(null);

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

async function populateList() {
    try {
        if (!context.isEventContext()) {
            // TODO Properly log error & localise reason.
            throw Error('Invalid context');
        }

        ticketsList.value = context.event.model.tickets.list({ deferInitialization: true });
        await ticketsList.value.initialization;
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }
}

// Run this on created
void populateList();

function handleSelectInput(listOfGuids: string[]) {
    linkedTicketsNew.value = listOfGuids;
}

function close() {
    void accessMomentTicketsLinkModalRef.value?.close();

    accessMomentModel.value = null;
}

async function show(model: EventDate<ManagementClient> | EventDate<Event<ManagementClient>>) {
    if (!model) {
        return;
    }

    try {
        accessMomentModel.value = management.eventdates.$factory(model.$raw);

        // Only get the first 100 linked tickets for an eventdate.
        // This will result in issues when more than 100 tickets are linked to a single eventdate.
        // TODO: Update the PaginationSelect component to properly handle multi selection.
        const eventdateTicketList = accessMomentModel.value.tickets.list({ deferInitialization: true, perPage: 100 });
        await eventdateTicketList.initialization;

        linkedTicketsInitial.value = eventdateTicketList.records.map((m) => m.id || '');
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }

    accessMomentTicketsLinkModalRef.value?.open();
}

async function submit() {
    if (!context.isEventContext()) {
        // TODO Properly log error & localise reason.
        throw Error('Invalid context');
    }

    if (!accessMomentModel.value) {
        return;
    }

    const existingLinks = new Set(linkedTicketsInitial.value);
    const newLinks = new Set(linkedTicketsNew.value);

    const ticketGuidsToLink = linkedTicketsNew.value.filter((guid) => !existingLinks.has(guid));
    const ticketGuidsToUnLink = linkedTicketsInitial.value.filter((guid) => !newLinks.has(guid));

    try {
        if (ticketGuidsToLink.length > 0) {
            await accessMomentModel.value.tickets.linkMulti(...ticketGuidsToLink);
        }

        if (ticketGuidsToUnLink.length > 0) {
            await accessMomentModel.value.tickets.unlinkMulti(...ticketGuidsToUnLink);
        }

        emit('submit', accessMomentModel.value);
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }

    close();
}

defineExpose({
    show,
});
</script>

<style lang="scss" scoped>
.access-moment-tickets-link-modal {
    &__card {
        &__description {
            &__highlighted {
                color: var(--ot-ui-color-brand);
            }
        }
    }
}
</style>
