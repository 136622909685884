/* eslint-disable */
// The majority of the code below comes from Intercom directly and is not type / lint safe. Ignoring this file.

import { send } from '@openticket/lib-log';
import { IntercomInitError, IntercomUpdateError } from './errors';

interface IntercomSettings {
    company: {
        company_id: string | null,
        dashboard_link: string | null,
        name: string| null,
        remote_created_at: number | null,
        whitelabel_id: string | null,
        whitelabel_name: string | null,
    },
    current_event_id: string | null,
    email: string | null,
    has_a_company: boolean | null,
    name: string | null,
    two_factor_enabled: boolean | null,
    user_id: string | null,
}

export class Intercom {

    init(app_id: string, api_base: string) {
        try {
            /* The code below comes from Intercom directly and is not type safe. Decided on ignoring this for now. */

            const d = document;
            const i: Function = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };

            window.Intercom = i;
            window.intercomSettings = {
                app_id,
                api_base,
            };

            const l: Function = function () {
                const s: HTMLScriptElement = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = `https://widget.intercom.io/widget/${app_id}`;
                const x: HTMLScriptElement = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
                l();
            } else if (window.attachEvent) {
                window.attachEvent('onload', l);
            } else {
                window.addEventListener('load', l, false);
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                throw new IntercomInitError(e);
            }

            throw e;
        }
    }

    updateSettings(data?: Partial<IntercomSettings>): void {
        try {
            if (window.Intercom) {
                window.Intercom('update', data);
            }
        } catch (e) {
            if (e instanceof Error) {
                send(new IntercomUpdateError(e));
            }

            console.error('Failed to update intercom settings.', e);
        }
    }

    shutdown(): void {
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
    }

}
