// These define the typing of the flags, and are also used as the default values.
export const definedFlags = {
    app__access_moments__navigation: false,
    app__addon_products__navigation: false,
    app__event_settings__details__cancel_reschedule: false,
    app__feature_test__company_context: false,
    app__feature_test__global: false,
    app__feature_test__has_company: false,
    app__feature_test__is_wladmin: false,
    app__new_ticket_wizard: false,
    app__payment_methods__navigation: false,
    app__read_only_views__global: false,
    app__waiting_list__reseller: false,
};

// Use these flags to overwrite local behavior without needing to initializing rudderstack or posthog
// Make sure to add any flags to the definedFlags Object to also make Product aware of the applicable FF
// To make this work also enable the VITE_LOCAL_FEATURE_FLAG_ENABLED environment variable as per ./FeatureFlags.md
export const localFlags: typeof definedFlags = {
    ...definedFlags,
    ...loadFeatureFlagsFromEnv(),

    // Example of a flag you want to overwrite for local development
    // app__addon_products__navigation: true,
};

// Set local feature flags using environment variables prefixed with VITE_FEATURE_FLAG_, all uppercase
// Example: VITE_FEATURE_FLAG_APP__ADDON_PRODUCTS__NAVIGATION=true
function loadFeatureFlagsFromEnv(): Partial<typeof definedFlags> {
    const updatedFlags: Partial<typeof definedFlags> = {};
    for (const key of Object.keys(definedFlags)) {
        const envVar = import.meta.env[`VITE_FEATURE_FLAG_${key.toUpperCase()}`] as unknown;
        updatedFlags[key as keyof typeof definedFlags] = envVar === 'true' || envVar === true || envVar === '1';
    }
    return updatedFlags;
}
