import { OtError } from '@openticket/lib-log';
import type { ContextType } from '../types';

export class ContextParentInvalidError extends OtError {

    readonly contextType: ContextType;
    readonly expectedId: string;
    readonly actualId: string;

    constructor(contextType: ContextType, expectedId?: string | null, actualId?: string | null) {
        super('ContextParentInvalidError', 'dashboard.errors.plugins.context.parent.invalid', 'Invalid parent id.');

        this.contextType = contextType;
        this.addContext('contextType', contextType);

        this.expectedId = expectedId || '';
        this.addContext('expectedId', expectedId || '');

        this.actualId = actualId || '';
        this.addContext('actualId', actualId || '');

        this.captureStackTrace(ContextParentInvalidError);
    }

}
