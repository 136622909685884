<template>
    <div
        class="input-price-vat"
    >
        <OtInputField
            :label="translations.price.label"
            :description="translations.price.description"
            :optional="priceRules && !priceRules.includes('required')"
            class="input-price-vat__price-wrapper"
        >
            <div
                v-if="isFree"
                tabindex="0"
                class="input-price-vat__price--free"
                role="button"
                @click="onFreeFocus"
                @keydown.enter="onFreeFocus"
            >
                <OtTextInput
                    class="input-price-vat__price__input"
                    data-testid="input-price-vat-free"
                    :model-value="$t('dashboard.common.free')"
                    :readonly="readonly"
                    @click="onFreeFocus"
                />
            </div>
            <OtMoneyInput
                v-else
                ref="inputMoney"
                v-model="price"
                class="input-price-vat__price--money"
                :min="0"
                :currency="currency"
                :decimals="2"
                :factor="100"
                :readonly="readonly"
                data-testid="input-price-vat__price"
                @input="onPriceChange"
            />

            <OtToggleInput
                v-if="!readonly"
                data-testid="input-price-vat__isfree"
                class="input-price-vat__toggle"
                :label="$t('dashboard.common.free')"
                :model-value="isFree"
                @input="handleIsFreeToggle"
            />
        </OtInputField>
        <OtInputField
            class="input-price-vat__vat"
            data-testid="input-price-vat__vat"
            :label="translations.vat?.label"
            :description="translations.vat?.description"
            :optional="vatRules && !vatRules.includes('required')"
            :error="allErrors"
        >
            <OtSelectBarInput
                v-model="vatPercentage"
                :options="vatOptions"
                :readonly="readonly"
                class="input-price-vat__vat--select"
                data-testid="input-price-vat-vat-select"
                @input="handleVatEvent"
            >
                <template #none>
                    {{ vatOptions['none'] }}%
                </template>
                <template #reduced>
                    {{ vatOptions['reduced'] }}%
                </template>
                <template #normal>
                    {{ vatOptions['normal'] }}%
                </template>
                <template #other>
                    {{ vatOptions['other'] }}
                </template>
            </OtSelectBarInput>
            <OtFloatInput
                v-if="vatPercentage === 'other'"
                :model-value="vat"
                class="input input-price-vat__vat--float"
                data-testid="input-price-vat-vat-input"
                suffix="%"
                :min="0"
                :readonly="readonly"
                @input="changeVat"
            />
        </OtInputField>
    </div>
</template>

<script setup lang="ts">
import {
    computed, nextTick, onMounted, ref, useTemplateRef, watch,
} from 'vue';
import {
    useLocalization,
} from '@openticket/vue-ui';
import type { Context } from '../../services/context';
import type { FormErrors } from '../../composables/forms/types';
import { injectOrFail } from '../../services/util';

// TODO Move these hardcoded values to the backend where the values can be updated when necessary
type VatCountries = 'BE' | 'CH' | 'DE' | 'ES' | 'FR' | 'GT' | 'IE' | 'NL' | 'TR' | 'UK' | 'US';
const VAT_RATES: { [key in VatCountries ]: CountryVatRates } = {
    BE: { reduced: 6, normal: 21 },
    CH: { reduced: 2.5, normal: 7.7 },
    DE: { reduced: 7, normal: 19 },
    ES: { reduced: 10, normal: 21 },
    FR: { reduced: 5.5, normal: 20 },
    GT: { reduced: 5, normal: 12 },
    IE: { reduced: 13.5, normal: 23 },
    NL: { reduced: 9, normal: 21 },
    TR: { reduced: 10, normal: 20 },
    UK: { reduced: 5, normal: 20 },
    US: {},
};

type CountryVatRates = { reduced?: number, normal?: number };
type StandardVatRates = { none: number, other: string };
type VatRates = CountryVatRates & StandardVatRates;

type Props = {
    translations: {
        price: { label: string, description?: string }
        vat?: { label: string, description?: string }
    }
    currency: string
    priceRules?: string[]
    vatRules?: string[];
    readonly?: boolean;
}

const props = defineProps<Props>();
const price = defineModel<number>('price');
const vat = defineModel<number>('vat');
const priceErrors = defineModel<FormErrors | undefined>('priceErrors');
const vatErrors = defineModel<FormErrors | undefined>('vatErrors');

const context = injectOrFail<Context>('context');

const { localization, t } = useLocalization();

const isFree = ref<boolean>(price.value === 0);
const inputMoney = useTemplateRef('inputMoney');
const vatPercentage = ref<string | number>(0);

const vatOptions = computed<VatRates>(() => {
    const standarVatOptions = {
        none: 0,
        other: t('dashboard.events.wizard.steps.tickets.upsert.form.vat.other'),
    };

    if (!context.isCompanyContext()) {
        return standarVatOptions;
    }

    const companyLocation = context.company.model.$data.country ?? localization.locale.territory;
    const vatRates = VAT_RATES[companyLocation as VatCountries];

    if (!vatRates) {
        return standarVatOptions;
    }

    return {
        none: 0,
        ...vatRates,
        other: t('dashboard.events.wizard.steps.tickets.upsert.form.vat.other'),
    };
});

const allErrors = computed<FormErrors>(() => {
    const errors: FormErrors = [];

    if (priceErrors.value) {
        errors.push(...priceErrors.value);
    }

    if (vatErrors.value) {
        const map = vatErrors.value.map((item) => ({
            ...item,
            customAttribute: t('dashboard.events.wizard.steps.tickets.upsert.form.price.vat'),
        }));

        errors.push(...map);
    }

    return errors;
});

watch(() => price.value, () => {
    if (price.value === 0) {
        isFree.value = true;
    }
});

onMounted(() => {
    const vatRate: keyof VatRates | undefined = (Object.keys(vatOptions.value) as Array<keyof VatRates>)
        .find((key: keyof VatRates) => vatOptions.value[key] === vat.value);

    updateVatPercentage(vatRate || 'other');
});

function handleVatEvent(e: string | Event) {
    let inputValue;

    if (e instanceof Event && e.target instanceof HTMLInputElement) {
        inputValue = e.target.value;
    } else if (typeof e === 'string') {
        inputValue = e;
    }

    updateVatPercentage(inputValue as keyof VatRates);
}

function updateVatPercentage(value: keyof VatRates) {
    const newValue = vatOptions.value[value];
    vatPercentage.value = value;

    if (value !== 'other' && typeof newValue === 'number') {
        changeVat(newValue);
    }
}

function handleIsFreeToggle() {
    isFree.value = !isFree.value;

    if (isFree.value) {
        price.value = 0;
        onPriceChange();
    } else {
        void onFreeFocus();
    }
}

async function onFreeFocus() {
    if (props.readonly) {
        return;
    }

    isFree.value = false;

    await nextTick();

    const inputElement = document.querySelector('.input-price-vat__price-wrapper .ot-ui-float-input input');

    if (inputElement && inputElement instanceof HTMLInputElement) {
        inputElement.select();
        inputElement.focus();
    }
}

function onPriceChange() {
    priceErrors.value = [];
}

function changeVat(value: number | string | Event) {
    let number = 0;
    if (value instanceof Event && value.target instanceof HTMLInputElement) {
        number = parseFloat(value.target.value);
    } else if (typeof value === 'string') {
        number = parseFloat(value);
    } else if (typeof value === 'number') {
        number = value;
    }

    vat.value = number;
    vatErrors.value = [];
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/mixins.scss";

.input-price-vat {
    gap: var(--ot-ui-spacing-xs);
    display: flex;
    flex-direction: column;

    &__wrapper {
        flex-direction: column;
    }

    &__price-wrapper {
        width: 100%;
    }

    &__toggle {
        :deep(.ot-ui-toggle-input__text) {
            display: flex;
            align-items: start;
        }
    }

    &__price {
        &--money, &--free {
            width: 50%;
        }

        @include breakpoint(mob) {
            &--money, &--free {
                width: 100%;
            }
        }
    }

    &__price, &__vat {
        @include breakpoint(mob) {
            &:deep(.ot-ui-input-field__content) {
                align-items: flex-start;
                flex-direction: column;
                gap: var(--ot-ui-spacing-sm);
            }
        }
    }

    &__vat--select {
        max-width: max-content;
    }
}
</style>
