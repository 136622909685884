import type { RouteRecordRaw } from 'vue-router';
import AppWrapper from '../layouts/AppWrapper.vue';
import { accessMomentsRoutes } from './access-moments/routes';
import { addonProductsRoutes } from './addon-products/routes';
import { appLibraryRoutes } from './app-library/routes';
import { authRoutes } from './auth/routes';
import { companiesRoutes } from './companies/routes';
import { connectionsRoutes } from './connections/routes';
import { datesRoutes } from './dates/routes';
import { eventsRoutes } from './events/routes';
import { eventWizardRoutes } from './event-wizard/routes';
import { fixRoutes } from './fix/routes';
import { ordersRoutes } from './orders/routes';
import { productPortalRoutes } from './product-portal/routes';
import { profileRoutes } from './profile/routes';
import { scannersRoutes } from './scanners/routes';
import { shopsRoutes } from './shops/routes';
import { ticketsRoutes } from './tickets/routes';
import { visitorDataRoutes } from './visitor-data/routes';
import { waitingListRoutes } from './waiting-list/routes';
import { ticketWizardRoutes } from './ticket-wizard/routes';

export function moduleRoutes(): RouteRecordRaw[] {
    return [
        {
            // 'Temporary™' password reset redirect (i.e. email button)
            path: '/passwordReset',
            redirect: (to) => {
                const { token } = to.query;

                return {
                    name: 'fix.forgot_password',
                    params: { token: typeof token === 'string' ? token : '' },
                    query: {},
                };
            },
        },

        // // Bare layout modules
        ...authRoutes(),
        ...fixRoutes(),

        // Dashboard layout modules (Authenticated !?)
        {
            path: '/',
            component: AppWrapper,
            redirect: { name: 'home' },
            meta: {
                // Default context switch, when nothing is found for the specific route.
                onContextSelect: (id, context) => {
                    if (!id || typeof id === 'symbol' || !context) {
                        return {
                            name: 'events.list',
                        };
                    }

                    return {
                        name: 'events.list',
                        params: { company: id },
                    };
                },
            },
            children: [
                {
                    path: '/home',
                    name: 'home',
                    redirect: { name: 'events.list' },
                },

                ...appLibraryRoutes(),
                ...companiesRoutes(),
                ...connectionsRoutes(),
                ...eventsRoutes({
                    accessMoments: accessMomentsRoutes(),
                    addonProducts: addonProductsRoutes(),
                    dates: datesRoutes(),
                    tickets: ticketsRoutes({
                        wizard: ticketWizardRoutes(),
                    }),
                    waitingList: waitingListRoutes(),
                    wizard: eventWizardRoutes(),
                }),
                ...ordersRoutes(),
                ...productPortalRoutes(),
                ...profileRoutes(),
                ...scannersRoutes(),
                ...shopsRoutes(),
                ...visitorDataRoutes(),

                {
                    path: '/:catchAll(.*)',
                    redirect: { name: 'home' },
                },
            ],
        },
    ];
}
