import { Log, registerGlobalErrorHandlers } from '@openticket/lib-log';
import type { OtError } from '@openticket/lib-log';
import { meta } from '@openticket/lib-release-meta';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import type { LogOptions } from './types';

export class LogPlugin extends Plugin<Log> {

    lastError: OtError | null = null;

    install(plugins: PluginsManager): void {
        try {
            const options: LogOptions = plugins.options.log || {};

            const log = Log.register({
                appName: meta.appName,
                appVersion: meta.appVersion,
                url: options.url || 'https://notify.openticket.tech/',
            });

            registerGlobalErrorHandlers();

            if (options.session) {
                log.linkSession(options.session);
            }

            this.resolve(log);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

    setLastError(error: OtError): void {
        this.lastError = error;
    }

}
