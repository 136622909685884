<template>
    <div class="shop-tickets__collapse ot-ui-my[xs]">
        <div class="shop-tickets__collapse--header ot-ui-p[md] ot-ui-stackx[sm]">
            <h3 class="ot-ui-text-heading-3">
                <OtIcon
                    v-if="collapse.concept"
                    type="file"
                    size="small"
                />
                {{ collapse.title }}
                <OtButton
                    data-testid="shop-tickets-collapse-edit"
                    variant="outline"
                    size="tiny"
                    icon="edit"
                    :disabled="isReadOnly"
                    @click="emit('open-edit:collapse', collapse)"
                >
                    {{ $t('dashboard.shop_tickets.edit.text' ) }}
                </OtButton>
            </h3>
            <OtButton
                v-if="!collapse.concept"
                data-drag-handle
                data-testid="shop-tickets-collapse-drag"
                variant="inline"
                size="tiny"
                icon="drop-both"
                class="ot-ui-px[sm] drop-both"
                :disabled="isReadOnly"
            />
            <OtButton
                v-if="collapse.concept"
                class="ot-ui-px[sm] close"
                data-testid="shop-tickets-collapse-remove"
                variant="inline"
                size="tiny"
                icon="close"
                :disabled="isReadOnly"
                @click="removeCollapseFromShop(collapse)"
            />
        </div>

        <draggable
            v-if="tickets"
            :list="tickets"
            handle="[data-drag-handle]"
            :group="draggableGroupKey"
            class="shop-tickets__collapse--content ot-ui-px[lg] ot-ui-pb[md]"
            @add="onAdd"
            @remove="onRemove"
            @sort="onSort"
        >
            <template #item="{ element: ticket }">
                <DraggableTicket
                    :key="ticket.guid"
                    :ticket="ticket"
                    @remove="emit('remove:ticketFromShop', ticket.guid, collapse.guid)"
                />
            </template>
            <template #footer>
                <div
                    v-if="!isReadOnly"
                    class="shop-tickets__collapse--footer ot-ui-mt[md]"
                >
                    {{ $t('dashboard.shop_tickets.drag_tickets.text') }}
                    <!-- TODO Add functionality to button -->
                    <!-- <button class="ot-button is-tiny is-outline" disabled>
                            <OtIcon type="plus" class="ot-button-icon--left" size="tiny" />
                            {{ $t('dashboard.shop_tickets.add_tickets.text') }}
                        </button> -->
                </div>
            </template>
        </draggable>
    </div>
</template>

<script setup lang="ts">
import { reactive, watch } from 'vue';
import { useConfirmationModal, useLocalization } from '@openticket/vue-ui';
import draggable from 'vuedraggable';
import DraggableTicket from './DraggableTicket.vue';
import type { ShopDataCollapse, ShopDataTicket } from '../../../../services/shop';
import useDraggableInterpreter from '../../composables/useDraggableInterpreter';
import type { LinkEvent, SortEvent } from '../../../../services/shop/types';
import { useRoles } from '../../../../composables';

const dialog = useConfirmationModal();

const { t } = useLocalization();
const { isReadOnly } = useRoles();

type Props = {
    collapse: ShopDataCollapse,
    draggableGroupKey: string
}

type Emits = {
    (e: 'update:collapse', value: ShopDataCollapse): void,
    (e: 'remove:conceptCollapse', collapseId: string): void,
    (e: 'remove:ticketFromShop', ticketId: string, collapseId: string): void,
    (e: 'open-edit:collapse', collapse: ShopDataCollapse): void,
    (e: 'move:up', value: SortEvent): void,
    (e: 'move:down', value: SortEvent): void,
    (e: 'link', value: LinkEvent): void,
    (e: 'unlink', value: LinkEvent): void,
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

// DON'T Change this to ref because it breaks the reactivity of the drag and drop system.
// Ignore warning in the console, this warning will dissapear when migrated to vue3.
const tickets = reactive<ShopDataTicket[]>(props.collapse.items);

watch(tickets, () => {
    emit('update:collapse', {
        ...props.collapse,
        ...{
            items: tickets,
        },
    });
});

async function removeCollapseFromShop(collapse: ShopDataCollapse) {
    const confirm = await dialog?.confirm({
        title: t('dashboard.shop_tickets.confirm.remove_collapse.title') || '',
        message: t('dashboard.shop_tickets.confirm.remove_collapse.description') || '',
        variant: 'destructive',
    });

    if (confirm) {
        if (collapse.concept) {
            emit('remove:conceptCollapse', collapse.guid);
        } else {
            // TODO Implement removing existing collapses
        }
    }
}

const {
    onSort, onAdd, onRemove,
} = useDraggableInterpreter(tickets, emit, props.collapse);
</script>

<style lang="scss" scoped>
.shop-tickets__collapse {
    border-radius: var(--ot-ui-radius-lg);
    border: 1px solid var(--ot-ui-color-accent-secondary);
    overflow: hidden;
    background-color: var(--ot-ui-color-background-secondary);

    &--header {
        background-color: var(--ot-ui-color-intent-invert-secondary);

        & > h3 {
            display: inline-flex;
            align-items: center;
            flex: 1;
            gap: var(--ot-ui-spacing-xs);
        }

        .close {
            color: var(--ot-ui-color-foreground-secondary);

            &:hover {
                color: var(--ot-ui-color-intent-error-primary);
            }
        }

        .drop-both {
            color: var(--ot-ui-color-foreground-secondary);

            &:hover {
                color: var(--ot-ui-color-brand);
            }
        }
    }

    &--content {
        display: flex;
        flex-direction: column;
    }

    &--footer {
        order: 9999;
        color: var(--ot-ui-color-accent-primary)
    }
}
</style>
