<template>
    <div class="locations-input">
        <ErrorView
            v-if="error"
            :label="error.message"
        />
        <OtPaginatedSelectInput
            v-else-if="locationsList"
            v-model="modelValue"
            :allow-empty="allowEmpty"
            :can-create="canCreateLocation"
            :can-edit="canEditLocation"
            :name-resolver="nameResolver"
            :not-shown-label="$t('dashboard.locations.options_not_shown')"
            :pagination="locationsList"
            data-primary-key="name"
            data-secondary-key="address"
            :readonly="readonly"
            @create="createNewLocation"
            @edit="editLocation"
        />

        <OtSpinner v-else />

        <LocationCreateFormModalVue
            v-if="creating"
            :key="id"
            :modal="modalRef"
            @saved="onFormSaved"
        />

        <LocationUpdateFormModalVue
            v-if="updating && locationModel"
            :key="id"
            :modal="modalRef"
            :model="locationModel"
            @saved="onFormSaved"
        />
    </div>
</template>

<script lang="ts" setup>
import {
    computed, reactive, ref, watch,
} from 'vue';
import type { CompanyPath, Location, ManagementClient } from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import { useGenericErrorHandling } from '../../composables';
import type { Context } from '../../services/context';
import { injectOrFail } from '../../services/util';
import ErrorView from '../ErrorView.vue';
import { useFormModal } from '../../composables/forms';
import LocationCreateFormModalVue from './location/LocationCreateFormModal.vue';
import LocationUpdateFormModalVue from './location/LocationUpdateFormModal.vue';

type SelectOptions = {
    [key: PropertyKey]: object | string | number | boolean;
};

type Props = {
    allowEmpty?: boolean;
    canCreate?: boolean,
    canEdit?: boolean,
    readonly?: boolean;
}

type Emits = {
    (e: 'error', error: Error): void,
    (e: 'input', value: unknown): void,
}

const props = withDefaults(defineProps<Props>(), {
    canCreate: undefined,
    canEdit: undefined,
});
const emit = defineEmits<Emits>();

const modelValue = defineModel<keyof SelectOptions |(keyof SelectOptions)[] | undefined>();

const { error, handleError } = useGenericErrorHandling();

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const locationsList = ref<Pagination<Location<CompanyPath<ManagementClient>> | Location<ManagementClient>>>();
const creating = ref<boolean>(false);
const updating = ref<boolean>(false);
const locationModel = ref<Location<ManagementClient>>();
const id = ref<string>('');

const canCreateLocation = computed<boolean>(() => props.canCreate ?? context.isCompanyContext());
const canEditLocation = computed<boolean>(() => props.canEdit ?? context.isCompanyContext());

const modalRef = reactive(useFormModal());

// Update locations list when company changes
watch(() => context.company?.id, () => {
    void populateList();
});

function onFormSaved(guid: string | null) {
    emit('input', guid);
    if (guid) {
        modelValue.value = guid;
    }
    void populateList();
}

async function populateList() {
    try {
        if (context.isCompanyContext()) {
            locationsList.value = context.company.model.locations.list({ deferInitialization: true });
            await locationsList.value.initialization;
        } else {
            locationsList.value = management.locations.list({ deferInitialization: true });
            await locationsList.value.initialization;
        }
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }
}

// Run this on created
void populateList();

function createNewLocation() {
    try {
        id.value = Math.random().toString();
        updating.value = false;
        creating.value = true;

        modalRef.open();
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }
}

async function editLocation(guid: PropertyKey) {
    try {
        if (!guid || typeof guid !== 'string') {
            // TODO: Proper error handling + logging
            throw Error('Cannot update location when guid is undefined');
        }

        // TODO: Replace with top level await in Vue 3 on the component itself
        locationModel.value = await management.locations.find(guid);

        id.value = guid;
        creating.value = false;
        updating.value = true;

        modalRef.open();
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }
}

async function nameResolver(guid: string): Promise<string> {
    const foundLocation = locationsList.value?.records.find((location) => location.$data.guid === guid);
    if (foundLocation) {
        return foundLocation.$data.name;
    }

    try {
        const _locationModel = await management.locations.find(guid);
        return _locationModel.$data.name;
    } catch (e) {
        void handleError(e);
    }

    return '';
}
</script>

<style lang="scss" scoped>
.locations-input {
    width: 100%;
}
</style>
