import type { AxiosError } from 'axios';

export function getXLocation(axiosError: AxiosError): string | null {
    if (!axiosError.response) {
        return null;
    }

    const { headers } = axiosError.response;

    if (!(headers as { 'x-location'?: unknown })['x-location']) {
        return null;
    }

    const xLocation: unknown = (headers as { 'x-location': unknown })['x-location'];

    return typeof xLocation === 'string' ? xLocation : null;
}
