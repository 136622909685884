<template>
    <div class="event-wizard-shop">
        <div class="event-wizard-shop__header">
            <OtDocsSection
                :label="$t('dashboard.common.read_docs')"
            >
                <h2 class="ot-ui-text-heading-2">
                    {{ $t('dashboard.events.wizard.steps.shop.docs.title') }}
                </h2>
                <span class="ot-ui-text-body-lg">{{ $t('dashboard.events.wizard.steps.shop.docs.description') }}</span>
            </OtDocsSection>
        </div>

        <div class="event-wizard-shop__content">
            <div
                v-if="isTicketListEmpty"
                class="event-wizard-shop__content__no-tickets"
            >
                <h2 class="ot-ui-text-heading-2">
                    {{ $t('dashboard.events.wizard.steps.shop.selection.empty.title') }}
                </h2>
                <p class="ot-ui-text-body-lg">
                    {{ $t('dashboard.events.wizard.steps.shop.selection.empty.description') }}
                </p>
            </div>

            <div
                v-else
                class="event-wizard-shop__content__shop-selection"
            >
                <h3 class="ot-ui-text-heading-3">
                    {{ $t('dashboard.events.wizard.steps.shop.selection.title') }}
                </h3>
                <span class="ot-ui-text-body-md">
                    {{ $t('dashboard.events.wizard.steps.shop.selection.description') }}
                </span>

                <div class="event-wizard-shop__content__shop-selection__selector">
                    <FancyRadio
                        :value="ticketAvailability"
                        :options="ticketAvailabilityOptions"
                        data-testid="event-wizard-shop-select-availability"
                        @checked="updateTicketAvailability"
                    >
                        <template #available>
                            <ShopsSelect
                                v-model="selectedShop"
                                :disabled="ticketAvailability !== 'available'"
                                data-testid="event-wizard-shop-select-shop"
                            />
                        </template>
                    </FancyRadio>
                </div>

                <div
                    v-if="selectedShop"
                    class="event-wizard-shop__content__shop-selection__tickets"
                >
                    <h3 class="ot-ui-text-heading-3">
                        {{ $t('dashboard.events.wizard.steps.shop.selection.tickets.title') }}
                    </h3>
                    <span class="ot-ui-text-body-md">
                        {{ $t('dashboard.events.wizard.steps.shop.selection.tickets.description') }}
                    </span>
                    <div class="event-wizard-shop__content__shop-selection__tickets__checkboxes">
                        <OtCheckboxInput
                            class="event-wizard-shop__content__shop-selection__tickets__checkboxes-all"
                            data-testid="event-wizard-shop-select-ticket-checkboxes-all"
                            :label="$t('dashboard.events.wizard.steps.shop.selection.tickets.select_all.label')"
                            :model-value="allTicketsSelected"
                            @input="toggleAllTickets()"
                        />
                        <div
                            class="
                            event-wizard-shop__content__shop-selection__tickets__checkboxes-separator
                            ot-ui-text-body-xs-strong"
                        >
                            {{ $t('dashboard.events.wizard.steps.shop.selection.tickets.separator.text') }}
                        </div>
                        <OtCheckboxGroupInput
                            v-model="selectedTickets"
                            class="event-wizard-shop__content__shop-selection__tickets__checkboxes-individual"
                            :options="ticketOptions"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { Event, ManagementClient, Ticket } from '@openticket/lib-management';
import type { OtCheckboxOptions } from '@openticket/vue-ui';
import { useLocalization } from '@openticket/vue-ui';
import FancyRadio from '../../../../components/FancyRadio.vue';
import { injectOrFail } from '../../../../services/util';
import ShopsSelect from '../ShopsSelect.vue';
import { useGenericErrorHandling } from '../../../../composables';
import type { WizardProvide } from '../../types';

const { handleError } = useGenericErrorHandling();
const { l, t } = useLocalization();

const management = injectOrFail<ManagementClient>('management');
const wizardProvide = injectOrFail<WizardProvide>('wizardProvide');

const {
    selectedShop,
    selectedTickets,
    ticketAvailability,
    ticketOptions,
} = wizardProvide.shopView;

const ticketAvailabilityOptions = [
    {
        value: 'available',
        label: t('dashboard.events.wizard.steps.tickets.upsert.form.availability.available.label'),
        description: t('dashboard.events.wizard.steps.tickets.upsert.form.availability.available.description'),
    }, {
        value: 'not-available',
        label: t('dashboard.events.wizard.steps.tickets.upsert.form.availability.not_available.label'),
        description: t(
            'dashboard.events.wizard.steps.tickets.upsert.form.availability.not_available.description',
        ),
    },
];

const isNextButtonDisabled = computed(() => {
    // Enable button when there are no tickets to be selected.
    if (isTicketListEmpty.value) {
        return false;
    }

    // Disable button when user has selected an option but has not yet selected a shop or/and tickets to sell.
    return !ticketAvailability.value || (ticketAvailability.value === 'available'
        && (!selectedShop.value || (!selectedTickets.value || selectedTickets.value.length <= 0)));
});

const isTicketListEmpty = computed(() => !wizardProvide.eventTickets.pagination.value
        || !wizardProvide.eventTickets.pagination.value.total
        || wizardProvide.eventTickets.pagination.value.total <= 0);

const allTicketsSelected = computed(() => Object.keys(ticketOptions.value ?? {}).length
    === (selectedTickets.value ?? []).length);

function getPriceWithCurrency(price: number): string {
    return l.currency(price ?? 0, wizardProvide.eventForm.model.$data.currency);
}

function parseTicketOptions(data: Ticket<Event<ManagementClient>>[]): OtCheckboxOptions {
    const map: OtCheckboxOptions = {};

    for (const ticket of Object.values(data)) {
        if (ticket.id) {
            map[ticket.id] = { label: ticket.$data.name, suffix: getPriceWithCurrency(ticket.$data.min_price) };
        }
    }

    return map;
}

void (() => {
    try {
        if (wizardProvide.eventForm.model.id) {
            ticketOptions.value = parseTicketOptions(wizardProvide.eventTickets.pagination.value?.records ?? []);
        }
    } catch (e) {
        void handleError(e);
    }
})();

function toggleAllTickets() {
    selectedTickets.value = allTicketsSelected.value ? undefined : Object.keys(ticketOptions.value ?? {});
}

function updateTicketAvailability(value: string) {
    ticketAvailability.value = value;

    if (value === 'not-available') {
        selectedShop.value = undefined;
        selectedTickets.value = undefined;
    }
}

async function linkTicketsToShop(): Promise<{ result: boolean, redirectTo?: 'shops' | 'events', guid?: string | null }> {
    if (isTicketListEmpty.value || ticketAvailability.value === 'not-available') {
        return Promise.resolve({ result: true, redirectTo: 'events', guid: wizardProvide.eventForm.model.id });
    }

    if (ticketAvailability.value === 'available' && selectedShop.value && selectedTickets.value) {
        const shopModel = management.shops.$factory({
            ...management.shops.new().$data,
            guid: selectedShop.value,
        });

        // Fetch selected ticket models
        const ticketModels = [];
        for (let index = 0; index < selectedTickets.value.length; index++) {
            const ticketModel = await management.tickets.find(selectedTickets.value[index]);
            ticketModels.push(ticketModel);
        }

        // Use linkMulti to link ticket models to shop model
        await shopModel.tickets.linkMulti(...ticketModels);

        return Promise.resolve({ result: true, redirectTo: 'shops', guid: shopModel.id });
    }

    return Promise.resolve({ result: false });
}

defineExpose({
    linkTicketsToShop,
    isNextButtonDisabled,
});
</script>

<style scoped lang="scss">
.event-wizard-shop {
    &__header {
        padding: var(--ot-ui-spacing-xl) 0;
        border-bottom: 1px solid var(--ot-ui-color-accent-tertiary);
        margin-bottom: var(--ot-ui-spacing-xl);

        h1 {
            margin-bottom: var(--ot-ui-spacing-xs);
        }

        span {
            color: var(--ot-ui-color-foreground-secondary);
        }
    }

    &__content {
        margin: var(--ot-ui-spacing-2xl) 0;

        h3 {
            margin-bottom: var(--ot-ui-spacing-2xs);
        }

        span {
            color: var(--ot-ui-color-foreground-secondary);
        }

        &__no-tickets {
            height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            gap: var(--ot-ui-spacing-md);
        }

        &__shop-selection {
            &__selector {
                margin: var(--ot-ui-spacing-2xl) 0;
            }

            &__tickets {
                margin-top: var(--ot-ui-spacing-2xl);
                padding-top: var(--ot-ui-spacing-2xl);
                border-top: 1px solid var(--ot-ui-color-accent-tertiary);

                &__checkboxes {
                    padding-top: var(--ot-ui-spacing-2xl);

                    &-all.ot-ui-checkbox-input {
                        border: 2px solid var(--ot-ui-color-accent-tertiary);
                        padding: 0 var(--ot-ui-spacing-lg);
                        border-radius: var(--ot-ui-radius-lg);
                        height: auto;

                        :deep(label) {
                            padding: var(--ot-ui-spacing-sm) 0;
                        }
                    }

                    &-separator {
                        display: flex;
                        align-items: center;
                        color: var(--ot-ui-color-accent-primary);
                        gap: var(--ot-ui-spacing-md);
                        text-transform: uppercase;
                        margin: var(--ot-ui-spacing-md) 0;

                        &::before, &::after {
                            flex: 1;
                            content: '';
                            padding: 1px;
                            background-color: var(--ot-ui-color-accent-tertiary);
                        }
                    }

                    &-individual {
                        :deep(.ot-ui-checkbox-input) {
                            border: 2px solid var(--ot-ui-color-accent-tertiary);
                            padding: 0 var(--ot-ui-spacing-lg);
                            border-radius: var(--ot-ui-radius-lg);

                            & label {
                                padding: var(--ot-ui-spacing-sm) 0;
                            }

                            .ot-ui-checkbox-input__text {
                                display: flex!important;
                                flex-direction: row;
                                justify-content: space-between;
                                overflow: hidden;
                                gap: var(--ot-ui-spacing-sm);

                                & > div {
                                    flex: 1;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                & > span {
                                    color: var(--ot-color-accent-primary);
                                }
                            }
                        }
                    }
                }

            }

        }
    }
}
</style>
