<template>
    <FormWrapper :form="eventForm">
        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.events.wizard.steps.details.form.event_name.label')"
                :description="$t('dashboard.events.wizard.steps.details.form.event_name.description')"
                :error="eventForm.errors.name"
            >
                <OtTextInput
                    v-model="eventForm.model.$data.name"
                    data-testid="event-wizard-event-form-name"
                    @input="eventForm.errors.name = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.events.wizard.steps.details.form.location.label')"
                :description="$t('dashboard.events.wizard.steps.details.form.location.description')"
                :error="eventForm.errors.location"
            >
                <LocationsInput
                    v-model="location.$data.guid"
                    data-testid="event-wizard-event-form-location"
                    @error="(e) => error = e"
                    @input="eventForm.errors.location = []"
                />
            </OtInputField>

            <OtInputField
                :label="$t('dashboard.events.wizard.steps.details.form.currency.label')"
                :description="$t('dashboard.events.wizard.steps.details.form.currency.description')"
                :error="eventForm.errors.currency"
            >
                <OtSelectInput
                    v-model="eventForm.model.$data.currency"
                    sort="alphabetically"
                    :options="currencies"
                    :searchable="true"
                    :internal-search="true"
                    data-testid="event-wizard-event-form-currency"
                    @input="eventForm.errors.currency = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                data-testid="event-wizard-event-form-start"
                :label="$t('dashboard.events.wizard.steps.details.form.date_start.label')"
                :description="$t('dashboard.events.wizard.steps.details.form.date_start.description')"
                :error="eventForm.errors.start"
            >
                <OtDateInput
                    v-model="eventDateForm.model.$data.start"
                    :time="true"
                    position="above"
                    @input="(start) => { eventForm.errors.start = []; fillEndDate(start) }"
                />
            </OtInputField>

            <OtInputField
                data-testid="event-wizard-event-form-end"
                :label="$t('dashboard.events.wizard.steps.details.form.date_end.label')"
                :description="$t('dashboard.events.wizard.steps.details.form.date_end.description')"
                :error="eventForm.errors.end"
            >
                <OtDateInput
                    v-model="eventDateForm.model.$data.end"
                    :disabled="!eventDateForm.model.$data.start"
                    :time="true"
                    position="above"
                    @input="eventForm.errors.end = []"
                />
            </OtInputField>
        </OtFormRow>
    </FormWrapper>
</template>

<script setup lang="ts">
import {
    computed,
    watch,
} from 'vue';
import { isValidateError, validate } from '@openticket/lib-crud';
import type { ManagementClient } from '@openticket/lib-management';
import { isOtError } from '@openticket/lib-log';
import { OtSelectInput, useLocalization } from '@openticket/vue-ui';
import { injectOrFail } from '../../../../services/util';
import type { Context } from '../../../../services/context';
import FormWrapper from '../../../../components/form/FormWrapper.vue';
import LocationsInput from '../../../../components/form/LocationsInput.vue';
import { useGenericErrorHandling } from '../../../../composables';
import type { WizardProvide } from '../../types';

const { error, handleError } = useGenericErrorHandling();
const { localization } = useLocalization();

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');
const wizardProvide = injectOrFail<WizardProvide>('wizardProvide');

const { location, eventDateForm, eventForm } = wizardProvide;

if (!eventForm.model.$data.currency && context.isCompanyContext()) {
    eventForm.model.$data.currency = context.company.model.$data.currency;
}

watch(() => context.company?.id, () => {
    if (!eventForm.model.$data.currency && context.isCompanyContext()) {
        eventForm.model.$data.currency = context.company.model.$data.currency;
    }
});

/**
 * Fill in the end date after the start date has been filled in
 */
function fillEndDate(value: Event | string | string[]): void {
    let start = '';

    if (value instanceof Event && value.target instanceof HTMLInputElement) {
        start = value.target.value;
    } else if (typeof value === 'string') {
        start = value;
    }

    if (!start || eventDateForm.model.$data.end) {
        return;
    }

    const endAsDate = new Date(start);

    // Set the end date 1 hour later as the start date so the event has valid dates initially
    endAsDate.setHours(endAsDate.getHours() + 1);

    eventDateForm.model.$data.end = endAsDate.toISOString();
}

const currencies = computed<Record<string, string>>(() => {
    const map: Record<string, string> = {};
    for (const key of Object.keys(localization.supportedCurrencies)) {
        map[key] = `${localization.supportedCurrencies[key].currencySymbol} - ${localization.supportedCurrencies[key].currencyDisplayName}`;
    }

    return map;
});

async function submit(): Promise<boolean> {
    if (!context.isCompanyContext()) {
        return false;
    }

    eventForm.errors = {};

    // Before submitting the event, validate the location and event date
    if (!location.value.id) {
        eventForm.errors = {
            location: [ {
                data: {},
                message: 'validation.required',
            } ],
        };
    }

    const eventDateValidation = validate(eventDateForm.model.$raw, eventDateForm.rules);
    if (!eventDateValidation.valid) {
        eventForm.errors = {
            ...eventForm.errors,
            ...eventDateValidation.result.messages,
        };
    }

    eventForm.model.$data.locale = context.company.model.$data.locale;
    const eventValidation = validate(eventForm.model.$raw, eventForm.rules);
    if (!eventValidation.valid) {
        eventForm.errors = {
            ...eventForm.errors,
            ...eventValidation.result.messages,
        };
    }

    if (Object.keys(eventForm.errors).length) {
        return false;
    }

    try {
        // Update the relational data to include the selected guid, thereby enabling the use of the correct path.
        const locationRelation = management.locations.new();
        locationRelation.$raw = location.value.$raw;
        if (!eventForm.model.id) {
            eventForm.init(eventForm.model, locationRelation.events);
        }
        await eventForm.submit();

        const eventRelation = management.events.new();
        eventRelation.$raw = eventForm.model.$raw;
        if (!eventDateForm.model.id) {
            eventDateForm.init(eventDateForm.model, eventRelation.eventDates);
        }
        await eventDateForm.submit();

        return true;
    } catch (e) {
        if (isOtError(e) && isValidateError(e.cause)) {
            eventForm.errors = e.cause.validation.result.messages;
            return false;
        }
        void handleError(e);
    }

    return false;
}

defineExpose({
    submit,
});
</script>
