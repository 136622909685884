<template>
    <OtSpinner v-if="isLoading" />

    <OtCard
        v-else-if="tokenErrors.notLoggedOut"
        class="forgot-password"
        no-padding
    >
        <ModalHeader>
            <template #logout>
                <OtButton
                    icon="logout"
                    size="small"
                    :title="$t('dashboard.invitation.invalid.logout.title')"
                    variant="inline"
                    @click="logoutAndReload"
                >
                    {{ $t('dashboard.invitation.invalid.logout.text') }}
                </OtButton>
            </template>
        </ModalHeader>

        <OtCardHeader :title="$t('dashboard.reset_password.title')" />

        <OtCardContent>
            <OtAside
                :title="$t('dashboard.reset_password.invalid')"
                :description="$t(tokenErrors.notLoggedOut)"
                type="warning"
                icon="warning"
            />
        </OtCardContent>

        <OtCardFooter>
            <template #right>
                <OtButton
                    icon-right="arrow-right"
                    :title="$t('dashboard.invitation.invalid.dashboard.title')"
                    @click="$router.push({name: 'home'})"
                >
                    {{ $t('dashboard.invitation.invalid.dashboard.text') }}
                </OtButton>
            </template>
        </OtCardFooter>
    </OtCard>

    <OtCard
        v-else-if="tokenErrors.token"
        class="forgot-password"
        no-padding
    >
        <ModalHeader hide-padding />

        <OtCardHeader :title="$t('dashboard.reset_password.title')" />

        <OtCardContent>
            <OtAside
                :title="$t(tokenErrors.token)"
                type="warning"
                icon="warning"
            />
        </OtCardContent>
    </OtCard>

    <FixResetPassword
        v-else
        :form="forgotPasswordForm"
        class="forgot-password"
    />
</template>

<script setup lang="ts">
import type { AuthClient, ForgotPassword, Password } from '@openticket/lib-auth';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { isAxiosError } from 'axios';
import urlJoin from 'url-join';
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import FixResetPassword from '../components/FixResetPassword.vue';
import { injectOrFail } from '../../../services/util';
import type { RudderStack } from '../../../services/rudderstack';
import { useResetTokenCheck } from '../composable/useResetTokenCheck';
import { useCustomValidatedPostForm } from '../../../composables/forms';

const auth = injectOrFail<AuthClient>('auth');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const rudderstack = injectOrFail<RudderStack>('rudderstack');
const isLoading = ref<boolean>(true);

const router = useRouter();
const { tokenErrors, checkValidResetToken } = useResetTokenCheck('forgotPassword');

void (async () => {
    rudderstack.track('vue-dashboard fix forgot password init');
    await checkValidResetToken(() => {
        forgotPasswordForm.reset();

        isLoading.value = false;
    });
})();

const forgotPasswordForm = reactive(
    useCustomValidatedPostForm<ForgotPassword<Password<AuthClient>>, ForgotPassword<Password<AuthClient>>>(
        auth.passwords.new().forgotPassword.new(),
        auth.passwords.new().forgotPassword,
        {
            onSubmit() {
                rudderstack.track('vue-dashboard fix forgot password submit success');

                if (whitelabel.dashboard.login_url) {
                    window.location.href = whitelabel.dashboard.login_url;
                } else {
                    void router.push({ name: 'auth.login' });
                }
            },
        },
    ),
);

async function logoutAndReload(): Promise<void> {
    try {
        // TODO - @openticket/lib-auth
        await Promise.all([
            auth.$token.logout(), // TODO @Peter - lib-auth add a 'clear tokens' (e.g. logout without hooks) method.
            auth.$http.get(urlJoin(auth.$path, 'sessions', 'logout')),
        ]);
    } catch (e: unknown) {
        if (isAxiosError(e)
            && e.response
            && (
                e.response.status === Number(import.meta.env.VITE_LOGOUT_REDIRECT_STATUS)
                || e.response.status === 355
            )) {
            void await checkValidResetToken(() => {
                forgotPasswordForm.reset();

                isLoading.value = false;
            });
        } else {
            await router.push({ name: 'error' });
        }
    }
}

</script>
