<template>
    <div
        v-if="user"
        class="profile-update-wrapper"
    >
        <ProfileUpdateView :user="user" />
    </div>
    <OtSpinner v-else />
</template>

<script setup lang="ts">
import type { AuthClient, User } from '@openticket/lib-auth';
import { ref } from 'vue';
import { injectOrFail } from '../../../services/util';
import { useGenericErrorHandling } from '../../../composables';
import ProfileUpdateView from '../views/ProfileUpdateView.vue';

const { handleError } = useGenericErrorHandling();

const auth = injectOrFail<AuthClient>('auth');
const user = ref<User<AuthClient>>();

void (async () => {
    try {
        user.value = await auth.users.me();
    } catch (e) {
        void handleError(e);
    }
})();
</script>
