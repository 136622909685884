import type { DragGroup, DragItem } from '../../components/drag-and-drop/types';
import type {
    LinkEvent,
    SortEvent,
    VueDraggableAddedEvent,
    VueDraggableBaseEvent,
    VueDraggableMovedEvent,
    VueDraggableRemovedEvent,
} from './types';

type EmitFn = {
    (e: 'move:group', mutation: SortEvent): void;
    (e: 'move:item', mutation: SortEvent): void;
    (e: 'link', mutation: LinkEvent): void;
    (e: 'unlink', mutation: LinkEvent): void;
}

export default function useDraggableInterpreter(
    items: Array<DragItem | DragGroup>,
    emit: EmitFn,
    parentGroup?: DragGroup,
) {
    function onMoved(e: VueDraggableMovedEvent) {
        const anchorIndex = (e.newIndex > e.oldIndex) ? e.newIndex - 1 : e.newIndex + 1;

        const sortEvent: SortEvent = {
            itemGuid: String(e.element.guid),
            anchorGuid: String(items[anchorIndex].guid),
            parentGuid: parentGroup?.guid,
            direction: (e.newIndex > e.oldIndex) ? 'down' : 'up',
        };

        if (e.element.type === 'group') {
            emit('move:group', sortEvent);
        }

        if (e.element.type === 'item') {
            emit('move:item', sortEvent);
        }
    }

    function onAdd(e: VueDraggableAddedEvent, newParent?: DragGroup) {
        // 'concept'- (new) collapses are not persisted, and therefor not yet linkable
        // Exit and leave handling to the top component
        if (newParent?.concept === true || e.element.type === 'group') {
            return;
        }

        if (newParent?.guid === undefined) {
            console.error('Trying to link without a parent guid');
            return;
        }

        emit('link', {
            itemGuid: e.element.guid,
            parentGuid: newParent?.guid,
            newIndex: e.newIndex,
        });
    }

    function onRemove(e: VueDraggableRemovedEvent, oldParent?: DragGroup) {
        if (!oldParent?.guid) {
            console.error('Trying to unlink without a parent guid');
            return;
        }

        emit('unlink', {
            itemGuid: String(e.element.guid),
            parentGuid: String(parentGroup?.guid),
        });
    }

    const onChange = (e: VueDraggableBaseEvent) => {
        if (e.moved) {
            onMoved(e.moved);
        } else if (e.added) {
            onAdd(e.added, parentGroup);
        } else if (e.removed) {
            onRemove(e.removed, parentGroup);
        }
    };

    return {
        onChange,
    };
}
