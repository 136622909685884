import type { App } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { AuthClient } from '@openticket/lib-auth';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import type { TemplateOptions } from './types';
import TemplatesClient from '../services/templates/client/templatesClient';

/**
 * Plugin for uploading files.
 */
export class TemplatesPlugin extends Plugin<TemplatesClient> {

    async install(plugins: PluginsManager, app: App): Promise<void> {
        const options: TemplateOptions = plugins.options.template || {};

        const whitelabel: Whitelabel = await plugins.whitelabel.loading;

        const baseUrl: string = options.url
            || whitelabel.dashboard.templates_url
            || 'https://templates.openticket.tech/';

        const auth: AuthClient = await plugins.auth.loading;

        const templates = new TemplatesClient({
            http: auth.$http,
            baseUrl,
        });

        app.provide('templates', templates);

        this.resolve(templates);
    }

}
