import type {
    NavigationGuardNext, RouteLocationNormalized, RouteRecord,
} from 'vue-router';
import type { AuthClient } from '@openticket/lib-auth';
import { ROLE_ADMIN, ROLE_COMPANY_ADMIN, ROLE_WHITELABEL_ADMIN } from '@openticket/lib-auth';
import type { RestrictedRoles } from '../navigator/types';
import type { Roles } from '../../plugins/types';
import type { Context } from '../context';

export async function roleGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
    auth: AuthClient,
    context: Context,
    notifyFn?: () => void,
) {
    if (!to.matched.some((route: RouteRecord) => !!route.meta.restrictedBy)) {
        // Exit early: Not a restricted route
        next();
        return;
    }

    const roles = await getRolesForRoutes(auth, context);

    if (to.meta?.restrictedBy && hasCorrespondingRole(to.meta.restrictedBy, roles)) {
        // Move on to restricted route
        next();
        return;
    }

    // Perform notification callback to potentially show a notification on why the user will be redirected.
    if (notifyFn) {
        notifyFn();
    }

    // Redirects to home.
    next({ name: 'home' });
}

export function disableByRole(roles: Roles, role: RestrictedRoles): boolean {
    return !hasCorrespondingRole(role, roles);
}

export const hasCorrespondingRole = (role: RestrictedRoles, roles: Roles) => {
    if (role === ROLE_COMPANY_ADMIN && roles.isManager) {
        return true;
    }
    if (role === ROLE_WHITELABEL_ADMIN && roles.isWhitelabelAdmin) {
        return true;
    }

    return role === ROLE_ADMIN && roles.isSuperAdmin;
};

export const getRolesForRoutes = async (auth: AuthClient, context: Context): Promise<Roles> => {
    try {
        const isSuperAdmin = await auth.$token.isSuperAdmin();
        const isWhitelabelAdmin = await auth.$token.isWhitelabelAdmin();
        const isManager = context.isCompanyContext() ? await auth.$token.isCompanyManager(context.company.id) : false;

        return { isSuperAdmin, isWhitelabelAdmin, isManager };
    } catch (e) {
        // Logging error in console but not blocking app
        console.error(e);
    }

    return { isSuperAdmin: false, isWhitelabelAdmin: false, isManager: false };
};
