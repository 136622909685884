<template>
    <div
        v-if="settings"
        class="shop-settings__order"
    >
        <h2 class="ot-ui-text-heading-2">
            {{ $t('dashboard.shop.settings.order.title') }}
        </h2>
        <p class="shop-settings__order-description ot-ui-text-body-lg ot-ui-mb[md]">
            {{ $t('dashboard.shop.settings.order.description') }}
        </p>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.shop.settings.order.general.title')"
            >
                <OtToggleInput
                    v-model="settings.static.order.enablePassbook"
                    data-testid="shop-settings-order-enable-passbook"
                    :label="$t('dashboard.shop.settings.order.general.apple_wallet.label')"
                    :readonly="isReadOnly"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.shop.settings.order.resell.title')"
                :description="$t('dashboard.shop.settings.order.resell.description')"
            >
                <OtToggleInput
                    v-model="settings.static.order.enableResell"
                    data-testid="shop-settings-order-enable-resell"
                    :label="$t('dashboard.shop.settings.order.resell.resell.label')"
                    :readonly="isReadOnly"
                />

                <OtToggleInput
                    v-model="settings.static.order.enableTicketSwap"
                    data-testid="shop-settings-order-enable-ticket-swap"
                    :label="$t('dashboard.shop.settings.order.resell.ticket_swap.label')"
                    :disabled="!settings.static.order.enableResell"
                    :readonly="isReadOnly"
                />

                <OtToggleInput
                    v-model="settings.static.order.enableTixel"
                    data-testid="shop-settings-order-enable-tixel"
                    :label="$t('dashboard.shop.settings.order.resell.tixel.label')"
                    :disabled="!settings.static.order.enableResell"
                    :readonly="isReadOnly"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.shop.settings.order.event_card.title')"
                :description="$t('dashboard.shop.settings.order.event_card.description')"
            >
                <OtToggleInput
                    v-model="settings.static.order.components.eventCard.showEventDate"
                    data-testid="shop-settings-order-show-event-date"
                    :label="$t('dashboard.shop.settings.order.event_card.show_event_date.label')"
                    :readonly="isReadOnly"
                />

                <OtToggleInput
                    v-model="settings.static.order.components.eventCard.showLocation"
                    data-testid="shop-settings-order-show-location"
                    :label="$t('dashboard.shop.settings.order.event_card.show_location.label')"
                    :readonly="isReadOnly"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                v-if="roles.isWhitelabelAdmin"
                :label="$t('dashboard.shop.settings.order.admin.title')"
                :description="$t('dashboard.shop.settings.order.admin.description')"
            >
                <OtToggleInput
                    v-model="settings.static.order.enableAppic"
                    :label="$t('dashboard.shop.settings.order.admin.enable_appic.label')"
                />

                <OtToggleInput
                    v-model="settings.static.order.enablePartyPay"
                    :label="$t('dashboard.shop.settings.order.admin.enable_party_pay.label')"
                />

                <OtToggleInput
                    v-model="settings.static.order.disableDownloadButton"
                    :label="$t('dashboard.shop.settings.order.admin.disable_download_button.label')"
                />

                <OtToggleInput
                    v-model="settings.static.order.components.eventCard.disableDownloadButton"
                    :label="$t('dashboard.shop.settings.order.admin.event_card.disable_download_button.label')"
                />

                <div class="ot-toggle-label">
                    {{ $t('dashboard.shop.settings.order.admin.event_card.close_url.label') }}
                </div>
                <OtTextInput
                    v-model="settings.static.order.components.eventCard.closeUrl"
                />

                <OtToggleInput
                    v-model="settings.static.order.header.showLogo"
                    :label="$t('dashboard.shop.settings.order.admin.header.showLogo.label')"
                />

                <OtToggleInput
                    v-model="settings.static.order.footer.showPoweredBy"
                    :label="$t('dashboard.shop.settings.order.admin.footer.showPoweredBy.label')"
                />

                <OtToggleInput
                    v-model="settings.static.order.footer.showLocaleSwitcher"
                    :label="$t('dashboard.shop.settings.order.admin.footer.showLocaleSwitcher.label')"
                />

                <OtToggleInput
                    v-model="settings.static.order.footer.showCopyright"
                    :label="$t('dashboard.shop.settings.order.admin.footer.showCopyright.label')"
                />

                <OtToggleInput
                    v-model="settings.static.order.footer.showSupport"
                    :label="$t('dashboard.shop.settings.order.admin.footer.showSupport.label')"
                />
            </OtInputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import type { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import type { UnwrapNestedRefs } from 'vue';
import { OtFormRow } from '../../../../components';
import { injectOrFail } from '../../../../services/util';
import type { Roles } from '../../../../plugins/types';
import { useRoles } from '../../../../composables';

const settings = injectOrFail<CustomShopSettingsClient>('settings');
const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');
const { isReadOnly } = useRoles();

</script>

<style lang="scss" scoped>
.shop-settings__order {
    &-description {
        color: var(--ot-ui-color-foreground-secondary);
    }

    :deep(.ot-ui-input-field__content) {
        display: flex;
        align-items: start;
        flex-direction: column;
        gap: 0px;
    }
}
</style>
