<template>
    <OtTFAConfirmation
        v-if="tfaTypes"
        :error="error"
        :tfa-types="tfaTypes"
        :require-password="false"
        hide-logo
        hide-logout
        @submit="submitAfterTfaConfirmation"
        @back="tfaConfirmationBack"
    />

    <OtCard
        v-else
        class="fix-reset-password"
        no-padding
    >
        <ModalHeader />

        <OtCardHeader :title="$t('dashboard.reset_password.title')" />

        <form @submit.prevent="submit()">
            <OtCardContent class="ot-ui-stacky[sm]">
                <p class="ot-ui-text-body-intro ot-ui-mb[md]">
                    <i18n-t keypath="dashboard.reset_password.subtitle.text">
                        <template #contact>
                            <OtLink
                                v-if="$whitelabel.dashboard.support_url"
                                :href="$whitelabel.dashboard.support_url"
                                :label="$t('dashboard.reset_password.subtitle.contact')"
                            />

                            <span v-else>
                                {{ $t('dashboard.reset_password.subtitle.contact') }}
                            </span>
                        </template>
                        <template #entity>
                            <span class="terms-entity terms-entity-first">
                                {{ $whitelabel.name }}
                            </span>
                        </template>
                    </i18n-t>
                </p>

                <OtInputField
                    :label="$t('dashboard.reset_password.form.create_password')"
                    :optional="form.rules.new_password && !form.rules.new_password.includes('required')"
                    :error="form.errors.new_password"
                >
                    <OtPasswordInput
                        v-model="form.model.$data.new_password"
                        data-testid="password-field"
                        :invalid="form.errors.new_password?.length > 0"
                        :placeholder="$t('dashboard.user.change_password.new_password')"
                        @input="form.errors.new_password = []"
                    />
                </OtInputField>

                <OtInputField
                    :label="$t('dashboard.reset_password.form.confirm_password')"
                    :optional="form.rules.new_password && !form.rules.new_password.includes('required')"
                    :error="form.errors.new_password_confirmation"
                >
                    <OtPasswordInput
                        v-model="form.model.$data.new_password_confirmation"
                        data-testid="password-confirmation-field"
                        :invalid="form.errors.new_password_confirmation?.length > 0"
                        :placeholder="$t('dashboard.user.change_password.new_password_confirmation')"
                        @input="form.errors.new_password_confirmation = []"
                    />
                </OtInputField>
            </OtCardContent>

            <OtCardFooter>
                <OtButton
                    type="submit"
                    :disabled="!formCompleted"
                    icon="check"
                    full-width
                    :title="$t('dashboard.reset_password.form.button.title')"
                >
                    {{ $t('dashboard.reset_password.form.button.text') }}
                </OtButton>
            </OtCardFooter>
        </form>
    </OtCard>
</template>

<script setup lang="ts">
import { computed, ref, type UnwrapNestedRefs } from 'vue';
import type { AuthClient, ForgotPassword, Password } from '@openticket/lib-auth';
import { useRoute } from 'vue-router';
import type { ValidationError } from '@openticket/lib-crud';
import type { OtTFATypes } from '@openticket/vue-ui';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import { injectOrFail } from '../../../services/util';
import type { ModelFormComposableResult } from '../../../composables/forms';
import { useGenericErrorHandling } from '../../../composables';

type Props = {
    form: UnwrapNestedRefs<
        ModelFormComposableResult<
            ForgotPassword<Password<AuthClient>>,
            ForgotPassword<Password<AuthClient>>
        >
    >,
}

type TfaErrors = {
    incorrectPassword: ValidationError & { customAttribute?: string } | null,
    incorrectTFAInput: ValidationError & { customAttribute?: string } | null
}

const props = defineProps<Props>();

const auth = injectOrFail<AuthClient>('auth');
const route = useRoute();
const { handleError } = useGenericErrorHandling();

const tfaTypes = ref<OtTFATypes | null>(null);
const error = ref<TfaErrors>({
    incorrectPassword: null,
    incorrectTFAInput: null,
});

const formCompleted = computed(
    (): boolean => !!props.form.model.$data.new_password && !!props.form.model.$data.new_password_confirmation,
);

async function submit(): Promise<void> {
    const { token } = route.params;
    props.form.model.$data.token = String(token);

    try {
        const response = await props.form.submit();

        if (response.success) {
            return;
        }

        if (props.form.errors.new_password && props.form.errors.new_password.length > 0) {
            [ error.value.incorrectPassword ] = props.form.errors.new_password;
        } else if (props.form.errors.tfa_type) {
            error.value.incorrectPassword = null;
            error.value.incorrectTFAInput = null;

            for (const err of props.form.errors.tfa_type) {
                let split = err.message.split(':');

                if (split[0] === 'validation.in') {
                    split = split[1].split(',');

                    tfaTypes.value = {};
                    for (const tfa_method of split) {
                        tfaTypes.value[tfa_method] = { name: tfa_method, settings: 1 };
                    }
                }
            }
        } else if (props.form.errors.tfa_input) {
            [ error.value.incorrectTFAInput ] = props.form.errors.tfa_input;
        }
    } catch (e) {
        handleError(e);
    }
}

async function submitAfterTfaConfirmation(tfaConfirmed: { input: string, type: string }): Promise<void> {
    if (!auth) {
        return;
    }

    props.form.model.$data.tfa_type = tfaConfirmed.type;
    props.form.model.$data.tfa_input = tfaConfirmed.input;

    await submit();
}

function tfaConfirmationBack(): void {
    tfaTypes.value = null;
}
</script>
