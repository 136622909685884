<template>
    <div class="timeline">
        <OtButton
            variant="inline"
            size="small"
            class="timeline-toggle ot-ui-text-body-sm-strong"
            :icon-right="showTimeline ? 'drop-up' : 'drop-down'"
            @click="toggleTimeline"
        >
            {{ $t(!showTimeline ? 'dashboard.revisions.toggle.open' : 'dashboard.revisions.toggle.close') }}
        </OtButton>
        <div
            class="timeline-content"
            :class="{open: showTimeline}"
        >
            <div
                v-for="[key, value] in Object.entries(items)"
                :key="key"
                class="timeline-content__item"
            >
                <i18n-t
                    keypath="dashboard.revisions.item.update.single"
                    tag="p"
                    class="ot-ui-text-body-md"
                >
                    <template #name>
                        <strong class="ot-ui-text-body-md-strong">{{ getKeyTranslation(key) }}</strong>
                    </template>
                    <template #oldValue>
                        <s>{{ parseChange(key, value.old, model) }}</s>
                    </template>
                    <template #newValue>
                        <strong class="ot-ui-text-body-md-strong">{{ parseChange(key, value.val, model) }}</strong>
                    </template>
                </i18n-t>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useLocalization } from '@openticket/vue-ui';
import type { RevisionModelType, UpdateMutation } from '../../services/revisor';
import { humanize, parseChange } from '../revisions/changeFormatter';

type Props = {
  items: UpdateMutation['changes'],
  model: RevisionModelType
}

const props = defineProps<Props>();

const { t, te } = useLocalization();

const showTimeline = ref<boolean>(false);

function toggleTimeline(): void {
    showTimeline.value = !showTimeline.value;
}

function getKeyTranslation(key: string): string {
    const tKey = `models.${props.model}.attributes.${key}.label`;
    if (te(tKey)) {
        return t(tKey);
    }
    return humanize(key);
}
</script>

<style lang="scss" scoped>
.timeline {
  &-content {
    padding: 0 0 0 var(--ot-ui-spacing-xl);
    position: relative;
    max-height: 0px;
    transition: all 250ms ease-in-out;
    overflow: hidden;

    &.open {
      padding: var(--ot-ui-spacing-md) 0 var(--ot-ui-spacing-md) var(--ot-ui-spacing-xl);
      max-height: 30rem;
      overflow-y: auto;
    }

    &__item {
      position: relative;
      padding-bottom: 1rem;

      &::before {
        content: '';
        position: absolute;
        left: calc(-2rem + 5px);
        top: 0;
        width: 1px;
        height: 100%;
        background: var(--ot-ui-color-brand);
      }

      &:after {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        display: block;
        left: calc(-2rem + 2.5px);
        border-radius: 6px;
        top: 6px;
        background: var(--ot-ui-color-brand);
      }

      &:first-child {
        &::before {
          top: 9px;
          width: 1px;
          height: calc(100% - 9px);
          background: var(--ot-ui-color-brand);
        }
      }

      &:last-child {
        padding-bottom: 0;
        &::before {
          bottom: 9px;
          width: 1px;
          height: 9px;
          background: var(--ot-ui-color-brand);
        }
      }
    }
  }
}

</style>
