<template>
    <OtModal
        ref="ticketAccessMomentsLinkModalRef"
        class="ticket-access-moments-link-modal"
        data-testid="access-moment-unlinked-ticket-modal"
        :width="540"
        toplayer
        @close="$emit('close')"
    >
        <template #header>
            <OtCardHeader :title="$t('dashboard.access_moments.unlinked_tickets.modal.title')" />
        </template>
        <template #content>
            <OtCardContent v-if="ticketModel">
                <i18n-t
                    tag="span"
                    keypath="dashboard.access_moments.unlinked_tickets.modal.description"
                    class="ticket-access-moments-link-modal__card__description"
                >
                    <template #ticket>
                        <span class="ticket-access-moments-link-modal__card__description__highlighted">
                            {{ ticketModel.$data.name }}
                        </span>
                    </template>
                </i18n-t>

                <OtFormRow>
                    <OtInputField
                        :label="$t('dashboard.access_moments.unlinked_tickets.modal.select.label')"
                        :description="$t('dashboard.access_moments.unlinked_tickets.modal.select.description')"
                    >
                        <AccessMomentsPicker
                            :key="ticketModel.id"
                            ref="accessMomentsPickerRef"
                            :access-moments="accessMomentsList"
                            :ticket="ticketModel"
                            ticket-is-unlinked
                        />
                    </OtInputField>
                </OtFormRow>
            </OtCardContent>
        </template>
        <template #footer-action>
            <OtButton
                size="default"
                variant="primary"
                icon="check"
                :title="$t('dashboard.access_moments.unlinked_tickets.modal.link.title', { ticket: ticketModel?.$data.name })"
                data-testid="access-moment-unlinked-ticket-modal-submit"
                @click="submit()"
            >
                {{ $t('dashboard.access_moments.unlinked_tickets.modal.link.text') }}
            </OtButton>
        </template>
    </OtModal>
</template>

<script setup lang="ts">
import { ref, useTemplateRef } from 'vue';
import type {
    Event, EventDate, ManagementClient, Ticket,
} from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import { OtModal } from '@openticket/vue-ui';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling } from '../../../composables';
import AccessMomentsPicker from './AccessMomentsPicker.vue';

type Props = {
    accessMomentsList: Pagination<EventDate<Event<ManagementClient>>>;
}

defineProps<Props>();

type Emits = {
    (e: 'close'): void,
    (e: 'error', error: Error): void
    (e: 'submit', model: Ticket<ManagementClient>): void
}

const emit = defineEmits<Emits>();

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const { handleError } = useGenericErrorHandling();

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const accessMomentsPickerRef = useTemplateRef('accessMomentsPickerRef');
const ticketAccessMomentsLinkModalRef = ref<InstanceType<typeof OtModal> | null>(null);
const ticketModel = ref<Ticket<ManagementClient> | null>(null);

function close() {
    void ticketAccessMomentsLinkModalRef.value?.close();

    ticketModel.value = null;
}

function show(model: Ticket<Event<ManagementClient>>) {
    if (!model) {
        return;
    }

    try {
        ticketModel.value = management.tickets.$factory(model.$raw);
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }

    ticketAccessMomentsLinkModalRef.value?.open();
}

async function submit() {
    if (!ticketModel.value) {
        return;
    }

    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await accessMomentsPickerRef.value?.submit();

        emit('submit', ticketModel.value);
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }

    close();
}

defineExpose({
    show,
});
</script>
