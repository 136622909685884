import type { RouteRecordRaw } from 'vue-router';

/**
 * @deprecated replace with access moments
 */
export function datesRoutes(): RouteRecordRaw[] {
    return [
        {
            path: 'dates',
            meta: {
                title: 'dashboard.document_title.dates.list',
                titleFallback: 'Dates',
                onContextSelect: (id: string | symbol | undefined) => {
                    if (typeof id === 'string') {
                        return {
                            name: 'dates.list',
                            params: { event: id },
                        };
                    }
                    return null;
                },
            },
            children: [
                {
                    path: '',
                    name: 'dates.list',
                    // component: DatesListView, // Deprecated
                    redirect: (to) => ({
                        name: 'access-moments.list',
                        params: { ...to.params },
                    }),
                },
                {
                    path: ':date',
                    name: 'dates.home',
                    meta: {
                        context: 'date',
                        title: 'dashboard.document_title.dates.date',
                        titleFallback: 'Date',
                    },
                    redirect: (to) => ({
                        name: 'access-moments.list',
                        params: { ...to.params },
                    }),
                    children: [
                        {
                            path: 'edit',
                            name: 'dates.edit',
                            // component: DateUpdateView, // Deprecated
                            redirect: (to) => ({
                                name: 'dates.edit.details',
                                params: { ...to.params },
                            }),
                            children: [
                                {
                                    path: 'details',
                                    name: 'dates.edit.details',
                                    redirect: (to) => ({
                                        name: 'access-moments.list',
                                        params: { ...to.params },
                                    }),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
}
