export function useDebounce(time: number = 300) {
    let debounceTimeout: ReturnType<typeof setTimeout> | null = null;

    function debounce(value: string, resolve: () => void) {
        if (debounceTimeout !== null) {
            clearTimeout(debounceTimeout);
        }

        // Update immediately when clearing the input.
        debounceTimeout = setTimeout(resolve, value === '' ? 0 : time);
    }

    return debounce;
}
