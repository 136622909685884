<template>
    <ActionsEventSelect
        :get-count="countOrders"
        :do-action="rerenderOrders"
        slug="rerender-tickets"
    />
</template>

<script setup lang="ts">
import type { AdminActions } from '../../services/admin-actions/adminActions.service';
import { injectOrFail } from '../../services/util';
import ActionsEventSelect from '../../modules/companies/components/actions/ActionsEventSelect.vue';

const adminActions = injectOrFail<AdminActions>('adminActions');

async function countOrders(eventId: string) {
    return adminActions.countRerenderEventOrders(eventId);
}

async function rerenderOrders(eventId: string) {
    await adminActions.rerenderEventOrders(eventId);
}

</script>
