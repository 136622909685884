<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <OtSpinner v-else-if="!tickets" />

    <div
        v-else
        class="module__tickets__list"
    >
        <ComingSoon :number-of-graphs="3" />

        <OtDataGrid
            data-testid="ticketslistview-datagrid"
            class="module__tickets__list__grid"
            type="tiles"
            :pagination="tickets"
            :columns="columns"
            show-switcher
            show-controls="always"
            :empty-labels="{
                subtitle: $t('dashboard.tickets.list.empty.message'),
                title: $t('dashboard.tickets.list.empty.title'),
            }"
            :loading-text="$t('dashboard.tickets.list.header.loading')"
        >
            <template #list-header="{ typeSwitcher }">
                <OtListHeader
                    :pagination="tickets"
                    searchable
                    :search-key="'name'"
                    :search-placeholder="$t('dashboard.tickets.list.header.search.placeholder')"
                    :title="$t('dashboard.document_title.tickets.list')"
                >
                    <template #filters>
                        <TicketsFilters :tickets="tickets" />
                    </template>

                    <template #actions-top>
                        <div class="ot-ui-stackx[md]">
                            <!-- TODO cu-86c1a5pq9 Replace button when ticket wizard is ready for wider use -->
                            <OtButton
                                v-if="flags['app__new_ticket_wizard']"
                                :disabled="isReadOnly"
                                :title="$t('dashboard.tickets.list.new.title')"
                                data-testid="ticket-list-new-button-wizard"
                                icon="plus"
                                @on-click="goToTicketWizard"
                            >
                                {{ $t('dashboard.tickets.list.new.text') }}
                            </OtButton>
                            <OtButton
                                :disabled="isReadOnly"
                                :title="$t('dashboard.tickets.list.new.title')"
                                data-testid="ticket-list-new-button"
                                icon="plus"
                                @on-click="formModal.open()"
                            >
                                {{ $t('dashboard.tickets.list.new.text') }}
                            </OtButton>
                        </div>
                    </template>

                    <template #actions-bottom>
                        <component :is="typeSwitcher" />
                    </template>
                </OtListHeader>
            </template>

            <template
                v-if="!Object.keys(tickets.filters?.active ?? {}).length"
                #empty-action
            >
                <OtButton
                    :disabled="isReadOnly"
                    variant="outline"
                    :title="$t('dashboard.tickets.list.new.title')"
                    icon="plus"
                    @click="formModal.open()"
                >
                    {{ $t('dashboard.tickets.list.new.text') }}
                </OtButton>
            </template>

            <template #tile="{ record }">
                <TicketsListRecord
                    :record="record"
                    :currency="currency"
                />
            </template>

            <template #column__-data__available_stock="{ record, value }">
                <FractionColumn
                    :numerator="record.$data.sold_count"
                    :denominator="value"
                />
            </template>

            <template #column__-data__min_price="{ value }">
                <!-- TODO Pricing here is a lot harder than just min_price. Needs to be discussed!! -->
                {{ $l.currency(value, currency) }}
            </template>

            <template #actions="{ record }">
                <OtTileActions
                    :buttons="[
                        {
                            title: $t('dashboard.tickets.list.edit.title', { ticket: record.$data.name }),
                            text: $t('dashboard.tickets.list.edit.text', { ticket: record.$data.name }),
                            iconRight: 'carrot-right',
                            size: 'small',
                            variant: 'outline',
                            clickHandler: () => goToTicketEditPage(record.$data.event_id, record.$data.guid),
                        }
                    ]"
                />
            </template>
        </OtDataGrid>

        <TicketCreateModal
            v-if="formModal.isOpen"
            :modal="formModal"
            @submit="(model) => tickets?.loadPage()"
        />
    </div>
</template>

<script lang="ts" setup>
import { reactive, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useLocalization, type OtDataGridColumn } from '@openticket/vue-ui';
import TicketsListRecord from '../components/TicketsListRecord.vue';
import ErrorView from '../../../components/ErrorView.vue';
import FractionColumn from '../../../components/columns/FractionColumn.vue';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';
import ComingSoon from '../../../components/ComingSoon.vue';
import TicketCreateModal from '../components/TicketCreateModal.vue';
import { useGenericErrorHandling, useRoles, useRouterPagination } from '../../../composables';
import { useFormModal } from '../../../composables/forms';
import TicketsFilters from '../components/TicketsFilters.vue';
import type { FeatureFlagPlugin } from '../../../plugins/featureFlag.plugin';

const { error } = useGenericErrorHandling();
const formModal = reactive(useFormModal());

const context = injectOrFail<Context>('context');
const { flags } = injectOrFail<FeatureFlagPlugin>('featureFlags');

const { t } = useLocalization();
const { isReadOnly } = useRoles();
const router = useRouter();

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const { list: tickets, setPagination } = useRouterPagination(context.event.model.tickets, { perPage: 10 });

const { currency } = context.event.model.$data;

const columns: Array<OtDataGridColumn> = [
    {
        key: [ '$data', 'name' ],
        type: 'string',
        label: t('models.ticket.attributes.name.label'),
        hide: { tile: true },
    },
    {
        key: [ '$data', 'min_price' ],
        type: 'custom',
        label: t('models.ticket.attributes.min_price.label'),
        tileColumnSize: 'small',
    },
    {
        key: [ '$data', 'available_stock' ],
        type: 'custom',
        label: t('models.ticket.attributes.available_stock.label'),
    },
];

// Reload pagination when event context changes.
watch(() => context.event?.id, (value) => {
    if (value !== null && value !== undefined) {
        void setPagination(context.event.model.tickets);
    }
});

function goToTicketEditPage(event: string, ticket: string): void {
    void router.push({
        name: 'tickets.edit',
        params: { event, ticket },
    });
}

function goToTicketWizard() {
    void router.push({
        name: 'tickets.new',
        params: {},
    });
}
</script>

<style lang="scss" scoped>
.module__tickets__list {
    padding-top: var(--ot-ui-spacing-md);

    &__filters {
        display: flex;
        flex-direction: row;
        gap: var(--ot-ui-spacing-md);
    }
}
</style>
