import {
    DataType, Model,
} from '@openticket/lib-crud';
import type { Parent, DataInterface, RawInterface } from '@openticket/lib-crud';

const ModelConfig = {
    allow_marketing: [ DataType.boolean ],
    created_at: [ DataType.iso8601, DataType.readonly ],
    deleted_at: [ DataType.iso8601, DataType.null, DataType.readonly ],
    email: [ DataType.string ],
    event_id: [ DataType.string ],
    first_name: [ DataType.string ],
    guid: [ DataType.uuid, DataType.primary ],
    last_name: [ DataType.string ],
    locale: [ DataType.string ],
    quantity: [ DataType.number, DataType.undefined, DataType.null ],
    reseller_ticketswap_enabled: [ DataType.boolean ],
    reseller_ticketswap_status: [ DataType.string ],
    shop_id: [ DataType.string ],
    source: [ DataType.string ],
    updated_at: [ DataType.iso8601, DataType.readonly ],
};

type Config = typeof ModelConfig;

export type {
    Config as WaitingListSignupConfig,
};

export type WaitingListSignupRaw = RawInterface<Config>;
export type WaitingListSignupData = DataInterface<Config>;

export class WaitingListSignup<Ancestor extends Parent> extends Model<Ancestor, Config> {

    static $defaultData(): RawInterface<Config> {
        return {
            allow_marketing: false,
            created_at: '',
            deleted_at: null,
            email: '',
            event_id: '',
            first_name: '',
            guid: '',
            last_name: '',
            locale: '',
            quantity: null,
            reseller_ticketswap_enabled: false,
            reseller_ticketswap_status: '',
            shop_id: '',
            source: '',
            updated_at: '',
        };
    }

    get id(): string | null {
        return this.$data.guid || null;
    }

    $config(): Config {
        return ModelConfig;
    }

    $modelName(): string {
        return 'WaitingListSignup';
    }

}
