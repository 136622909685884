<template>
    <FormWrapper
        class="addon-product-update"
        :form="addonProductForm"
        back-route="addon-products.list"
    >
        <VerticalTabs>
            <template #default="{ selected }">
                <VerticalTabsItem
                    :label="$t('dashboard.addon_products.details.route_title')"
                    :route="{ name: 'addon-products.edit.details' }"
                    :selected="selected"
                >
                    <AddonProductForm :form="addonProductForm" />
                </VerticalTabsItem>
                <VerticalTabsItem
                    :label="$t('dashboard.addon_products.linked_tickets.route_title')"
                    :route="{ name: 'addon-products.edit.linked-tickets' }"
                    :selected="selected"
                >
                    <AddonProductLinkedTicketsView />
                </VerticalTabsItem>
            </template>
        </VerticalTabs>
    </FormWrapper>
</template>

<script setup lang="ts">
import {
    reactive,
} from 'vue';
import type {
    Event,
    ManagementClient,
    Product,
} from '@openticket/lib-management';
import { useUpdateForm } from '../../../composables/forms';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import VerticalTabs from '../../../components/verticaltabs/VerticalTabs.vue';
import VerticalTabsItem from '../../../components/verticaltabs/VerticalTabsItem.vue';
import FormWrapper from '../../../components/form/FormWrapperNew.vue';
import AddonProductForm from '../components/forms/AddonProductForm.vue';
import AddonProductLinkedTicketsView from './AddonProductLinkedTicketsView.vue';

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');

if (!context.isAddonProductContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const addonProductForm = reactive(
    useUpdateForm<Product<ManagementClient>, Event<ManagementClient>>(
        context.addonProduct.model,
        context.event.model.products,
    ),
);
</script>
