<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <OtSpinner v-else-if="!waitingListSignups" />

    <div
        v-else
        class="module__waiting-list__signup-list"
    >
        <OtDataGrid
            class="module__waiting-list__signup-list__grid"
            type="table"
            :pagination="waitingListSignups"
            :columns="filteredSignupColumns()"
            :empty-labels="{
                subtitle: $t(emptyMessageSlug),
                title: $t(emptyTitleSlug),
            }"
            :loading-text="$t('dashboard.waiting_list.list.header.loading')"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="waitingListSignups"
                    searchable
                    search-key="email"
                    :search-placeholder="$t('dashboard.waiting_list.signup_list.header.search.placeholder')"
                    :title="$t('dashboard.document_title.waiting_list.signup_list')"
                >
                    <template #filters>
                        <OtFilters
                            :categories-to-hide="filterCategoriesToHide"
                            :labels="$t('models.waiting-list')"
                            :pagination="waitingListSignups"
                            :translations="$t('dashboard.common.filters')"
                            :view-filter="{ label: context.event?.name }"
                        />
                    </template>

                    <template #actions-top>
                        <div class="module__waiting-list__signup-list__actions ot-ui-stack[md]">
                            <OtButton
                                data-testid="waiting-list-button-settings"
                                :title="$t('dashboard.waiting_list.signup_list.header.settings.title')"
                                icon-right="carrot-right"
                                variant="outline"
                                size="small"
                                @click="goToWaitingListSettings"
                            >
                                {{ $t('dashboard.waiting_list.signup_list.header.settings.text') }}
                            </OtButton>
                            <OtButton
                                data-testid="waiting-list-button-export"
                                :title="$t('dashboard.waiting_list.signup_list.header.export.title')"
                                icon="download"
                                size="small"
                                @click="handleExport"
                            >
                                {{ $t('dashboard.waiting_list.signup_list.header.export.text') }}
                            </OtButton>
                        </div>
                    </template>
                </OtListHeader>
            </template>
            <template
                v-if="!Object.keys(waitingListSignups.filters?.active ?? {}).length"
                #empty-action
            >
                <div
                    v-if="$te('dashboard.waiting_list.signup_list.empty.help_text')"
                >
                    <OtLink
                        v-if="empty_help_link"
                        :href="empty_help_link"
                        :label="$t('dashboard.waiting_list.signup_list.empty.help_text')"
                    />
                    <span v-else>
                        {{ $t('dashboard.waiting_list.signup_list.empty.help_text') }}
                    </span>
                </div>
                <div
                    v-if="isDisabledBySettings"
                    class="module__waiting-list__signup-list__grid__empty-button-wrapper"
                >
                    <OtButton
                        variant="outline"
                        size="small"
                        icon-right="carrot-right"
                        :title="$t('dashboard.waiting_list.signup_list.empty.settings_button.title')"
                        @on-click="goToWaitingListSettings"
                    >
                        {{ $t('dashboard.waiting_list.signup_list.empty.settings_button.text') }}
                    </OtButton>
                </div>
            </template>

            <template #column__$data__name="{ record }">
                {{ record.$data.first_name }} {{ record.$data.last_name }}
            </template>

            <template #column__$data__quantity="{ record }">
                {{ $t('dashboard.waiting_list.signup_list.column_values.quantity', record.$data.quantity) }}
            </template>

            <template #column__$data__submitted="{ record }">
                <span :title="$l.dateTime(new Date(record.$data.created_at))">
                    {{ getPastRelativeTimeString(new Date(record.$data.created_at), localization.locale.language) }}
                </span>
            </template>

            <template #actions="{ record }">
                <OtButton
                    class="module__waiting-list__signup-list__remove-button"
                    data-testid="waiting-list-signup-remove"
                    :disabled="isReadOnly"
                    :title="$t('dashboard.waiting_list.signup_list.remove.title')"
                    variant="inline"
                    icon="close"
                    size="small"
                    @on-click="handleDelete(record.$data.guid)"
                />
            </template>
        </OtDataGrid>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import type { Pagination } from '@openticket/lib-crud';
import { isAxiosError } from 'axios';
import { useRouter } from 'vue-router';
import {
    type OtDataGridColumn, useConfirmationModal, useLocalization, useNotifications,
} from '@openticket/vue-ui';
import ErrorView from '../../../components/ErrorView.vue';
import type { Context } from '../../../services/context';
import { getPastRelativeTimeString, injectOrFail } from '../../../services/util';
import { useGenericErrorHandling, useRoles } from '../../../composables';
import type { WaitingList, WaitingListSignup } from '../../../services/waiting-list/client/models';
import type { WaitingListClient } from '../../../services/waiting-list/client/waitingListClient';

const context = injectOrFail<Context>('context');
const { isReadOnly } = useRoles();

const dialog = useConfirmationModal();
const { error } = useGenericErrorHandling();
const { t, localization } = useLocalization();
const notifications = useNotifications();
const router = useRouter();

const empty_help_link = t('dashboard.waiting_list.signup_list.empty.help_link');

const columns: Array<OtDataGridColumn> = [
    {
        key: [ '$data', 'name' ],
        type: 'custom',
        label: t('dashboard.waiting_list.signup_list.columns.name'),
    },
    {
        key: [ '$data', 'email' ],
        type: 'email',
        label: t('models.waiting_list_signup.attributes.email.label'),
    },
    {
        key: [ '$data', 'quantity' ],
        type: 'custom',
        label: t('dashboard.waiting_list.signup_list.columns.quantity'),
    },
    {
        key: [ '$data', 'submitted' ],
        type: 'custom',
        label: t('dashboard.waiting_list.signup_list.columns.created_at'),
    },
    {
        key: [ '$data', 'reseller_ticketswap_enabled' ],
        type: 'boolean',
        label: t('dashboard.waiting_list.signup_list.columns.reseller_ticketswap_enabled'),
        hide: { table: true },
    },
];

const filterCategoriesToHide = [
    'updated_at',
    'deleted_at',
];

const waitingListClient = injectOrFail<WaitingListClient>('waitingListClient');
const waitingListSettings = ref<WaitingList<WaitingListClient>>();
const waitingListSignups = ref<Pagination<WaitingListSignup<WaitingList<WaitingListClient>>>>();

const isDisabledBySettings = computed<boolean>(
    () => !!(waitingListSettings.value && waitingListSettings.value.$data && !waitingListSettings.value.$data.enabled),
);

const emptyTitleSlug = computed<string>(() => {
    if (waitingListSignups.value && !!Object.keys(waitingListSignups.value.filters.active ?? {}).length) {
        return 'dashboard.waiting_list.signup_list.empty.title_filtered';
    }
    return 'dashboard.waiting_list.signup_list.empty.title';
});

const emptyMessageSlug = computed<string>(() => {
    if (isDisabledBySettings.value) {
        return 'dashboard.waiting_list.signup_list.empty.message';
    }
    return '';
});

watch(() => context.event?.id, (value) => {
    if (value) {
        if (context.company && context.company.id) {
            void loadSignupList(value, context.company.id);
        }
    }
});

async function loadSignupList(eventId: string, companyId: string) {
    if (!eventId) {
        return;
    }
    try {
        // Replace with backend findOrCreate when available
        waitingListSettings.value = await waitingListClient.findOrCreate(eventId, companyId);

        if (waitingListSettings.value && waitingListSettings.value.$data) {
            try {
                waitingListSignups.value = waitingListSettings.value.signups.list({ deferInitialization: true });
                await waitingListSignups.value.initialization;
            } catch (listError) {
                if (!isAxiosError(listError) || listError.response?.status !== 404) {
                    throw (listError);
                }
            }
        }
    } catch (e) {
        // TODO Proper error + logging
        console.error('Waiting list could not be loaded or created');
        notifications.error(t('dashboard.common.generic_error'));
    }
}

void (async () => {
    if (context.event && context.event.id && context.company && context.company.id) {
        await loadSignupList(context.event.id, context.company.id);
    }
})();

async function handleDelete(guidToDelete: string) {
    if (guidToDelete && context.event && context.event.id) {
        const confirm = await dialog?.confirm({
            title: t('dashboard.waiting_list.signup_list.confirm.remove_signup.title') || '',
            message: t('dashboard.waiting_list.signup_list.confirm.remove_signup.description') || '',
            confirmLabel: t('dashboard.waiting_list.signup_list.confirm.remove_signup.confirm_button') || '',
            variant: 'destructive',
        });

        if (!confirm || !waitingListSignups.value || !waitingListSettings.value) {
            return;
        }

        const signupToDelete = waitingListSignups.value?.records.find((signup) => signup.$data.guid === guidToDelete);
        if (signupToDelete) {
            await waitingListSettings.value?.signups.delete(signupToDelete);
            await loadSignupList(context.event.id, context.company.id);
        }
    }
}

function goToWaitingListSettings(): void {
    if (!context.event || !context.event.id) {
        return;
    }

    void router.push({
        name: 'waiting_list.settings',
        params: { event: context.event.id, waitingList: waitingListSettings.value.$data.guid },
    });
}

async function handleExport(): Promise<void> {
    if (!context.event || !context.event.id || !waitingListSignups.value) {
        return;
    }

    await waitingListSettings.value?.signups.exportList(context.event.name || '', waitingListSignups.value?.filters?.active);
}

function filteredSignupColumns(): typeof columns {
    const columnsToHide: string[] = [];
    if (!waitingListSettings.value || !waitingListSettings.value.$data.allow_quantity) {
        columnsToHide.push('quantity');
    }

    if (!waitingListSettings.value || !waitingListSettings.value.$data.allow_reseller_ticketswap) {
        columnsToHide.push('reseller_ticketswap_enabled');
    }

    return columns.filter((c) => c.key && c.key.length > 1 && typeof c.key[1] === 'string' && !columnsToHide.includes(c.key[1]));
}
</script>

<style lang="scss" scoped>
.module__waiting-list__signup-list {
    padding-top: var(--ot-ui-spacing-md);

    &__filters {
        display: flex;
        flex-direction: row;
        gap: var(--ot-ui-spacing-md);
    }

    &__remove-button:hover {
        color: var(--ot-ui-color-intent-error-primary);
    }

    &__grid__empty-button-wrapper {
        margin-top: var(--ot-ui-spacing-md);
        display: flex;
        justify-content: center;
    }

}
</style>
