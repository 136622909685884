import { OtError, UnknownError } from '@openticket/lib-log';

export class AlbatoInvalidResponseError extends OtError {

    constructor(cause?: Error) {
        super('AlbatoInvalidResponseError', 'dashboard.errors.plugins.albato.invalid.response', 'Response is not a valid');

        if (cause) {
            this.causedBy(new UnknownError(cause));
        }

        this.captureStackTrace(AlbatoInvalidResponseError);
    }

}
