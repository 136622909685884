<template>
    <div class="profile-invitations">
        <OtButton
            icon="inbox"
            full-width
            size="small"
            variant="outline"
            @on-click="openInvitations()"
        >
            {{ $t('dashboard.header.profile.invitations.button.text', count) }}
        </OtButton>

        <ProfileInvitationsModal
            ref="invitationsModal"
            @close="closeEvent"
        />
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import type { AuthClient } from '@openticket/lib-auth';
import ProfileInvitationsModal from '../ProfileInvitationsModal.vue';
import { injectOrFail } from '../../../../services/util';
import { useGenericErrorHandling } from '../../../../composables/useGenericErrorHandling';

type Emits = {
    (e: 'has-invitations', value: boolean): void
}
const emit = defineEmits<Emits>();

const auth = injectOrFail<AuthClient>('auth');

const invitationsModal = ref<InstanceType<typeof ProfileInvitationsModal>>();
const count = ref<number>(0);

void (async () => {
    await updateCount();
})();

function openInvitations(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    invitationsModal.value?.open();
}

async function updateCount() {
    try {
        const invitations = auth.invitations.list({ deferInitialization: true });

        await invitations.initialization;

        count.value = invitations.total ? invitations.total : 0;
        emit('has-invitations', !!count.value);
    } catch (e) {
        useGenericErrorHandling();
    }
}

async function closeEvent() {
    await updateCount();
}
</script>
