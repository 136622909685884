<template>
    <DebugOverlay
        v-if="show"
        :is-loading="isLoading"
        :components-registered="componentsRegistered"
        @close="close"
    />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import DebugOverlay from './DebugOverlay.vue';
import { injectOrFail } from '../../services/util';
import type { PluginsManager } from '../../plugins';

const plugins = injectOrFail<PluginsManager>('plugins');

type Props = {
    isLoading: boolean;
    componentsRegistered: boolean;
};
defineProps<Props>();

const show = ref<boolean>(false);

void (() => {
    try {
        show.value = !!new URLSearchParams(window.location.search).get('debugger');
    } catch (e) { /* No-op */ }

    try {
        plugins.hotkeys.registerHotKey(() => {
            show.value = !show.value;
        }, 'Show debugger window', 'd', 'KeyD', { altKey: true, ctrlKey: true, shiftKey: true });
    } catch (e) { /* No-op */ }
})();

function close() {
    show.value = false;
}
</script>
