<template>
    <div
        class="draggable-item"
    >
        <div class="draggable-item__header">
            <img
                v-if="item.image"
                :alt="item.name"
                :src="item.image"
                class="draggable-item__header__image"
            >
            <OtIcon
                v-else-if="icon"
                :type="icon"
            />
            <div class="draggable-item__title">
                {{ item.name }}
                <div
                    v-if="item.description"
                    class="draggable-item__title__description ot-ui-text-body-sm"
                >
                    {{ item.description }}
                </div>
            </div>
        </div>
        <div class="draggable-item__actions">
            <OtButton
                v-if="isDraggable"
                data-drag-handle
                :data-testid="`draggable-item-drag-handle-${item.guid}`"
                class="draggable-item__actions__sort"
                size="tiny"
                variant="inline"
                icon="sort"
            />
            <OtButton
                class="draggable-item__actions__close"
                data-testid="draggable-item-remove"
                icon="close"
                size="tiny"
                variant="inline"
                @click="emit('remove', item.guid)"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { OtIconTypes } from '@openticket/vue-ui';
import type { DragItem } from './types';

type Props = {
    item: DragItem,
    icon?: OtIconTypes,
    isDraggable?: boolean,
}

type Emits = {
    (e: 'remove', ticketId: string): void
}

withDefaults(defineProps<Props>(), {
    icon: undefined,
    isDraggable: true,
});
const emit = defineEmits<Emits>();
</script>

<style lang="scss" scoped>
.draggable-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--ot-ui-spacing-md) 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--ot-ui-color-accent-tertiary);
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--ot-ui-spacing-xs);

        &__image {
            margin-right: var(--ot-ui-spacing-xs);
        }
    }

    &__title {
        &__description {
            color: var(--ot-ui-color-accent-primary);
        }
    }

    &__actions {
        display: flex;

        &__close.ot-ui-button, &__sort.ot-ui-button {
            color: var(--ot-ui-color-foreground-secondary);
        }

        &__close, &__sort {
            margin-left: var(--ot-ui-spacing-md);
        }

        &__close.ot-ui-button:hover {
            color: var(--ot-ui-color-intent-warning-primary);
        }

        &__sort.ot-ui-button:hover {
            color: var(--ot-ui-color-brand);
        }
    }
}
</style>
