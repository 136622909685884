<template>
    <div class="addon-product-update__linked-tickets">
        <ErrorView
            v-if="error"
            :label="error.message"
        />

        <OtDataGrid
            class="addon-product-update__linked-tickets__grid"
            type="table"
            :columns="columns"
            :pagination="linkedTickets"
            hide-header
            :empty-labels="{
                title: $t('dashboard.addon_products.linked_tickets.list.empty.title'),
            }"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="linkedTickets"
                    :title="$t('dashboard.addon_products.linked_tickets.list.header.title')"
                />
            </template>
            <template #actions="{ record }">
                <RouterLink
                    class="addon-product-update__linked-tickets__grid__item__delete"
                    data-testid="addon-product-linked-tickets-navigation"
                    :to="{ name: 'tickets.home', params: { ticket: record.id }}"
                >
                    <OtButton
                        icon-right="carrot-right"
                        size="tiny"
                        variant="outline"
                    >
                        {{ $t('dashboard.addon_products.linked_tickets.list.ticket.navigation.text') }}
                    </OtButton>
                </RouterLink>
            </template>
        </OtDataGrid>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type {
    Ticket,
    ManagementClient,
    Product,
} from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import {
    OtDataGrid,
    useLocalization,
    type OtDataGridColumn,
} from '@openticket/vue-ui';
import { RouterLink } from 'vue-router';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling } from '../../../composables';
import ErrorView from '../../../components/ErrorView.vue';

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const { error } = useGenericErrorHandling();
const { t } = useLocalization();

if (!context.isAddonProductContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const columns: OtDataGridColumn[] = [
    {
        key: [ '$data', 'name' ],
        type: 'string',
        label: t('Name'),
        tileColumnSize: 'default',
    },
];

// Type adjusted copy of the contexts addonproduct to have tickets relation
const addonProduct = management.products.$factory(context.addonProduct.model.$raw);
const linkedTickets = ref<Pagination<Ticket<Product<ManagementClient>>>>();

void loadTickets();

async function loadTickets() {
    linkedTickets.value = addonProduct.tickets.list({ deferInitialization: true });
    await linkedTickets.value?.initialization;
}
</script>
