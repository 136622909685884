<template>
    <div class="debug-app ot-card">
        <div
            class="debug-app__header debug__header"
            role="switch"
            tabindex="0"
            :aria-expanded="opened"
            :aria-checked="opened"
            @click="keepOpen"
            @keydown.space="keepOpen"
            @keydown.enter="keepOpen"
        >
            <!-- Class needed for styling -->
            <OtIcon
                :class="ok ? 'oti-checkmark' : 'oti-warning'"
                size="small"
                :type="ok ? 'checkmark' : 'warning'"
            />
            App status

            <span class="hspace" />

            <!-- Class needed for styling -->
            <OtIcon
                :class="opened ? 'oti-drop-up' : 'oti-drop-down'"
                :type="opened ? 'drop-up' : 'drop-down'"
            />
        </div>

        <div
            v-if="opened"
            class="debug-app__content"
        >
            <div class="debug-app__name">
                <code class="grow">Name:</code>

                <code>{{ releaseMeta.appName }}</code>
            </div>

            <div class="debug-app__version">
                <code class="grow">Version:</code>

                <code>{{ version }}</code>
            </div>

            <div class="debug-app__date">
                <code class="grow">Build date:</code>

                <code>{{ releaseMeta.date }}</code>
            </div>

            <div class="debug-app__components-registered">
                <code class="grow">Components registered:</code>

                <code
                    :class="componentsRegistered ? '' : 'is-failure'"
                >{{ componentsRegistered ? 'Registered' : 'Waiting...' }}</code>
            </div>

            <div class="debug-app__loading">
                <code class="grow">Loading:</code>

                <code :class="isLoading ? 'is-failure' : ''">{{ isLoading ? 'Loading...' : 'Done' }}</code>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { meta, type ReleaseMeta } from '@openticket/lib-release-meta';

type Props = {
    opened?: boolean;
    isLoading: boolean;
    componentsRegistered: boolean;
};
const props = withDefaults(defineProps<Props>(), {
    opened: false,
});

type Emits = {
    (e: 'keep-open'): void
}
const emit = defineEmits<Emits>();

const ok = computed<boolean>(() => !props.isLoading && props.componentsRegistered);
const releaseMeta = computed<ReleaseMeta>(() => meta);
const version = computed<string>(() => `${releaseMeta.value.appVersion}${releaseMeta.value.semVersion ? ` (${releaseMeta.value.semVersion})` : ''}`);

function keepOpen() {
    emit('keep-open');
}
</script>

<style lang="scss" scoped>
.debug-app {
    padding: 0;

    & > &__header, & > &__content {
        padding: var(--ot-card-padding);
    }

    & > &__header {
        cursor: pointer;
    }

    & > &__content > div {
        display: flex;
        margin-top: 1px;

        & > code.grow {
            flex-grow: 1;
        }
    }
}
</style>
