<template>
    <OtFilters
        :categories-to-hide="filterCategoriesToHide"
        :categories-order="filterCategoriesOrder"
        :pagination="events"
        translations-prefix-key="models.event"
        :view-filter="{ label: companyViewFilterSlug }"
    >
        <template #tag__past="{ filter }">
            <OtFilterTag
                v-if="filter.active.scope !== null"
                closable
                :values="[ { label: $t('models.event.scopes.past.label'), filtertype: 'scope' } ]"
                @close="(filtertype) => events.setFilter(filter.attribute, filtertype, null)"
            />
        </template>

        <template #tag__upcoming="{ filter }">
            <OtFilterTag
                v-if="filter.active.scope !== null"
                closable
                :values="[ { label: $t('models.event.scopes.upcoming.label'), filtertype: 'scope' } ]"
                @close="(filtertype) => events.setFilter(filter.attribute, filtertype, null)"
            />
        </template>

        <OtFilterCategory
            id="event_period"
            v-slot="{ state }"
            :attributes="['past', 'upcoming']"
            icon="calendar"
            label="Event period"
            class="events-filters__custom__event-period"
        >
            <OtRadioGroupInput
                :model-value="eventPeriodValue(state)"
                :options="eventPeriodOptions"
                @input="(val) => eventPeriodUpdate(state, val)"
            />
        </OtFilterCategory>
    </OtFilters>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { Pagination } from '@openticket/lib-crud';
import type { Event as ManagementEvent, ManagementClient } from '@openticket/lib-management';
import { useLocalization } from '@openticket/vue-ui';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';

type Props = {
    events: Pagination<ManagementEvent<ManagementClient>>;
}

defineProps<Props>();

const context = injectOrFail<Context>('context');

const { t } = useLocalization();

const companyViewFilterSlug = computed<string>(() => (context.isCompanyContext()
    ? context.company.name
    : t('dashboard.sidebar.context_filter.company.all_companies')
));

const filterCategoriesToHide = [
    'contact_email',
    'contact_name',
    'contact_phone',
    'created_at',
    'currency',
    'email_info',
    'gui_mode',
    'updated_at',
    'locale',
    'retrievable_after',
    'status',
    'status_until',
    'type',
    'visitor_contact_email',
    'visitor_contact_phone',
    'visitor_contact_url',
    'website',
];

const filterCategoriesOrder = [
    'event_period',
    'start',
    'end',
    'name',
];

type EventPeriodState = {
    past: { scope: string | null },
    upcoming: { scope: string | null },
}

const eventPeriodOptions = {
    upcoming: { label: t('models.event.scopes.upcoming.label') },
    past: { label: t('models.event.scopes.past.label') },
    all: { label: t('dashboard.events.list.filters.all_events') },
};

function eventPeriodValue(state: Partial<EventPeriodState>): keyof typeof eventPeriodOptions {
    if (state.past?.scope) {
        return 'past';
    } if (state.upcoming?.scope) {
        return 'upcoming';
    }

    return 'all';
}

function eventPeriodUpdate(state: Partial<EventPeriodState>, value: Event) {
    if (value instanceof Event && value.target instanceof HTMLInputElement) {
        if (state.past) {
            state.past.scope = value.target?.value === 'past' ? '1' : null;
        }
        if (state.upcoming) {
            state.upcoming.scope = value.target?.value === 'upcoming' ? '1' : null;
        }
    }
}
</script>

<style lang="scss" scoped>
.events-filters {
    &__custom__event-period {
        :deep(.ot-ui-radio-input__content) {
            min-height: 1.5rem !important;
        }
    }
}
</style>
