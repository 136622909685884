import { RudderAnalytics } from '@rudderstack/analytics-js';
import { send } from '@openticket/lib-log';
import { RudderstackCallError, RudderstackInitError } from './errors';

export class RudderStack {

    private rudderanalytics: RudderAnalytics | null = null;

    init(writeKey: string, dataPlaneUrl: string, configUrl?: string, pluginsUrl?: string, integrationsUrl?: string) {
        if (window.rudderanalytics) {
            console.warn('RudderStack instance initiated twice. Updating existing instance.');

            this.rudderanalytics = window.rudderanalytics as RudderAnalytics;

            return;
        }

        try {
            this.rudderanalytics = new RudderAnalytics();

            this.rudderanalytics.load(writeKey, dataPlaneUrl, {
                configUrl,
                destSDKBaseURL: integrationsUrl,
                plugins: [
                    'GoogleLinker',
                    'DeviceModeDestinations',
                    'NativeDestinationQueue',
                    'StorageEncryptionLegacy',
                    'StorageEncryption',
                    'StorageMigrator',
                ],
                pluginsSDKBaseURL: pluginsUrl,
                storage: {
                    encryption: {
                        version: 'legacy',
                    },
                    migrate: false,
                },
            });

            window.rudderanalytics = this.rudderanalytics;

            this.setAnonymousIdFromHashIfPresent();
        } catch (e: unknown) {
            if (e instanceof Error) {
                throw new RudderstackInitError(e);
            }

            throw e;
        }
    }

    identify(...args: Parameters<RudderAnalytics['identify']>) : void {
        this.methodWrapper((rs) => rs.identify(...args));
    }

    page(...args: Parameters<RudderAnalytics['page']>) : void {
        this.methodWrapper((rs) => rs.page(...args));
    }

    track(...args: Parameters<RudderAnalytics['track']>): void {
        this.methodWrapper((rs) => rs.track(...args));
    }

    private methodWrapper(rudderStackFn: (rs: RudderAnalytics) => void) : void {
        try {
            if (this.rudderanalytics) {
                rudderStackFn(this.rudderanalytics);
            }
        } catch (e) {
            if (e instanceof Error) {
                send(new RudderstackCallError(e));
            }

            console.error('Error in rudderanalytics call', e);
        }
    }

    private setAnonymousIdFromHashIfPresent() {
        const regex = /eaid=([^&]*)/;
        const hashMatches = window.location.hash.match(regex);
        const eaid = hashMatches ? hashMatches[1] : null;

        if (eaid) {
            this.methodWrapper((rs) => rs.setAnonymousId(eaid));
            window.location.hash = '';
        }
    }

}
