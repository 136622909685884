<template>
    <div
        class="shop-settings__details"
    >
        <div class="shop-settings__details__form">
            <OtFormRow>
                <!--
                    TODO CU-86c0a8wx8: Replace hardcoded translations below with slugs
                    'dashboard.shops.edit.details.form.shop_name.label'
                    'dashboard.shops.edit.details.form.shop_name.description'
                -->
                <OtInputField
                    label="Shop name"
                    description="This is the public name of your shop"
                    :optional="form.rules.name && !form.rules.name.includes('required')"
                    :error="form.errors.name"
                >
                    <OtTextInput
                        v-model="form.model.$data.name"
                        data-testid="shop-settings-details-form-name"
                        :readonly="isReadOnly"
                        @input="form.errors.name = []"
                    />
                </OtInputField>
            </OtFormRow>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ManagementClient, Shop } from '@openticket/lib-management';
import type { UnwrapNestedRefs } from 'vue';
import { type ModelFormComposableResult } from '../../../../composables/forms';
import { useRoles } from '../../../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Shop<ManagementClient>, Shop<ManagementClient>>>
}

defineProps<Props>();

const { isReadOnly } = useRoles();
</script>
