<template>
    <div class="module__access-moments__list">
        <OtDataGrid
            class="module__access-moments__list__grid"
            type="tiles"
            :pagination="accessMoments"
            :columns="columns"
            :loading-text="$t('common.loading')"
            :empty-labels="{
                title: $t('dashboard.access_moments.list.empty.title', {
                    name: context.event.name,
                    start: context.event.model.$data.start && $l.dateTimeLong(context.event.model.$data.start),
                    end: context.event.model.$data.end && $l.dateTimeLong(context.event.model.$data.end)
                })
            }"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="accessMoments"
                    searchable
                    search-key="name"
                    :search-placeholder="$t('common.placeholder.search')"
                    :title="$t('dashboard.document_title.access_moments.list')"
                >
                    <template #filters>
                        <OtFilters
                            :categories-to-hide="filterCategoriesToHide"
                            :pagination="accessMoments"
                            translations-prefix-key="models.eventdate"
                            :view-filter="{ label: context.event?.name }"
                        />
                    </template>

                    <template #actions-top>
                        <OtButton
                            :disabled="isReadOnly"
                            icon="plus"
                            :title="$t('dashboard.access_moments.list.header.new.title')"
                            data-testid="accessmoment-create-btn"
                            @click="formModal.open()"
                        >
                            {{ $t('dashboard.access_moments.list.header.new.text') }}
                        </OtButton>
                    </template>
                </OtListHeader>
            </template>

            <template #empty-subtitle>
                <i18n-t
                    class="company-selector__title"
                    tag="span"
                    keypath="dashboard.access_moments.list.empty.message"
                >
                    <template #link>
                        <!-- TODO: Replace with correct support url link -->
                        <OtLink
                            :label="$t('dashboard.access_moments.list.empty.link')"
                            :href="whitelabel.dashboard.support_url"
                        />
                    </template>
                </i18n-t>
            </template>

            <template #empty-action>
                <OtButton
                    variant="outline"
                    icon="plus"
                    data-testid="access-moment-grid-empty-new"
                    @click="formModal.open()"
                >
                    {{ $t('dashboard.access_moments.list.header.new.text') }}
                </OtButton>
            </template>

            <template #tile="{ record }">
                <AccessMomentsListTile
                    :record="record"
                    @link-action="showModal(record)"
                />
            </template>
        </OtDataGrid>

        <UnlinkedTicketsList
            v-if="accessMoments.records.length > 0"
            ref="unlinkedTicketsListRef"
            :access-moments-list="accessMoments"
            data-testid="access-moment-unlinked-tickets-list"
            @reload="reloadLists()"
        />

        <AccessMomentCreateModal
            v-if="formModal.isOpen"
            :modal="formModal"
            show-add-another-button
            :show-location-field="false"
            :show-name-field="true"
            @submit="() => accessMoments?.loadPage()"
        />

        <AccessMomentTicketsLinkModal
            ref="accessMomentTicketsLinkModalRef"
            @submit="reloadLists()"
        />
    </div>
</template>

<script setup lang="ts">
import { reactive, useTemplateRef, watch } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { Event, EventDate, ManagementClient } from '@openticket/lib-management';
import { type OtDataGridColumn, useLocalization } from '@openticket/vue-ui';
import { useRoles, useRouterPagination } from '../../../composables';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import AccessMomentsListTile from '../components/AccessMomentListTile.vue';
import { useFormModal } from '../../../composables/forms';
import AccessMomentCreateModal from '../components/AccessMomentCreateModal.vue';
import AccessMomentTicketsLinkModal from '../components/AccessMomentTicketsLinkModal.vue';
import UnlinkedTicketsList from '../components/UnlinkedTicketsList.vue';

const context = injectOrFail<Context>('context');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const { isReadOnly } = useRoles();
const { t } = useLocalization();

if (!context.isEventContext()) {
    throw new Error('Invalid context!');
}

const { list: accessMoments, setPagination } = useRouterPagination(context.event.model.eventDates, {
    perPage: 5,
    defaultIncludes: [ 'tickets_count' ],
    defaultSort: [ [ 'start', 'asc' ] ],
});
const formModal = reactive(useFormModal());

const accessMomentTicketsLinkModalRef = useTemplateRef('accessMomentTicketsLinkModalRef');
const unlinkedTicketsListRef = useTemplateRef('unlinkedTicketsListRef');

const columns: Array<OtDataGridColumn> = [
    {
        key: [ '$data', 'start' ],
        type: 'date',
        label: t('models.eventdate.attributes.start.label'),
        tileColumnSize: 'default',
    },
    {
        key: [ '$data', 'end' ],
        type: 'date',
        label: t('models.eventdate.attributes.end.label'),
        tileColumnSize: 'default',
    },
    {
        key: [ '$data', 'location_id' ],
        type: 'custom',
        label: t('models.location.name.single'),
        tileColumnSize: 'default',
    },
    {
        key: [ '$data', 'capacity' ],
        type: 'number',
        label: t('models.eventdate.attributes.capacity.label'),
        tileColumnSize: 'default',
    },
];

const filterCategoriesToHide = [
    'created_at',
    'facebook_event_id',
    'name',
    'updated_at',
];

watch(() => context.event?.id, (value) => {
    if (value !== null && value !== undefined) {
        void setPagination(context.event.model.eventDates);
    }
});

function showModal(record: EventDate<Event<ManagementClient>>): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    accessMomentTicketsLinkModalRef.value?.show(record);
}

function reloadLists() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    void unlinkedTicketsListRef.value?.reload();
    void accessMoments.value.loadPage();
}
</script>

<style scoped lang="scss">
.module__access-moments__list {
    display: flex;
    flex-direction: column;
    gap: var(--ot-ui-spacing-2xl);

    &__grid {
        min-height: 25rem;
    }

    &__empty-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--ot-ui-spacing-md);
    }
}
</style>
