import { OtError } from '@openticket/lib-log';

export class FormMixinMissingError extends OtError {

    readonly mixinName: string;
    readonly relationPath: string;

    constructor(mixinName: string, relationPath: string) {
        super(
            'FormMixinMissingError',
            'dashboard.errors.services.form.mixin.missing',
            `The ${mixinName}-Mixin does not exist on the provided relation.`,
        );

        this.mixinName = mixinName;
        this.addContext('mixin_name', mixinName);

        this.relationPath = relationPath;
        this.addContext('relation_path', relationPath);

        this.captureStackTrace(FormMixinMissingError);
    }

}
