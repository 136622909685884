import { type App, reactive, ref } from 'vue';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import { definedFlags, localFlags } from '../services/featureFlag';
import type { DefinedFlags } from '../services/featureFlag/types';

export class FeatureFlagPlugin extends Plugin<FeatureFlagPlugin> {

    private localMode: boolean = import.meta.env.VITE_LOCAL_FEATURE_FLAG_ENABLED === 'true';

    plugins: PluginsManager | null = null;
    flags = reactive<DefinedFlags>(this.localMode ? localFlags : { ...definedFlags });
    // Used to indicate whether the external flags are loaded from posthog, via rudderstack.
    loaded = ref<boolean>(false);

    async install(plugins: PluginsManager, app: App): Promise<void> {
        this.plugins = plugins;

        await this.init();

        app.provide('featureFlags', this);
        this.resolve(this);
    }

    async init(): Promise<void> {
        if (!this.plugins) {
            return;
        }

        // Rudderstack loads the posthog plugin on the window object.
        // The app needs to wait until the rudderstack sdk has loaded the posthog instance.
        await this.plugins.rudderstack.loading;
        window.rudderanalytics?.ready(() => {
            void this.setFeatureFlagCompanyContext();
        });
    }

    public async setFeatureFlagCompanyContext(companyId?: string): Promise<void> {
        if (!this.plugins || this.localMode) {
            return;
        }

        const rudderStack = await this.plugins.rudderstack.loading;
        const auth = await this.plugins.auth.loading;
        const user = (await auth.$token.$info)?.user;

        if (!rudderStack || !user || !window.posthog) {
            // eslint-disable-next-line no-console
            console.log('Keep default Feature Flags');
            return;
        }

        const handler = (resolve: (value: unknown) => void) => {
            if (!window.posthog?.featureFlags) {
                resolve(this.flags);
                return;
            }

            window.posthog.featureFlags.addFeatureFlagsHandler((_, variants) => {
                for (const key of Object.keys(this.flags)) {
                    this.flags[key as keyof typeof this.flags] = typeof variants[key] === 'string' ? variants[key] === 'true' : variants[key];
                }
                resolve(this.flags);
            });
        };

        const p = () => new Promise((resolve) => {
            handler(resolve);
        });

        rudderStack.identify(user.guid, {
            current_company_context: companyId || '',
        });

        await p().finally(() => {
            if (window.posthog && window.posthog.featureFlags) {
                window.posthog.featureFlags.featureFlagEventHandlers = [];
            }

            this.loaded.value = true;
        });
    }

}
