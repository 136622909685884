<template>
    <OtModal
        ref="modalRef"
        :width="800"
        :title="$t('dashboard.company.members.invite.title_new_member')"
    >
        <ErrorView
            v-if="error"
            :label="error.message"
        />

        <div
            v-else-if="invite && rules"
            data-testid="invitemember-modalcard"
        >
            <OtFormRow>
                <OtInputField
                    :label="$t('dashboard.common.input.name')"
                    :optional="rules.name && !rules.name.includes('required')"
                >
                    <OtTextInput
                        v-model="invite.$data.name"
                        data-testid="invitemember-form-name"
                        :invalid="!!validationError.name"
                    />
                </OtInputField>
                <OtInputField
                    :label="$t('dashboard.common.input.role')"
                    :optional="rules.roles && !rules.roles.includes('required')"
                >
                    <OtSelectInput
                        v-model="selectedRole"
                        :options="roles"
                        data-testid="invitemember-form-role"
                        :invalid="!!validationError.roles"
                        @input="onRolesChange"
                    />
                </OtInputField>
            </OtFormRow>

            <OtFormRow>
                <OtInputField
                    :label="$t('dashboard.common.input.email')"
                    :optional="rules.email && !rules.email.includes('required')"
                >
                    <OtEmailInput
                        v-model="invite.$data.email"
                        class="input"
                        data-testid="invitemember-form-email"
                        :invalid="!!validationError.email"
                    />
                </OtInputField>
            </OtFormRow>
        </div>
        <OtSpinner v-else />
        <template #footer>
            <OtCardFooter>
                <template #left>
                    <OtButton
                        variant="secondary"
                        :title="$t('dashboard.common.action.cancel.title')"
                        icon="close"
                        @click="close"
                    >
                        {{ $t('dashboard.common.action.cancel.text') }}
                    </OtButton>
                </template>
                <template #right>
                    <OtButton
                        :title="$t('dashboard.company.members.invite.send_invite.title')"
                        data-testid="invitemember-form-submit"
                        icon="check"
                        @click="submit"
                    >
                        {{ $t('dashboard.company.members.invite.send_invite.text') }}
                    </OtButton>
                </template>
            </OtCardFooter>
        </template>
    </OtModal>
</template>

<script setup lang="ts">
import { ref, useTemplateRef } from 'vue';
import type { AuthClient, Invitee, MixinAPIAuthError } from '@openticket/lib-auth';
import type { ValidationMessages } from '@openticket/lib-crud';
import { isAxiosError } from 'axios';
import { useLocalization, useNotifications } from '@openticket/vue-ui';
import { injectOrFail } from '../../../../services/util';
import type { PluginsManager } from '../../../../plugins';
import type { Context } from '../../../../services/context';
import { isErrorDescriptionAxiosError } from '../../../../services/http/axios';
import { useGenericErrorHandling } from '../../../../composables';
import ErrorView from '../../../../components/ErrorView.vue';

const { error, handleError } = useGenericErrorHandling();
const { t } = useLocalization();

const context = injectOrFail<Context>('context');
const notifications = useNotifications();
const plugins = injectOrFail<PluginsManager>('plugins');

const invite = ref<Invitee<AuthClient>>();
const modalRef = useTemplateRef('modalRef');
const rules = ref<ValidationMessages>();
const selectedRole = ref<string>('Is In Company');

let auth: AuthClient;

// TODO remove once validation is standardize
const validationError = ref<{
    name: string | null;
    email: string | null;
    roles: string | null;

}>({
    name: null,
    email: null,
    roles: null,
});

const roles: Record<string, string> = {
    // TODO Extract to lib auth...
    //  Preferably through an endpoint, but at the very least through constants.
    'Is In Company': t('dashboard.members.role.view_only'),
    'Company Admin': t('dashboard.members.role.manager'),
};

function close() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    modalRef.value?.close();
}

async function open() {
    try {
        if (!context.isCompanyContext()) {
            // TODO Proper error + logging
            throw Error('Cannot invite a new member when not in company context');
        }

        auth = await plugins.auth.loading;

        rules.value = await auth.invitees.$rules();

        invite.value = auth.invitees.$factory({
            email: '',
            name: '',
            roles: [ 'Is In Company' ],
        });

        validationError.value = {
            name: null,
            email: null,
            roles: null,
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalRef.value?.open();
    } catch (e) {
        void handleError(e);
    }
}

async function submit(): Promise<void> {
    if (!auth || !invite.value) {
        // TODO Proper error + logging
        console.error('Auth client or invite not initialized yet!');

        // TODO save.fail is not right here!!
        notifications.error(t('dashboard.common.notification.save.fail'));

        throw new Error('Auth client or invite not initialized yet!');
    }

    try {
        validationError.value = {
            name: null,
            email: null,
            roles: null,
        };

        await auth.invitees.inviteUser(invite.value);

        // TODO The 204 status (already in company) is hidden behind the above method.
        //  It should be exposed as an error and then handled below in the catch.
        //  It should not simply be ignored, due to a potential difference in roles.
        //  this.$notifications.success(this.$t('dashboard.company.members.invite.already_in_company'));
    } catch (e) {
        // TODO
        if (!isAxiosError(e) || !isErrorDescriptionAxiosError(e)) {
            // TODO Remove typecasting
            const err = e as MixinAPIAuthError;

            if (err.axiosError && isAxiosError(err.axiosError) && err.axiosError.response?.status === 406) {
                // TODO remove once validation is standardize
                interface errorAnswers {
                    error_description: {
                        name: string[],
                        email: string[],
                        roles: string[]
                    }
                }

                const { error_description } = err.axiosError.response.data as errorAnswers;

                if (error_description.name && error_description.name.length > 0) {
                    [ validationError.value.name ] = error_description.name;
                }

                if (error_description.email && error_description.email.length > 0) {
                    [ validationError.value.email ] = error_description.email;
                }

                if (error_description.roles && error_description.roles.length > 0) {
                    [ validationError.value.roles ] = error_description.roles;
                }

                return;
            }
            notifications.error(t('dashboard.company.members.invite.send_invite.danger'));

            throw e;
        } else {
            notifications.error(t('dashboard.company.members.invite.send_invite.danger'));
        }
        throw e;
    }

    notifications.success(t('dashboard.company.members.invite.send_invite.success'));
    close();
}

// TODO This should probably be rethought.
function onRolesChange(value: string): void {
    if (!invite.value) {
        return;
    }

    invite.value.$data.roles = [ value ];
}

defineExpose({
    open,
});
</script>
