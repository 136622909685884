import type { AxiosError } from 'axios';

export interface ErrorDescription {
    error_description: string | { [key: string]: string | string[] };
}

export interface ErrorDescriptionObject {
    error_description: { [key: string]: string[] }
}

export type ErrorDescriptionAxiosError = AxiosError<ErrorDescription> & { response: { data: ErrorDescription; }; };

export type ValidationAxiosError = AxiosError<ErrorDescription> & { response: { data: ErrorDescriptionObject } };

export function isErrorDescriptionAxiosError(error: AxiosError): error is ErrorDescriptionAxiosError {
    if (!error.response
        || !error.response.data
        || !Object.prototype.hasOwnProperty.call(error.response.data, 'error_description')) {
        return false;
    }

    const data: { error_description: unknown } = error.response.data as ({ error_description: unknown });

    if (typeof data.error_description === 'string') {
        return true;
    }

    if (!data.error_description || typeof data.error_description !== 'object') {
        return false;
    }

    return Object.values(data.error_description).every((v: unknown) => typeof v === 'string' || (
        Array.isArray(v) && v.every((v2: unknown) => typeof v2 === 'string')
    ));
}

export function isValidationAxiosError(e: ErrorDescriptionAxiosError): e is ValidationAxiosError {
    const errorDescription: ErrorDescription['error_description'] = e.response.data.error_description;

    if (!errorDescription || typeof errorDescription === 'string') {
        return false;
    }

    return Object.entries(errorDescription).every(([ key, value ]) => {
        if (typeof key !== 'string') {
            return false;
        }

        return !!value && Array.isArray(value);
    });
}
