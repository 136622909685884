import { createOpenTicketUI, type OpenTicketUIConfig } from '@openticket/vue-ui';
import '@openticket/vue-ui/style.css';
import { Localization, type PartialConfig } from '@openticket/lib-localization';
import type { App } from 'vue';
import { reactive } from 'vue';
import { registerComponents } from '../components';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import type { ThemeModes } from './types';
import { getGlobal, type OpenTicketGlobal } from '../openticket';

export class UIPlugin extends Plugin<void> {

    async install(plugins: PluginsManager, app: App): Promise<void> {
        try {
            // Localization Config
            const localizationConfig: PartialConfig = {
                locale: {
                    supported: [ 'en_GB', 'en_US', 'nl_NL', 'de_DE', 'fr_FR', 'es_ES' ],
                },
            };

            if (import.meta.env.VITE_DEFAULT_TRANSLATIONS_URL) {
                localizationConfig.urls = {
                    translations: import.meta.env.VITE_DEFAULT_TRANSLATIONS_URL,
                };
            }

            const localization = reactive(new Localization());

            await localization.init(localizationConfig);

            // TODO: Is this needed?
            const OT: OpenTicketGlobal = getGlobal();
            OT.Localization = localization;

            const UIConfig: OpenTicketUIConfig = {
                localization,
            };

            const openTicketUI = createOpenTicketUI(UIConfig);

            app.use(openTicketUI);

            app.use(registerComponents);

            let mode: ThemeModes = 'light';

            app.provide('theme', reactive({
                get mode() {
                    return mode;
                },
                set mode(value: ThemeModes) {
                    mode = value;

                    const { classList } = document.body;

                    if (value === 'dark') {
                        classList.remove('is-light');
                        classList.add('is-dark');
                    } else {
                        classList.remove('is-dark');
                        classList.add('is-light');
                    }
                },
            }));

            this.resolve();
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

}
