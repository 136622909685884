<template>
    <FormModal
        :form="form"
        :modal="modal"
        :title="$t('dashboard.tickets.addon_products.groups.create.title')"
        :subtitle="$t('dashboard.tickets.addon_products.groups.create.subtitle')"
        :submit-button-labels="{
            text: $t('dashboard.tickets.addon_products.groups.create.submit.text'),
            title: $t('dashboard.tickets.addon_products.groups.create.submit.title')
        }"
    >
        <AddonProductGroupForm
            ref="addonProductGroupFormRef"
            :form="form"
            is-create
        />
    </FormModal>
</template>

<script setup lang="ts">
import {
    reactive, useTemplateRef, watch, type UnwrapNestedRefs,
} from 'vue';
import type { ManagementClient, ProductGroup, Ticket } from '@openticket/lib-management';
import FormModal from '../../../../components/form/FormModal.vue';
import { useCreateForm, type FormModalComposableResult } from '../../../../composables/forms';
import { injectOrFail } from '../../../../services/util';
import type { Context } from '../../../../services/context';
import AddonProductGroupForm from '../forms/AddonProductGroupForm.vue';
import { useGenericErrorHandling } from '../../../../composables';

type Props = {
    modal: UnwrapNestedRefs<FormModalComposableResult>,
}

type Emits = {
    (e: 'submit', model: ProductGroup<ManagementClient>): void
}

defineProps<Props>();
const emit = defineEmits<Emits>();

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const { handleError } = useGenericErrorHandling();

if (!context.isTicketContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const addonProductGroupFormRef = useTemplateRef('addonProductGroupFormRef');

const form = reactive(
    useCreateForm<ProductGroup<ManagementClient>, ProductGroup<Ticket<ManagementClient>>>(
        management.productGroups.new(),
        context.ticket.model.productGroups,
        {
            onSubmit(model) {
                void linkProductsToNewlyCreatedGroup(model);
                emit('submit', model);
            },
        },
    ),
);

async function linkProductsToNewlyCreatedGroup(model: ProductGroup<ManagementClient>) {
    if (!addonProductGroupFormRef.value) {
        return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const guidsToLink = addonProductGroupFormRef.value.addonProductGuids() as string[] | undefined;

    if (!guidsToLink?.length) {
        return;
    }

    try {
        const reconstructedProductGroupRootModelWithoutRelation = management.productGroups.$factory(model.$raw);
        await reconstructedProductGroupRootModelWithoutRelation
            .products
            .linkMulti(...guidsToLink);
    } catch (e) {
        handleError(e);
    }
}

watch(() => form.rules, () => {
    // The event_id properties are marked as required in the rules but not settable as it is readonly.
    // Until CU-86byezdqr is implemented, the below is needed for the validation to succeed
    // TODO: Remove below after the above task is implemented
    delete form.rules.event_id;
});
</script>
