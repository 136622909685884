<template>
    <OtSpinner v-if="!user || !tfaTypes" />

    <TfaSetup
        v-else-if="!user.two_factor_enabled"
        :type="tfaTypeToSetup"
        :tfa-types="tfaTypes"
        :show-forced-warning="showForcedWarning"
        :hide-logo="false"
        :hide-logout="false"
        success-message="dashboard.common.state.created"
        hide-back-button
        @close="tfaSetupClose"
    />

    <OtCard v-else>
        <ModalHeader />

        <OtCardContent no-padding>
            <OtAside
                :title="$t('dashboard.user.tfa.setup.already_setup.title')"
                :description="$t('dashboard.user.tfa.setup.already_setup.description')"
                type="warning"
                icon="warning"
            />

            <OtButton
                :title="$t('dashboard.invitation.invalid.dashboard.title')"
                full-width
                icon-right="arrow-right"
                @click="$router.push({ name: 'auth.login', query: clientConfig })"
            >
                {{ $t('dashboard.invitation.invalid.dashboard.text') }}
            </OtButton>
        </OtCardContent>
    </OtCard>
</template>

<script setup lang="ts">
import urlJoin from 'url-join';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import type { AuthClient } from '@openticket/lib-auth';
import { useConfirmationModal, useLocalization, type OtTFATypes } from '@openticket/vue-ui';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import TfaSetup from '../../../components/tfa/TfaSetup.vue';
import type { OptionalClientConfig, StrippedMinimalUser } from '../types';
import { injectOrFail } from '../../../services/util';

const auth = injectOrFail<AuthClient>('auth');
const user = injectOrFail<StrippedMinimalUser>('minimal_user');

const confirmationModal = useConfirmationModal();
const { t } = useLocalization();

const clientConfig = ref<OptionalClientConfig>();
const tfaTypes = ref<OtTFATypes | null>(null);
const showForcedWarning = ref<boolean>(true);

const tfaTypeToSetup = 'totp' as const;

const route = useRoute();
const router = useRouter();

void (async (): Promise<void> => {
    clientConfig.value = route.query;

    try {
        // TODO - @openticket/lib-auth
        tfaTypes.value = (await auth.$http.get<OtTFATypes>(urlJoin(auth.$path, 'twofactor'))).data;
    } catch {
        await router.push({ name: 'error' });
    }

    if (route.query['forced-warning'] === 'hide') {
        showForcedWarning.value = false;
    }
})();

async function tfaSetupClose(): Promise<void> {
    const confirm = await confirmationModal.confirm({
        title: t('dashboard.user.tfa.setup.code.confirm_close.title'),
        message: t('dashboard.user.tfa.setup.code.confirm_close.description'),
        variant: 'default',
    });

    if (!confirm) {
        return;
    }
    // TODO @PETER - auth.login route (or equivalent)
    await router.push({ name: 'auth.login', query: clientConfig.value });
}
</script>
