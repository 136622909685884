import type { AuthClient } from '@openticket/lib-auth';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { App } from 'vue';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import type { LegacyApiOptions } from './types';
import { LegacyApiClient } from '../services/legacy-api';

export class LegacyApiPlugin extends Plugin<LegacyApiClient> {

    async install(plugins: PluginsManager, app: App): Promise<void> {
        try {
            const options: LegacyApiOptions = plugins.options.legacyApi || {};

            const auth: AuthClient = await plugins.auth.loading;
            const whitelabel: Whitelabel = await plugins.whitelabel.loading;

            const baseUrl: string | null = options.url
                || whitelabel.dashboard.legacy_api_url
                || null;

            const legacyApi = new LegacyApiClient({ http: auth.$http, baseUrl });

            app.provide('legacyApi', legacyApi);

            this.resolve(legacyApi);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

}
