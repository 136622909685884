import type { InternalAxiosRequestConfig } from 'axios';
import {
    Create, CrudRootNode, Delete, List, New, Reinstate, ToMany, Update,
} from '@openticket/lib-crud';
import type { TemplateConfig } from './models/template';
import { Template, TemplateLink } from './models';
import type { TemplateLinkConfig } from './models/templateLink';
import type { TemplatesConfig } from './types';
import { CopyParts, GetLinkByParameters, RenderExample } from './mixins';

export default class TemplatesClient extends CrudRootNode {

    templateLinks = new ToMany<TemplateLink<typeof this>, TemplateLinkConfig, typeof this, {}>(TemplateLink, this, 'template_link')
        .with(List)
        .with(New)
        .with(Create)
        .with(Update)
        .with(GetLinkByParameters)
        .with(RenderExample);

    templates = new ToMany<Template<typeof this>, TemplateConfig, typeof this, {}>(Template, this, 'template')
        .with(Delete)
        .with(List)
        .with(Reinstate)
        .with(CopyParts);

    partlessTemplates = new ToMany<Template<typeof this>, TemplateConfig, typeof this, {}>(Template, this, 'template/without/parts')
        .with(List);

    linklessTemplates = new ToMany<Template<typeof this>, TemplateConfig, typeof this, {}>(Template, this, 'template/without/links')
        .with(List);

    archivedTemplates = new ToMany<Template<typeof this>, TemplateConfig, typeof this, {}>(Template, this, 'template/list/justtrashed')
        .with(List);

    private readonly path: string;
    private readonly interceptorId: number;
    public config: TemplatesConfig;

    constructor(config: TemplatesConfig) {
        super(config.http);

        this.config = config;
        this.path = config.baseUrl;
        this.interceptorId = this.$http.interceptors.request.use((axiosConfig: InternalAxiosRequestConfig) => {
            if (axiosConfig.url && axiosConfig.url.includes(this.$path)) {
                axiosConfig.headers.Company = '*';
            }

            return axiosConfig;
        });
    }

    get $path(): string {
        return this.path;
    }

}
