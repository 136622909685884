<template>
    <div class="ticket-visitor-data">
        <div class="ticket-visitor-data__header">
            <h2 class="ot-ui-text-heading-2">
                {{ $t('dashboard.tickets.visitor_data.header.title') }}
            </h2>
            <div class="ot-text-body-intro">
                {{ $t('dashboard.tickets.visitor_data.header.description') }}
            </div>
        </div>
        <div class="ticket-visitor-data__sorter">
            <OtForm>
                <OtInputField class="ticket-visitor-data__sorter-search">
                    <OtSearchInput
                        :placeholder="$t('dashboard.tickets.visitor_data.search.placeholder')"
                    />
                </OtInputField>
                <div>
                    <!-- TODO Use CrudSorter/SlickSorter here, can't really use CrudSorter here because of design reasons. -->
                    <div
                        v-for="(model, index) in list.data"
                        :key="index"
                        class="ticket-visitor-data__sorter__item"
                    >
                        {{ index + 1 }}
                        {{ model.name }}
                        <div class="ticket-visitor-data__sorter__item-right">
                            <div class="drop-both">
                                <OtIcon type="drop-both" />
                            </div>
                            <div class="close">
                                <OtIcon
                                    type="close"
                                    class="close"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </OtForm>
        </div>
        <div class="ticket-visitor-data__footer">
            {{ $t('dashboard.tickets.visitor_data.footer.text') }}
            <OtButton
                variant="outline"
                icon-right="carrot-right"
            >
                {{ $t('dashboard.tickets.visitor_data.footer.button.text') }}
            </OtButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ArrayPaginatable, LazyList } from '@openticket/lib-sdk-helpers';
import { ref } from 'vue';

// TODO Open+++Ticket hardcoded
const uselessData = {
    guid: 'not-a-ticket-visitor-data-guid',
    created_at: '2023-01-01T01:01:01+01:00',
    updated_at: '2023-01-01T01:01:01+01:00',
};

// Replace with correct ticket visitor data
const list = ref(new LazyList(new ArrayPaginatable([
    {
        ...uselessData,
        name: 'Open+++Ticket - First Name',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - Last Name',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - Email',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - Street',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - House number',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - House number appendix',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - ZIP code',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - Date of birth',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - City',
    },
    {
        ...uselessData,
        name: 'Open+++Ticket - Gender',
    },
])));

void list.value.init({
    defaults: {
        per_page: 15,
    },
});
</script>

<style scoped lang="scss">
.ticket-visitor-data {
    max-width: 40rem;

    &__sorter {
        margin: var(--ot-ui-spacing-xl) 0;

        &-search {
            margin: var(--ot-ui-spacing-xl) 0;
        }

        &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            padding: var(--ot-ui-spacing-sm) 0;

            border-bottom: 1px solid var(--ot-ui-color-accent-secondary);

            &-right {
                display: flex;
                flex-direction: row;
                gap: var(--ot-ui-spacing-md);

                color: var(--ot-ui-color-accent-primary);

                .close:hover {
                    color: var(--ot-ui-color-intent-error-primary);
                }

                .drop-both:hover {
                    color: var(--ot-ui-color-brand);
                }
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: var(--ot-ui-spacing-md);
    }
}
</style>
