import { OtError, UnknownError } from '@openticket/lib-log';

export class IntercomInitError extends OtError {

    constructor(cause?: Error) {
        super('IntercomInitError', 'dashboard.errors.plugins.intercom.init', 'Failed to initialize intercom.');

        if (cause) {
            this.causedBy(new UnknownError(cause));
        }

        this.captureStackTrace(IntercomInitError);
    }

}
