<template>
    <OtCard
        v-if="finalizeForm || tokenErrors.notLoggedOut || tokenErrors.token"
        class="complete-profile"
        no-padding
    >
        <template v-if="tokenErrors.notLoggedOut">
            <ModalHeader>
                <template #logout>
                    <OtButton
                        icon="logout"
                        size="small"
                        variant="inline"
                        :title="$t('dashboard.invitation.invalid.logout.title')"
                        @click="logoutAndReload"
                    >
                        {{ $t('dashboard.invitation.invalid.logout.text') }}
                    </OtButton>
                </template>
            </ModalHeader>

            <OtCardHeader :title="$t('dashboard.invitation.complete_profile.title')" />

            <OtCardContent>
                <OtAside
                    :title="$t('dashboard.invitation.invalid.invalid')"
                    :description="$t(tokenErrors.notLoggedOut)"
                    type="warning"
                    icon="warning"
                />
            </OtCardContent>

            <OtCardFooter>
                <template #right>
                    <OtButton
                        icon-right="arrow-right"
                        :title="$t('dashboard.invitation.invalid.dashboard.title')"
                        @click="$router.push({name: 'auth.login'})"
                    >
                        {{ $t('dashboard.invitation.invalid.dashboard.text') }}
                    </OtButton>
                </template>
            </OtCardFooter>
        </template>

        <template v-else-if="tokenErrors.token">
            <ModalHeader />

            <OtCardHeader :title="$t('dashboard.invitation.complete_profile.title')" />

            <OtCardContent>
                <OtAside
                    :title="$t('dashboard.invitation.invalid.invalid')"
                    :description="$t(tokenErrors.token)"
                    type="warning"
                    icon="warning"
                />
            </OtCardContent>
        </template>

        <template v-else-if="finalizeForm">
            <ModalHeader />

            <OtCardHeader :title="$t('dashboard.invitation.complete_profile.title')" />

            <OtCardContent class="complete-profile__content ot-ui-stacky[xs]">
                <OtFormRow>
                    <OtInputField
                        :label="$t('dashboard.invitation.complete_profile.form.your_name')"
                        :error="finalizeForm.errors.name"
                        :optional="finalizeForm.rules.name && !finalizeForm.rules.name.includes('required')"
                    >
                        <OtTextInput
                            v-model="finalizeForm.model.$data.name"
                            :invalid="!!finalizeForm.errors.name"
                        />
                    </OtInputField>
                </OtFormRow>

                <OtFormRow>
                    <OtInputField
                        :label="$t('dashboard.invitation.complete_profile.form.create_password')"
                        :error="finalizeForm.errors.new_password"
                        :optional="finalizeForm.rules.new_password && !finalizeForm.rules.new_password.includes('required')"
                    >
                        <OtPasswordInput
                            v-model="finalizeForm.model.$data.new_password"
                            :invalid="!!finalizeForm.errors.new_password"
                            data-testid="password-field"
                        />
                    </OtInputField>
                </OtFormRow>

                <OtFormRow>
                    <OtInputField
                        :label="$t('dashboard.invitation.complete_profile.form.confirm_password')"
                        :error="finalizeForm.errors.new_password_confirmation"
                        :optional="finalizeForm.rules.new_password_confirmation
                            && !finalizeForm.rules.new_password_confirmation.includes('required')"
                    >
                        <OtPasswordInput
                            v-model="finalizeForm.model.$data.new_password_confirmation"
                            :invalid="!!finalizeForm.errors.new_password_confirmation"
                            data-testid="confirm-password-field"
                        />
                    </OtInputField>
                </OtFormRow>

                <div class="terms">
                    <OtInputField :error="finalizeForm.errors.accept_tos">
                        <OtCheckboxInput
                            id="terms"
                            v-model="finalizeForm.model.$data.accept_tos"
                            class="terms-label__checkbox"
                        />

                        <i18n-t
                            keypath="dashboard.invitation.complete_profile.form.terms.first"
                            class="terms-label__label"
                        >
                            <template #tos>
                                <OtLink
                                    class="terms-tos-first"
                                    :href="$whitelabel.dashboard.terms_url || 'https://eventix.io/documents/2018_EN_organiser_terms.pdf?lang=en_GB'"
                                    :label="$t('dashboard.invitation.complete_profile.form.terms.tos')"
                                />
                            </template>

                            <template #entity>
                                <span class="terms-entity terms-entity-first">
                                    {{ $whitelabel.name }}
                                </span>
                            </template>
                        </i18n-t>
                    </OtInputField>
                </div>
            </OtCardContent>

            <OtCardFooter>
                <template #right>
                    <OtButton
                        icon="check"
                        :disabled="formNotCompleted"
                        :title="$t('dashboard.invitation.complete_profile.form.button.title')"
                        @click="submit()"
                    >
                        {{ $t('dashboard.invitation.complete_profile.form.button.text') }}
                    </OtButton>
                </template>
            </OtCardFooter>
        </template>
    </OtCard>
    <OtSpinner v-else />
</template>

<script setup lang="ts">
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { AuthClient, FinalizeToken, FinalizeUser } from '@openticket/lib-auth';
import { isAxiosError } from 'axios';
import urlJoin from 'url-join';
import { computed, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import { isErrorDescriptionAxiosError } from '../../../services/http/axios';
import { injectOrFail } from '../../../services/util';
import type { RudderStack } from '../../../services/rudderstack';
import { useCustomValidatedPostForm } from '../../../composables/forms';
import { useResetTokenCheck } from '../composable/useResetTokenCheck';

const auth = injectOrFail<AuthClient>('auth');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const rudderstack = injectOrFail<RudderStack>('rudderstack');

const formNotCompleted = computed<boolean>(() => !finalizeForm.model.$data.name
    || !finalizeForm.model.$data.new_password
    || !finalizeForm.model.$data.new_password_confirmation
    || !finalizeForm.model.$data.accept_tos);

const route = useRoute();
const router = useRouter();
const { tokenErrors, checkValidResetToken } = useResetTokenCheck('finalizeUser');

const finalizeForm = reactive(
    useCustomValidatedPostForm<FinalizeUser<AuthClient>, FinalizeUser<AuthClient>>(
        auth.finalizeUser.new(),
        auth.finalizeUser,
        {
            byId: true,
            onSubmit() {
                if (whitelabel.dashboard.login_url) {
                    window.location.href = whitelabel.dashboard.login_url;
                } else {
                    void router.push({ name: 'auth.login' });
                }
            },
        },
    ),
);

void (async (): Promise<void> => {
    if (!auth) {
        return;
    }

    rudderstack.track('vue-dashboard fix finalize profile init');

    await checkValidResetToken(undefined, receiveAndSetUsername);
})();

function receiveAndSetUsername(response: FinalizeToken<AuthClient> | undefined) {
    if (!response) {
        return;
    }

    if (!response.$data.user.name) {
        return;
    }

    finalizeForm.model.$data.name = response.$data.user.name;
}

async function logoutAndReload(): Promise<void> {
    try {
        // TODO - @openticket/lib-auth
        await Promise.all([
            auth.$token.logout(), // TODO @Peter - lib-auth add a 'clear tokens' (e.g. logout without hooks) method.
            auth.$http.get(urlJoin(auth.$path, 'sessions', 'logout')),
        ]);
    } catch (e: unknown) {
        if (isAxiosError(e) && e.response && (
            e.response.status === Number(import.meta.env.VITE_LOGOUT_REDIRECT_STATUS)
                || e.response.status === 355
        )) {
            await checkValidResetToken(undefined, receiveAndSetUsername);
        } else {
            await router.push({ name: 'error' });
        }
    }
}

async function submit(): Promise<void> {
    if (!auth) {
        return;
    }

    const { token } = route.params;
    finalizeForm.model.$data.token = token.toString();

    try {
        await finalizeForm.submit();
    } catch (e: unknown) {
        if (!isAxiosError(e) || !isErrorDescriptionAxiosError(e)) {
            void router.push({ name: 'error' });
        }
    }
}
</script>

<style lang="scss" scoped>
.complete-profile {
    &__content {
        .terms {
            .terms-label__checkbox {
                flex: initial;
                width: auto;
                margin-right: 0;
            }

            &-label__label {
                cursor: default;
            }
        }
    }
}
</style>
