<template>
    <OtWizard
        ref="wizard"
        class="ticket-wizard"
        :title="$t('dashboard.tickets.wizard.title')"
        :footer-logo="whitelabelLogo"
        :footer-copyright="$t('common.copyright', { year: (new Date()).getFullYear() })"
        @close="onCloseOrFinish"
        @finish="onCloseOrFinish"
    >
        <OtWizardStep
            :title="$t('dashboard.tickets.wizard.steps.tickets.title')"
            @next="handleStepNext"
            @previous="handleStepPrevious"
        >
            <TicketWizardTicketsStep />
        </OtWizardStep>
        <OtWizardStep
            :title="$t('dashboard.tickets.wizard.steps.shops.title')"
            @next="handleStepNext"
            @previous="handleStepPrevious"
        >
            <TicketWizardShopsStep />
        </OtWizardStep>
    </OtWizard>
</template>

<script setup lang="ts">
import { OtWizard } from '@openticket/vue-ui';
import {
    computed, inject, onMounted, useTemplateRef, type UnwrapNestedRefs,
} from 'vue';
import { useRouter } from 'vue-router';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import TicketWizardTicketsStep from '../components/steps/TicketWizardTicketsStep.vue';
import TicketWizardShopsStep from '../components/steps/TicketWizardShopsStep.vue';
import { injectOrFail } from '../../../services/util';
import type { ThemeModes } from '../../../plugins/types';

const router = useRouter();

const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const theme = inject<UnwrapNestedRefs<{ mode: ThemeModes }>>('theme');

const wizard = useTemplateRef<InstanceType<typeof OtWizard>>('wizard');

const whitelabelLogo = computed(() => (theme?.mode === 'dark' ? whitelabel.resources.logoDark : whitelabel.resources.logo));

onMounted(() => {
    wizard.value?.open();
});

async function onCloseOrFinish() {
    await router.replace({
        name: 'tickets.list',
    });
}

function handleStepNext() {
    wizard.value?.next();
}

function handleStepPrevious() {
    void wizard.value?.previous();
}
</script>

<style lang="scss" scoped>
.ticket-wizard {
    color: var(--ot-ui-color-foreground-primary);
}
</style>
