<template>
    <AdminActionColumns>
        <template #left>
            <ActionsSeatsForm
                v-if="companySettingsForm"
                data-testid="actions-seats"
                :company-settings-form="companySettingsForm"
            />

            <ActionsAntiFraudWatermark
                v-if="companySettingsForm"
                :company-settings-form="companySettingsForm"
            />
        </template>
        <template #right>
            <ActionsResendEmails />

            <ActionsRerenderTickets />

            <ActionsRenderReceipts
                v-if="roles.isSuperAdmin"
                :company-form="companyForm"
            />
        </template>
    </AdminActionColumns>
</template>

<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue';
import { reactive } from 'vue';
import type { CompanySettings, ManagementClient } from '@openticket/lib-management';
import type { AuthClient, Company } from '@openticket/lib-auth';
import { injectOrFail } from '../../../../services/util';
import type { Roles } from '../../../../plugins/types';
import ActionsRerenderTickets from '../../../../components/admin-actions/ActionsRerenderTickets.vue';
import ActionsSeatsForm from './ActionsSeatsForm.vue';
import ActionsRenderReceipts from './ActionsRenderReceipts.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { useUpdateForm } from '../../../../composables/forms';
import ActionsResendEmails from '../../../../components/admin-actions/ActionsResendEmails.vue';

import ActionsAntiFraudWatermark from './ActionsAntiFraudWatermark.vue';
import AdminActionColumns from '../../../../components/columns/AdminActionColumns.vue';

type Props = {
    companyForm: UnwrapNestedRefs<ModelFormComposableResult<Company<AuthClient>, Company<AuthClient>>>
    companySettings: CompanySettings<ManagementClient>;
}
const props = defineProps<Props>();

const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');
const management = injectOrFail<ManagementClient>('management');

// TODO: implement partial update because this shared form overwrites the data of another tile when it is submitted.
const companySettingsForm = reactive(
    useUpdateForm<CompanySettings<ManagementClient>, CompanySettings<ManagementClient>>(
        props.companySettings,
        management.companies,
        { disableLocalChangesDialog: true },
    ),
);

</script>
