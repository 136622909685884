// Disabling sorting of keys as the order below is the same company wide
/* eslint-disable sort-keys */

export const CATEGORIES: Record<string, string[]> = {
    festival: [ '00s', '90s', '80s', '70s', '60s', '50s', 'house', 'future-house', 'techno', 'urban', 'hip-hop', 'apres-ski', 'blues', 'blues-rock', 'metal', 'classical', 'dance', 'drum-bass', 'dubstep', 'hardstyle', 'hardcore', 'pop', 'indie', 'trance', 'disco', 'edm', 'jazz', 'dutch', 'dance-hall', 'funk', 'groove', 'hard-techno', 'punk', 'psy-trance', 'rave', 'reggae', 'ska', 'latin', 'melodic-techno', 'live-electronic', 'afro', 'bass', 'folk', 'hits', 'rock', 'acoustic', 'soul', 'tech-house', 'tribute', 'warehouse' ],
    business: [ 'convention', 'conference', 'training', 'workshop', 'master-class', 'network', 'course', 'exposition', 'gala' ],
    fashion: [ 'gala', 'conference', 'training', 'workshop', 'master-class', 'network', 'exposition', 'sample-sale', 'catwalk-show' ],
    sports: [ 'gala', 'training', 'running', 'obstacle run', 'football', 'golf', 'rugby', 'baseball', 'basketball', 'table tennis', 'volleyball', 'field hockey', 'cricket', 'rally', 'horse back riding' ],
    theatre: [ 'musical', 'comedy', 'dance', 'ballet', 'opera', 'play', 'kids', 'dancing' ],
    family: [],
    adult: [],
    circus: [],
    'art-film': [ 'screening', 'convention', 'conference', 'training', 'workshop', 'master-class', 'network', 'course', 'exposition', 'gala', 'reading', 'film', 'junior', 'spoken-word', 'cultural' ],
    'food-drinks': [],
    concert: [ '00s', '90s', '80s', '70s', '60s', '50s', 'house', 'future-house', 'techno', 'urban', 'hip-hop', 'apres-ski', 'blues', 'blues-rock', 'metal', 'classical', 'dance', 'drum-bass', 'dubstep', 'hardstyle', 'hardcore', 'pop', 'indie', 'trance', 'disco', 'edm', 'jazz', 'dutch', 'dance-hall', 'funk', 'groove', 'hard-techno', 'punk', 'psy-trance', 'rave', 'reggae', 'ska', 'latin', 'melodic-techno', 'live-electronic', 'afro', 'bass', 'folk', 'hits', 'rock', 'acoustic', 'soul', 'tech-house', 'tribute', 'warehouse' ],
    club: [ '00s', '90s', '80s', '70s', '60s', '50s', 'house', 'future-house', 'techno', 'urban', 'hip-hop', 'apres-ski', 'blues', 'blues-rock', 'metal', 'classical', 'dance', 'drum-bass', 'dubstep', 'hardstyle', 'hardcore', 'pop', 'indie', 'trance', 'disco', 'edm', 'jazz', 'dutch', 'dance-hall', 'funk', 'groove', 'hard-techno', 'punk', 'psy-trance', 'rave', 'reggae', 'ska', 'latin', 'melodic-techno', 'live-electronic', 'afro', 'bass', 'folk', 'hits', 'rock', 'acoustic', 'soul', 'tech-house', 'tribute', 'warehouse' ],
    charity: [ 'convention', 'conference', 'master-class', 'network', 'exposition', 'gala' ],
    hobby: [],
    museum: [],
    gaming: [ 'e-sports', 'conference', 'network' ],
    'escape-room': [],
    queer: [],
    other: [],
};
