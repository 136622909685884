<template>
    <div class="event-wizard-tickets">
        <template v-if="wizardProvide.showUpsertTicketView.value">
            <EventWizardTicketsUpsert ref="eventWizardTicketsUpsert" />
        </template>
        <template v-else>
            <EventWizardTicketsList />
        </template>
    </div>
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue';
import EventWizardTicketsList from '../EventWizardTicketsList.vue';
import EventWizardTicketsUpsert from '../EventWizardTicketsUpsert.vue';
import type { WizardProvide } from '../../types';
import { injectOrFail } from '../../../../services/util';

const wizardProvide = injectOrFail<WizardProvide>('wizardProvide');
const eventWizardTicketsUpsert = useTemplateRef('eventWizardTicketsUpsert');

function submitTicket(): Promise<boolean> {
    if (eventWizardTicketsUpsert.value && eventWizardTicketsUpsert.value.submit) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        return eventWizardTicketsUpsert.value.submit();
    }

    return Promise.resolve(false);
}

function resetForm() {
    if (eventWizardTicketsUpsert.value && eventWizardTicketsUpsert.value.resetForm) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        eventWizardTicketsUpsert.value.resetForm();
    }
}

defineExpose({
    submitTicket,
    resetForm,
});
</script>
