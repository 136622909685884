import urljoin from 'url-join';
import type {
    Model,
    ModelConfig,
    Parent,
    RulesMap,
    ToMany,
} from '@openticket/lib-crud';
import {
    ModelConstructorError,
    UnexpectedResponseFactoryMixinError,
} from '@openticket/lib-crud';
import type { AxiosResponse } from 'axios';

export async function GetLinkByParameters<
    M extends Model<Ancestor, C, R>,
    C extends ModelConfig,
    Ancestor extends Parent,
    R extends RulesMap = {},
    PivotConfig extends ModelConfig = {},
>(this: ToMany<M, C, Ancestor, R, PivotConfig>, data: {
    'Company': string | null,
    'Whitelabel':string | null,
    'Event':string | null,
    'Ticket':string | null,
    'belongs_type': string | null,
    'belongs_id': string | null,
    'fallback': boolean | null,
}): Promise<M> {
    const path = urljoin(this.$path, 'findMostSpecific');
    const response: AxiosResponse<M['$raw']> = await this.$http.post(path, data);

    try {
        return this.$factory(response.data);
    } catch (e) {
        if (e instanceof ModelConstructorError) {
            throw new UnexpectedResponseFactoryMixinError(GetLinkByParameters.mixinName, path, e);
        }

        throw e;
    }
}

GetLinkByParameters.mixinName = 'CUSTOM-GET-LINK-BY-PARAMETERS' as const;
GetLinkByParameters.methodName = 'getLinkByParameters' as const;
