import type {
    Model, ModelConfig, Parent, RulesMap,
} from '@openticket/lib-crud';
import { Create } from '@openticket/lib-crud';
import { useModelForm } from './useModelForm';
import type { ModelFormComposableResult } from './useModelForm';
import type { ModelCreateFormConfig, Relation } from './types';

export function useCreateForm<
    M extends Model<P, ModelConfig, RulesMap>,
    P extends Parent,
>(
    _model: M,
    _relation: Relation<M, P>,
    config?: ModelCreateFormConfig<M, P>,
): ModelFormComposableResult<M, P> {
    return useModelForm(_model, _relation, Create, config);
}
