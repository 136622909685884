<template>
    <div class="addon-product-form-fields">
        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.addon_products.details.inputs.name.label')"
                :description="$t('dashboard.addon_products.details.inputs.name.description')"
                :optional="form.rules.name && !form.rules.name.includes('required')"
                :error="form.errors.name"
            >
                <OtTextInput
                    v-model="form.model.$data.name"
                    :readonly="isReadOnly"
                    data-testid="addon-product-form-fields-name"
                    @input="form.errors.name = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow v-if="!hideDescriptionField">
            <OtInputField
                :label="$t('dashboard.addon_products.details.inputs.description.label')"
                :description="$t('dashboard.addon_products.details.inputs.description.description')"
                :optional="form.rules.description && !form.rules.description.includes('required')"
                :error="form.errors.description"
            >
                <OtTextareaInput
                    v-model="form.model.$data.description"
                    :readonly="isReadOnly"
                    data-testid="addon-product-form-fields-description"
                    @input="form.errors.description = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.addon_products.details.inputs.available_stock.label')"
                :description="$t('dashboard.addon_products.details.inputs.available_stock.description')"
                :optional="form.rules.available_stock && !form.rules.available_stock.includes('required')"
                :error="form.errors.available_stock"
            >
                <InputFloatUnlimited
                    v-model="form.model.$data.available_stock"
                    :readonly="isReadOnly"
                    data-testid="addon-product-form-fields-available-stock"
                    @input="form.errors.available_stock = []"
                />
            </OtInputField>
        </OtFormRow>
        <InputPriceVat
            v-model:price="form.model.$data.price"
            v-model:vat="form.model.$data.vat_percentage"
            v-model:price-errors="form.errors.price"
            v-model:vat-errors="form.errors.vat_percentage"
            data-testid="addon-product-form-fields-price"
            :translations="{
                price: {
                    label:$t('dashboard.addon_products.details.inputs.price.label'),
                    description: $t('dashboard.addon_products.details.inputs.price.description')
                },
                vat: {
                    label: $t('dashboard.addon_products.details.inputs.vat_percentage.label'),
                    description: $t('dashboard.addon_products.details.inputs.vat_percentage.description')
                }
            }"
            :currency="context.event.model.$data.currency"
            :price-rules="form.rules.price"
            :readonly="isReadOnly"
            :vat-rules="form.rules.vat_percentage"
        />
    </div>
</template>

<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue';
import type { ManagementClient, Product } from '@openticket/lib-management';
import type { Context } from '../../../../services/context';
import InputPriceVat from '../../../../components/form/InputPriceVat.vue';
import InputFloatUnlimited from '../../../../components/InputFloatUnlimited.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { injectOrFail } from '../../../../services/util';
import { useRoles } from '../../../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Product<ManagementClient>, ManagementClient>>;
    hideDescriptionField?: boolean;
}

const context = injectOrFail<Context>('context');

const { isReadOnly } = useRoles();

defineProps<Props>();

if (!context.isEventContext()) {
    // TODO Proper error handling and logging
    throw Error('Invalid context');
}
</script>

<style scoped lang="scss">
.addon-product-form-fields {
    display: flex;
    flex-direction: column;
}
</style>
