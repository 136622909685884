<template>
    <div
        class="shop-tickets__item ot-ui-py[md]"
        :class="{ 'solo ot-ui-pl[lg] ot-ui-pr[lg] ot-ui-py[md] ot-ui-my[xs]': solo }"
    >
        <div class="shop-tickets__item--header">
            <OtIcon type="ticket" />
            {{ ticket.name }}
        </div>
        <div class="shop-tickets__item--actions ot-ui-stackx[sm]">
            <OtButton
                data-drag-handle
                class="ot-ui-px[sm] drop-both"
                data-testid="shop-tickets-drag"
                variant="inline"
                size="tiny"
                icon="drop-both"
                :disabled="isReadOnly"
            />
            <OtButton
                class="ot-ui-px[sm] close"
                data-testid="shop-tickets-remove"
                variant="inline"
                size="tiny"
                icon="close"
                :disabled="isReadOnly"
                @click="removeTicketFromShop(ticket.guid)"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useConfirmationModal, useLocalization } from '@openticket/vue-ui';
import type { ShopDataTicket } from '../../../../services/shop';
import { useRoles } from '../../../../composables';

type Props = {
    ticket: ShopDataTicket,
    solo?: boolean,
}

type Emits = {
    (e: 'remove', ticketId: string): void
}

defineProps<Props>();
const emit = defineEmits<Emits>();

const dialog = useConfirmationModal();

const { t } = useLocalization();
const { isReadOnly } = useRoles();

async function removeTicketFromShop(ticketId: string) {
    const confirm = await dialog?.confirm({
        title: t('dashboard.shop_tickets.confirm.remove_ticket.title') || '',
        message: t('dashboard.shop_tickets.confirm.remove_ticket.description') || '',
        variant: 'destructive',
    });

    if (confirm) {
        emit('remove', ticketId);
    }
}
</script>

<style lang="scss" scoped>
.shop-tickets__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--ot-ui-radius-lg);

    // TODO Find other classname for a single ticket without collapse parent instead of .solo
    &.solo {
        border: 1px solid var(--ot-ui-color-accent-secondary);
        overflow: hidden;
        background-color: var(--ot-ui-color-background-primary);
    }

    &--header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--ot-ui-spacing-xs);
    }

    &--actions {
        .close {
            color: var(--ot-ui-color-foreground-secondary);

            &:hover {
                color: var(--ot-ui-color-intent-error-primary);
            }
        }

        .drop-both {
            color: var(--ot-ui-color-foreground-secondary);

            &:hover {
                color: var(--ot-ui-color-brand);
            }
        }
    }

    &:not(.sortable-ghost) + & {
        border-top: 1px solid var(--ot-ui-color-accent-tertiary);
    }
}
</style>
