<template>
    <div class="invitation">
        <OtAvatar
            :name="invitation.name"
            size="48"
        />

        <div class="invitation__info">
            <span class="ot-ui-text-body-lg-strong">{{ invitation.name }}</span>

            <div
                v-if="tfaNeeded && !twoFactorEnabled"
                class="ot-ui-text-body-md"
            >
                <i18n-t keypath="dashboard.header.profile.invitations.two_factor_needed.text">
                    <template #setup>
                        <OtButton
                            variant="inline"
                            @on-click="redirect"
                        >
                            {{ $t('dashboard.header.profile.invitations.two_factor_needed.setup') }}
                        </OtButton>
                    </template>
                </i18n-t>
            </div>
        </div>

        <div class="invitation__action">
            <OtSpinner
                v-if="status === 'loading'"
                class="ot-ui-p[xs]"
            />

            <OtButton
                v-else-if="status === 'accepted'"
                disabled
                icon="check"
                size="small"
                variant="outline"
            >
                {{ $t('dashboard.header.profile.invitations.accepted') }}
            </OtButton>

            <OtButton
                v-else-if="status === 'declined'"
                disabled
                icon="close"
                size="small"
                variant="destructive"
            >
                {{ $t('dashboard.header.profile.invitations.declined') }}
            </OtButton>

            <template v-else-if="!status">
                <OtButton
                    :disabled="tfaNeeded && !twoFactorEnabled"
                    icon="check"
                    size="small"
                    :title="$t('dashboard.header.profile.invitations.action.accept.title')"
                    @on-click="acceptInvitation()"
                >
                    {{ $t('dashboard.header.profile.invitations.action.accept.text') }}
                </OtButton>

                <OtButton
                    icon="close"
                    size="small"
                    :title="$t('dashboard.header.profile.invitations.action.decline.title')"
                    variant="destructive"
                    @on-click="declineInvitation()"
                >
                    {{ $t('dashboard.header.profile.invitations.action.decline.text') }}
                </OtButton>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { AuthClient } from '@openticket/lib-auth';
import { isAxiosError } from 'axios';
import urlJoin from 'url-join';
import {
    computed, inject, reactive, ref, type UnwrapNestedRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useConfirmationModal, useLocalization, useNotifications } from '@openticket/vue-ui';
import { injectOrFail } from '../../services/util';
import type { SidebarUpdateTriggers } from '../../layouts/types';

interface MinimalCompany {
    name: string;
    guid: string;
    force_tfa: boolean;
}

type Props = {
    invitation: MinimalCompany,
    showRedirectToFixPage: boolean,
    twoFactorEnabled: boolean,
}

type Emits = {
    (e: 'accepted'): void
}

const props = withDefaults(
    defineProps<Props>(),
    {
        showRedirectToFixPage: true,
    },
);

const emit = defineEmits<Emits>();

const auth = injectOrFail<AuthClient>('auth');
// Normal inject, as on fix routes the sidebar is not provided.
const sidebarUpdate = inject<UnwrapNestedRefs<SidebarUpdateTriggers> | null>('sidebarUpdate', null);

const dialog = useConfirmationModal();
const { t } = useLocalization();
const notifications = useNotifications();

const status = ref<'accepted' | 'declined' | 'loading' | null>(null);
const tfaNeededByResponse = ref<boolean>(false);
let clientConfig = reactive<{
        client_id?: string;
        redirect_uri?: string;
        state?: string;
}>({});

const tfaNeeded = computed<boolean>(() => props.invitation.force_tfa || tfaNeededByResponse.value);

const route = useRoute();
const router = useRouter();

async function redirect(): Promise<void> {
    if (props.showRedirectToFixPage) {
        clientConfig = route.query;

        await router.push({ name: 'fix.tfa', query: { ...clientConfig, 'forced-warning': 'hide' } });
    } else {
        // TODO: Check how this should be re-introduced. The `modal` is not provided anymore
        // if (typeof modal !== 'string') {
        //     await modal.close();
        // }
        await router.push({ name: 'profile.settings.security' });
    }
}

async function acceptInvitation(): Promise<void> {
    status.value = 'loading';

    // TODO - @openticket/lib-auth
    try {
        await auth.$http.post(urlJoin(auth.$path, 'invitations', 'companies', props.invitation.guid));
    } catch (error: unknown) {
        status.value = null;

        if (!isAxiosError(error)) {
            notifications.warn(t('dashboard.header.profile.invitations.accept.warning'));

            throw error;
        }

        if (error.response?.status === 400) {
            tfaNeededByResponse.value = true;
            return;
        }

        notifications.warn(t('dashboard.header.profile.invitations.accept.warning'));
        return;
    }

    status.value = 'accepted';
    notifications.success(t('dashboard.header.profile.invitations.accept.success'));
    emit('accepted');

    if (sidebarUpdate) {
        await sidebarUpdate.updateUserandCompanies();
    }
}

async function declineInvitation(): Promise<void> {
    const confirm = await dialog.confirm({
        title: t('dashboard.common.confirm.permanent.title'),
        message: t('dashboard.common.confirm.permanent.description'),
        variant: 'destructive',
    });

    if (confirm) {
        status.value = 'loading';

        // TODO - @openticket/lib-auth
        try {
            await auth.$http.delete(urlJoin(auth.$path, 'invitations', 'companies', props.invitation.guid));
        } catch (e: unknown) {
            status.value = null;

            notifications.warn(t('dashboard.header.profile.invitations.decline.warning'));

            throw e;
        }

        status.value = 'declined';
        notifications.success(t('dashboard.header.profile.invitations.decline.success'));
    }
}
</script>

<style lang="scss" scoped>
.invitation {
    display: flex;
    width: 100%;
    align-items: center;

    &__info {
        flex-grow: 1;
        margin: 0 var(--ot-ui-spacing-2xl);
        flex: min-content;
    }

    &__action {
        display: flex;
        gap: var(--ot-ui-spacing-xs);
    }
}
</style>
