<template>
    <div class="cancel-reschedule-event">
        <div>
            <h3 class="ot-ui-text-heading-3">
                {{ $t('dashboard.events.edit.details.cancel-reschedule.title') }}
            </h3>
            <p class="ot-ui-text-body-md">
                {{ $t('dashboard.events.edit.details.cancel-reschedule.description') }}
            </p>
        </div>
        <div class="cancel-reschedule-event__buttons">
            <OtButton
                id="intercom-cancel-event-action"
                icon="close"
                :title="$t('dashboard.events.edit.details.cancel-reschedule.buttons.cancel.title')"
                size="small"
                variant="destructive"
            >
                {{ $t('dashboard.events.edit.details.cancel-reschedule.buttons.cancel.text') }}
            </OtButton>
            <OtButton
                id="intercom-reschedule-event-action"
                icon="calendar"
                :title="$t('dashboard.events.edit.details.cancel-reschedule.buttons.reschedule.title')"
                size="small"
                variant="secondary"
            >
                {{ $t('dashboard.events.edit.details.cancel-reschedule.buttons.reschedule.text') }}
            </OtButton>
        </div>
    </div>
</template>

<style scoped lang="scss">
.cancel-reschedule-event {
    display: flex;
    flex-direction: column;
    gap: var(--ot-ui-spacing-md);

    &__buttons {
        display: flex;
        flex-direction: row;
        gap: var(--ot-ui-spacing-md);
    }
}
</style>
