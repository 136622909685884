import { OtError } from '@openticket/lib-log';

export class InjectionError extends OtError {

    constructor(pluginName: string) {
        super('InjectionError', 'dashboard.errors.injection', `Injection failed on '${pluginName}'-plugin.`);

        this.captureStackTrace(InjectionError);
    }

}
