<template>
    <TfaChoice
        v-if="!tfaType"
        @back="close"
        @choice="setTfaTypeChoice"
    />

    <TfaConfirmable
        v-else
        v-slot="{ submit, error, submitting }"
        class="tfa-setup"
        :tfa-types="tfaTypes"
        @close="close"
    >
        <TfaTotpSetup
            v-if="tfaType === 'totp'"
            :error="error"
            :submitting="submitting > 0"
            @close="close"
            @submit="submit"
        />

        <TfaCodeSetup
            v-else-if="tfaType === 'code'"
            :error="error"
            :submitting="submitting > 0"
            @close="close"
            @submit="submit"
        />
    </TfaConfirmable>
</template>

<script setup lang="ts">
import { computed, provide, ref } from 'vue';
import type { OtTFATypes } from '@openticket/vue-ui';
import TfaCodeSetup from './code/TfaCodeSetup.vue';
import TfaChoice from './TfaChoice.vue';
import TfaConfirmable from './TfaConfirmable.vue';
import TfaTotpSetup from './totp/TfaTotpSetup.vue';
import type { TfaSetupProvider } from './types';
import type { RudderStack } from '../../services/rudderstack';
import { injectOrFail } from '../../services/util';

type Props = {
    tfaTypes: OtTFATypes,
    type: string | null,
    hideBackButton?: boolean,
    hideLogo: boolean,
    hideLogout: boolean,
    showForcedWarning?: boolean,
    successMessage: string,
}

type Emits = {
    (e: 'close'): void
}

const props = withDefaults(
    defineProps<Props>(),
    {
        type: null,
        hideBackButton: false,
        hideLogo: false,
        hideLogout: false,
        showForcedWarning: false,
        successMessage: 'dashboard.common.state.created',
    },
);

const emit = defineEmits<Emits>();

const rudderstack = injectOrFail<RudderStack>('rudderstack');

provide<TfaSetupProvider>('TfaSetupProvider', {
    hideLogo: props.hideLogo,
    hideLogout: props.hideLogout,
    hideBackButton: props.hideBackButton,
    showForcedWarning: props.showForcedWarning,
    successMessage: props.successMessage,
});

const tfaTypeChoice = ref<string | null>(null);

const tfaType = computed(() => props.type || tfaTypeChoice);

const close = (): void => {
    tfaTypeChoice.value = null;
    emit('close');
};

const setTfaTypeChoice = (type: string): void => {
    tfaTypeChoice.value = type;

    rudderstack.track('vue-dashboard fix tfa setup choice', {
        choice: type,
    });
};
</script>

<style lang="scss" scoped>
.tfa-setup {
    text-align: center;
}
</style>
