import { OtError } from '@openticket/lib-log';

export class FailedLoadShopData extends OtError {

    readonly shop: string | null;
    readonly url: string | null;

    constructor(shop?: string, url?: string) {
        super('FailedLoadShopData', 'dashboard.error.module.shops.tickets.load.data.failed', 'Failed to load shop data.');

        this.shop = shop || null;
        this.addContext('shop', shop || '');

        this.url = url || null;
        this.addContext('url', url || '');

        this.captureStackTrace(FailedLoadShopData);
    }

}
