import { OtError } from '@openticket/lib-log';

export class ContextAddonProductError extends OtError {

    constructor() {
        super('ContextAddonProductError', 'dashboard.errors.plugins.context.addon_product', 'Failed to set addon product context.');

        this.captureStackTrace(ContextAddonProductError);
    }

}
