<template>
    <div class="debug-plugins__auth">
        <code
            class="debug-plugins__auth__status full-width"
        >
            <span>Status:</span>

            <span v-if="loading">Loading</span>
            <span v-else-if="!activeToken">Unauthenticated</span>
            <span v-else>Authenticated</span>
        </code>

        <code
            class="debug-plugins__auth__user full-width"
        ><span>User:</span><span>{{ userName }}</span></code>

        <code
            class="debug-plugins__auth__token__token-id full-width"
        ><span>Token ID:</span><span>{{ tokenID }}</span></code>

        <code class="debug-plugins__auth__token__expires-at full-width">
            <span>Expires at:</span><span>{{ expiresAt }}</span>
        </code>

        <code class="debug-plugins__auth__token__refresh-token full-width">
            <span>Has Refresh Token:</span>
            <span>{{ refreshToken }}</span>
        </code>

        <code class="debug-plugins__auth__token__companies full-width">
            <span class="row"><span>Companies:</span><span>#{{ companies.length }}</span></span>
            <span
                v-for="(companyID, i) in companies"
                :key="`${i}_${companyID}`"
            >{{ companyID }}</span>
        </code>
    </div>
</template>

<script lang="ts" setup>
import type { AuthClient, Token } from '@openticket/lib-auth';
import { computed, onMounted, ref } from 'vue';
import type { Formatters } from '@openticket/lib-localization';
import { useLocalization } from '@openticket/vue-ui';
import type { PluginsManager } from '../../../../plugins';
import { injectOrFail } from '../../../../services/util';

const plugins = injectOrFail<PluginsManager>('plugins');

const loading = ref<boolean>(true);
const client = ref<AuthClient | null>(null);
const formatters = ref<Formatters | null>(null);

const { localization } = useLocalization();

onMounted(() => {
    void initializePluginDebug();
});

async function initializePluginDebug() {
    try {
        client.value = await plugins.auth.loading;
    } finally {
        loading.value = false;
    }

    formatters.value = localization.formatters;
}

const dateTimeShort = computed<(ts: number) => string>(() => {
    if (!formatters.value) {
        return ((ts: number) => ts.toFixed(0));
    }

    return (ts: number) => formatters.value?.dateTimeShort(new Date(ts)) || ts.toFixed(0);
});

const activeToken = computed<Token | null>(() => {
    if (!client.value) {
        return null;
    }

    return client.value.$token.$activeToken;
});

const userName = computed<string>(() => {
    if (!client.value) {
        return '';
    }

    // TODO return this.client.$token.user.$data.name;
    return 'USERNAME';
});

const tokenID = computed<string>(() => (activeToken.value ? activeToken.value.token_id : 'NONE'));

const expiresAt = computed<string>(() => (activeToken.value ? `[${dateTimeShort.value(activeToken.value.expires_at)}]` : ''));

const refreshToken = computed<string>(() => {
    if (!activeToken.value) {
        return '';
    }

    return activeToken.value.refresh_token ? `Yes [${dateTimeShort.value(activeToken.value.refresh_token_expires_at)}]` : 'No';
});

const companies = computed<string[]>(() => (activeToken.value ? activeToken.value.companies : []));
</script>

<style lang="scss" scoped>
.debug-plugins__auth {
    & > code {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;

        & > span:not(:first-child) {
            padding-left: .5rem;
        }

        &.debug-plugins__auth__token__token-id > span:nth-child(2) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.debug-plugins__auth__token__companies {
            flex-direction: column;
            justify-content: flex-start;

            & > .row {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
</style>
