import { DataType } from '@openticket/lib-crud';
import type { DataInterface, ModelConfig, Pagination } from '@openticket/lib-crud';
import type { LegacyApiHelperMethods } from '../types';

export function getShopEndpoints(methods: LegacyApiHelperMethods) {
    /**
     * Returns all PaymentMethods which are attached to this Shop.
     *
     * @param shopId The guid of the Shop.
     * @returns
     */
    async function attachedPaymentMethods(shopId: string): Promise<Pagination<DataInterface<typeof config>>> {
        // TODO/Note: The below config is incomplete, add other properties when needed
        const config = {
            guid: [ DataType.string ],
            name: [ DataType.string ],
        } satisfies ModelConfig;

        return methods.performGetWithArrayReponse([ 'shop', shopId, 'payment_methods' ], config);
    }

    return {
        attachedPaymentMethods,
    };
}
