import type { RouteRecordRaw } from 'vue-router';
import WaitingListSignupListView from './views/WaitingListSignupListView.vue';
import WaitingListSettingsView from './views/WaitingListSettingsView.vue';
import WelcomeMessageWrapper from '../../layouts/WelcomeMessageWrapper.vue';

export function waitingListRoutes(): RouteRecordRaw[] {
    return [
        {
            path: 'waiting-list',
            component: WelcomeMessageWrapper,
            meta: {
                title: 'dashboard.document_title.waiting_list.signup_list',
                titleFallback: 'Waiting list',
                onContextSelect: (id: string | symbol | undefined) => {
                    if (typeof id === 'string') {
                        return {
                            name: 'waiting_list.signup_list',
                            params: { event: id },
                        };
                    }
                    return null;
                },
            },
            children: [
                {
                    path: '',
                    name: 'waiting_list.signup_list',
                    component: WaitingListSignupListView,
                },
                {
                    path: ':waitingList',
                    name: 'waiting_list.settings',
                    component: WaitingListSettingsView,
                    meta: {
                        // TODO build a guard that passes the eventId to the context as eventId
                        //   so that the link does not duplicate the guid...
                        context: 'waitingList',
                        title: 'dashboard.document_title.waiting_list.settings',
                        titleFallback: 'Waiting list',
                    },
                },
            ],
        },
    ];
}
