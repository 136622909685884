<template>
    <div class="access-moments-picker">
        <ErrorView
            v-if="error"
            :label="error.message"
        />

        <OtPaginatedSelectInput
            v-else-if="accessMomentsPagination"
            ref="paginationSelect"
            :pagination="accessMomentsPagination"
            :model-value="linkedAccessMoments"
            data-primary-key="name"
            data-testid="access-moments-picker-select"
            :can-create="canCreateNewAccessMoment"
            multiple
            :placeholder="$t('dashboard.access_moments.tickets.select.placeholder')"
            :name-resolver="nameResolver"
            :readonly="readonly"
            @input="handleSelectInput"
            @create="modalRef.open()"
        >
            <template #primaryLabel="{ option }">
                {{ option.name || '-' }}
            </template>

            <template #secondaryLabel="{ option }">
                {{ $l.dateTimeRangeCollapseLong(option.start, option.end) }}
            </template>
        </OtPaginatedSelectInput>

        <AccessMomentCreateModal
            v-if="modalRef.isOpen"
            :key="id"
            :modal="modalRef"
            @submit="populateList()"
        />
    </div>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue';
import type {
    Event, EventDate, ManagementClient, Ticket,
} from '@openticket/lib-management';
import { Pagination } from '@openticket/lib-crud';
import { OtPaginatedSelectInput } from '@openticket/vue-ui';
import { useGenericErrorHandling } from '../../../composables';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';
import ErrorView from '../../../components/ErrorView.vue';
import { useFormModal } from '../../../composables/forms';
import AccessMomentCreateModal from './AccessMomentCreateModal.vue';

type Props = {
    // Provide a Pagination of access moments to remove an unnecessary call to fetch them otherwise
    accessMoments?: Pagination<EventDate<Event<ManagementClient>>>;

    canCreateNewAccessMoment?: boolean;
    readonly?: boolean;
    ticket: Ticket<ManagementClient>;

    // Set to true to skip the linked eventdates fetch call, which is unnecessary for unlinked tickets
    ticketIsUnlinked?: boolean;
}

type Emits = {
    (e: 'error', error: Error): void,
    (e: 'input', value: unknown): void,
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { error, handleError } = useGenericErrorHandling();

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const accessMomentsPagination = ref<Pagination<EventDate<Event<ManagementClient>>> | null>(null);
const accessMomentsDiff = reactive<{ toLink: string[], toUnlink: string[] }>({
    toLink: [],
    toUnlink: [],
});
const hasLocalChanges = ref<boolean>(false);
const id = ref<string>('');
const linkedAccessMoments = ref<string[]>([]);
const paginationSelect = ref<typeof OtPaginatedSelectInput | null>(null);

const modalRef = reactive(useFormModal());

function handleSelectInput(listOfGuids: string[]) {
    hasLocalChanges.value = true;

    const existingLinks = new Set(linkedAccessMoments.value);
    const newLinks = new Set(listOfGuids);

    accessMomentsDiff.toLink = listOfGuids.filter((guid) => !existingLinks.has(guid));
    accessMomentsDiff.toUnlink = linkedAccessMoments.value.filter((guid) => !newLinks.has(guid));
}

async function populateList() {
    try {
        if (!context.isEventContext()) {
            // TODO Properly log error & localise reason.
            throw Error('Invalid context');
        }

        // TODO: Remove the `false` below when it is possible to copy an existing Pagination object in lib-crud. See CU-86c0dqvzf
        // eslint-disable-next-line no-constant-condition
        if (props.accessMoments && false) {
            // Clone the provided access moments to remove reference to original Pagination, otherwise we would get unexpected
            // side effects. Linting and TS is complaining about this as the `pageLoader` property is private. However,
            // implementing this will result in one less call.
            // @ts-expect-error The `pageLoader` property is private
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            accessMomentsPagination.value = new Pagination<EventDate<Event<ManagementClient>>>(props.accessMoments.pageLoader);
        } else {
            // When no access moments have been provided, fetch them
            accessMomentsPagination.value = context.event.model.eventDates.list({
                defaultSort: [ [ 'start', 'asc' ] ],
                deferInitialization: true,
            });
        }

        await accessMomentsPagination.value.initialization;

        // When the ticket is known to be unlinked, don't perform the extra call of fetching an empty list
        if (props.ticketIsUnlinked) {
            return;
        }

        const ticketEventdateList = props.ticket.eventdates.pick();
        await ticketEventdateList.initialization;

        linkedAccessMoments.value = ticketEventdateList.records
            .filter((eventdate) => !!eventdate.linked)
            .map((model) => model.id || '');
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }
}

async function submit(): Promise<void> {
    if (!context.isEventContext()) {
        // TODO Properly log error & localise reason.
        throw Error('Invalid context');
    }

    try {
        if (accessMomentsDiff.toLink.length) {
            await props.ticket.eventdates.linkMulti(...accessMomentsDiff.toLink);
        }

        if (accessMomentsDiff.toUnlink.length) {
            await props.ticket.eventdates.unlinkMulti(...accessMomentsDiff.toUnlink);
        }
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    } finally {
        const toRemove = new Set(accessMomentsDiff.toUnlink);

        linkedAccessMoments.value = [
            ...linkedAccessMoments.value,
            ...accessMomentsDiff.toLink,
        ].filter((guid) => !toRemove.has(guid));

        hasLocalChanges.value = false;
    }
}

async function nameResolver(guid: string, opt?: EventDate<Event<ManagementClient>>['$data']): Promise<string> {
    if (opt && 'name' in opt) {
        return opt.name || '-';
    }

    try {
        const model = await management.eventdates.find(guid);
        return model.$data.name || '-';
    } catch (e) {
        void handleError(e);
    }

    return '';
}

// Run this on created
void populateList();

defineExpose({
    hasLocalChanges,
    submit,
});
</script>

<style lang="scss" scoped>
.access-moments-picker {
    width: 100%;
}
</style>
