<template>
    <div class="access-moment-form-fields">
        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.access_moments.list.new.inputs.start.label')"
                :optional="form.rules.start && !form.rules.start.includes('required')"
                :error="form.errors.start"
            >
                <OtDateInput
                    v-model="form.model.$data.start"
                    time
                    data-testid="access-moment-form-fields-start"
                    :readonly="isReadOnly"
                    @input="form.errors.start = []"
                />
            </OtInputField>

            <OtInputField
                :label="$t('dashboard.access_moments.list.new.inputs.end.label')"
                :optional="form.rules.end && !form.rules.end.includes('required')"
                :error="form.errors.end"
            >
                <OtDateInput
                    v-model="form.model.$data.end"
                    time
                    data-testid="access-moment-form-fields-end"
                    :readonly="isReadOnly"
                    @input="form.errors.end = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow
            v-if="showNameField"
            :class="[ nameFieldOnTop && 'on-top']"
        >
            <OtInputField
                :label="$t('dashboard.access_moments.list.new.inputs.name.label')"
                :description="$t('dashboard.access_moments.list.new.inputs.name.description')"
                :optional="form.rules.name && !form.rules.name.includes('required')"
                :error="form.errors.name"
            >
                <OtTextInput
                    v-model="form.model.$data.name"
                    data-testid="access-moment-form-fields-name"
                    :readonly="isReadOnly"
                    @input="form.errors.name = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow v-if="showLocationField">
            <OtInputField
                :label="$t('dashboard.access_moments.list.new.inputs.location.label')"
                :optional="form.rules.location_id && !form.rules.location_id.includes('required')"
                :error="form.errors.location_id"
            >
                <LocationsInput
                    v-model="form.model.$data.location_id"
                    allow-empty
                    :can-create="false"
                    :can-edit="false"
                    :readonly="isReadOnly"
                    data-testid="access-moment-form-fields-location-id"
                    @input="form.errors.location = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.events.wizard.steps.details.form.location.modal.form.capacity.label')"
                :description="$t('dashboard.events.wizard.steps.details.form.location.modal.form.capacity.description')"
                :optional="form.rules.capacity && !form.rules.capacity.includes('required')"
                :error="form.errors.capacity"
            >
                <InputFloatUnlimited
                    v-model="form.model.$data.capacity"
                    data-testid="access-moment-form-fields-capacity"
                    :readonly="isReadOnly"
                    @input="form.errors.capacity = []"
                />
            </OtInputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue';
import type { EventDate, ManagementClient } from '@openticket/lib-management';
import InputFloatUnlimited from '../../../../components/InputFloatUnlimited.vue';
import LocationsInput from '../../../../components/form/LocationsInput.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { useRoles } from '../../../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<EventDate<ManagementClient>, ManagementClient>>;
    nameFieldOnTop?: boolean;
    showLocationField?: boolean;
    showNameField?: boolean;
}

withDefaults(defineProps<Props>(), {
    showLocationField: true,
    showNameField: true,
});

const { isReadOnly } = useRoles();
</script>

<style scoped lang="scss">
.access-moment-form-fields {
    display: flex;
    flex-direction: column;

    .form-row.on-top {
        order: -1;
    }
}
</style>
