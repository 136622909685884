import type {
    Model, ModelConfig, Parent, RulesMap,
} from '@openticket/lib-crud';
import { Update } from '@openticket/lib-crud';
import type { ModelFormComposableResult } from './useModelForm';
import { useModelForm } from './useModelForm';
import type { Relation, ModelFormConfig } from './types';

export function useUpdateForm<
    M extends Model<P, ModelConfig, RulesMap>,
    P extends Parent,
>(
    _model: M,
    _relation: Relation<M, P>,
    config?: ModelFormConfig<M, P>,
): ModelFormComposableResult<M, P> {
    return useModelForm(_model, _relation, Update, config);
}
