import { OtError } from '@openticket/lib-log';

export class LegacyApiUnsetBaseUrlError extends OtError {

    constructor() {
        super('LegacyApiUnsetBaseUrlError', 'dashboard.errors.plugins.legacy.api.unset.base.url', 'LegacyApi Base URL not set!');

        this.captureStackTrace(LegacyApiUnsetBaseUrlError);
    }

}
