import { useRouter } from 'vue-router';
import { ROLE_COMPANY_ADMIN } from '@openticket/lib-auth';
import type VueRouter from 'vue-router';
import type { Context, ContextWithCompany, ContextWithEvent } from '../context';
import type { ContextWithShop } from '../context/types';
import type { InternalNavigatorItem, InternalNavigatorSeparator } from './types';
import type { Roles } from '../../plugins/types';
import { disableByRole } from '../util/roleGuard';
import type { DefinedFlags } from '../featureFlag/types';

export type SidebarNavigationItems = Array<InternalNavigatorItem | InternalNavigatorSeparator>

export function itemIsSelected(item: InternalNavigatorItem): boolean {
    if (!item.route?.name) {
        return false;
    }

    const router = useRouter();

    const itemRoutePath = router.resolve({ name: item.route.name, params: item.route.params }).href;

    if (itemRoutePath === '/') {
        return false;
    }

    return router.currentRoute.value.path.includes(itemRoutePath);
}

export function getNavigatorItems(context: Context | null, roles: Roles): SidebarNavigationItems {
    if (!context) {
        return [
            {
                type: 'item',
                icon: 'home',
                label: 'dashboard.document_title.home',
                route: { name: 'home' },
            },
        ];
    }

    if (context.isEventContext()) {
        return eventContextItems(context);
    }

    if (context.isShopContext()) {
        return shopContextItems(context);
    }

    if (context.isCompanyContext()) {
        return companyContextItems(context, roles);
    }

    return globalContextItems();
}

export function obscureItemsByFlags(
    navItems: SidebarNavigationItems,
    flags: DefinedFlags,
    router: VueRouter.Router,
): SidebarNavigationItems {
    return navItems.flatMap((item) => {
        if (item.type === 'separator' || !item.route) {
            return item;
        }

        const routeFlag = router.resolve({ name: item.route.name, params: item.route.params }).meta?.allowedByFlag;

        if (!routeFlag) {
            return item;
        }

        if (flags[routeFlag] === true) {
            return item;
        }

        return [];
    });
}

function eventContextItems(context: Context & ContextWithEvent): SidebarNavigationItems {
    const params = {
        event: context.event.id,
    };

    const items: SidebarNavigationItems = [
        {
            type: 'item',
            icon: 'ticket',
            label: 'dashboard.document_title.events.tickets.list',
            route: { name: 'tickets.list', params },
        },
        {
            type: 'item',
            icon: 'calendar',
            label: 'dashboard.document_title.access_moments.list',
            route: { name: 'access-moments.list', params },
        },
        {
            type: 'item',
            icon: 'shops',
            label: 'dashboard.document_title.addon_products.list',
            route: { name: 'addon-products.list', params },
        },
        {
            type: 'item',
            icon: 'bell',
            label: 'dashboard.document_title.waiting_list.signup_list',
            route: { name: 'waiting_list.signup_list', params },
        },
        {
            type: 'item',
            icon: 'tools',
            label: 'dashboard.document_title.events.edit.main',
            route: { name: 'events.edit', params },
        },
    ];

    // TODO: Return all items, add the items that are 'ready'/worked on
    // TODO: Return items directly when all items are available
    return [
        items[0], // Tickets
        items[1], // Access moments
        items[2], // Addon Products
        items[3], // Waiting List
        items[4], // event edit
    ];
}

function shopContextItems(context: Context & ContextWithShop): SidebarNavigationItems {
    const params = {
        shop: context.shop.id,
    };

    const items: SidebarNavigationItems = [
        {
            type: 'item',
            icon: 'ticket',
            label: 'dashboard.document_title.shops.tickets',
            route: { name: 'shops.tickets', params },
        },
        {
            type: 'item',
            icon: 'login',
            label: 'dashboard.document_title.shops.styling',
            route: { name: 'shops.styling', params },
        },
        {
            type: 'item',
            icon: 'tools',
            label: 'dashboard.document_title.shops.settings',
            route: { name: 'shops.edit', params },
        },
        {
            type: 'separator',
        },
    ];

    // TODO: Return all items, add the items that are 'ready'/worked on
    // TODO: Return items directly when all items are available
    return [ ...items ];
}

function companyContextItems(context: Context & ContextWithCompany, roles: Roles): SidebarNavigationItems {
    const params = {
        company: context.company.id,
    };

    return [
        {
            type: 'item',
            icon: 'event',
            label: 'dashboard.document_title.events.list',
            route: { name: 'events.list', params },
        },
        {
            type: 'item',
            icon: 'login',
            label: 'dashboard.document_title.orders.list',
            route: { name: 'orders.list', params },
        },
        {
            type: 'item',
            icon: 'tools',
            label: 'dashboard.document_title.companies.edit.main',
            route: { name: 'companies.edit', params },
        },
        {
            type: 'separator',
        },
        {
            type: 'item',
            icon: 'shops',
            label: 'dashboard.document_title.shops.list',
            route: { name: 'shops.list', params },
        },
        {
            type: 'item',
            icon: 'scanners',
            label: 'dashboard.document_title.scanners.list',
            route: { name: 'scanners.list', params },
            disabled: true,
        },
        {
            type: 'item',
            icon: 'message',
            label: 'dashboard.document_title.visitor_data.list',
            route: { name: 'visitor-data.list', params },
            disabled: true,
        },
        {
            type: 'separator',
        },
        {
            type: 'item',
            icon: 'marketplace',
            label: 'dashboard.document_title.app_library.title',
            route: { name: 'app-library', params },
        },
        // DD-2727 - Enable Connections (Albato) nav item when Whitelabel config is set
        {
            type: 'item',
            icon: 'integrations',
            label: 'dashboard.document_title.connections.title',
            route: { name: 'connections', params },
            disabled: disableByRole(roles, ROLE_COMPANY_ADMIN),
        },
    ];
}

function globalContextItems(): SidebarNavigationItems {
    const params = {};

    return [
        {
            type: 'item',
            icon: 'event',
            label: 'dashboard.document_title.events.list',
            route: { name: 'events.list', params },
        },
        {
            type: 'item',
            icon: 'login',
            label: 'dashboard.document_title.orders.list',
            route: { name: 'orders.list', params },
        },
        {
            type: 'item',
            icon: 'tools',
            label: 'dashboard.document_title.companies.edit.main',
            route: undefined,
            disabled: true,
        },
        {
            type: 'separator',
        },
        {
            type: 'item',
            icon: 'shops',
            label: 'dashboard.document_title.shops.list',
            route: { name: 'shops.list', params },
        },
        {
            type: 'item',
            icon: 'scanners',
            label: 'dashboard.document_title.scanners.list',
            route: { name: 'scanners.list', params },
            disabled: true,
        },
        {
            type: 'item',
            icon: 'message',
            label: 'dashboard.document_title.visitor_data.list',
            route: { name: 'visitor-data.list', params },
            disabled: true,
        },
        {
            type: 'separator',
        },
        {
            type: 'item',
            icon: 'marketplace',
            label: 'dashboard.document_title.app_library.title',
            route: {
                name: 'app-library',
                params: {
                    ...params,
                    company: '-', // This string needs to be filled as the `company` param is required. Note that this is never used as the route is disabled
                },
            },
            disabled: true,
        },
        {
            type: 'item',
            icon: 'integrations',
            label: 'dashboard.document_title.connections.title',
            route: {
                name: 'connections',
                params: {
                    ...params,
                    company: '-', // This string needs to be filled as the `company` param is required. Note that this is never used as the route is disabled
                },
            },
            disabled: true,
        },
    ];
}
