<template>
    <div class="debug-plugins__context">
        <code
            class="debug-plugins__context__status full-width"
        >
            <span>Status:</span>

            <span v-if="context && context.loading">Loading</span>
            <span v-else>Ready</span>
        </code>

        <code
            v-if="context"
            class="debug-plugins__context__type full-width"
        >
            <span>Context:</span>

            <span>{{ context.type }}</span>
        </code>

        <code
            v-if="context"
            class="debug-plugins__context__company full-width"
        >
            <span>Company:</span>

            <span v-if="context.company">{{ context.company.name }} ({{ context.company.id }})</span>
            <span v-else>[No]</span>
        </code>

        <code
            v-if="context"
            class="debug-plugins__context__event full-width"
        >
            <span>Event:</span>

            <span v-if="context.event">{{ context.event.name }} ({{ context.event.id }})</span>
            <span v-else>[No]</span>
        </code>
    </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue';
import type { Context } from '../../../../services/context';

const context = inject<Context>('context');
</script>

<style lang="scss" scoped>
.debug-plugins__context {
    & > code {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;
    }
}
</style>
