<template>
    <OtFilters
        class="tickets-filters"
        :categories-to-hide="filterCategoriesToHide"
        :pagination="tickets"
        translations-prefix-key="models.ticket"
        :view-filter="{ label: context.event?.name }"
    >
        <OtFilterCategory
            id="orderable_amount"
            v-slot="{ state }"
            class="tickets-filters__custom__orderable-amount"
            :attributes="[ 'max_orderable_amount_per_order', 'min_orderable_amount_per_order' ]"
            icon="shops"
            :label="$t('dashboard.tickets.filters.orderable_amount.label')"
        >
            <OtFloatInput
                v-model="state.min_orderable_amount_per_order.min"
                :decimals="0"
                :min="0"
                :placeholder="$t('dashboard.common.filters.datatype.number.min')"
            />

            <span class="ot-ui-text-body-strong">
                -
            </span>

            <OtFloatInput
                v-model="state.max_orderable_amount_per_order.max"
                :decimals="0"
                :min="0"
                :placeholder="$t('dashboard.common.filters.datatype.number.max')"
            />
        </OtFilterCategory>
    </OtFilters>
</template>

<script setup lang="ts">
import type { Pagination } from '@openticket/lib-crud';
import type { ManagementClient, Ticket } from '@openticket/lib-management';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';

type Props = {
    tickets: Pagination<Ticket<ManagementClient>>;
}

defineProps<Props>();

const context = injectOrFail<Context>('context');

const filterCategoriesToHide = [
    'availability_margin',
    'barcode_type',
    'class',
    'combines_products',
    'created_at',
    'increment',
    'max_orderable_amount_per_order',
    'min_orderable_amount_per_order',
    'status_overrule',
    'updated_at',
];
</script>

<style scoped lang="scss">
.tickets-filters {
    &__custom__orderable-amount {
        display: flex;
        gap: var(--ot-ui-spacing-md);
        align-items: center;

        span {
            color: var(--ot-ui-color-accent-primary);
        }
    }
}
</style>
