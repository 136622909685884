<template>
    <div
        v-if="settings"
        class="shop-settings__shop"
    >
        <h2 class="ot-ui-text-heading-2">
            {{ $t('dashboard.shop.settings.shop.title') }}
        </h2>
        <p class="shop-settings__shop-description ot-ui-text-body-lg ot-ui-mb[md]">
            {{ $t('dashboard.shop.settings.shop.description') }}
        </p>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.shop.settings.shop.pricing.title')"
                :description="$t('dashboard.shop.settings.shop.pricing.description')"
            >
                <OtToggleInput
                    v-model="settings.static.shop.enableCoupons"
                    data-testid="shop-settings-shop-enable-coupons"
                    :label="$t('dashboard.shop.settings.shop.pricing.coupons.label')"
                    :readonly="isReadOnly"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.shop.settings.shop.timeslots.title')"
                :description="$t('dashboard.shop.settings.shop.timeslots.description')"
            >
                <OtToggleInput
                    v-model="settings.static.shop.enableTimeslotsFilters"
                    data-testid="shop-settings-shop-enable-timeslots-filters"
                    :label="$t('dashboard.shop.settings.shop.timeslots.enable_timeslots_filters.label')"
                    :readonly="isReadOnly"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                v-if="roles.isWhitelabelAdmin"
                :label="$t('dashboard.shop.settings.shop.seats.title')"
                :description="$t('dashboard.shop.settings.shop.seats.description')"
                :readonly="isReadOnly"
            >
                <p>For now these are admin only as well, after all translations are added, they could be opened to everyone.</p>
                <OtToggleInput
                    v-model="settings.static.shop.seats.hideLegend"
                    :label="$t('dashboard.shop.settings.shop.seats.hide_legend.label')"
                />
                <OtToggleInput
                    v-model="settings.static.shop.seats.hideSectionsNotForSale"
                    :label="$t('dashboard.shop.settings.shop.seats.hide_sections_not_for_sale.label')"
                />
                <OtToggleInput
                    v-model="settings.static.shop.seats.tooltip.hideActionHint"
                    :label="$t('dashboard.shop.settings.shop.seats.tooltip.hide_action_hint.label')"
                />
                <OtToggleInput
                    v-model="settings.static.shop.seats.tooltip.hideAvailability"
                    :label="$t('dashboard.shop.settings.shop.seats.tooltip.hide_availability.label')"
                />
                <OtToggleInput
                    v-model="settings.static.shop.seats.tooltip.hideCategory"
                    :label="$t('dashboard.shop.settings.shop.seats.tooltip.hide_category.label')"
                />
                <OtToggleInput
                    v-model="settings.static.shop.seats.tooltip.hideLabel"
                    :label="$t('dashboard.shop.settings.shop.seats.tooltip.hide_label.label')"
                />
                <OtToggleInput
                    v-model="settings.static.shop.seats.tooltip.hidePricing"
                    :label="$t('dashboard.shop.settings.shop.seats.tooltip.hide_pricing.label')"
                />
                <OtToggleInput
                    v-model="settings.static.shop.seats.tooltip.hideUnavailableNotice"
                    :label="$t('dashboard.shop.settings.shop.seats.tooltip.hide_unavailable_notice.label')"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                v-if="roles.isWhitelabelAdmin"
                :label="$t('dashboard.shop.settings.shop.admin.title')"
                :description="$t('dashboard.shop.settings.shop.admin.description')"
            >
                <OtToggleInput
                    v-model="settings.static.shop.blockFrameAccess"
                    :label="$t('dashboard.shop.settings.shop.admin.block_frame_access.label')"
                />

                <OtToggleInput
                    v-model="settings.static.shop.rememberTerms"
                    :label="$t('dashboard.shop.settings.shop.admin.remember_terms.label')"
                />

                <OtToggleInput
                    v-model="settings.static.shop.footer.showPoweredBy"
                    :label="$t('dashboard.shop.settings.shop.admin.footer.showPoweredBy.label')"
                />

                <OtToggleInput
                    v-model="settings.static.shop.footer.showLocaleSwitcher"
                    :label="$t('dashboard.shop.settings.shop.admin.footer.showLocaleSwitcher.label')"
                />

                <OtToggleInput
                    v-model="settings.static.shop.showReservationTimer"
                    :label="$t('dashboard.shop.settings.shop.admin.showReservationTimer.label')"
                />
            </OtInputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import type { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import type { UnwrapNestedRefs } from 'vue';
import { OtFormRow } from '../../../../components';
import { injectOrFail } from '../../../../services/util';
import type { Roles } from '../../../../plugins/types';
import { useRoles } from '../../../../composables';

const settings = injectOrFail<CustomShopSettingsClient>('settings');
const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');

const { isReadOnly } = useRoles();

</script>

<style lang="scss" scoped>
.shop-settings__shop {
    &-description {
        color: var(--ot-ui-color-foreground-secondary);
    }

    :deep(.ot-ui-input-field__content) {
        display: flex;
        align-items: start;
        flex-direction: column;
        gap: 0px;
    }

    .ot-ui-input-field > .ot-toggle:not(:last-child) {
        margin-bottom: var(--ot-ui-spacing-xs);
    }
}
</style>
