<template>
    <div class="module__profile__change-password">
        <div class="module__profile__change-password__title">
            <h3 class="ot-ui-text-heading-3">
                {{ $t('dashboard.user.change_password.title') }}
            </h3>

            <span class="ot-ui-text-body-md">
                {{ $t('dashboard.user.change_password.explanation') }}
            </span>
        </div>

        <div class="ot-ui-stacky[md] ot-ui-mt[md]">
            <OtFormRow>
                <OtInputField
                    :label="$t('dashboard.user.change_password.old_password')"
                    :error="form.errors.old_password"
                    :optional="form.rules.old_password && !form.rules.old_password.includes('required')"
                >
                    <OtPasswordInput
                        v-model="form.model.$data.old_password"
                        :invalid="form.errors.old_password?.length > 0"
                        :placeholder="$t('dashboard.user.change_password.old_password')"
                        @input="form.errors.old_password = []"
                    />
                </OtInputField>
            </OtFormRow>

            <OtFormRow>
                <OtInputField
                    :label="$t('dashboard.user.change_password.new_password')"
                    :error="form.errors.new_password"
                    :optional="form.rules.new_password && !form.rules.new_password.includes('required')"
                >
                    <OtPasswordInput
                        v-model="form.model.$data.new_password"
                        :invalid="form.errors.new_password?.length > 0"
                        :placeholder="$t('dashboard.user.change_password.new_password')"
                        @input="form.errors.new_password = []"
                    />
                </OtInputField>

                <OtInputField
                    :label="$t('dashboard.user.change_password.new_password_confirmation')"
                    :optional="form.rules.new_password && !form.rules.new_password.includes('required')"
                    :error="form.errors.new_password_confirmation"
                    @input="form.errors.new_password_confirmation = []"
                >
                    <OtPasswordInput
                        v-model="form.model.$data.new_password_confirmation"
                        :invalid="form.errors.new_password_confirmation?.length > 0"
                        :placeholder="$t('dashboard.user.change_password.new_password_confirmation')"
                        @input="form.errors.new_password_confirmation = []"
                    />
                </OtInputField>
            </OtFormRow>
        </div>

        <div class="module__profile__change-password__submit ot-ui-mt[lg]">
            <OtButton
                :disabled="changePasswordFormNotCompleted"
                full-width
                icon="check"
                size="small"
                @on-click="submit()"
            >
                {{ $t('dashboard.user.change_password.button.text') }}
            </OtButton>
        </div>

        <!-- TODO: Update this to use new DS | CU-86c0xx23g -->
        <OtModal ref="modalTfaConfirmation">
            <OtTFAConfirmation
                v-if="tfaTypes"
                :error="error"
                :tfa-types="tfaTypes"
                :require-password="false"
                hide-logo
                hide-logout
                @submit="submitAfterTfaConfirmation"
                @back="tfaSetupClose"
            />
        </OtModal>
    </div>
</template>

<script lang="ts" setup>
import type { AuthClient, Password, ResetPassword } from '@openticket/lib-auth';
import { computed, ref, type UnwrapNestedRefs } from 'vue';
import { OtModal, type OtTFAError, type OtTFATypes } from '@openticket/vue-ui';
import { injectOrFail } from '../../../../services/util';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { useGenericErrorHandling } from '../../../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<ResetPassword<Password<AuthClient>>, ResetPassword<Password<AuthClient>>>>,
}

const props = defineProps<Props>();

const auth = injectOrFail<AuthClient>('auth');

const modalTfaConfirmation = ref<InstanceType<typeof OtModal>>();
const tfaTypes = ref<OtTFATypes | null>(null);
const { handleError } = useGenericErrorHandling();

// TODO: replace OTTFAConfirmation error with lib-auth
const error = ref<OtTFAError>({
    incorrectPassword: null,
    incorrectTFAInput: null,
});

const changePasswordFormNotCompleted = computed(
    () => !props.form.model.$data.old_password || !props.form.model.$data.new_password
        || !props.form.model.$data.new_password_confirmation,
);

async function submit(): Promise<void> {
    try {
        const response = await props.form.submit();

        if (response.success) {
            void modalTfaConfirmation.value?.close();
            return;
        }

        if (props.form.errors.tfa_type) {
            // TODO: change OTTFAConfirmation to work with TwoFactor from lib-auth
            const twoFactorTypes = await auth.twofactor.listAll();
            tfaTypes.value = {};
            for (const [ key, value ] of Object.entries(twoFactorTypes)) {
                tfaTypes.value[key] = value.$raw;
            }

            modalTfaConfirmation.value?.open();
        } else if (props.form.errors.tfa_input && props.form.errors.tfa_input[0].message) {
            error.value = {
                incorrectPassword: null,
                incorrectTFAInput: props.form.errors.tfa_input[0].message,
            };
        }
    } catch (e) {
        handleError(e);
    }
}

async function tfaSetupClose(): Promise<void> {
    if (!auth) {
        return;
    }
    props.form.model.$data.tfa_type = null;
    props.form.model.$data.tfa_input = null;
    error.value.incorrectTFAInput = null;

    // TODO: change OTTFAConfirmation to work with TwoFactor from lib-auth
    const twoFactorTypes = await auth.twofactor.listAll();
    tfaTypes.value = {};
    for (const [ key, value ] of Object.entries(twoFactorTypes)) {
        tfaTypes.value[key] = value.$raw;
    }

    void modalTfaConfirmation.value?.close();
}

async function submitAfterTfaConfirmation(tfaConfirmed: { input: string, type: string }): Promise<void> {
    if (!auth) {
        return;
    }

    props.form.model.$data.tfa_type = tfaConfirmed.type;
    props.form.model.$data.tfa_input = tfaConfirmed.input;

    await submit();
}
</script>
