<template>
    <div>
        <CompanySelector
            ref="eventCompanySelector"
            :create-model-label="$t('dashboard.events.model_name').toString().toLocaleLowerCase()"
            @close="handleCompanySelectorClose"
        />

        <OtWizard
            ref="wizard"
            class="event-wizard"
            :title="$t('dashboard.events.wizard.title')"
            :label-finish="$t('dashboard.events.wizard.steps.finish_label')"
            :label-previous="$t(wizardPrevLabel)"
            :label-next="$t(wizardNextLabel)"
            :icon-next="nextIcon"
            :previous-button-disabled="!!loading"
            :next-button-disabled="!!nextButtonDisabled"
            :next-button-loading="!!loading"
            :confirm-title="$t('dashboard.common.confirm.permanent.title')"
            :confirm-message="$t('dashboard.common.confirm.permanent.description')"
            :footer-logo="whitelabelLogo"
            :footer-copyright="$t('common.copyright', { year: (new Date()).getFullYear() })"
            @close="redirectAfterClose"
            @finish="onWizardFinish"
        >
            <template #default>
                <OtWizardStep
                    :title="$t('dashboard.events.wizard.steps.details.title')"
                    @next="onEventStepFinish"
                    @previous="wizard.previous()"
                >
                    <EventWizardDetails ref="eventWizardDetails" />
                </OtWizardStep>

                <OtWizardStep
                    :title="$t('dashboard.events.wizard.steps.tickets.title')"
                    @next="onTicketStepFinish"
                    @previous="onTicketStepPrevious"
                >
                    <EventWizardTickets
                        ref="eventWizardTickets"
                    />

                    <template #footer-right-actions>
                        <!-- Button on ticket step -->
                        <OtButton
                            v-if="!ticketToEditGuid"
                            size="small"
                            icon="plus"
                            :title="$t(ticketStepFooterRightButtonText.title)"
                            data-testid="event-wizard-add-ticket"
                            :disabled="!!loading"
                            @click="handleTicketStepExtraButton"
                        >
                            {{ $t(ticketStepFooterRightButtonText.text) }}
                        </OtButton>
                    </template>
                </OtWizardStep>

                <OtWizardStep
                    :title="$t('dashboard.events.wizard.steps.shop.title')"
                    @next="onShopStepFinish"
                    @previous="onShopStepPrevious"
                >
                    <EventWizardShop ref="eventWizardShop" />
                </OtWizardStep>
            </template>
        </OtWizard>
    </div>
</template>

<script lang="ts" setup>
import {
    computed,
    inject,
    onMounted,
    provide,
    reactive,
    ref,
    useTemplateRef,
} from 'vue';
import type { UnwrapNestedRefs } from 'vue';
import { useRouter } from 'vue-router';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { ThemeModes } from 'src/plugins/types';
import type {
    Event,
    EventDate, ManagementClient, Location,
    Ticket,
} from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import {
    type OtCheckboxOptions,
    type OtIconTypes, OtWizard, useLocalization, useNotifications,
} from '@openticket/vue-ui';
import EventWizardDetails from '../components/steps/EventWizardDetails.vue';
import EventWizardTickets from '../components/steps/EventWizardTickets.vue';
import EventWizardShop from '../components/steps/EventWizardShop.vue';
import { injectOrFail } from '../../../services/util';
import type { WizardProvide } from '../types';
import CompanySelector from '../../../components/CompanySelector.vue';
import type { Context } from '../../../services/context';
import { useCreateForm } from '../../../composables/forms';
import { useGenericErrorHandling } from '../../../composables';
import type { RudderStack } from '../../../services/rudderstack';

const router = useRouter();
const { handleError } = useGenericErrorHandling();
const { t } = useLocalization();
const { notify } = useNotifications();

const context = injectOrFail<Context>('context');
const theme = inject<UnwrapNestedRefs<{ mode: ThemeModes }>>('theme');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const management = injectOrFail<ManagementClient>('management');
const rudderstack = injectOrFail<RudderStack>('rudderstack');

const eventCompanySelector = useTemplateRef('eventCompanySelector');
const wizard = useTemplateRef<InstanceType<typeof OtWizard>>('wizard');
const eventWizardDetails = useTemplateRef('eventWizardDetails');
const eventWizardTickets = useTemplateRef('eventWizardTickets');
const eventWizardShop = useTemplateRef<InstanceType<typeof EventWizardShop>>('eventWizardShop');

const eventTickets = ref<Pagination<Ticket<Event<ManagementClient>>> | undefined>();
const showUpsertTicketView = ref<boolean>(false);
const ticketToEditGuid = ref<string | null>(null);
const redirectPath = ref<{ to: 'events' | 'shops', guid: string } | undefined>();
const loading = ref(0);

const location = ref<Location<ManagementClient>>(management.locations.new());

// Shop view
const ticketAvailability = ref<string>('');
const ticketOptions = ref<OtCheckboxOptions>({});
const selectedShop = ref<string>();
const selectedTickets = ref<string[]>();

// Creating new instances of the parent, otherwise typing is an issue. Upon submission the data of the parent is set.
const eventForm = reactive(
    useCreateForm<Event<Location<ManagementClient>>, Location<ManagementClient>>(
        management.locations.new().events.new(),
        management.locations.new().events,
        {
            toUpdateMixinAfterCreation: true,
            notifications: {
                show: false,
            },
            disableLocalChangesDialog: true,
        },
    ),
);

// Using a second form for the validation rules and errors of the event date.
const eventDateForm = reactive(
    useCreateForm<EventDate<Event<ManagementClient>>, Event<ManagementClient>>(
        management.events.new().eventDates.new(),
        management.events.new().eventDates,
        {
            notifications: {
                show: false,
            },
            toUpdateMixinAfterCreation: true,
            disableLocalChangesDialog: true,
        },
    ),
);

provide<WizardProvide>('wizardProvide', {
    showUpsertTicketView,
    ticketToEditGuid,
    location,
    eventForm,
    eventDateForm,
    eventTickets: {
        pagination: eventTickets,
        update: updateEventTickets,
    },
    shopView: {
        selectedShop,
        selectedTickets,
        ticketAvailability,
        ticketOptions,
    },
});

const whitelabelLogo = computed(() => (theme?.mode === 'dark' ? whitelabel.resources.logoDark : whitelabel.resources.logo));
const nextButtonDisabled = computed(() => {
    if (eventWizardShop.value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        return eventWizardShop.value.isNextButtonDisabled;
    }

    return false;
});

// TODO: Implement footer slot in wizard components
const ticketStepFooterRightButtonText = computed(() => {
    if (showUpsertTicketView.value) {
        return {
            text: 'dashboard.events.wizard.steps.tickets.save_add_another.text',
            title: 'dashboard.events.wizard.steps.tickets.save_add_another.title',
        };
    }

    if (eventTickets.value && eventTickets.value.total === 0) {
        return {
            text: 'dashboard.events.wizard.steps.tickets.new.text',
            title: 'dashboard.events.wizard.steps.tickets.new.title',
        };
    }

    return {
        text: 'dashboard.events.wizard.steps.tickets.addanother',
        title: 'dashboard.events.wizard.steps.tickets.addanother',
    };
});

const wizardPrevLabel = computed(() => {
    if (showUpsertTicketView.value) {
        return 'dashboard.common.action.cancel.text';
    }

    return 'dashboard.common.action.back.text';
});

const wizardNextLabel = computed(() => {
    if (wizard.value && wizard.value.currentStepIndex() === 0) {
        return 'dashboard.events.wizard.steps.details.next_label';
    }

    if (wizard.value && wizard.value.currentStepIndex() === 1) {
        if (showUpsertTicketView.value) {
            return 'dashboard.events.wizard.steps.tickets.save.text';
        }

        return 'dashboard.events.wizard.steps.tickets.next_label';
    }

    return 'dashboard.common.action.continue.text';
});

const nextIcon = computed<OtIconTypes | undefined>(() => {
    if ((wizard.value && wizard.value.currentStepIndex() === 1) && showUpsertTicketView.value) {
        return 'check';
    }

    return undefined;
});

onMounted(() => {
    if (!context.isCompanyContext()) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        eventCompanySelector.value?.open();
    }

    wizard.value?.open();
});

function handleCompanySelectorClose() {
    if (!context.isCompanyContext()) {
        wizard.value?.close();
    }
}

// TODO: Implement back when footer slot is back in wizard component
async function handleTicketStepExtraButton() {
    if (showUpsertTicketView.value) {
        try {
            loading.value++;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
            const result = await eventWizardTickets.value?.submitTicket();

            if (!result) {
                return;
            }

            ticketToEditGuid.value = null;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            eventWizardTickets.value?.resetForm();
        } catch (error) {
            handleError(error);
        } finally {
            loading.value--;
        }
    } else {
        ticketToEditGuid.value = null;
        showUpsertTicketView.value = true;
    }
}

async function onEventStepFinish() {
    try {
        loading.value++;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        const result = await eventWizardDetails.value?.submit();

        if (!result) {
            return;
        }

        showUpsertTicketView.value = false;

        // Fetch ticket list
        if (eventForm.model.id) {
            const event = management.events.new();
            event.$raw = eventForm.model.$raw;

            const list = event.tickets.list({ deferInitialization: true });
            await list.initialization;

            updateEventTickets(list);
        }

        rudderstack.track('vue-dashboard wizard step details continue');

        wizard.value?.next();
    } catch (error) {
        handleError(error);
    } finally {
        loading.value--;
    }
}

async function onTicketStepPrevious() {
    if (showUpsertTicketView.value) {
        ticketToEditGuid.value = null;
        showUpsertTicketView.value = false;
        return;
    }

    rudderstack.track('vue-dashboard wizard step tickets back');

    await wizard.value?.previous();
}

async function onTicketStepFinish() {
    if (!showUpsertTicketView.value) {
        rudderstack.track('vue-dashboard wizard step tickets continue');

        wizard.value?.next();
        return;
    }

    try {
        loading.value++;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        const result = await eventWizardTickets.value?.submitTicket();

        if (!result) {
            return;
        }

        showUpsertTicketView.value = false;
        ticketToEditGuid.value = null;
    } catch (error) {
        handleError(error);
    } finally {
        loading.value--;
    }
}

async function onShopStepPrevious() {
    rudderstack.track('vue-dashboard wizard step shop back');

    await wizard.value?.previous();
}

async function onShopStepFinish() {
    try {
        loading.value++;
        // eslint-disable-next-line max-len
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        const result = await eventWizardShop.value?.linkTicketsToShop();

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!result || (!result.result || !result.guid || !result.redirectTo)) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        redirectPath.value = { to: result.redirectTo, guid: result.guid };

        rudderstack.track('vue-dashboard wizard step shop continue');

        wizard.value?.next();
    } catch (error) {
        handleError(error);
    } finally {
        loading.value--;
    }
}

async function onWizardFinish() {
    notify({
        message: t('dashboard.events.wizard.steps.tickets.notifications.event_created'),
        variant: 'success',
    });

    await redirectAfterClose();
}

// Using router.replace to ensure that the user does not navigate back and has to start the wizard process over again.
// Keep the query, to skip triggering the filter update.
function redirectAfterClose() {
    if (!redirectPath.value) {
        return router.replace({
            name: 'events.list',
            query: router.currentRoute.value.query,
        });
    }

    if (redirectPath.value.to === 'shops') {
        return router.replace({
            name: 'shops.home',
            params: { shop: redirectPath.value.guid },
            query: router.currentRoute.value.query,
        });
    }

    if (redirectPath.value.to === 'events') {
        return router.replace({
            name: 'events.home',
            params: { event: redirectPath.value.guid },
            query: router.currentRoute.value.query,
        });
    }

    return router.replace({
        name: 'events.list',
        query: router.currentRoute.value.query,
    });
}

function updateEventTickets(data: Pagination<Ticket<Event<ManagementClient>>>) {
    eventTickets.value = data;
}
</script>

<style lang="scss" scoped>
.event-wizard {
    color: var(--ot-ui-color-foreground-primary);
}
</style>
