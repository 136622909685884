import { OtError } from '@openticket/lib-log';

export class RevisorInvalidResponseError extends OtError {

    constructor() {
        super('RevisorInvalidResponseError', 'dashboard.errors.plugins.revisor.invalid.response', 'Response is not a valid pagination object');

        this.captureStackTrace(RevisorInvalidResponseError);
    }

}
