import { onMounted, ref, type Ref } from 'vue';
import type { Pagination } from '@openticket/lib-crud';
import type { MutationTypes, RevisionModelType, Revisor } from '../services/revisor';
import { injectOrFail } from '../services/util';
import { useGenericErrorHandling } from './useGenericErrorHandling';

export function useRevisions(context: RevisionModelType, id: Ref<string | null | undefined>) {
    const revisor = injectOrFail<Revisor>('revisor');

    const { handleError } = useGenericErrorHandling();

    const revisions = ref<Pagination<MutationTypes>>();

    function updateRevisions() {
        if (!id.value) {
            return;
        }

        try {
            revisions.value = revisor.getRevisions(context, id.value);
            void revisions.value?.initialization;
        } catch (error) {
            handleError(error);
        }
    }

    onMounted(() => {
        updateRevisions();
    });

    return { revisions, updateRevisions };
}
