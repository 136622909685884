<template>
    <div>
        <ErrorView
            v-if="error"
            :label="error.message"
        />

        <OtPaginatedSelectInput
            v-else-if="shopsList"
            v-model="modelValue"
            :pagination="shopsList"
            :data-primary-key="'name'"
            :not-shown-label="$t('dashboard.shops.options_not_shown')"
            :disabled="disabled"
            can-create
            :name-resolver="nameResolver"
            @create="companySelector?.open()"
        />

        <OtSpinner v-else />

        <CompanySelector
            ref="companySelector"
            :create-model-label="$t('dashboard.shops.model_name').toString().toLocaleLowerCase()"
            data-testid="shop-list-company-selector"
            :update-context-on-select="false"
            @selected="createNewShop"
        />

        <ShopCreateModal
            v-if="creating && modalRef.isOpen && shopCreateCompanyGuid"
            :key="id"
            :company-guid="shopCreateCompanyGuid"
            :modal="modalRef"
            @saved="onFormSaved"
        />
    </div>
</template>

<script lang="ts" setup>
import { reactive, ref, useTemplateRef } from 'vue';
import type {
    CompanyPath, ManagementClient, Shop,
} from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import { OtPaginatedSelectInput } from '@openticket/vue-ui';
import { injectOrFail } from '../../../services/util';
import ErrorView from '../../../components/ErrorView.vue';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling } from '../../../composables';
import ShopCreateModal from '../../shops/components/ShopCreateModal.vue';
import { useFormModal } from '../../../composables/forms';
import CompanySelector from '../../../components/CompanySelector.vue';

type SelectOptions = {
    [key: PropertyKey]: object | string | number | boolean;
};

type Props = {
    disabled?: boolean,
}

type Emits = {
    (e: 'error', error: Error): void,
    (e: 'input', value: unknown): void,
}

defineProps<Props>();
const emit = defineEmits<Emits>();

const modelValue = defineModel<keyof SelectOptions |(keyof SelectOptions)[] | undefined>();

const { error, handleError } = useGenericErrorHandling();

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const creating = ref<boolean>(false);
const id = ref<string>('');
const shopCreateCompanyGuid = ref<string | null>(null);

const shopsList = ref<Pagination<Shop<CompanyPath<ManagementClient>>> | Pagination<Shop<ManagementClient>>>();

const companySelector = useTemplateRef('companySelector');
const modalRef = reactive(useFormModal());

function onFormSaved(guid: string | null) {
    emit('input', guid);
    // TODO: Figure out if this is still needed, see CU-86c0x0q3p
    // void paginationSelect.value?.setSelectedLabel(guid, true);

    modelValue.value = guid || undefined;
    void populateList();
    modalRef.close();
}

async function populateList() {
    try {
        shopsList.value = context.isCompanyContext()
            ? context.company.model.shops.list({ deferInitialization: true })
            : management.shops.list({ deferInitialization: true });

        await shopsList.value.initialization;
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }
}

// Run this on created
void populateList();

async function nameResolver(guid: string): Promise<string> {
    try {
        const shopModel = await management.shops.find(guid);
        return shopModel.$data.name;
    } catch (e) {
        void handleError(e);
    }

    return guid;
}

function createNewShop(companyGuid: string) {
    try {
        id.value = Math.random().toString();
        creating.value = true;

        shopCreateCompanyGuid.value = companyGuid;

        modalRef.open();
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }
}
</script>
