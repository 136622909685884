<template>
    <div
        v-if="!error"
        class="module__companies__members__invite"
    >
        <div class="module__companies__members__invite__content">
            <h3 class="ot-ui-text-heading-3">
                {{ $t('dashboard.company.members.invite.title_pending_invites') }}
            </h3>

            <div>
                <OtSpinner v-if="!invitations || invitations.isLoading" />

                <OtDataGrid
                    v-else
                    :pagination="invitations"
                    type="table"
                    :columns="columns"
                    :empty-labels="{
                        subtitle: $t('dashboard.company.members.invites.empty.message'),
                        title: $t('dashboard.company.members.invites.empty.title'),
                    }"
                >
                    <template #column__$data__roles="{ value }">
                        {{ $t(value.includes('Company Admin')
                            ? 'dashboard.members.role.manager'
                            : 'dashboard.members.role.view_only') }}
                    </template>

                    <template #column__$data__created_at="{ value }">
                        {{ $l.dateTimeLong(value) }}
                    </template>

                    <template #actions="{ record }">
                        <OtButton
                            variant="secondary"
                            size="small"
                            icon="close"
                            :title="$t('dashboard.company.members.invite.revoke.title')"
                            @click="revokeInvitation(record)"
                        >
                            {{ $t('dashboard.company.members.invite.revoke.text') }}
                        </OtButton>
                    </template>
                </OtDataGrid>
            </div>
        </div>

        <OtPageFooter>
            <template #left>
                <OtButton
                    variant="secondary"
                    :title="$t('dashboard.common.action.back.title')"
                    @click="back()"
                >
                    <OtIcon
                        type="arrow-left"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.common.action.back.text') }}
                </OtButton>
            </template>
        </OtPageFooter>
    </div>

    <ErrorView
        v-else-if="error"
        :label="error.message"
    />

    <OtSpinner v-else />
</template>

<script setup lang="ts">
import type { AuthClient, Invitee } from '@openticket/lib-auth';
import type { Pagination } from '@openticket/lib-crud';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import type { OtDataGridColumn } from '@openticket/vue-ui';
import { useConfirmationModal, useLocalization, useNotifications } from '@openticket/vue-ui';
import ErrorView from '../../../../components/ErrorView.vue';
import { injectOrFail } from '../../../../services/util';
import { useGenericErrorHandling } from '../../../../composables';

const route = useRoute();
const router = useRouter();
const { error, handleError } = useGenericErrorHandling();
const { t } = useLocalization();

const auth = injectOrFail<AuthClient>('auth');
const notifications = useNotifications();
const dialog = useConfirmationModal();

const invitations = ref<Pagination<Invitee<AuthClient>>>();

const columns: OtDataGridColumn[] = [
    {
        key: [ '$data', 'email' ],
        type: 'email',
        label: 'E-mail',
        tileColumnSize: 'default',
    },
    {
        key: [ '$data', 'roles' ],
        type: 'string',
        label: 'Role',
        tileColumnSize: 'default',
    },
    {
        key: [ '$data', 'created_at' ],
        type: 'string',
        label: 'Invite Sent',
        tileColumnSize: 'default',
    },
];

void (async () => {
    if (auth) {
        try {
            invitations.value = auth.invitees.list({ deferInitialization: true });
            await invitations.value.initialization;
        } catch (e) {
            void handleError(e);
        }
    }
})();

async function revokeInvitation(invitation: Invitee<AuthClient>): Promise<void> {
    if (!auth) {
        // TODO Proper error + logging
        console.error('Auth client not initialized yet!');

        // TODO @Peter save.fail is somewhat weird here...
        notifications.error(t('dashboard.common.notification.save.fail'));

        throw new Error('Auth client not initialized yet!');
    }

    const confirm = await dialog?.confirm({
        title: t('dashboard.common.confirm.permanent.title'),
        message: t('dashboard.common.confirm.permanent.description'),
        variant: 'destructive',
    });

    if (!confirm) {
        return;
    }

    try {
        await auth.invitees.delete(invitation);

        notifications.success(
            t(
                'dashboard.company.members.invite.deletion.success',
                { email: invitation.$data.email },
            ),
        );
    } catch (e) {
        // TODO Logging

        notifications.warn(
            t(
                'dashboard.company.members.invite.deletion.warning',
                { email: invitation.$data.email },
            ),
        );

        throw e;
    }

    if (invitations.value) {
        await invitations.value.loadPage();
    }
}

function back() {
    void router.push({
        name: 'companies.edit.members',
        params: {
            company: route.params.company,
        },
    });
}
</script>

<style lang="scss" scoped>
.module__companies__members__invite {
    &__content {
        margin-bottom: var(--ot-ui-spacing-xl);

        h3 {
            margin-bottom: var(--ot-ui-spacing-md);
        }
    }
}
</style>
