import { OtError } from '@openticket/lib-log';

export class ContextDateError extends OtError {

    constructor() {
        super('ContextDateError', 'dashboard.errors.plugins.context.date', 'Failed to set date context.');

        this.captureStackTrace(ContextDateError);
    }

}
