import type { Mutation, PropChange, RevisionModelType } from '../../services/revisor';

const propsMap: Record<'ticket', Record<string, string[]>> = {
    ticket: {
        money: [ 'min_price' ],
        percentage: [ 'vat_percentage', 'percentage_service_costs_in_ticket' ],
    },
};

export function getChange(mutation: Mutation<'update'>): PropChange {
    return mutation.change.changes[Object.keys(mutation.change.changes)[0]];
}

function addCentsComma(value: number): string {
    const valArr = value.toString().match(/(\d{2})$/g);
    if (valArr && value >= 100) {
        const cents = valArr[0];
        const numbers = value.toString().split('').slice(0, -2).join('');

        return `${numbers},${cents}`;
    }
    return value.toString();
}

export function parseChange(name: string, change: string | number | boolean, type: RevisionModelType): string | number | boolean {
    if (type !== 'ticket' || typeof change !== 'number') {
        return change;
    }

    // Monetary value
    if (propsMap[type].money.includes(name)) {
        return addCentsComma(change);
    }

    // Percentage value
    if (propsMap[type].percentage.includes(name)) {
        return `${change}%`;
    }

    return change;
}

export function formatChangeFromMutation(mutation: Mutation<'update'>): PropChange {
    const model = mutation.model.type;
    const changeName = Object.keys(mutation.change.changes)[0];
    const change = getChange(mutation);

    return {
        old: parseChange(changeName, change.old, model),
        val: parseChange(changeName, change.val, model),
    };
}

// Used for humanizing the keys in a mutation
// ex. vat_percentage => vat percentage
export function humanize(key: string): string {
    return key.split('_').join(' ');
}
