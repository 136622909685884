<template>
    <div
        ref="scrollable"
        class="lazy-load-scroll"
        :class="{'lazy-load-scroll--scrollable': hasNext}"
        @scroll="onScroll"
    >
        <div
            ref="scrollContent"
            :class="{'lazy-load-scroll__content': hasNext}"
        >
            <slot />
        </div>
        <OtSpinner
            class="lazy-load-scroll__spinner"
            :class="{ 'lazy-load-scroll__spinner--transition': loading }"
        />
    </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';

type Props = {
    hasNext?: boolean,
}

type Emits = {
    (e: 'next-page'): void,
}

const props = withDefaults(
    defineProps<Props>(),
    {
        hasNext: false,
    },
);
const emit = defineEmits<Emits>();

const slotObserver = ref<MutationObserver | null>(null);
const scrollable = ref<HTMLDivElement>();
const scrollContent = ref<HTMLDivElement>();
const loading = ref<boolean>(false);

onMounted(() => {
    observeSlotChanges();
    adjustHeight();
});

onBeforeUnmount(() => {
    if (slotObserver.value) {
        slotObserver.value?.disconnect();
    }
});

function onSlotChange() {
    if (scrollable.value instanceof HTMLDivElement) {
        scrollable.value.style.paddingBottom = '0';
    }
    adjustHeight();
    loading.value = false;
}

function observeSlotChanges() {
    if (!scrollContent.value) {
        return;
    }

    slotObserver.value = new MutationObserver(onSlotChange);

    slotObserver.value?.observe(scrollContent.value, {
        childList: true,
        subtree: true,
    });
}

function adjustHeight() {
    if (!scrollable.value) {
        return;
    }
    scrollable.value.style.height = 'initial';

    if (!props.hasNext) {
        return;
    }

    const currentHeight = scrollable.value.scrollHeight;
    scrollable.value.style.height = `${currentHeight}px`;
    scrollable.value.style.paddingBottom = 'var(--ot-ui-spacing-md)';
}

function onScroll(event: Event) {
    const target = event.target as HTMLElement;
    if (target.scrollHeight - target.scrollTop <= target.clientHeight && !loading.value) {
        emit('next-page');
        loading.value = true;
        if (scrollable.value instanceof HTMLDivElement) {
            scrollable.value.style.height = 'initial';
        }
    }
}

</script>

<style lang="scss" scoped>
.lazy-load-scroll {
    scrollbar-width: thin;
    scrollbar-color: var(--ot-ui-color-foreground-secondary) transparent;
    overflow-y: auto;
    padding-right: 10px;

    &--scrollable {
        padding-right: 0;
        overflow-y: scroll;
    }

    &__spinner {
        overflow: hidden;
        height: 0;

        &.spinner {
            padding: 0;
        }

        &--transition {
            height: auto;
            transition: height 200ms ease 200ms, padding 200ms ease 200ms;
            padding-top: var(--ot-ui-spacing-xl);
            padding-bottom: var(--ot-ui-spacing-xl);
        }
    }
}
</style>
