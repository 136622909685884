import { OtError, UnknownError } from '@openticket/lib-log';

export class IntercomUpdateError extends OtError {

    constructor(cause?: Error) {
        super('IntercomUpdateError', 'dashboard.errors.plugins.intercom.update', 'Failed to update intercom settings.');

        if (cause) {
            this.causedBy(new UnknownError(cause));
        }

        this.captureStackTrace(IntercomUpdateError);
    }

}
