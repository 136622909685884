import { OtError } from '@openticket/lib-log';
import type { AxiosError } from 'axios';

export class InvalidShopDataApiError extends OtError {

    readonly url: string;
    readonly axiosError: AxiosError;

    constructor(url: string, axiosError: AxiosError) {
        super('InvalidShopDataApiError', 'dashboard.error.service.shop.parser.invalid.shop.data.api', 'Failed to parse raw shop data. Shop data retrieval failed.');

        this.url = url;
        this.addContext('url', url);

        this.axiosError = axiosError;
        this.addContext('axios_error', axiosError.message);

        // TODO Implement generic lib-log API error

        this.captureStackTrace(InvalidShopDataApiError);
    }

}
