import { OtError } from '@openticket/lib-log';

export class LegacyApiUnmarshalError extends OtError {

    constructor(causedBy: OtError) {
        super('LegacyApiUnmarshalError', 'dashboard.errors.services.legacy.api.unmarshal', 'Failed to unmarshal response');

        this.causedBy(causedBy);

        this.captureStackTrace(LegacyApiUnmarshalError);
    }

}
