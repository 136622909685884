import type { RouteRecordRaw } from 'vue-router';
import TicketsListView from './views/TicketsListView.vue';
import TicketUpdateView from './views/TicketUpdateView.vue';

type NestedRoutes = {
    wizard: RouteRecordRaw[],
}

export function ticketsRoutes(nestedRoutes: NestedRoutes): RouteRecordRaw[] {
    return [
        {
            path: 'tickets',
            meta: {
                title: 'dashboard.document_title.tickets',
                titleFallback: 'Tickets',
                onContextSelect: (id: string | symbol | undefined) => {
                    if (typeof id === 'string') {
                        return {
                            name: 'tickets.list',
                            params: { event: id },
                        };
                    }
                    return null;
                },
            },
            children: [
                {
                    path: '',
                    name: 'tickets.list',
                    component: TicketsListView,
                },
                ...nestedRoutes.wizard,
                {
                    path: ':ticket',
                    name: 'tickets.home',
                    meta: {
                        context: 'ticket',
                        title: 'dashboard.document_title.tickets.ticket',
                        titleFallback: 'Ticket',
                    },
                    redirect: (to) => ({
                        name: 'tickets.edit.details',
                        params: { ...to.params },
                    }),
                    children: [
                        {
                            path: 'edit',
                            name: 'tickets.edit',
                            component: TicketUpdateView,
                            redirect: (to) => ({
                                name: 'tickets.edit.details',
                                params: { ...to.params },
                            }),
                            children: [
                                {
                                    path: 'details',
                                    name: 'tickets.edit.details',
                                },
                                {
                                    path: 'sale-settings',
                                    name: 'tickets.edit.sale_settings',
                                },
                                {
                                    path: 'ticket-design',
                                    name: 'tickets.edit.ticket_design',
                                },
                                {
                                    path: 'visitor-data',
                                    name: 'tickets.edit.visitor_data',
                                },
                                {
                                    path: 'addon-products',
                                    name: 'tickets.edit.addon_products',
                                    meta: {
                                        allowedByFlag: 'app__addon_products__navigation',
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
}
