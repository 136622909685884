<template>
    <OtCard
        v-if="!!goBackToClassicRoute"
        class="back-to-card ot-ui-p[md]!"
        no-padding
    >
        <h3 class="back-to-card-title ot-ui-text-heading-3">
            {{ $t('dashboard.classic.back_to_classic_dashboard_card.title') }}
        </h3>
        <p class="back-to-card-description ot-ui-text-body-sm">
            {{ $t('dashboard.classic.back_to_classic_dashboard_card.description') }}
        </p>
        <a
            :title="$t('dashboard.classic.back_to_classic_dashboard_card.button.title')"
            :aria-label="$t('dashboard.classic.back_to_classic_dashboard_card.button.title')"
            :href="goBackToClassicRoute"
        >
            <OtButton
                icon="carrot-left"
                size="small"
                variant="outline"
                text-align="start"
            >
                {{ $t('dashboard.classic.back_to_classic_dashboard_card.button.text') }}
            </OtButton>
        </a>
    </OtCard>
</template>

<script setup lang="ts">
import { useClassicDashboardRouting } from '../composables';

const { goBackToClassicRoute } = useClassicDashboardRouting();
</script>

<style scoped lang="scss">
.back-to-card {
    display: flex;
    flex-direction: column;
    gap: var(--ot-ui-spacing-sm);
}
</style>
