<template>
    <div class="company-bank-info">
        <div class="company-bank-info__header">
            <h3 class="ot-ui-text-heading-3">
                {{ $t('dashboard.company.edit.bank_info.page_title') }}
            </h3>
            <p class="company-bank-info__header-description ot-ui-text-body-md">
                {{ $t('dashboard.company.edit.bank_info.page_description') }}
            </p>
        </div>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.company.edit.bank_info.form.bank_account_name.label')"
                :description="$t('dashboard.company.edit.bank_info.form.bank_account_name.description')"
                :optional="form.rules.bank_account_name && !form.rules.bank_account_name.includes('required')"
                :error="form.errors.bank_account_name"
            >
                <OtTextInput
                    v-model="form.model.$data.bank_account_name"
                    :invalid="form.errors.bank_account_name?.length > 0"
                    data-testid="company-bank-info-form-bank-account-name"
                    @input="form.errors.bank_account_name = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.company.edit.bank_info.form.bank_account_number.label')"
                :description="$t('dashboard.company.edit.bank_info.form.bank_account_number.description')"
                :optional="form.rules.bank_account_number && !form.rules.bank_account_number?.includes('optional')"
                :error="form.errors.bank_account_number"
            >
                <OtTextInput
                    v-model="form.model.$data.bank_account_number"
                    :invalid="form.errors.bank_account_number?.length > 0"
                    :placeholder="$t('dashboard.company.edit.bank_info.form.bank_account_number.placeholder')"
                    data-testid="company-bank-info-form-bank-account-number"
                    @input="form.errors.bank_account_number = []"
                />
            </OtInputField>

            <OtInputField
                :label="$t('dashboard.company.edit.bank_info.form.bank_account_bic.label')"
                :description="$t('dashboard.company.edit.bank_info.form.bank_account_bic.description')"
                :optional="form.rules.bank_account_bic && !form.rules.bank_account_bic.includes('required')"
                :error="form.errors.bank_account_bic"
            >
                <OtTextInput
                    v-model="form.model.$data.bank_account_bic"
                    :invalid="form.errors.bank_account_bic?.length > 0"
                    data-testid="company-bank-info-form-bank-account-bic"
                    @input="form.errors.bank_account_bic = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtSeparator />

        <div class="company-bank-info__header">
            <h3 class="ot-ui-text-heading-3">
                {{ $t('dashboard.company.edit.bank_info.second_title') }}
            </h3>
            <span class="ot-ui-text-body-md">{{ $t('dashboard.company.edit.bank_info.second_description') }}</span>
        </div>

        <OtFormRow>
            <!-- TODO: Update error to have correct payout threshold label -->
            <OtInputField
                :label="$t('dashboard.company.edit.bank_info.form.payout_frequency.label')"
                :description="$t('dashboard.company.edit.bank_info.form.payout_frequency.description')"
                :optional="form.rules.payout_frequency && !form.rules.payout_frequency.includes('required')"
                :error="[...(form.errors.payout_frequency || []), ...(form.errors.payout_threshold || [])]"
            >
                <OtSelectInput
                    v-model="form.model.$data.payout_frequency"
                    :disabled="form.model.$data.payout_frequency === 'disabled_by_admin' && !roles.isSuperAdmin"
                    :invalid="form.errors.payout_frequency?.length > 0"
                    :options="payoutOptions"
                    data-testid="company-bank-info-form-payout-frequency"
                    @input="form.errors.payout_frequency = []"
                />

                <OtMoneyInput
                    v-if="form.model.$data.payout_frequency === 'threshold'"
                    v-model="form.model.$data.payout_threshold"
                    :invalid="form.errors.currency?.length > 0"
                    :currency="form.model.$data.currency"
                    data-testid="company-bank-info-form-payout-threshold"
                    @input="form.errors.payout_threshold = []"
                />
            </OtInputField>

            <OtInputField
                :label="$t('dashboard.company.edit.bank_info.form.payout_reference.label')"
                :description="$t('dashboard.company.edit.bank_info.form.payout_reference.description')"
                :optional="form.rules.payout_reference && !form.rules.payout_reference.includes('required')"
                :error="form.errors.payout_reference"
            >
                <OtTextInput
                    v-model="form.model.$data.payout_reference"
                    :invalid="form.errors.payout_reference?.length > 0"
                    data-testid="company-bank-info-form-payout-reference"
                    @input="form.errors.payout_reference = []"
                />
            </OtInputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import { computed, type UnwrapNestedRefs } from 'vue';
import type { AuthClient, Company } from '@openticket/lib-auth';
import { useLocalization } from '@openticket/vue-ui';
import { injectOrFail } from '../../../../services/util';
import type { Roles } from '../../../../plugins/types';
import type { ModelFormComposableResult } from '../../../../composables/forms';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Company<AuthClient>, Company<AuthClient>>>
}

const props = defineProps<Props>();

const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');

const { t } = useLocalization();

const payoutOptions = computed<Record<string, string>>(() => {
    const options: Record<string, string> = {
        weekly: t('dashboard.company.edit.bank_info.form.payout_frequency.options.weekly'),
        monthly: t('dashboard.company.edit.bank_info.form.payout_frequency.options.monthly'),
    };

    // Threshold can only be chosen by super admins.
    if (props.form.model.$data.payout_frequency === 'threshold' || roles.isSuperAdmin) {
        options.threshold = t('dashboard.company.edit.bank_info.form.payout_frequency.options.threshold');
    }

    if (props.form.model.$data.payout_frequency === 'disabled_by_admin' || roles.isSuperAdmin) {
        options.disabled_by_admin = t('dashboard.company.edit.bank_info.form.payout_frequency.options.disabled_by_admin');
    }

    return options;
});
</script>

<style lang="scss" scoped>
.company-bank-info {
    &__header {
        margin-bottom: var(--ot-ui-spacing-md);
        &-description {
            color: var(--ot-ui-color-foreground-secondary)
        }
    }
}
</style>
