<template>
    <div class="event-wizard-tickets__upsert">
        <div class="event-wizard-tickets__upsert__docs">
            <OtDocsSection
                :label="$t('dashboard.common.read_docs')"
                variant="tickets"
                :url="$whitelabel.dashboard.docs_urls?.tickets?.new"
            >
                <h2 class="ot-ui-text-heading-2">
                    {{ $t(`dashboard.events.wizard.steps.tickets.upsert.title_${
                        wizardProvide.ticketToEditGuid.value ? 'update' : 'insert'
                    }`) }}
                </h2>
                <span class="ot-ui-text-body-lg">{{ $t('dashboard.events.wizard.steps.tickets.upsert.description') }}</span>
            </OtDocsSection>
        </div>

        <OtSpinner
            v-if="!!loading"
            class="event-wizard-tickets__upsert-spinner"
        />

        <FormWrapper
            v-else-if="!error"
            class="event-wizard-tickets__upsert__form"
            :form="ticketForm"
        >
            <OtFormRow>
                <OtInputField
                    data-testid="event-wizard-ticket-form-name"
                    :label="$t('dashboard.events.wizard.steps.tickets.upsert.form.ticket_name.label')"
                    :description="$t('dashboard.events.wizard.steps.tickets.upsert.form.ticket_name.description')"
                    :error="ticketForm.errors.name"
                >
                    <OtTextInput
                        v-model="ticketForm.model.$data.name"
                        @input="ticketForm.errors.name = []"
                    />
                </OtInputField>
            </OtFormRow>

            <OtFormRow>
                <OtInputField
                    data-testid="event-wizard-ticket-form-available-stock"
                    :label="$t('dashboard.events.wizard.steps.tickets.upsert.form.capacity.label')"
                    :description="$t('dashboard.events.wizard.steps.tickets.upsert.form.capacity.description')"
                    :error="ticketForm.errors.available_stock"
                >
                    <InputFloatUnlimited
                        v-model="ticketForm.model.$data.available_stock"
                        @input="ticketForm.errors.available_stock = []"
                    />
                </OtInputField>
            </OtFormRow>

            <OtFormRow>
                <InputPriceVat
                    v-model:price="ticketForm.model.$data.min_price"
                    v-model:vat="ticketForm.model.$data.vat_percentage"
                    v-model:price-errors="ticketForm.errors.min_price"
                    v-model:vat-errors="ticketForm.errors.vat_percentage"
                    data-testid="event-wizard-ticket-form-min-price"
                    :currency="wizardProvide.eventForm.model.$raw.currency"
                    :price-rules="ticketForm.rules.min_price"
                    :translations="priceInputTranslations"
                />
            </OtFormRow>
        </FormWrapper>

        <ErrorView
            v-else-if="error"
            :label="error.message"
        />
    </div>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue';
import type { ManagementClient } from '@openticket/lib-management';
import { Create, Update } from '@openticket/lib-crud';
import { useLocalization } from '@openticket/vue-ui';
import FormWrapper from '../../../components/form/FormWrapper.vue';
import type { WizardProvide } from '../types';
import ErrorView from '../../../components/ErrorView.vue';
import { injectOrFail } from '../../../services/util';
import InputFloatUnlimited from '../../../components/InputFloatUnlimited.vue';
import { useModelForm } from '../../../composables/forms/useModelForm';
import InputPriceVat from '../../../components/form/InputPriceVat.vue';
import { useGenericErrorHandling } from '../../../composables';

const { error, handleError } = useGenericErrorHandling();

const management = injectOrFail<ManagementClient>('management');
const wizardProvide = injectOrFail<WizardProvide>('wizardProvide');

const loading = ref(0);
const { t } = useLocalization();

// Use a copy of the parent to fix the relation url.
const event = management.events.new();
event.$raw = wizardProvide.eventForm.model.$raw;

// When the ticket guid is set, the form should be an update form.
const ticketForm = reactive(
    useModelForm(
        event.tickets.new(),
        event.tickets,
        wizardProvide.ticketToEditGuid.value ? Update : Create,
        {
            disableLocalChangesDialog: true,
        },
    ),
);

const priceInputTranslations = {
    price: {
        label: t('dashboard.tickets.details.inputs.ticket_price.label'),
        description: t('dashboard.tickets.details.inputs.ticket_price.description'),
    },
};

void (async () => {
    if (!wizardProvide.ticketToEditGuid.value) {
        return;
    }

    try {
        loading.value++;
        const model = (await event.tickets.find(wizardProvide.ticketToEditGuid.value));
        ticketForm.init(model, event.tickets);
    } catch (e) {
        handleError(e);
    } finally {
        loading.value--;
    }
})();

async function submit(): Promise<boolean> {
    try {
        const { success } = await ticketForm.submit();

        if (!success) {
            return false;
        }

        // Link date to ticket
        const ticketWithoutEvent = management.tickets.$factory(ticketForm.model.$raw);
        const ticketDate = ticketWithoutEvent.eventdates.$factory(wizardProvide.eventDateForm.model.$raw);
        await ticketWithoutEvent.eventdates.link(ticketDate, {});
    } catch (e) {
        void handleError(e);
        return false;
    }

    return true;
}

function resetForm() {
    // Reset name but keep the previously set values
    const keepValues = {
        vat_percentage: ticketForm.model.$data.vat_percentage,
        available_stock: ticketForm.model.$data.available_stock,
    };

    ticketForm.init(
        event.tickets.new(),
        event.tickets,
    );

    ticketForm.model.$data.vat_percentage = keepValues.vat_percentage;
    ticketForm.model.$data.available_stock = keepValues.available_stock;
}

defineExpose({
    submit,
    resetForm,
});
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins.scss";

.event-wizard-tickets__upsert {
    &__docs {
        padding: var(--ot-ui-spacing-xl) 0;
        border-bottom: 1px solid var(--ot-ui-color-accent-tertiary);
        margin-bottom: var(--ot-ui-spacing-xl);

        h1 {
            margin-bottom: var(--ot-ui-spacing-xs);
        }

        span {
            color: var(--ot-ui-color-foreground-secondary);
        }
    }

    &-spinner {
        height: 20rem
    }

    &__form {
        &__availability {
            border-top: 1px solid var(--ot-ui-color-accent-tertiary);
            margin-top: var(--ot-ui-spacing-xl);
            padding-top: var(--ot-ui-spacing-xl);

            span {
                color: var(--ot-ui-color-foreground-secondary);
            }

            .fancy-radio {
                margin-top: var(--ot-ui-spacing-lg);
            }
        }
    }
}
</style>
