<template>
    <div class="ticket-wizard-shop">
        <div class="ticket-wizard-shop__header">
            <OtDocsSection
                :label="$t('dashboard.common.read_docs')"
            >
                <h2 class="ot-ui-text-heading-2">
                    {{ $t('dashboard.tickets.wizard.steps.shop.docs.title') }}
                </h2>
                <span class="ot-ui-text-body-lg">{{ $t('dashboard.tickets.wizard.steps.shop.docs.description') }}</span>
            </OtDocsSection>
        </div>
        <div class="ticket-wizard-shop__content" />
    </div>
</template>

<style scoped lang="scss">
.ticket-wizard-shop {
    &__header {
        padding: var(--ot-ui-spacing-xl) 0;
        border-bottom: 1px solid var(--ot-ui-color-accent-tertiary);
        margin-bottom: var(--ot-ui-spacing-xl);

        h1 {
            margin-bottom: var(--ot-ui-spacing-xs);
        }

        span {
            color: var(--ot-ui-color-foreground-secondary);
        }
    }

    &__content {
        margin: var(--ot-ui-spacing-2xl) 0;
    }
}
</style>
