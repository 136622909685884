<template>
    <div
        v-if="company"
        class="companies-update-wrapper"
    >
        <CompanyUpdateView :company="company" />
    </div>
    <OtSpinner v-else />
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import type { AuthClient, Company } from '@openticket/lib-auth';
import {
    ref, watch, type UnwrapNestedRefs,
} from 'vue';
import { injectOrFail } from '../../../services/util';
import { useGenericErrorHandling } from '../../../composables';
import type { Context } from '../../../services/context';
import type { Roles } from '../../../plugins/types';
import CompanyUpdateView from '../views/CompanyUpdateView.vue';

const router = useRouter();
const route = useRoute();
const { handleError } = useGenericErrorHandling();

const auth = injectOrFail<AuthClient>('auth');
const context = injectOrFail<Context>('context');
const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');

const company = ref<Company<AuthClient>>();

// Updating the company should only happen after the route change, as view-only users get redirected to the members page.
// Fetching the company directly after the context switch and thus before the router change,
// the context service redirects the user unintended back the the general edit page.
watch(() => route.params.company, () => {
    if (context.isCompanyContext()) {
        void fetchCompany(context.company.id);
    }
});

const fetchCompany = async (guid = route.params.company) => {
    if (!guid || guid === 'new') {
        console.error('Company edit error: Company ID missing or invalid');

        // TODO translate and log error
        void router.push({ name: 'error', query: { reason: 'Company ID missing or invalid' } });

        return;
    }

    // DD-DASHBOARD-2716 - Only managers (company admins) and admins can view and edit company settings
    if (!roles.isManager) {
        void router.push({
            name: 'companies.edit.members',
            params: {
                company: guid,
            },
        });
    }

    try {
        company.value = await auth.companies.find(guid);
    } catch (e) {
        void handleError(e);
    }
};

void fetchCompany();

</script>
