<template>
    <div class="addon-product-form-fields">
        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.addon_products.product_group.inputs.name.label')"
                :description="$t('dashboard.addon_products.product_group.inputs.name.description')"
                :required="form.rules.name?.includes('required')"
                :error="form.errors.name"
            >
                <OtTextInput
                    v-model="form.model.$data.name"
                    data-testid="addon-product-form-fields-name"
                    @input="form.errors.name = []"
                />
            </OtInputField>
        </OtFormRow>
        <OtFormRow v-if="isCreate">
            <OtInputField
                :label="$t('dashboard.addon_products.link_product.input_label')"
                :description="$t('dashboard.addon_products.link_product.input_description')"
            >
                <LinkUnlinkPicker
                    ref="linkAddonProductFormRef"
                    class="addon-product-form-fields__link-product"
                    :items-label="$t('dashboard.addon_products.link_product.items_label')"
                    :pagination-relation="context.event.model.products"
                />
            </OtInputField>
        </OtFormRow>
        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.addon_products.product_group.inputs.preset.label')"
                :description="$t('dashboard.addon_products.product_group.inputs.preset.description')"
            >
                <OtSelectInput
                    v-model="preset"
                    :options="presets"
                    @input="onPreset"
                >
                    <template #singleLabel="{ option }">
                        {{ option.title }}
                    </template>
                    <template #option="{ option }">
                        <div class="addon-product-form-fields__preset">
                            <div class="addon-product-form-fields__preset__image">
                                <img
                                    :alt="option.title"
                                    :src="option.image"
                                >
                            </div>
                            <div class="addon-product-form-fields__preset__text">
                                <div class="addon-product-form-fields__preset__title ot-ui-text-body-md-strong">
                                    {{ option.title }}
                                </div>
                                <div class="addon-product-form-fields__preset__description ot-ui-text-body-sm">
                                    {{ option.description }}
                                </div>
                            </div>
                        </div>
                    </template>
                </OtSelectInput>
            </OtInputField>
        </OtFormRow>
        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.addon_products.product_group.inputs.min_bound.label')"
                :description="$t('dashboard.addon_products.product_group.inputs.min_bound.description')"
                :required="form.rules.min_bound?.includes('required')"
                :error="form.errors.min_bound"
            >
                <OtFloatInput
                    v-model="form.model.$data.min_bound"
                    :min="0"
                    class="addon-product-form-fields__integer"
                    data-testid="addon-product-form-fields-min-bound"
                    @input="setPresetCustomAndResetErrors('min_bound')"
                />
            </OtInputField>
        </OtFormRow>
        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.addon_products.product_group.inputs.max_bound.label')"
                :description="$t('dashboard.addon_products.product_group.inputs.max_bound.description')"
                :required="form.rules.max_bound?.includes('required')"
                :error="form.errors.max_bound"
            >
                <InputFloatUnlimited
                    ref="maxBoundInput"
                    v-model="form.model.$data.max_bound"
                    :min="0"
                    data-testid="addon-product-form-fields-max-bound"
                    size="small"
                    type="integer"
                    @input="setPresetCustomAndResetErrors('max_bound')"
                />
            </OtInputField>
        </OtFormRow>
        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.addon_products.product_group.inputs.uniqueness.label')"
                :description="$t('dashboard.addon_products.product_group.inputs.uniqueness.description')"
                :required="form.rules.uniqueness?.includes('required')"
                :error="form.errors.uniqueness"
            >
                <InputFloatUnlimited
                    ref="uniquenessInput"
                    v-model="form.model.$data.uniqueness"
                    :min="0"
                    data-testid="addon-product-form-fields-uniqueness"
                    size="small"
                    type="integer"
                    @input="setPresetCustomAndResetErrors('uniqueness')"
                />
            </OtInputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import {
    computed, ref, watch, type UnwrapNestedRefs, useTemplateRef,
} from 'vue';
import type { ManagementClient, ProductGroup } from '@openticket/lib-management';
import { useLocalization } from '@openticket/vue-ui';
import type { Context } from '../../../../services/context';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { injectOrFail } from '../../../../services/util';
import SelectMultipleSvg from '../../../../assets/images/product_group_can_select_multiple.svg';
import SelectOneSvg from '../../../../assets/images/product_group_can_select_one.svg';
import ForcedSelectionSvg from '../../../../assets/images/product_group_forced_selection.svg';
import CustomSelectionSvg from '../../../../assets/images/product_group_custom_selection.svg';
import InputFloatUnlimited from '../../../../components/InputFloatUnlimited.vue';
import LinkUnlinkPicker from '../../../../components/modal/link-unlink/LinkUnlinkPicker.vue';

type Preset = {
    title: string;
    description: string;
    image: string;
}

type Presets = 'select_one' | 'select_multiple' | 'forced_selection' | 'custom_selection';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<ProductGroup<ManagementClient>, ManagementClient>>;
    isCreate?: boolean;
    nrOfProductsInGroup?: number;
}

const props = defineProps<Props>();

const context = injectOrFail<Context>('context');
const uniquenessInput = useTemplateRef<InstanceType<typeof InputFloatUnlimited>>('uniquenessInput');
const maxBoundInput = useTemplateRef<InstanceType<typeof InputFloatUnlimited>>('maxBoundInput');

const { t } = useLocalization();

const preset = ref<string>('custom_selection');
const linkAddonProductFormRef = ref<InstanceType<typeof LinkUnlinkPicker>>();
const presets = ref<Record<Presets, Preset>>({
    select_one: {
        title: t('dashboard.addon_products.product_group.presets.select_one.title'),
        description: t('dashboard.addon_products.product_group.presets.select_one.description'),
        image: SelectOneSvg,
    },
    select_multiple: {
        title: t('dashboard.addon_products.product_group.presets.select_multiple.title'),
        description: t('dashboard.addon_products.product_group.presets.select_multiple.description'),
        image: SelectMultipleSvg,
    },
    forced_selection: {
        title: t('dashboard.addon_products.product_group.presets.forced_selection.title'),
        description: t('dashboard.addon_products.product_group.presets.forced_selection.description'),
        image: ForcedSelectionSvg,
    },
    custom_selection: {
        title: t('dashboard.addon_products.product_group.presets.custom_selection.title'),
        description: t('dashboard.addon_products.product_group.presets.custom_selection.description'),
        image: CustomSelectionSvg,
    },
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
const toLinkProducts = computed<string[] | undefined>(() => linkAddonProductFormRef.value?.diff.toLink);

watch(() => toLinkProducts.value, () => {
    if (toLinkProducts.value?.length) {
        props.form.hasLocalChanges = true;
    }
});

if (!context.isTicketContext()) {
    // TODO Proper error handling and logging
    throw Error('Invalid context');
}

function setPresetCustomAndResetErrors(field: keyof typeof props.form.model.$data) {
    props.form.errors[field] = [];
    preset.value = 'custom_selection';
}

function onPreset(presetValue: Presets) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    maxBoundInput.value?.toggleUnlimited(false);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    uniquenessInput.value?.toggleUnlimited(false);

    switch (presetValue) {
        case 'select_one':
            props.form.model.$data.min_bound = 0;
            props.form.model.$data.max_bound = 1;
            props.form.model.$data.uniqueness = 1;
            break;
        case 'select_multiple':
            props.form.model.$data.min_bound = 0;
            props.form.model.$data.max_bound = 0;
            props.form.model.$data.uniqueness = 1;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            maxBoundInput.value?.toggleUnlimited(true);
            break;
        case 'forced_selection':
            props.form.model.$data.min_bound = props.nrOfProductsInGroup || 1;
            props.form.model.$data.max_bound = props.nrOfProductsInGroup || 1;
            props.form.model.$data.uniqueness = 1;
            break;
        default:
            props.form.model.$data.min_bound = 0;
            props.form.model.$data.max_bound = 0;
            props.form.model.$data.uniqueness = 0;
            break;
    }
}

defineExpose({
    addonProductGuids: () => toLinkProducts.value,
});
</script>

<style scoped lang="scss">
.addon-product-form-fields {
    display: flex;
    flex-direction: column;

    &__integer {
        max-width: 10rem;
    }

    &__link-product {
        flex-grow: 1;
    }

    &__preset {
        display: flex;
        gap: var(--ot-ui-spacing-md);

        &__text {
            text-align: left;
        }

        &__description {
            color: var(--ot-ui-color-foreground-secondary);
        }

        &__image {
            width: 2.5rem;
            display: flex;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
}
</style>
