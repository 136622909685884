import { OtError } from '@openticket/lib-log';

export class ContextOrderError extends OtError {

    constructor() {
        super('ContextOrderError', 'dashboard.errors.plugins.context.order', 'Failed to set order context.');

        this.captureStackTrace(ContextOrderError);
    }

}
