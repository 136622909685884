<template>
    <div class="drag-and-drop">
        <LazyLoadScroll
            :has-next="hasNext"
            @next-page="emit('paginate:draggables')"
        >
            <VueDraggable
                class="drag-and-drop__draggable"
                data-testid="drag-and-drop-draggable"
                handle="[data-drag-handle]"
                :list="draggables"
                item-key="guid"
                :group="{ name: 'wrapper', put: disallowChildrenInRoot ? 'wrapper' : true }"
                @change="onChange"
            >
                <template #item="{ element: draggable }">
                    <DraggableItem
                        v-if="draggable.type === 'item'"
                        :icon="icon"
                        :item="draggable"
                        :is-draggable="draggables.length > 1"
                        class="drag-and-drop__draggable-item"
                        @remove="emit('remove:item', draggable.guid)"
                    />
                    <DraggableGroup
                        v-else-if="draggable.type === 'group'"
                        :draggable-group-key="{ name: 'child', put: 'child' }"
                        :group="draggable"
                        :icon="icon"
                        :is-child-draggable="isChildDraggable(draggable)"
                        :is-draggable="draggables.length > 1"
                        :is-removable="true"
                        :item-name="itemName"
                        :group-name="groupName"
                        :drag-to-footer-text="dragToFooterText"
                        @link="(mutation) => emit('link', mutation)"
                        @unlink="(mutation) => emit('unlink', mutation)"
                        @move:group="(mutation) => emit('move:group', mutation)"
                        @move:item="(mutation) => emit('move:item', mutation)"
                        @paginate:group="(groupId) => emit('paginate:group', groupId)"
                        @update:group="(groupId) => emit('update:group', groupId)"
                        @remove:item="(itemId, groupId) => emit('remove:item', itemId, groupId)"
                        @remove:group="(groupId) => emit('remove:group', groupId)"
                        @open-add:item="(groupId) => emit('open-add:item', groupId)"
                        @open-edit:group="(group) => emit('open-edit:group', group)"
                    >
                        <template #sub-text>
                            <slot
                                name="sub-text"
                                :group="draggable"
                            />
                        </template>
                        <template #footer>
                            <slot
                                name="footer"
                                :group="draggable"
                            />
                        </template>
                    </DraggableGroup>
                </template>
            </VueDraggable>
        </LazyLoadScroll>
    </div>
</template>

<script lang="ts" setup generic="T extends DragGroup">
import VueDraggable from 'vuedraggable';
import type { OtIconTypes } from '@openticket/vue-ui';
import type { DragGroup, DragItem } from './types';
import DraggableGroup from './DraggableGroup.vue';
import useDraggableInterpreter from '../../composables/draggable-interpreter/useDraggableInterpreter';
import DraggableItem from './DraggableItem.vue';
import LazyLoadScroll from '../lazy-load-scroll/LazyLoadScroll.vue';
import type { LinkEvent, SortEvent } from '../../composables/draggable-interpreter/types';

type Props = {
    disallowChildrenInRoot?: boolean,
    draggables: (T | DragItem)[],
    dragToFooterText?: string,
    icon?: OtIconTypes,
    itemName?: string,
    groupName?: string,
    hasNext?: boolean,
}

type Emits = {
    (e: 'update:group', value: DragGroup): void,
    (e: 'remove:group', groupId: string): void,
    (e: 'remove:item', itemId: string, groupId?: string): void,
    (e: 'open-edit:group', group: DragGroup): void,
    (e: 'open-add:item', groupId: string): void,
    (e: 'paginate:group', groupId: string): void,
    (e: 'paginate:draggables'): void,
    (e: 'move:group', mutation: SortEvent): void,
    (e: 'move:item', mutation: SortEvent): void,
    (e: 'link', mutation: LinkEvent): void,
    (e: 'unlink', mutation: LinkEvent): void,
}

const props = withDefaults(
    defineProps<Props>(),
    {
        icon: undefined,
        disallowChildrenInRoot: false,
        dragToFooterText: undefined,
        hasNext: false,
        itemName: 'item',
        groupName: 'Group',
    },
);

const emit = defineEmits<Emits>();

const { onChange } = useDraggableInterpreter(props.draggables, emit);

function isChildDraggable(group: DragGroup | DragItem): boolean {
    if ((!props.disallowChildrenInRoot) || (group.type === 'group' && group.items.length > 1)) {
        return true;
    }

    return props.draggables.length > 1;
}

</script>

<style lang="scss" scoped>
.drag-and-drop {
    &__draggable {
        display: flex;
        flex-direction: column;
        gap: var(--ot-ui-spacing-md);

        :deep(> .sortable-ghost.drag-and-drop__draggable-item), :deep(> .sortable-ghost.draggable-item) {
            border-radius: var(--ot-ui-input-radius);
            padding:  var(--ot-ui-spacing-md) var(--ot-ui-spacing-lg);
            border: 1px solid var(--ot-ui-color-accent-secondary);
        }
    }

    &__draggable-item {
        border-radius: var(--ot-ui-input-radius);
        padding:  var(--ot-ui-spacing-md) var(--ot-ui-spacing-lg);
        border: 1px solid var(--ot-ui-color-accent-secondary);
    }

    :deep(.sortable-ghost) {
        opacity: 0.5;
        cursor: grabbing;
    }

    &:deep(.sortable-ghost .ot-button) {
        opacity: 0;
    }
}
</style>
