<template>
    <div
        class="draggable-group"
        data-testid="draggable-group"
    >
        <div class="draggable-group__content">
            <div class="draggable-group__header">
                <div class="draggable-group__title">
                    <h3
                        class="draggable-group__title__text ot-ui-text-heading-3"
                        data-testid="draggable-group-name"
                    >
                        <OtIcon
                            v-if="group.concept"
                            type="file"
                            size="small"
                        />
                        {{ title }}
                    </h3>
                    <slot name="sub-text" />
                </div>

                <OtButton
                    class="draggable-group__edit"
                    data-testid="draggable-group-edit-group"
                    icon="edit"
                    size="tiny"
                    variant="outline"
                    @click="emit('open-edit:group', group)"
                >
                    <slot name="edit">
                        {{ $t('dashboard.components.draggable_group.edit_item', { item: groupName.toLowerCase() }) }}
                    </slot>
                </OtButton>
                <OtButton
                    class="draggable-group__add-item"
                    data-testid="draggable-group-add-item"
                    icon="plus"
                    size="tiny"
                    variant="outline"
                    @click="emit('open-add:item', group.guid)"
                >
                    <slot name="add">
                        {{ $t('dashboard.components.draggable_group.add_item', { item: itemName }) }}
                    </slot>
                </OtButton>
                <OtButton
                    v-if="isDraggable"
                    class="draggable-group__header__sort"
                    data-drag-handle
                    :data-testid="`draggable-group-drag-handle-${group.guid}`"
                    icon="sort"
                    size="tiny"
                    variant="inline"
                />
                <OtButton
                    v-if="isRemovable"
                    class="draggable-group__header__close"
                    data-testid="draggable-group-remove"
                    icon="close"
                    size="tiny"
                    variant="inline"
                    @click="emit('remove:group', group.guid)"
                />
            </div>
            <LazyLoadScroll
                :has-next="group.hasNext"
                @next-page="emit('paginate:group', group.guid)"
            >
                <VueDraggable
                    v-if="group.items"
                    :list="group.items"
                    handle="[data-drag-handle]"
                    item-key="guid"
                    :group="draggableGroupKey"
                    class="draggable-group__draggable"
                    @change="onChange"
                >
                    <template #item="{ element: item }">
                        <DraggableItem
                            :icon="icon"
                            :item="item"
                            :is-draggable="isChildDraggable"
                            data-testid="draggable-group-item"
                            @remove="emit('remove:item', item.guid, group.guid)"
                        />
                    </template>
                    <template #footer>
                        <div
                            v-if="dragToFooterText && isChildDraggable"
                            class="draggable-group__content__footer"
                        >
                            {{ dragToFooterText }}
                        </div>
                    </template>
                </VueDraggable>
                <OtSpinner
                    v-if="loading"
                    class="draggable-group__spinner"
                    :class="{ 'draggable-group__spinner--transition': loading }"
                />
            </LazyLoadScroll>
        </div>
        <slot name="footer" />
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import VueDraggable from 'vuedraggable';
import type { OtIconTypes } from '@openticket/vue-ui';
import type { DragGroup } from './types';
import DraggableItem from './DraggableItem.vue';
import LazyLoadScroll from '../lazy-load-scroll/LazyLoadScroll.vue';
import type { LinkEvent, SortEvent } from '../../composables/draggable-interpreter/types';
import useDraggableInterpreter from '../../composables/draggable-interpreter/useDraggableInterpreter';

type Props = {
    draggableGroupKey: object | string,
    dragToFooterText?: string,
    group: DragGroup,
    icon?: OtIconTypes,
    isChildDraggable?: boolean,
    isDraggable?: boolean,
    isRemovable?: boolean,
    itemName?: string,
    groupName?: string,
}

type Emits = {
    (e: 'update:group', value: DragGroup): void,
    (e: 'remove:group', groupId: string): void,
    (e: 'remove:item', itemId: string, groupId: string): void,
    (e: 'open-edit:group', group: DragGroup): void,
    (e: 'open-add:item', groupId: string): void,
    (e: 'paginate:group', groupId: string): void,
    (e: 'move:group', mutation: SortEvent): void,
    (e: 'move:item', mutation: SortEvent): void,
    (e: 'link', mutation: LinkEvent): void,
    (e: 'unlink', mutation: LinkEvent): void,
}

const props = withDefaults(
    defineProps<Props>(),
    {
        dragToFooterText: undefined,
        isChildDraggable: false,
        isDraggable: false,
        isRemovable: false,
        icon: undefined,
        itemName: 'item',
        groupName: 'Group',
    },
);
const emit = defineEmits<Emits>();

const loading = ref<boolean>(false);

const title = computed<string>(() => (!props.group.name ? props.groupName : props.group.name));
const {
    onChange,
} = useDraggableInterpreter(props.group.items, emit, props.group);
</script>

<style lang="scss" scoped>
.draggable-group {
    &__content {
        border-radius: var(--ot-ui-input-radius);
        border: 1px solid var(--ot-ui-color-accent-secondary);
        overflow: hidden;
        background-color: var(--ot-ui-color-background-secondary);

        &__footer {
            padding: var(--ot-ui-spacing-md) 0;
            color: var(--ot-ui-color-accent-primary);
            order: 9999;
        }
    }

    &__spinner {
        padding-top: var(--ot-ui-spacing-xl) !important;
        padding-bottom: var(--ot-ui-spacing-xl) !important;
        overflow: hidden;
        height: 0;
        transition: height 0.3s ease, padding 0.3s ease;

        &--transition {
            height: auto;
        }
    }

    &__title {
        flex: 1;
        display: flex;
        align-items: center;
        gap: var(--ot-ui-spacing-xs);

        &__text {
            flex: initial;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__edit {
        margin-left: var(--ot-ui-spacing-md);
    }

    &__header {
        background-color: var(--ot-ui-color-background-primary);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: var(--ot-ui-spacing-lg);
        flex-wrap: nowrap;
        text-wrap: nowrap;

        > *:not(:first-child) {
            margin-left: var(--ot-ui-spacing-xs);
            color: var(--ot-ui-color-foreground-secondary);
        }

        &__close.ot-ui-button, &__sort.ot-ui-button {
            margin-left: var(--ot-ui-spacing-md);
        }

        &__close.ot-ui-button:hover {
            color: var(--ot-ui-color-intent-warning-primary);
        }

        &__sort.ot-ui-button:hover {
            color: var(--ot-ui-color-brand);
        }
    }

    &__draggable {
        padding-left: var(--ot-ui-spacing-lg);
        padding-right: calc(var(--ot-ui-spacing-lg) - 10px);
        display: flex;
        flex-direction: column;
    }

    &__footer {
        color: var(--ot-ui-color-accent-primary);
        margin-top: var(--ot-ui-spacing-md);
        padding-left: var(--ot-ui-spacing-lg);
        padding-right: var(--ot-ui-spacing-lg);
        padding-bottom: var(--ot-ui-spacing-md);
    }

    &:deep(.sortable-ghost) {
        border-radius: 0;
        padding: var(--ot-ui-spacing-md) 0;
        border: none;
    }
}
</style>
