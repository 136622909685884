<template>
    <div
        v-if="tabIsSelected"
        class="vertical-tabs-item"
    >
        <slot />
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

type Props = {
    route: { name: string, params?: Record<string, string> },
    label: string,
    disabled?: boolean,
    selected: string | boolean,
}

const props = defineProps<Props>();

const tabIsSelected = computed<boolean>(() => props.selected === true || props.selected === props.route.name);
</script>
