import type { RouteRecordRaw } from 'vue-router';
import VisitorDataListView from './views/VisitorDataListView.vue';

export function visitorDataRoutes(): RouteRecordRaw[] {
    return [
        {
            path: '/visitor-data',
            name: 'visitor-data.list',
            component: VisitorDataListView,
            meta: {
                title: 'dashboard.document_title.visitor_data.list',
                titleFallback: 'Visitor Data',
            },
        },
    ];
}
