import { OtError } from '@openticket/lib-log';

export class ContextShopError extends OtError {

    constructor() {
        super('ContextEventError', 'dashboard.errors.plugins.context.shop', 'Failed to set shop context.');

        this.captureStackTrace(ContextShopError);
    }

}
