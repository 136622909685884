import type { RouteRecordRaw } from 'vue-router';
import TicketWizardView from './views/TicketWizardView.vue';

export function ticketWizardRoutes(): RouteRecordRaw[] {
    return [
        {
            path: 'new',
            name: 'tickets.new',
            component: TicketWizardView,
            meta: {
                context: 'event',
                title: 'dashboard.document_title.tickets.new',
                titleFallback: 'New',
            },
        },
    ];
}
