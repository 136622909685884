<template>
    <div
        class="modal-header"
        :class="{ textCentered, 'padding': !hidePadding }"
    >
        <div
            v-if="showHeader"
            class="modal-header__header"
        >
            <img
                v-if="showLogo"
                class="modal-header__header__logo"
                :src="whitelabel.resources.logo"
                alt="logo"
            >

            <div class="modal-header__header__separator" />

            <div
                v-if="showLogout"
                class="modal-header__header__logout"
            >
                <slot name="logout">
                    <OtButton
                        icon="logout"
                        size="small"
                        :title="$t(logoutTitle)"
                        variant="ghost"
                        @click="logoutRedirect"
                    >
                        {{ $t(logoutText) }}
                    </OtButton>
                </slot>
            </div>
        </div>

        <div
            v-if="showContent"
            class="modal-header__content"
        >
            <slot name="title">
                <h1
                    v-if="title"
                    class="modal-header__content__title ot-ui-text-heading-1"
                >
                    {{ title }}
                </h1>
            </slot>

            <slot name="subtitle">
                <span
                    v-if="showSubtitle"
                    class="modal-header__content__subtitle"
                >{{ subtitle }}</span>
            </slot>

            <slot name="extra">
                <span
                    v-if="showExtra"
                    class="ot-label modal-header__content__extra"
                >{{ extra }}</span>
            </slot>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, useSlots } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { useRoute, useRouter } from 'vue-router';
import { injectOrFail } from '../../services/util';

type Props = {
    title?: string,
    subtitle?: string,
    extra?: string,
    logout?: 'back-login' | 'logout',
    hideLogo?: boolean,
    hideLogout?: boolean,
    textCentered?: boolean,
    hidePadding?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
    title: undefined,
    subtitle: undefined,
    extra: undefined,
    logout: 'back-login',
    hideLogo: false,
    hideLogout: false,
    textCentered: false,
    hidePadding: false,
});

const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const slots = useSlots();
const route = useRoute();
const router = useRouter();

const logoutTitle = computed<string>(() => {
    if (props.logout === 'logout') {
        return 'dashboard.common.action.logout.text';
    }

    return 'dashboard.common.action.back_to_login.text';
});

const logoutText = computed<string>(() => {
    if (props.logout === 'logout') {
        return 'dashboard.common.action.logout.text';
    }

    return 'dashboard.common.action.back_to_login.text';
});

const showLogo = computed<boolean>(() => !props.hideLogo && !!whitelabel.resources.logo);
const showLogout = computed<boolean>(() => !props.hideLogout);
const showHeader = computed<boolean>(() => showLogo.value || showLogout.value);
const showTitle = computed<boolean>(() => !!(props.title || slots.title));
const showSubtitle = computed<boolean>(() => !!(props.subtitle || slots.subtitle));
const showExtra = computed<boolean>(() => !!(props.extra || slots.extra));
const showContent = computed<boolean>(() => showTitle.value || showSubtitle.value || showExtra.value);

async function logoutRedirect() {
    await router.push({ name: 'auth.logout', query: route.query });
}
</script>

<style lang="scss" scoped>
.padding {
    padding: var(--ot-ui-spacing-xl) var(--ot-ui-spacing-xl) 0;
}

.modal-header {
    .text-centered {
        text-align: center;
    }

    &__header {
        margin-bottom: var(--ot-ui-spacing-lg);
        display: flex;
        align-items: center;

        &__logo {
            max-width: 100%;
            max-height: 3rem;
        }

        &__separator {
            align-self: stretch;
            flex-grow: 1;
        }

        &__logout {
            color: var(--ot-ui-color-brand);
        }
    }

    &__content {
        text-align: center;

        &__subtitle, &__extra {
            margin-top: 0.5rem;
        }
    }
}
</style>
