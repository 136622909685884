<template>
    <section>
        <draggable
            handle="[data-drag-handle]"
            :list="collapsedTickets"
            :group="draggableGroupKey"
            @sort="onSort"
        >
            <template #item="{ element: item }">
                <DraggableTicket
                    v-if="item._type_ === 'ticket'"
                    :key="item.guid"
                    :ticket="item"
                    solo
                    @remove="emit('remove:ticketFromShop', item.guid)"
                />
                <DraggableCollapse
                    v-else-if="item._type_ === 'collapse'"
                    :key="item.guid"
                    :collapse="item"
                    :draggable-group-key="draggableGroupKey"
                    @update:collapse="onUpdateNestedCollapse"
                    @remove:conceptCollapse="onRemoveConceptCollapse"
                    @open-edit:collapse="onOpenEditCollapseModal"
                    @remove:ticketFromShop="onCollapseRemoveTicket"
                    @move:up="emit('move:up', $event)"
                    @move:down="emit('move:down', $event)"
                    @link="emit('link', $event)"
                    @unlink="emit('unlink', $event)"
                />
            </template>
        </draggable>
    </section>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import draggable from 'vuedraggable';
import DraggableTicket from './DraggableTicket.vue';
import DraggableCollapse from './DraggableCollapse.vue';
import type { ShopDataCollapse, ShopDataEvent, ShopDataTicket } from '../../../../services/shop';
import type { LinkEvent, SortEvent } from '../../../../services/shop/types';
import useDraggableInterpreter from '../../composables/useDraggableInterpreter';

type Props = {
    collapsedTickets: Array<ShopDataTicket | ShopDataCollapse>
    event: ShopDataEvent
}

type Emits = {
    (e: 'update:collapses', value: Array<ShopDataTicket | ShopDataCollapse>): void,
    (e: 'update:nestedCollapse', value: ShopDataCollapse): void,
    (e: 'remove:conceptCollapse', collapseId: string): void,
    (e: 'remove:ticketFromShop', ticketId: string, collapseId?: string): void,
    (e: 'open-edit:collapse', collapse: ShopDataCollapse): void,
    (e: 'move:up', value: SortEvent): void,
    (e: 'move:down', value: SortEvent): void,
    (e: 'link', value: LinkEvent): void,
    (e: 'unlink', value: LinkEvent): void,
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const draggableGroupKey = computed<string>(() => `collapses-${props.event.guid}`);
const collapsedTickets = ref<Array<ShopDataTicket | ShopDataCollapse>>(props.collapsedTickets);

watch(collapsedTickets, () => {
    emit('update:collapses', collapsedTickets.value);
});

function onUpdateNestedCollapse(collapse: ShopDataCollapse) {
    emit('update:nestedCollapse', collapse);
}

const { onSort } = useDraggableInterpreter(props.collapsedTickets, emit);

function onRemoveConceptCollapse(collapseId: string) {
    emit('remove:conceptCollapse', collapseId);
}

function onOpenEditCollapseModal(collapse: ShopDataCollapse) {
    emit('open-edit:collapse', collapse);
}

function onCollapseRemoveTicket(ticketId: string, collapseId: string) {
    emit('remove:ticketFromShop', ticketId, collapseId);
}
</script>

<style lang="scss" scoped>
:deep(.sortable-ghost) {
    opacity: 0.5;
    background-color: var(--ot-ui-color-background-secondary);
    cursor: grabbing;

    &:deep(.ot-ui-button) {
        opacity: 0;
    }
}

:deep(.shop-tickets__collapse) {
  .sortable-ghost.shop-tickets__collapse {
    display: none;
  }
}
</style>
