import type { RouteRecordRaw } from 'vue-router';
import ProfileUpdateWrapper from './layouts/ProfileUpdateWrapper.vue';
import WelcomeMessageWrapper from '../../layouts/WelcomeMessageWrapper.vue';

export function profileRoutes(): RouteRecordRaw[] {
    return [
        {
            path: 'profile',
            component: WelcomeMessageWrapper,
            children: [
                {
                    path: '',
                    name: 'profile.settings',
                    component: ProfileUpdateWrapper,
                    meta: {
                        title: 'dashboard.document_title.profile.settings',
                        titleFallback: 'Profile Settings',
                    },
                    redirect: (to) => ({
                        name: 'profile.settings.general',
                        params: { ...to.params },
                    }),
                    children: [
                        {
                            path: 'general',
                            name: 'profile.settings.general',
                        },
                        {
                            path: 'security',
                            name: 'profile.settings.security',
                        },
                    ],
                },
            ],
        },
    ];
}
