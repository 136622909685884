import type { RouteLocation, RouteLocationNormalized, Router } from 'vue-router';
import type { Context } from './context.service';

export type OnContextFilterSelect = (id: string | symbol | undefined, context?: Context | undefined) => RouteLocation | null;

function extractOnContextSelect(from: RouteLocationNormalized): OnContextFilterSelect | null {
    for (const routeRecord of from.matched.slice().reverse()) {
        const { onContextSelect } = routeRecord.meta;

        if (onContextSelect) {
            return onContextSelect;
        }
    }
    return null;
}

/**
 * Update the route when another context is selected.
 */
export function handleContextFilterUpdate(router: Router, id: string | symbol, context: Context | undefined) {
    const toRoute = extractOnContextSelect(router.currentRoute.value);

    if (!toRoute) {
        return;
    }

    const route = toRoute(id, context);

    if (!route) {
        return;
    }

    if (router.currentRoute.value.query.company) {
        delete router.currentRoute.value.query.company;
    }

    void router.push({
        ...route,
        query: {
            ...router.currentRoute.value.query,

            // Needed to perform context update as the url doesn't change when switching between companies
            // TODO: We might need to return the company id in the url
            nonce: Date.now().toString(),
        },
    });
}

/**
 * Updating the company context based on the given id
 */
// TODO: Reinstate the correct return types: RouteLocation | null after refactor the context service
export function onCompanyContextSelect(id: string | symbol | undefined, context: Context | undefined) {
    if (!context) {
        return null;
    }

    if (!id || typeof id === 'symbol') {
        // TODO: Route params should be removed/refactored when updating the context service
        return { params: { company: '' } };
    }

    // TODO: Route params should be removed/refactored when updating the context service
    return { params: { company: id } };
}
