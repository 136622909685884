import { OtError } from '@openticket/lib-log';

export class InvalidShopDataTypeError extends OtError {

    readonly key: string;
    readonly actualType: string;
    readonly expectedTypes: string[];

    constructor(key: string, actualType: string, ...expectedTypes: string[]) {
        super('InvalidShopDataTypeError', 'dashboard.error.service.shop.parser.invalid.shop.data.type', 'Failed to parse raw shop data. Invalid data type.');

        this.key = key;
        this.addContext('key', key);

        this.actualType = actualType;
        this.addContext('actual_type', actualType);

        this.expectedTypes = expectedTypes;
        this.addContext('expected_types', expectedTypes.join(','));

        this.captureStackTrace(InvalidShopDataTypeError);
    }

}
