import type { RouteRecordRaw } from 'vue-router';
import EventWizardView from './views/EventWizardView.vue';

export function eventWizardRoutes(): RouteRecordRaw[] {
    return [
        {
            path: 'new',
            name: 'events.new',
            component: EventWizardView,
            meta: {
                context: 'global',
                contextIfSet: 'company',
                title: 'dashboard.document_title.events.new',
                titleFallback: 'New',
            },
            props: (route) => ({ company: route.query.company }),
        },
    ];
}
