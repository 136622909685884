<template>
    <div class="form">
        <slot />
    </div>
</template>

<script lang="ts" setup>
import { provide } from 'vue';
import {
    type Model, type ModelConfig, type Parent, type ValidationMessages, isValidateError,
} from '@openticket/lib-crud';
import { isOtError } from '@openticket/lib-log';
import type { ModelForm } from '../../services/form/ModelForm';

type Props = {
    form: ModelForm<Model<Parent, ModelConfig>, Parent>
}

type Emits = {
    (e: 'error', value: unknown): void,
    (e: 'success'): void,
    (e: 'validation-failed', value: ValidationMessages): void,
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

provide('form', props.form);
provide('inFormCollapse', false);

async function submit(): Promise<void> {
    try {
        await props.form.submit();
        emit('success');
    } catch (e) {
        if (isOtError(e) && isValidateError(e.cause)) {
            emit('validation-failed', e.cause.validation.result.messages);
            return;
        }

        emit('error', e);
        throw e;
    }
}

defineExpose({
    submit,
});
</script>
