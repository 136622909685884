<template>
    <div class="debug-plugins__feature_flags">
        <code class="full-width">
            <span class="info">Do a hard refresh for latest FF settings</span>
        </code>
        <code
            v-for="(flag, index) in definedFeatureFlags"
            :key="index"
            class="full-width"
        >
            <span class="indicator">
                {{ flags[flag] ? '✅' : '❌' }}
            </span>
            <span>{{ flag }}</span>
        </code>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import type { FeatureFlagPlugin } from 'src/plugins/featureFlag.plugin';
import type { DefinedFlag } from '../../../../services/featureFlag';
import { definedFlags } from '../../../../services/featureFlag';
import { injectOrFail } from '../../../../services/util';

const { flags } = injectOrFail<FeatureFlagPlugin>('featureFlags');
const definedFeatureFlags = ref<[DefinedFlag]>(Object.keys(definedFlags) as [DefinedFlag]);
</script>

<style lang="scss" scoped>
.debug-plugins__feature_flags {
    & > code {
        display: flex;
        justify-content: flex-start;
        margin-top: 1px;

        & > span:not(:first-child) {
            padding-left: .5rem;
        }

        & > span:first-child:not(.info) {
            margin-left: -24px;
        }
    }
}
</style>
