import { OtError } from '@openticket/lib-log';

export class InvalidShopDataRelationError extends OtError {

    readonly modelA: string;
    readonly idA: string;
    readonly modelB: string;
    readonly idB: string;

    constructor(modelA: string, idA: string, modelB: string, idB: string) {
        super('InvalidShopDataRelationError', 'dashboard.error.service.shop.parser.invalid.shop.data.relation', 'Failed to parse raw shop data. Invalid relation.');

        this.modelA = modelA;
        this.addContext('model_a', modelA);

        this.idA = idA;
        this.addContext('id_a', idA);

        this.modelB = modelB;
        this.addContext('model_b', modelB);

        this.idB = idB;
        this.addContext('id_b', idB);

        this.captureStackTrace(InvalidShopDataRelationError);
    }

}
