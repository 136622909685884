<template>
    <div
        v-if="revisions && showRevisions"
        class="revisions"
    >
        <OtButton
            variant="inline"
            @click="showRevisionsModal"
        >
            <div
                class="revisions_button ot-ui-text-body-xs"
            >
                {{ $t('dashboard.revisions.last_edit') }} {{ lastEditDate }}<br>
                <span v-if="lastEditUser">{{ $t('dashboard.revisions.by') }} <strong>{{ lastEditUser }}</strong></span>
            </div>
        </OtButton>
        <OtModal
            ref="modalRef"
            :title="$t('dashboard.revisions.title')"
            :subtitle="modelName"
        >
            <RevisionList :mutations="revisions.records || []" />
            <RevisionPagination
                v-if="revisions.lastPage && revisions.lastPage > 1"
                :revisions="revisions"
            />
            <template #footer>
                <div />
            </template>
        </OtModal>
    </div>
</template>

<script setup lang="ts">
import {
    computed, ref, watch,
} from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { OtModal, useLocalization } from '@openticket/vue-ui';
import { injectOrFail, getPastRelativeTimeString } from '../../services/util';
import RevisionList from './RevisionList.vue';
import type { RevisionModelType } from '../../services/revisor';
import RevisionPagination from './RevisionPagination.vue';
import { useRevisions } from '../../composables';

type Props = {
    type: RevisionModelType
    id?: string | null
    modelName?: string
}

const props = defineProps<Props>();

const { revisions, updateRevisions } = useRevisions(props.type, computed(() => props.id));

const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const { localization } = useLocalization();

const modalRef = ref<InstanceType<typeof OtModal> | null>(null);
const lastEditDate = ref('');
const lastEditUser = ref('');

const showRevisions = computed(() => revisions.value && revisions.value.records && revisions.value.records.length > 0);

watch([ () => revisions.value?.records, () => localization.locale.language ], () => {
    if (revisions.value?.currentPage === 1) {
        setLastEdited();
    }
});

function setLastEdited() {
    if (!revisions.value || !revisions.value.records || !revisions.value.records.length) {
        return;
    }

    const firstRecord = revisions.value.records.at(0);

    if (!firstRecord) {
        return;
    }

    lastEditDate.value = getPastRelativeTimeString(new Date(firstRecord.time), localization.locale.language);

    if (firstRecord.meta) {
        lastEditUser.value = firstRecord.meta.anonymous ? whitelabel.name : firstRecord.meta.user_name;
    }
}

function showRevisionsModal() {
    modalRef.value?.open();
}

defineExpose({
    updateRevisions,
});
</script>

<style lang="scss" scoped>
.revisions_button {
    display: inline-block;
    text-align: right;
    color: var(--ot-ui-color-accent-primary);

    > span {
        display: inline-block;
        width: 18ch;
        margin-top: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    strong {
        font-weight: var(--ot-ui-font-weight-semibold);
    }
}
</style>
