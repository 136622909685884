<template>
    <div class="context-provider">
        <slot />
    </div>
</template>

<script setup lang="ts">
import { provide, reactive } from 'vue';
import type { ManagementClient } from '@openticket/lib-management';
import { type AuthClient } from '@openticket/lib-auth';
import { useRoute, useRouter, type RouteRecord } from 'vue-router';
import { useNotifications } from '@openticket/vue-ui';
import type { WaitingListClient } from '../services/waiting-list/client/waitingListClient';
import { Context, type ContextType, type Value } from '../services/context';
import { injectOrFail, roleGuard } from '../services/util';
import { getRolesForRoutes, hasCorrespondingRole } from '../services/util/roleGuard';

// TODO Create translation string for this notification.
const NO_ACCESS_NOTIFICATION_TEXT = 'No access for this page. Redirecting to home.';

const management = injectOrFail<ManagementClient>('management');
const auth = injectOrFail<AuthClient>('auth');
const waitingList = injectOrFail<WaitingListClient>('waitingListClient');

const router = useRouter();
const route = useRoute();
const notification = useNotifications();

const contextReactive = reactive<Context>(new Context(auth, management, waitingList));
provide('context', contextReactive);

/**
 * A route can have a restrictedBy meta value. Every route change will go through this check.
 * If this value is present in the to route, a check will be performed to check whether the user has access to this route.
 * If the user has no access it will be redirected to home.
 */
router.beforeEach(async (to, from, next) => {
    // Typecasting contextReactive to Context type because using reactive gets rid of the private values/methods
    // Which makes it not the exact type anymore.
    await roleGuard(to, from, next, auth, contextReactive as Context, () => notification.info(NO_ACCESS_NOTIFICATION_TEXT));
});

router.beforeResolve(async (to, from, next) => {
    try {
        const { params } = to;

        // To prevent the native browser's back button from forgetting the company context.
        if ('company' in from.params && !('company' in to.params) && (!!contextReactive.context)) {
            params.company = from.params.company;
        }

        await contextReactive.beforeRouteChange({ ...to, params });
    } catch (e: unknown) {
        // Don't redirect to error page if we're already there
        if (to.name !== 'error') {
            next({
                name: 'error',
                query: {
                    reason: e instanceof Error ? e.message : 'Failed to update context.',
                },
            });

            return;
        }
    }

    next();
});

async function initContext() {
    try {
        contextReactive.routeValues = contextReactive.routeValues
            .filter((value: Value) => route.matched.some((record: RouteRecord) => record === value.record));

        // Checks the query params if it contains the company id so the context is set immediately
        // This is useful for the event wizard company setting. But can also be used for setting the
        // company from a link.
        if (route.query.company) {
            route.params.company = route.query.company.toString();
        }

        const newContext: ContextType = contextReactive.extractContext(route);

        // TODO Fix types in entire context rewrite
        await contextReactive.updateContext(newContext, route.params);

        await redirectOnRestriction();
    } catch (e) {
        console.error('Initializing context created error: ', { e });

        if (router.currentRoute.value.name !== 'error' && router.currentRoute.value.name !== 'auth.login') {
            void router.push({ name: 'error', query: { reason: e instanceof Error ? e.message : 'unknown context init error' } });
        }
    }
}

// This will execute on the initial route load. If a user goes to a restricted url directly this check will happen.
async function redirectOnRestriction() {
    if (!route.meta.restrictedBy) {
        return;
    }

    const roles = await getRolesForRoutes(auth, contextReactive as Context);

    if (!hasCorrespondingRole(route.meta.restrictedBy, roles)) {
        notification.notify(NO_ACCESS_NOTIFICATION_TEXT);
        await router.replace({ name: 'home' });
    }
}

await initContext();
</script>
