import type { AuthClient } from '@openticket/lib-auth';
import { ManagementClient } from '@openticket/lib-management';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { App } from 'vue';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import type { ManagementOptions } from './types';

export class ManagementPlugin extends Plugin<ManagementClient> {

    async install(plugins: PluginsManager, app: App): Promise<void> {
        try {
            const options: ManagementOptions = plugins.options.management || {};

            const auth: AuthClient = await plugins.auth.loading;
            const whitelabel: Whitelabel = await plugins.whitelabel.loading;

            const baseUrl: string = options.url
                || whitelabel.dashboard.management_api_url
                || 'https://management.openticket.tech/';

            const management: ManagementClient = new ManagementClient({
                http: auth.$http,
                baseUrl,
                shopUrl: whitelabel.shop.url,
            });

            app.config.globalProperties.$management = management;

            app.provide('management', management);

            this.resolve(management);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

}
