import type { Whitelabel } from '@openticket/lib-whitelabels';
import urlJoin from 'url-join';
import { computed } from 'vue';
import type { Context } from '../services/context';
import { injectOrFail } from '../services/util/injectOrFail';

export const useClassicDashboardRouting = () => {
    const whitelabel = injectOrFail<Whitelabel>('whitelabel');
    const context = injectOrFail<Context>('context');

    const goBackToClassicRoute = computed(() => {
        const classicDashboardUrl = whitelabel.dashboard.legacy_url;

        if (typeof classicDashboardUrl !== 'string' || !classicDashboardUrl) {
            return '';
        }

        if (context.isEventContext()) {
            return urlJoin(classicDashboardUrl, '/home/event/', context.company.event.id, `?as=${context.company.id}`);
        }

        if (context.isCompanyContext()) {
            return urlJoin(classicDashboardUrl, 'home', `?as=${context.company.id}`);
        }

        return classicDashboardUrl;
    });

    return { goBackToClassicRoute };
};
