<template>
    <OtModal
        ref="modalRef"
        class="add-tickets-to-event-modal"
        :width="600"
        :title="title"
        :subtitle="subtitle"
    >
        <OtFormRow v-if="!loading && eventModel">
            <OtInputField
                :label="$t('dashboard.shop_tickets.details.inputs.tickets.label')"
                :description="$t('dashboard.shop_tickets.details.inputs.tickets.description')"
            >
                <div class="add-tickets-to-event-modal__tickets-input">
                    <LinkUnlinkPicker
                        ref="linkTicketsToShopRef"
                        :items-label="$t('models.ticket.name.lowercase.plural')"
                        :pagination-relation="eventModel.tickets"
                    />
                </div>
            </OtInputField>
        </OtFormRow>

        <OtError v-else-if="error" />

        <OtSpinner v-else />

        <template #footer-action>
            <OtButton
                icon="check"
                :title="$t('dashboard.common.action.save.title')"
                :disabled="saveDisabled"
                @click="save"
            >
                {{ $t('dashboard.common.action.save.text') }}
            </OtButton>
        </template>
    </OtModal>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef } from 'vue';
import type { Event, ManagementClient } from '@openticket/lib-management';
import { OtError } from '@openticket/lib-log';
import { OtModal } from '@openticket/vue-ui';
import { injectOrFail } from '../../../../services/util';
import { useGenericErrorHandling } from '../../../../composables';
import LinkUnlinkPicker from '../../../../components/modal/link-unlink/LinkUnlinkPicker.vue';

type Props = {
    title: string,
    subtitle?: string,
}

type Emits = {
    (e: 'saved', ticketGuids: string[]): void
}

const management = injectOrFail<ManagementClient>('management');

defineProps<Props>();
const emit = defineEmits<Emits>();

const { handleError, error } = useGenericErrorHandling();

const modalRef = ref<InstanceType<typeof OtModal> | null>(null);
const linkTicketsToShopRef = useTemplateRef<InstanceType<typeof LinkUnlinkPicker>>('linkTicketsToShopRef');

const eventModel = ref<Event<ManagementClient>>();
const loading = ref(0);

const saveDisabled = computed(() => !!loading.value
        || !(linkTicketsToShopRef.value
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        && linkTicketsToShopRef.value.hasLocalChanges
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        && linkTicketsToShopRef.value.diff?.toLink.length > 0
        ));

function save() {
    if (linkTicketsToShopRef.value
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    && linkTicketsToShopRef.value.hasLocalChanges
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    && linkTicketsToShopRef.value.diff?.toLink.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        emit('saved', linkTicketsToShopRef.value.diff.toLink);
        close();
    }
}

async function open(eventId: string) {
    try {
        loading.value++;
        if (modalRef.value) {
            void modalRef.value.open();
            eventModel.value = await management.events.find(eventId);
        }
    } catch (e) {
        handleError(e);
    } finally {
        loading.value--;
    }
}

function close() {
    if (modalRef.value) {
        void modalRef.value.close();
    }
}

defineExpose({
    open,
});
</script>

<style lang="scss" scoped>
.add-tickets-to-event-modal {
    &__tickets-input {
        flex-grow: 1;
    }
}
</style>
