<template>
    <div class="ticket-details-form">
        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.tickets.details.inputs.ticket_name.label')"
                :description="$t('dashboard.tickets.details.inputs.ticket_name.description')"
                :optional="form.rules.name && !form.rules.name.includes('required')"
                :error="form.errors.name"
            >
                <OtTextInput
                    v-model="form.model.$data.name"
                    data-testid="ticket-details-form-name"
                    :readonly="isReadOnly"
                    @input="form.errors.name = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.tickets.details.inputs.ticket_capacity.label')"
                :description="$t('dashboard.tickets.details.inputs.ticket_capacity.description')"
                :optional="form.rules.available_stock && !form.rules.available_stock.includes('required')"
                :error="form.errors.available_stock"
            >
                <InputFloatUnlimited
                    v-model="form.model.$data.available_stock"
                    data-testid="ticket-details-form-available-stock"
                    :readonly="isReadOnly"
                    @input="form.errors.available_stock = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <InputPriceVat
                v-model:price="form.model.$data.min_price"
                v-model:vat="form.model.$data.vat_percentage"
                v-model:price-errors="form.errors.min_price"
                v-model:vat-errors="form.errors.vat_percentage"
                :translations="{
                    price: {
                        label:$t('dashboard.tickets.details.inputs.ticket_price.label'),
                        description: $t('dashboard.tickets.details.inputs.ticket_price.description')
                    }
                }"
                :currency="context.event.model.$data.currency"
                :price-rules="form.rules.min_price"
                :readonly="isReadOnly"
                data-testid="ticket-create-form-price"
            />
        </OtFormRow>

        <OtFormRow v-if="flags['app__access_moments__navigation']">
            <OtInputField
                :label="$t('dashboard.access_moments.tickets.label')"
                :description="$t('dashboard.access_moments.tickets.description')"
                optional
            >
                <!-- TODO: replace with generic LinkUnlinkPicker -->
                <AccessMomentsPicker
                    ref="accessMomentsPickerRef"
                    :readonly="isReadOnly"
                    :ticket="context.ticket.model"
                />
            </OtInputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import {
    type UnwrapNestedRefs, useTemplateRef, watch,
} from 'vue';
import type { ManagementClient, Ticket } from '@openticket/lib-management';
import InputFloatUnlimited from '../../../../components/InputFloatUnlimited.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { injectOrFail } from '../../../../services/util';
import type { Context } from '../../../../services/context';
import AccessMomentsPicker from '../../../access-moments/components/AccessMomentsPicker.vue';
import type { FeatureFlagPlugin } from '../../../../plugins/featureFlag.plugin';
import InputPriceVat from '../../../../components/form/InputPriceVat.vue';
import { useRoles } from '../../../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Ticket<ManagementClient>, ManagementClient>>
}

const props = defineProps<Props>();

const context = injectOrFail<Context>('context');
const { flags } = injectOrFail<FeatureFlagPlugin>('featureFlags');

const { isReadOnly } = useRoles();

if (!context.isTicketContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const accessMomentsPickerRef = useTemplateRef('accessMomentsPickerRef');

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
watch(() => accessMomentsPickerRef.value?.hasLocalChanges, (value) => {
    if (value) {
        props.form.hasLocalChanges = true;
    }
});

async function linkAccessMoments(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await accessMomentsPickerRef.value?.submit();
}

defineExpose({
    linkAccessMoments,
});
</script>
