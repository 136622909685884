import { OtError } from '@openticket/lib-log';

export class ContextEventError extends OtError {

    constructor() {
        super('ContextEventError', 'dashboard.errors.plugins.context.event', 'Failed to set event context.');

        this.captureStackTrace(ContextEventError);
    }

}
