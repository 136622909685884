import {
    DataType, Delete, Find, List, Model, ToMany,
} from '@openticket/lib-crud';
import type { Parent, DataInterface, RawInterface } from '@openticket/lib-crud';
import { WaitingListSignup, type WaitingListSignupConfig } from './waitingListSignup';
import { ExportList } from '../mixins';

const ModelConfig = {
    allow_quantity: [ DataType.boolean ],
    allow_reseller_ticketswap: [ DataType.boolean ],
    company_id: [ DataType.string ],
    created_at: [ DataType.iso8601, DataType.readonly, DataType.undefined ],
    deleted_at: [ DataType.iso8601, DataType.null, DataType.readonly, DataType.undefined ],
    enabled: [ DataType.boolean ],
    event_id: [ DataType.string ],
    guid: [ DataType.uuid, DataType.primary ],
    type: [ DataType.string, DataType.undefined, DataType.null ],
    updated_at: [ DataType.iso8601, DataType.readonly, DataType.undefined ],
};
type Config = typeof ModelConfig;

export type {
    Config as WaitingListConfig,
};

export type WaitingListRaw = RawInterface<Config>;
export type WaitingListData = DataInterface<Config>;

export class WaitingList<Ancestor extends Parent> extends Model<Ancestor, Config> {

    static $defaultData(): RawInterface<Config> {
        return {
            allow_quantity: false,
            allow_reseller_ticketswap: false,
            company_id: '',
            deleted_at: null,
            enabled: false,
            event_id: '',
            guid: '',
            type: 'all_tickets_sold_out',
        };
    }

    get id(): string | null {
        return this.$data.guid || null;
    }

    get name(): string | null {
        return null;
    }

    signups = new ToMany<
        WaitingListSignup<this>,
        WaitingListSignupConfig,
        this,
        {}
    >(WaitingListSignup, this, 'signup')
        .with(List)
        .with(Find)
        .with(Delete)
        .with(ExportList);

    $config(): Config {
        return ModelConfig;
    }

    $modelName(): string {
        return 'WaitingList';
    }

}
