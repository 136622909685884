import type { App } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { AuthClient } from '@openticket/lib-auth';
import { Plugin } from './plugin';
import type { PluginsManager } from './manager';
import { Revisor } from '../services/revisor/revisor.service';

export class RevisorPlugin extends Plugin<Revisor> {

    async install(plugins: PluginsManager, app: App): Promise<void> {
        try {
            const auth: AuthClient = await plugins.auth.loading;
            const whitelabel: Whitelabel = await plugins.whitelabel.loading;

            const baseUrl: string = whitelabel.dashboard.revisor_url
                || 'https://revisor.openticket.tech/';

            const revisor = new Revisor({ baseUrl, http: auth.$http });

            app.provide('revisor', revisor);

            this.resolve(revisor);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

}
