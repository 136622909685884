<template>
    <div class="events-marketing-form">
        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.events.edit.marketing.form.description.label')"
                :description="$t('dashboard.events.edit.marketing.form.description.description')"
                :optional="form.rules.description && !form.rules.description.includes('required')"
                :error="form.errors.description"
            >
                <OtTextareaInput
                    v-model="form.model.$data.description"
                    :placeholder="$t('dashboard.events.edit.marketing.form.description.label')"
                    :readonly="isReadOnly"
                    data-testid="events-marketingform-description"
                    @input="form.errors.description = []"
                />
            </OtInputField>
        </OtFormRow>

        <!-- TODO: Restore when categories can be fetched -->
        <!-- <OtFormRow>
            <OtInputField
                :label="$t('dashboard.events.edit.marketing.form.category.label')"
                :description="$t('dashboard.events.edit.marketing.form.category.description')"
                optional
            >
                <OtSelectInput
                    sort="alphabetically"
                    :options="categories"
                    v-model="eventModel.$data.category"
                />
            </OtInputField>

            <OtInputField
                :label="$t('dashboard.events.edit.marketing.form.sub_category.label')"
                :description="$t('dashboard.events.edit.marketing.form.sub_category.description')"
                optional
            >
                <OtSelectInput
                    sort="alphabetically"
                    :options="subCategories"
                    v-model="eventModel.$data.subcategories"
                />
            </OtInputField>
        </OtFormRow> -->

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.events.edit.marketing.form.website.label')"
                :description="$t('dashboard.events.edit.marketing.form.download_page')"
                :optional="form.rules.visitor_contact_url && !form.rules.visitor_contact_url.includes('required')"
                :error="form.errors.visitor_contact_url"
            >
                <OtTextInput
                    v-model="form.model.$data.visitor_contact_url"
                    :placeholder="$t('dashboard.events.edit.marketing.form.website.placeholder')"
                    :readonly="isReadOnly"
                    data-testid="events-marketingform-website"
                    @input="form.errors.visitor_contact_url = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.events.edit.marketing.form.email.label')"
                :description="$t('dashboard.events.edit.marketing.form.download_page')"
                :optional="form.rules.visitor_contact_email && !form.rules.visitor_contact_email.includes('required')"
                :error="form.errors.visitor_contact_email"
            >
                <OtEmailInput
                    v-model="form.model.$data.visitor_contact_email"
                    :placeholder="$t('dashboard.events.edit.marketing.form.email.placeholder')"
                    :readonly="isReadOnly"
                    data-testid="events-marketingform-email"
                    @input="form.errors.visitor_contact_email = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.events.edit.marketing.form.phone.label')"
                :description="$t('dashboard.events.edit.marketing.form.download_page')"
                :optional="form.rules.visitor_contact_phone && !form.rules.visitor_contact_phone.includes('required')"
                :error="form.errors.visitor_contact_phone"
            >
                <OtPhoneInput
                    v-model="form.model.$data.visitor_contact_phone"
                    :placeholder="$t('dashboard.events.edit.marketing.form.phone.placeholder')"
                    :readonly="isReadOnly"
                    data-testid="events-marketingform-phone"
                    @input="form.errors.visitor_contact_phone = []"
                />
            </OtInputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue';
import type { ManagementClient, Event } from '@openticket/lib-management';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { useRoles } from '../../../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Event<ManagementClient>, Event<ManagementClient>>>
}

defineProps<Props>();

const { isReadOnly } = useRoles();
</script>
