import { OtError } from '@openticket/lib-log';

export class ContextAccessMomentError extends OtError {

    constructor() {
        super('ContextAccessMomentError', 'dashboard.errors.plugins.context.access_moment', 'Failed to set access moment context.');

        this.captureStackTrace(ContextAccessMomentError);
    }

}
