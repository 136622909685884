import {
    mapFilters,
    type Model,
    type ModelConfig, type PaginationFilters,
    type Parent,
    type RulesMap,
    type ToMany,
} from '@openticket/lib-crud';
import urlJoin from 'url-join';

export async function ExportList<
    M extends Model<Ancestor, C, R>,
    C extends ModelConfig,
    Ancestor extends Parent,
    R extends RulesMap = {},
    PivotConfig extends ModelConfig = {},
>(this: ToMany<M, C, Ancestor, R, PivotConfig>, title: string, activeFilters: PaginationFilters | undefined): Promise<void> {
    let path = urlJoin(this.$path, 'csv');

    if (activeFilters) {
        const mapped = mapFilters(activeFilters);
        const urlParams = new URLSearchParams(mapped);
        path = urlJoin(path, `?${urlParams.toString()}`);
    }
    const res = await this.$http.get(path);

    // Build filename
    const date = new Date();
    const fileName = `Signups ${date.toDateString()} ${title}`.toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-')
        .slice(0, 200);

    // Convert CSV to blob
    const blob = new Blob([ res.data ], { type: 'text/csv;charset=utf-8;' });

    // Create download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${fileName}.csv`;

    // Append, click and remove link
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

ExportList.mixinName = 'EXPORT-LIST' as const;
ExportList.methodName = 'exportList' as const;
