import type { ShopDataCollapse, ShopDataTicket } from '../../../services/shop';
import type { LinkEvent, SortEvent } from '../../../services/shop/types';
import DraggableInterpreter from '../services/DraggableInterpreter';

interface EmitFn {
    (event: string, ...args: [ LinkEvent | SortEvent ]): void;
}

export default function useDraggableInterpreter(
    ticketCollapses: Array<ShopDataTicket | ShopDataCollapse>,
    emit: EmitFn,
    parentCollapse?: ShopDataCollapse,
) {
    const draggableInterpreter = new DraggableInterpreter(ticketCollapses, parentCollapse?.guid);

    function onSort(e: VueDraggableBaseEvent) {
        draggableInterpreter.persistSort(e.oldIndex, e.newIndex);

        const events = draggableInterpreter.getEventQueue();
        events.forEach((event) => {
            emit(event.name, event.load);
        });

        draggableInterpreter.reset();
    }

    function onAdd(e: VueDraggableBaseEvent) {
        // 'concept'- (new) collapses are not persisted, and therefor not yet linkable
        // Exit and leave handling to the top component
        if (parentCollapse?.concept === true) {
            return;
        }

        draggableInterpreter.persistLink(e.oldIndex, e.newIndex);
    }

    function onRemove(e: VueDraggableBaseEvent) {
        draggableInterpreter.persistUnlink(e.oldIndex, e.newIndex);
    }

    function onDrag(e: VueDraggableMoveCallbackEvent) {
        if (e.draggedContext.element._type_ === 'collapse' && e.relatedContext?.element?._type_ === 'collapse') {
            return false;
        }

        return true;
    }

    return {
        draggableInterpreter,
        onSort,
        onAdd,
        onRemove,
        onDrag,
    };
}

// living the life
type VueDraggableBaseEvent = {
    oldIndex: number;
    newIndex: number;
}

type VueDraggableMoveCallbackEvent = {
    draggedContext: {
        element: ShopDataTicket | ShopDataCollapse;
    };
    relatedContext: {
        element?: ShopDataTicket | ShopDataCollapse;
    };
}
