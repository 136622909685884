<template>
    <div
        v-if="!error"
        class="module__events__list"
    >
        <ComingSoon :number-of-graphs="2" />
        <OtDataGrid
            class="module__events__list__grid"
            type="tiles"
            :pagination="events"
            :columns="columns"
            show-switcher
            :empty-labels="{
                subtitle: $t('dashboard.events.list.empty.message'),
                title: $t('dashboard.events.list.empty.title'),
            }"
            :loading-text="$t('dashboard.events.list.header.loading')"
        >
            <template #list-header="{ typeSwitcher }">
                <OtListHeader
                    :pagination="events"
                    searchable
                    search-key="name"
                    :search-placeholder="$t('dashboard.events.list.header.search.placeholder')"
                    :title="$t('dashboard.document_title.events.list')"
                >
                    <template #filters>
                        <EventsFilters :events="events" />
                    </template>

                    <template #actions-top>
                        <OtButton
                            :disabled="isReadOnly"
                            icon="plus"
                            size="small"
                            data-testid="event-wizard-btn"
                            @click="eventCompanySelector?.open()"
                        >
                            {{ $t('dashboard.events.new.title') }}
                        </OtButton>
                    </template>

                    <template #actions-bottom>
                        <component :is="typeSwitcher" />
                    </template>
                </OtListHeader>
            </template>

            <template
                v-if="!Object.keys(events.filters?.active ?? {}).length"
                #empty-action
            >
                <OtButton
                    :disabled="isReadOnly"
                    icon="plus"
                    variant="outline"
                    data-testid="event-wizard-btn"
                    @click="eventCompanySelector?.open()"
                >
                    {{ $t('dashboard.events.new.title') }}
                </OtButton>
            </template>

            <template #tile="{ record }">
                <OtTileAdvanced
                    class="module__events__list__grid__record"
                    clickable
                    :record="record"
                    :title="record.$data.name"
                    @click="goToEventsHome(record.$data.guid)"
                >
                    <template #content>
                        <EventsListRecord :record="record" />
                    </template>
                </OtTileAdvanced>
            </template>

            <template #column__$data__company_id="{ record, value }">
                <OtButton
                    variant="inline"
                    class="module__events__list-record__company-name"
                    @click="goToCompaniesHome(record.$data.company_id)"
                >
                    {{ companyName(value) }}
                </OtButton>
            </template>

            <template #actions="{ record }">
                <OtTileActions
                    :buttons="[
                        {
                            title: $t('dashboard.events.list.view.title', { event: record.$data.name }),
                            text: $t('dashboard.events.list.view.text', { ticket: record.$data.name }),
                            iconRight: 'carrot-right',
                            clickHandler: () => goToEventsHome(record.$data.guid)
                        }
                    ]"
                />
            </template>
        </OtDataGrid>

        <CompanySelector
            ref="eventCompanySelector"
            :create-model-label="$t('dashboard.events.model_name').toString().toLocaleLowerCase()"
            @selected="handleNewEvent"
        />
    </div>

    <ErrorView
        v-else-if="error"
        :label="error.message"
    />

    <OtSpinner v-else />
</template>

<script lang="ts" setup>
import type { ManagementClient } from '@openticket/lib-management';
import { ref, useTemplateRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import type { AuthClient, CompanyRaw } from '@openticket/lib-auth';
import type { OtDataGridColumn } from '@openticket/vue-ui';
import { useLocalization } from '@openticket/vue-ui';
import type { Context } from '../../../services/context';
import EventsListRecord from '../components/EventsListRecord.vue';
import ErrorView from '../../../components/ErrorView.vue';
import { injectOrFail } from '../../../services/util';
import ComingSoon from '../../../components/ComingSoon.vue';
import { useGenericErrorHandling, useRoles, useRouterPagination } from '../../../composables';
import EventsFilters from '../components/EventsFilters.vue';
import CompanySelector from '../../../components/CompanySelector.vue';

const { t } = useLocalization();
const { isReadOnly } = useRoles();
const router = useRouter();
const { error } = useGenericErrorHandling();

const auth = injectOrFail<AuthClient>('auth');
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const companies = ref<CompanyRaw[]>([]);
const eventCompanySelector = useTemplateRef('eventCompanySelector');

const { list: events, setPagination } = useRouterPagination(management.events, {
    defaultFilters: { upcoming: { scope: '' } },
    defaultSort: [ [ 'start', 'asc' ] ],
    perPage: 10,
});

const columns: Array<OtDataGridColumn> = [
    {
        key: [ '$data', 'name' ],
        label: t('models.event.attributes.name.label'),
        type: 'string',
    },
    {
        key: [ '$data', 'company_id' ],
        type: 'custom',
        label: t('models.company.name.single'),
    },
    {
        key: [ '$data', 'start' ],
        type: 'date',
        label: t('models.event.attributes.start.label'),
    },
    {
        key: [ '$data', 'end' ],
        type: 'date',
        label: t('models.event.attributes.end.label'),
    },
];

void prepareCompanyNameResolver();

watch(() => context.company, (newValue, oldValue) => {
    if (!context.isEventContext() && newValue?.id !== oldValue?.id) {
        void setPagination(management.events);
    }
});

async function goToCompaniesHome(companyId: string) {
    await context.updateContext('company', { company: companyId });
}

async function goToEventsHome(eventId: string) {
    await router.push({
        name: 'events.home',
        params: { event: eventId },
    });
}

function handleNewEvent(guid: string) {
    void router.push({
        name: 'events.new',
        query: { company: guid },
    });
}

async function prepareCompanyNameResolver(): Promise<void> {
    companies.value = (await auth.$token.$info)?.companies ?? [];
}

function companyName(companyId: PropertyKey): string | undefined {
    return companies.value.find((singleCompany) => singleCompany.guid === companyId)?.name;
}
</script>

<style lang="scss" scoped>
.module__events__list {
    padding-top: var(--ot-ui-spacing-md);

    &__filters {
        display: flex;
        flex-direction: row;
        gap: var(--ot-ui-spacing-md);
    }

    &-record__company-name.ot-ui-button {
        padding: 0;
    }

    &-record__company-name::after {
        display: none;
    }
}
</style>
