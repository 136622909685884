<template>
    <div
        class="ticket-create-form"
        data-testid="ticket-create-form"
    >
        <OtFormRow>
            <OtInputField
                :description="$t('dashboard.events.wizard.steps.tickets.upsert.form.ticket_name.description')"
                :error="form.errors.name"
                :label="$t('dashboard.events.wizard.steps.tickets.upsert.form.ticket_name.label')"
                :required="form.rules.name && form.rules.name.includes('required')"
                data-testid="ticket-create-form-name"
            >
                <OtTextInput
                    v-model="form.model.$data.name"
                    @input="form.errors.name = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :description="$t('dashboard.events.wizard.steps.tickets.upsert.form.capacity.description')"
                :error="form.errors.available_stock"
                :label="$t('dashboard.events.wizard.steps.tickets.upsert.form.capacity.label')"
                :required="form.rules.available_stock && form.rules.available_stock.includes('required')"
                data-testid="ticket-create-form-capacity"
            >
                <InputFloatUnlimited
                    v-model="form.model.$data.available_stock"
                    min="0"
                    @input="form.errors.available_stock = []"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <InputPriceVat
                v-model:price="form.model.$data.min_price"
                v-model:vat="form.model.$data.vat_percentage"
                v-model:price-errors="form.errors.min_price"
                v-model:vat-errors="form.errors.vat_percentage"
                :translations="{
                    price: {
                        label:$t('dashboard.tickets.details.inputs.ticket_price.label'),
                        description: $t('dashboard.tickets.details.inputs.ticket_price.description')
                    }
                }"
                :currency="context.event.model.$data.currency"
                :price-rules="form.rules.min_price"
                data-testid="ticket-create-form-price"
            />
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import type { Event, ManagementClient, Ticket } from '@openticket/lib-management';
import type { UnwrapNestedRefs } from 'vue';
import InputFloatUnlimited from '../../../../components/InputFloatUnlimited.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { injectOrFail } from '../../../../services/util';
import type { Context } from '../../../../services/context';
import InputPriceVat from '../../../../components/form/InputPriceVat.vue';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Ticket<ManagementClient>, Event<ManagementClient>>>
}

defineProps<Props>();

const context = injectOrFail<Context>('context');

if (!context.isEventContext()) {
    // TODO Proper error handling and logging
    throw Error('Invalid context');
}
</script>
