<template>
    <OtModal
        v-if="!welcomeMessageSeenState"
        ref="welcomeMessageModal"
        position="center"
        show-close-icon
    >
        <div class="welcome-message">
            <div class="welcome-message__emoji">
                {{ $t('dashboard.welcome.content.emoji') }}
            </div>
            <h2 class="ot-ui-text-heading-2">
                {{ $t('dashboard.welcome.content.header') }}
            </h2>
            <p class="ot-ui-text-body-intro">
                {{ $t('dashboard.welcome.content.description') }}
            </p>
            <p class="ot-ui-text-body-intro-strong">
                {{ $t('dashboard.welcome.content.subtext') }}
            </p>
            <div class="welcome-message__image">
                <img
                    src="../assets/images/welcome_message_modal.png"
                    :alt="$t('dashboard.welcome.content.image.alt')"
                    width="100%"
                    height="100%"
                >
            </div>
        </div>
        <template #footer>
            <div class="welcome-message__footer">
                <OtButton
                    variant="secondary"
                    icon="arrow-left"
                    :title="$t('dashboard.welcome.footer.back.title')"
                    @click="backToClassicDashboard()"
                >
                    {{ $t('dashboard.welcome.footer.back.text') }}
                </OtButton>
                <OtButton
                    icon="check"
                    :title="$t('dashboard.welcome.footer.continue.title')"
                    @click="continueToNewDashboard()"
                >
                    {{ $t('dashboard.welcome.footer.continue.text') }}
                </OtButton>
            </div>
        </template>
    </OtModal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { OtModal } from '@openticket/vue-ui';
import { useLocalStorage } from '../composables/useLocalStorage';
import type { RudderStack } from '../services/rudderstack';
import { injectOrFail } from '../services/util';

const WELCOME_MESSAGE_SEEN_KEY = 'ot-dashboard-welcome-message-seen';

const rudderstack = injectOrFail<RudderStack>('rudderstack');

const welcomeMessageSeenState = useLocalStorage<EpochTimeStamp | null>(WELCOME_MESSAGE_SEEN_KEY, null);
const route = useRoute();

const welcomeMessageModal = ref<InstanceType<typeof OtModal> | null>(null);
const continueNextFn = ref<NavigationGuardNext | null>(null);

function handleRouteChange(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    if (!from.name || !to.name) {
        return;
    }

    const hasClassicQueryParam = (!!from.query.classic || !!from.query.legacy);

    if (
        !welcomeMessageSeenState.value
        && hasClassicQueryParam
    ) {
        showModal(next);
    } else {
        next();
    }
}

onBeforeRouteLeave(handleRouteChange);

function showModal(next: NavigationGuardNext) {
    if (welcomeMessageModal.value) {
        welcomeMessageModal.value.open();
        continueNextFn.value = next;
    }
}

function closeModal() {
    if (welcomeMessageModal.value) {
        void welcomeMessageModal.value.close();
    }
}

function continueToNewDashboard() {
    if (continueNextFn.value) {
        welcomeMessageSeenState.value = Date.now();
        rudderstack.track('vue-dashboard welcome message popup continue');
        continueNextFn.value();
    }
}

function backToClassicDashboard() {
    if (!!route.query.classic || !!route.query.legacy) {
        // Closes the window after rudderstack completes its tracking call.
        // or when the call does not finish in 300ms it still closes.
        rudderstack.track(
            'vue-dashboard welcome message popup back',
            {},
            {},
            () => {
                window.close();
            },
        );
        setTimeout(() => window.close(), 300);
    }

    closeModal();
}
</script>

<style scoped lang="scss">
@import "../assets/scss/mixins.scss";

.welcome-message {
    display: flex;
    flex-direction: column;
    gap: var(--ot-ui-spacing-md);
    align-items: center;
    text-align: center;
    max-width: 600px;
    white-space: pre-line;

    &__emoji {
        width: 5rem;
        height: 5rem;
        font-size: 4rem;
        margin-top: -2rem;
        margin-bottom: 1rem;
    }

    &__image {
        width: 100%;
    }

    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: var(--ot-ui-spacing-md);

        background: var(--ot-ui-color-accent-tertiary);
        padding: var(--ot-ui-spacing-2xl) var(--ot-ui-spacing-3xl);
        border-radius: 0 0 var(--ot-ui-radius-lg) var(--ot-ui-radius-lg);

        @include breakpoint(mob) {
            flex-direction: column-reverse;
            padding: var(--ot-ui-spacing-lg) var(--ot-ui-spacing-xl);
        }
    }
}
</style>
