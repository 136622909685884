import { OtError } from '@openticket/lib-log';

export class AdminActionsUnsetBaseUrlError extends OtError {

    constructor() {
        super('AdminActionsUnsetBaseUrlError', 'dashboard.errors.plugins.admin.actions.unset.base.url', 'AdminActions Base URL not set!');

        this.captureStackTrace(AdminActionsUnsetBaseUrlError);
    }

}
