import type { App } from 'vue';
import AvatarHeader from './AvatarHeader.vue';
import ConfettiWrapper from './ConfettiWrapper.vue';
import FormCollapse from './FormCollapse.vue';
import FormRow from './FormRow.vue';
import ModalHeader from './modal/ModalHeader.vue';

export {
    FormCollapse as OtFormCollapse,
    FormRow as OtFormRow,
};

export function registerComponents(app: App): void {
    // TODO: Update global typing
    app.component('AvatarHeader', AvatarHeader);
    app.component('ConfettiWrapper', ConfettiWrapper);
    app.component('OtFormCollapse', FormCollapse);
    app.component('OtFormRow', FormRow);
    app.component('ModalHeader', ModalHeader);
}
