import type { PluginsManager } from './manager';
import { Plugin } from './plugin';

interface Modifiers {
    altKey?: boolean;
    ctrlKey?: boolean;
    metaKey?: boolean;
    shiftKey?: boolean;
}

const modifiersKeys: ReadonlyArray<keyof Modifiers> = [ 'altKey', 'ctrlKey', 'metaKey', 'shiftKey' ] as const;

export interface HotkeyListener {
    readonly cb: ((event: KeyboardEvent) => void);
    readonly description: string;
    readonly key: string;
    readonly code: string | null;
    readonly modifiers: Readonly<Modifiers>;
    readonly remove: () => void;
}

export interface ParsedHotKeyActions {
    altKey?: ParsedHotKeyActions;
    ctrlKey?: ParsedHotKeyActions;
    metaKey?: ParsedHotKeyActions;
    shiftKey?: ParsedHotKeyActions;

    actions: {
        [code: string]: HotkeyListener | undefined;
    };
}

export class HotkeysPlugin extends Plugin<void> {

    actions: ParsedHotKeyActions = { actions: {} };
    flatActions: HotkeyListener[] = [];

    install(plugins: PluginsManager): void {
        try {
            window.addEventListener('keydown', (e: KeyboardEvent) => this.hotkeyListener(plugins, e), {
                // Catch it as early as possible in the event's lifecycle.
                // See https://www.w3.org/TR/DOM-Level-3-Events/#dom-event-architecture for a descriptive explanation.
                // Or see https://dom.spec.whatwg.org/#dispatching-events for the formal 'standard' version.
                capture: true,
            });

            this.resolve();
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

    hotkeyListener(plugins: PluginsManager, event: KeyboardEvent): void {
        const narrowed: ParsedHotKeyActions | null = narrowModifiers(this.actions, event);

        if (!narrowed) {
            // Modifier narrowing of the actions resulted in zero actions.
            return;
        }

        const action: HotkeyListener | undefined = narrowed.actions[event.code || event.key.toLowerCase()];

        if (!action) {
            return;
        }

        event.preventDefault();
        event.stopImmediatePropagation();

        action.cb.call(null, event);
    }

    registerHotKey(
        cb: (event: KeyboardEvent) => void,
        description: string,
        key: string,
        code: string | null = null,
        modifiers: Modifiers = {},
    ): () => void {
        const narrowed: ParsedHotKeyActions | null = narrowModifiers(this.actions, modifiers, true);

        if (!narrowed) {
            return () => { /* No-op */ };
        }

        const index: string = code || key.toLowerCase();

        const definition: HotkeyListener = {
            cb,
            description,
            key,
            code,
            modifiers,
            remove: () => {
                if (narrowed.actions[index] === definition) {
                    delete narrowed.actions[index];

                    const flatIndex: number = this.flatActions.indexOf(definition);

                    if (flatIndex > -1) {
                        this.flatActions.splice(flatIndex, 1);
                    }
                }
            },
        };

        const existing: HotkeyListener | undefined = narrowed.actions[index];

        if (existing) {
            existing.remove();
        }

        narrowed.actions[index] = definition;
        this.flatActions.push(definition);

        return definition.remove;
    }

}

function narrowModifiers(actions: ParsedHotKeyActions, modifiers: Modifiers, force = false): ParsedHotKeyActions | null {
    let current: ParsedHotKeyActions = actions;

    for (const modifier of modifiersKeys) {
        if (!modifiers[modifier]) {
            continue;
        }

        const nested: undefined | ParsedHotKeyActions = current[modifier];

        if (nested) {
            current = nested;
        } else if (force) {
            const forced: ParsedHotKeyActions = { actions: {} };

            current[modifier] = forced;
            current = forced;
        } else {
            return null;
        }
    }

    return current;
}
