<template>
    <div
        class="form-modal"
        data-testid="form-modal"
    >
        <div
            class="form-modal__backdrop"
            data-testid="form-modal-backdrop"
            role="button"
            tabindex="0"
            @click="close"
            @keydown.esc="close"
        />

        <OtCard
            class="form-modal__card"
        >
            <OtCardHeader
                :title="props.title"
                :subtitle="props.subtitle"
            />

            <OtCardContent class="form-modal__content">
                <slot />
            </OtCardContent>

            <OtCardFooter class="form-modal__footer">
                <OtButton
                    :title="$t('dashboard.common.action.cancel.title')"
                    variant="secondary"
                    icon="close"
                    data-testid="form-modal-cancel"
                    @click="close"
                >
                    {{ $t('dashboard.common.action.cancel.text') }}
                </OtButton>
                <div class="form-modal__footer__right">
                    <OtButton
                        v-if="addAnotherEnabled"
                        icon="plus"
                        data-testid="form-modal-submit-add-another"
                        :title="addAnotherButtonLabels?.title || $t('dashboard.common.action.add_another.title')"
                        :disabled="!form.hasLocalChanges"
                        @click="submit(true)"
                    >
                        {{ addAnotherButtonLabels?.text || $t('dashboard.common.action.add_another.text') }}
                    </OtButton>
                    <OtButton
                        :disabled="!form.hasLocalChanges"
                        :title="submitButtonLabels?.title || $t('dashboard.common.action.save.title')"
                        data-testid="form-modal-submit"
                        icon="check"
                        @click="submit()"
                    >
                        {{ submitButtonLabels?.text || $t('dashboard.common.action.save.text') }}
                    </OtButton>
                </div>
            </OtCardFooter>
        </OtCard>
    </div>
</template>

<script setup lang="ts" generic="M extends Model<P, ModelConfig>, P extends Parent">
import { onMounted, onUnmounted, type UnwrapNestedRefs } from 'vue';
import type { Model, ModelConfig, Parent } from '@openticket/lib-crud';
import type { FormModalComposableResult, ModelFormComposableResult } from '../../composables/forms';
import { useGenericErrorHandling } from '../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<M, P>>,
    modal: UnwrapNestedRefs<FormModalComposableResult>,
    title: string,
    subtitle?: string,
    submitButtonLabels?: {
        text?: string,
        title?: string,
    },
    addAnotherEnabled?: boolean,
    addAnotherButtonLabels?: {
        text?: string,
        title?: string,
    },
}

const props = defineProps<Props>();

const { handleErrorSilently } = useGenericErrorHandling();

onMounted(() => {
    window.addEventListener('keydown', escListener, true);

    const inputElement = document.querySelector('.form-modal__content > input');

    if (inputElement && 'focus' in inputElement && typeof inputElement.focus === 'function') {
        inputElement.focus();
    }
});

onUnmounted(() => {
    window.removeEventListener('keydown', escListener, true);
});

async function submit(addAnother?: boolean) {
    try {
        const result = await props.form.submit();

        if (addAnother && result.success) {
            props.form.reset();
            return;
        }

        if (result.success) {
            close();
        }
    } catch (e) {
        void handleErrorSilently(e);
    }
}

function close() {
    props.modal.close();
}

function escListener(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') {
        void props.modal.close();
        event.stopImmediatePropagation();
    }

    if (event.key === 'Enter' && props.form.hasLocalChanges) {
        void submit();
    }
}
</script>

<style scoped lang="scss">
.form-modal {
    z-index: 300;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--ot-ui-color-foreground-secondary) transparent;

    &__footer {
        text-wrap: nowrap;
        gap: var(--ot-ui-spacing-default);
        align-items: center;

        :deep(.ot-ui-card-footer) {
            --ot-ui-card-footer-padding: var(--ot-ui-spacing-sm) var(--ot-ui-spacing-md);
        }

        &__right {
            justify-content: flex-end;
            display: flex;
            flex-wrap: wrap;
            gap: var(--ot-ui-spacing-md);
            flex-grow: 1;
        }
    }

    @media (max-width: 40rem) {
        &__footer {
            align-items: stretch;
            text-wrap: wrap;

            &__right {
                align-items: stretch;
                flex-direction: column-reverse;
            }
        }
    }

    &__backdrop {
        position: fixed;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.3;
    }

    // TODO: probably replace the component with OtModal, skipping this during the migration. cu-86c0xzeup
    &:deep(.ot-ui-card) {
        padding: 0;
    }

    &__card {
        border-radius: var(--ot-ui-input-radius);
        overflow: visible !important;
        z-index: 301;
        margin: 15vh auto var(--ot-ui-spacing-3xl) auto;
        width: 100%;
        max-width: calc(65rem - var(--ot-ui-dashboard-layout-sidebar-width, 16rem));
        padding: 0;

        @media (max-width: 40rem) {
            margin: 0;
        }
    }
}
</style>
