<template>
    <div class="context-filter">
        <div
            v-if="opened"
            class="context-filter__backdrop"
            role="button"
            tabindex="0"
            @click="close"
            @keydown.esc="close"
        />
        <OtCard
            v-if="opened"
            class="context-filter__card"
            no-padding
        >
            <OtSearchInput
                :placeholder="searchLabel"
                :value="searchValue"
                class="context-filter__card__search"
                @input="handleSearch"
            />
            <span
                v-if="!filteredList.length"
                class="context-filter__card__not-found ot-ui-text-body-md"
            >
                {{ notFoundLabel }}
            </span>
            <ul class="context-filter__card__list">
                <li
                    v-for="item of filteredList"
                    :key="item.guid"
                    class="context-filter__card__list__item"
                    :class="[
                        { selected: item.guid === selected },
                        `context-filter__card__list__item__${typeof item.guid === 'symbol' ? item.guid.description : item.guid}`,
                    ]"
                    role="button"
                    tabindex="0"
                    :title="item.name"
                    @click="selectOption(item.guid)"
                    @keydown.space="selectOption(item.guid)"
                    @keydown.enter="selectOption(item.guid)"
                >
                    <div class="context-filter__card__list__item__content">
                        <h4 class="two-line-max ot-ui-text-heading-4">
                            {{ item.name }}
                        </h4>
                        <OtIcon
                            type="carrot-right"
                            size="small"
                        />
                    </div>
                </li>

                <OtPaginationControls
                    v-if="optionsIsPagination(options) && lastPage > 1"
                    :pagination="options"
                    centered
                    hide-per-page
                    simple
                />
            </ul>
        </OtCard>
        <div
            v-if="back"
            class="context-filter__back"
            data-testid="context-filter-back"
        >
            <OtButton
                icon="carrot-left"
                variant="inline"
                size="small"
                text-align="start"
                @on-click="handleBackClick"
            >
                <span class="two-line-max">{{ back.text }}</span>
            </OtButton>
        </div>
        <div
            :title="activeOption?.name"
            class="context-filter__trigger"
            data-testid="context-filter-active-option"
            role="button"
            tabindex="0"
            @click="open"
            @keydown.space="open"
            @keydown.enter="open"
        >
            <h4 class="two-line-max ot-ui-text-heading-4">
                {{ activeOption?.name }}
            </h4>
            <OtIcon
                type="drop-both"
                size="small"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    computed, onMounted, onUnmounted, ref,
} from 'vue';
import type { UnwrapNestedRefs } from 'vue';
import type { CompanyRaw } from '@openticket/lib-auth';
import type {
    CompanyPath, Event as ManagementEvent, ManagementClient, Shop,
} from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import { useRouter } from 'vue-router';
import { useLocalization } from '@openticket/vue-ui';
import type { Context } from '../../services/context';
import { injectOrFail } from '../../services/util';
import { handleContextFilterUpdate } from '../../services/context/contextFilter';

type ContextFilterOption = {
    guid: string | symbol,
    name: string,
}

type UnwrappedPaginationEvents = UnwrapNestedRefs<Pagination<ManagementEvent<ManagementClient>
    | ManagementEvent<CompanyPath<ManagementClient>>> | null>;
type UnwrappedPaginationShops = UnwrapNestedRefs<Pagination<Shop<CompanyPath<ManagementClient>>> | null>;

type Props = {
    companies: CompanyRaw[],
    events: UnwrappedPaginationEvents,
    shops: UnwrappedPaginationShops,
}
const props = defineProps<Props>();

const context = injectOrFail<Context>('context');

const { t } = useLocalization();
const router = useRouter();

const opened = ref<boolean>(false);
const searchValue = ref<string>('');

const lastPage = computed<number>(() => (options.value
    && optionsIsPagination(options.value) && options.value.lastPage) || 1);

const allCompanies: symbol = Symbol.for('all_companies');

const selected = computed<string | symbol>(() => {
    if (context.isEventContext()) {
        return context.event.id;
    }
    if (context.isShopContext()) {
        return context.shop.id;
    }
    return context.company?.id || allCompanies;
});

const activeOption = computed<ContextFilterOption | undefined>((): ContextFilterOption | undefined => {
    if (context.isEventContext()) {
        return {
            guid: selected.value,
            name: context.event?.name || (typeof selected.value === 'string' ? selected.value : ''),
        };
    }
    if (context.isShopContext()) {
        return {
            guid: selected.value,
            name: context.shop?.name || (typeof selected.value === 'string' ? selected.value : ''),
        };
    }

    const active = (options.value as ContextFilterOption[]).find((option) => option.guid === selected.value);

    if (active || !context.isCompanyContext()) {
        return active;
    }

    // When the user is an admin and the company is not listed in the options, still display the context.
    return {
        name: context.company.name,
        guid: context.company.id,
    };
});

const notFoundLabel = computed<string>(() => {
    if (context.isEventContext()) {
        return t('dashboard.sidebar.context_filter.event.not_found');
    }
    if (context.isShopContext()) {
        return t('dashboard.sidebar.context_filter.shop.not_found');
    }
    return t('dashboard.sidebar.context_filter.company.not_found');
});

const searchLabel = computed<string>(() => {
    if (context.isEventContext()) {
        return t('dashboard.sidebar.context_filter.event.search');
    }
    if (context.isShopContext()) {
        return t('dashboard.sidebar.context_filter.shop.search');
    }
    return t('dashboard.sidebar.context_filter.company.search');
});

const back = computed(() => {
    if (context.isEventContext()) {
        return {
            text: context.company.name,
            title: t('dashboard.sidebar.context_filter.back_to', { name: context.company.name }),
            route: {
                name: 'events.list',
                // Replaced with `handleBackClick` function below. Leaving this commented code in till after the context service
                // rewrite so it's easier findable.
                // TODO: Remove
                // params: { company: context.company.id },
            },
        };
    }

    if (context.isShopContext()) {
        return {
            text: context.company.name,
            title: t('dashboard.sidebar.context_filter.back_to', { name: context.company.name }),
            route: {
                name: 'shops.list',
                // Replaced with `handleBackClick` function below. Leaving this commented code in till after the context service
                // rewrite so it's easier findable.
                // TODO: Remove
                // params: { company: context.company.id },
            },
        };
    }

    return null;
});

const filteredList = computed<ContextFilterOption[]>((): ContextFilterOption[] => {
    if (!options.value) {
        return [];
    }

    if (optionsIsPagination(options.value)) {
        return options.value.records.flatMap((item) => (item.id ? [ {
            guid: item.id,
            name: (item.$data.name) || '',
        } ] : []));
    }

    if (!searchValue.value) {
        return options.value;
    }

    return options.value.filter((option) => option.name.toUpperCase().includes(searchValue.value.toUpperCase()));
});

const options = computed<ContextFilterOption[] | UnwrappedPaginationShops | UnwrappedPaginationEvents>(() => {
    if (context.isEventContext()) {
        return props.events ?? [];
    }

    if (context.isShopContext()) {
        return props.shops ?? [];
    }

    return [
        {
            guid: allCompanies,
            name: String(t('dashboard.sidebar.context_filter.company.all_companies')),
        },
        ...(props.companies ?? []).map((company) => ({
            guid: String(company.guid),
            name: company.name,
        })),
    ];
});

const escListener = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' || event.key === 'Esc') {
        void close();
    }
};

onMounted(() => {
    window.addEventListener('keydown', escListener);
});

onUnmounted(() => {
    window.removeEventListener('keydown', escListener);
});

function open() {
    opened.value = true;
}

function close() {
    opened.value = false;
    searchValue.value = '';
}

function selectOption(guid: string | symbol) {
    close();
    handleContextFilterUpdate(router, guid, context);
}

function optionsIsPagination(contextFilterOptions: ContextFilterOption[] | UnwrappedPaginationEvents | UnwrappedPaginationShops)
    : contextFilterOptions is UnwrappedPaginationEvents | UnwrappedPaginationShops {
    return !Array.isArray(contextFilterOptions);
}

async function handleSearch(search: Event | string) {
    if (search instanceof Event && search.target instanceof HTMLInputElement) {
        searchValue.value = search.target.value;
    } else if (typeof search === 'string') {
        searchValue.value = search;
    }

    if (options.value && optionsIsPagination(options.value)) {
        await options.value.setFilter('name', 'contains', searchValue.value || null);
    }
}

async function handleBackClick() {
    if (!back.value) {
        return;
    }

    const companyId = context.isCompanyContext() ? context.company.id : null;

    await router.push(back.value.route);

    // Below is needed to set the company context when going to a global context route. The company is not stored correctly as
    // it is discarded from the params.
    // TODO: Replace this behaviour when the context service is rewritten
    setTimeout(() => {
        if (!companyId) {
            return;
        }

        void context.updateContext('company', { company: companyId });
    }, 0);
}
</script>

<style lang="scss" scoped>
.context-filter {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    height: 5rem;

    &__backdrop {
        z-index: 300;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.3;
    }

    &__back {
        a {
            width: 100%;
            justify-content: flex-start;

            svg {
                min-width: 1rem;
            }
        }
    }

    &__trigger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        h4 {
            margin: 0;
        }

        svg {
            min-width: 1rem;
        }
    }

    &__card {
        z-index: 301;
        position: absolute;
        width: calc(100% + var(--ot-ui-spacing-2xl));
        display: flex;
        flex-direction: column;
        gap: var(--ot-ui-spacing-sm);
        padding: var(--ot-ui-spacing-sm) var(--ot-ui-spacing-md) !important;
        margin: 0 calc(var(--ot-ui-spacing-md) * -1.25);

        @media (min-width: 40rem) and (max-width: 60rem) {
            --ot-ui-spacing-sm: calc(1rem * 0.75);
        }

        &__not-found {
            text-align: center;
            color: var(--ot-ui-color-accent-primary);
        }

        &__list {
            max-height: 50vh;
            overflow-x: hidden;
            overflow-y: auto;
            margin: 0 calc(-1 * var(--ot-ui-spacing-md));

            &__item {
                padding: 0 var(--ot-ui-spacing-lg);
                cursor: pointer;

                &__content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: var(--ot-ui-spacing-xs) 0;
                    border-top: 1px solid var(--ot-ui-color-accent-tertiary);

                    h4 {
                        margin: calc(-1 * var(--ot-ui-spacing-3xs)) 0 var(--ot-ui-spacing-3xs) 0;
                    }

                    svg {
                        min-width: var(--ot-ui-spacing-md);
                    }
                }

                &.selected {
                    background: var(--ot-ui-color-accent-secondary);
                    color: var(--ot-ui-color-brand);
                }

                &.selected &__content {
                    border-top-color: #00000000;
                }

                &.selected + & {
                    & > div {
                        border-top-color: #00000000;
                    }
                }

                &:first-child &__content {
                    border-top-color: #00000000;
                }
            }

            &__controls {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: var(--ot-spacing-xs);
                border-top: 1px solid var(--ot-color-core-accent-tertiary);
                padding-top: var(--ot-spacing-sm);

                &__icon {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &[disabled], &.disabled {
                        color: var(--ot-color-core-accent-primary);
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

.two-line-max {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports(-webkit-line-clamp: 2) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
</style>
