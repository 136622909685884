<template>
    <div class="debug-plugins__whitelabel">
        <code
            class="debug-plugins__whitelabel__status full-width"
        >
            <span>Status:</span>

            <span v-if="loading">Loading</span>
            <span v-else>Loaded</span>
        </code>

        <code
            class="debug-plugins__whitelabel__logo full-width"
        >
            <img
                :src="$whitelabel.resources.logo"
                alt="Whitelabel logo"
            >
        </code>

        <code
            class="debug-plugins__whitelabel__info full-width"
        >
            <span class="row">Info:</span>
            <span class="row"><span>[Name]</span><span v-if="$whitelabel">{{ $whitelabel.name }}</span></span>
            <span class="row"><span>[Website]</span><span v-if="$whitelabel">{{ $whitelabel.website_url }}</span></span>
        </code>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import type { PluginsManager } from '../../../../plugins';
import { injectOrFail } from '../../../../services/util';

const plugins = injectOrFail<PluginsManager>('plugins');
const loading = ref<boolean>(true);

onMounted(() => {
    void initializePluginDebug();
});

async function initializePluginDebug() {
    try {
        await plugins.whitelabel.loading;
    } finally {
        loading.value = false;
    }
}
</script>

<style lang="scss" scoped>
.debug-plugins__whitelabel {
    & > code {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;

        &.debug-plugins__whitelabel__info {
            flex-direction: column;
            justify-content: flex-start;

            & > .row {
                display: flex;
                justify-content: space-between;
            }
        }

        &.debug-plugins__whitelabel__logo {
            background: var(--ot-color-core-brand-invert, #FFFFFF);
            color: var(--ot-color-core-brand, #006AFF);
            border: 1px solid var(--ot-color-core-brand, #006AFF);
            padding: .5rem;

            & > img {
                max-width: 100%;
            }
        }
    }

}
</style>
