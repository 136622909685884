import type { App } from 'vue';
import { isOtError, send } from '@openticket/lib-log';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import { RudderStack } from '../services/rudderstack';
import type { RudderstackOptions } from './types';

export class RudderStackPlugin extends Plugin<RudderStack> {

    async install(plugins: PluginsManager, app: App): Promise<void> {
        const rudderstack = new RudderStack();

        const options: RudderstackOptions = plugins.options.rudderstack || {};

        try {
            const whitelabel = await plugins.whitelabel.loading;

            const rudderstackPlaneUrl = options.rudderstackPlaneUrl || whitelabel.dashboard.rudderstack_data_plane_url;
            const rudderstackWriteKey = options.rudderstackWriteKey || whitelabel.dashboard.rudderstack_write_key;
            const rudderstackPluginsUrl = options.rudderstackPluginsUrl || whitelabel.dashboard.rudderstack_plugins_url;
            const rudderstackIntegrationsUrl = options.rudderstackIntegrationsUrl
                || whitelabel.dashboard.rudderstack_integrations_url;
            const rudderstackConfigUrl = options.rudderstackConfigUrl || whitelabel.dashboard.rudderstack_config_url;

            if (rudderstackWriteKey && rudderstackPlaneUrl) {
                rudderstack.init(
                    rudderstackWriteKey,
                    rudderstackPlaneUrl,
                    rudderstackConfigUrl,
                    rudderstackPluginsUrl,
                    rudderstackIntegrationsUrl,
                );
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());

                if (isOtError(e)) {
                    send(e);
                }
            }
        } finally {
            app.provide('rudderstack', rudderstack);

            this.resolve(rudderstack);
        }
    }

}
