<template>
    <div id="app">
        <OtSplash
            v-if="isLoading && componentsRegistered"
            class="app__loading"
            :spinner="spinner"
        />

        <div
            v-if="!isLoading"
            class="app__content"
        >
            <Suspense>
                <ContextProvider>
                    <DebugWrapper
                        :is-loading="isLoading"
                        :components-registered="componentsRegistered"
                    />
                    <router-view />
                </ContextProvider>
                <template #fallback>
                    <OtSplash
                        class="app__loading"
                        :spinner="spinner"
                    />
                </template>
            </Suspense>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { send, Log } from '@openticket/lib-log';
import { computed, onMounted, ref } from 'vue';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { useRouter } from 'vue-router';
import { OtSplash } from '@openticket/vue-ui';
import DebugWrapper from './modules/debug/DebugWrapper.vue';
import type { PluginsManager } from './plugins';
import { injectOrFail } from './services/util';
import ContextProvider from './layouts/ContextProvider.vue';

const plugins = injectOrFail<PluginsManager>('plugins');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const router = useRouter();

const isLoading = ref<boolean>(true);
const componentsRegistered = ref<boolean>(false);

const spinner = computed<string>(() => whitelabel.resources.spinner);

onMounted(() => {
    void load();
});

const load = async () => {
    try {
        send({
            href: window.location.href,
            message: 'Dashboard: App initialized',
            slug: 'dashboard.log.messages.init.app',
            ts: Date.now(),
        }, Log.Info);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.debug('Failed to send app init log message', { e });
        // Fail silently
    }
    try {
    //     // Components are needed for the splash screen. Await those first.
        await plugins.ui.loading;
        componentsRegistered.value = true;

        //     // TODO Should localization be awaited on loading or registration???
        await plugins.all;
    } catch (e) {
    //     // TODO Send log
    //     // TODO Error data & Check what happens if already @ /error
    //     //  -> Duplicate navigation is aborted -> should be fixed/workaround (ignore??? and append reason???)
    //     // TODO also find a way to send more error context to the error page...
        console.error('App created error: ', { e });

        if (router.currentRoute.value.name !== 'error' && router.currentRoute.value.name !== 'auth.login') {
            void router.push({ name: 'error', query: { reason: e instanceof Error ? e.message : 'unknown init error' } });
        }
    } finally {
        isLoading.value = false; // TODO Check all loading vars, and make them consistent
    }
};
</script>

<style lang="scss">
@import "./assets/scss/mixins.scss";

a {
    text-decoration: none;
    font-weight: var(--ot-ui-font-weight-medium);
    color: inherit;

    &:visited {
        color: inherit;
    }
}
</style>
