<template>
    <OtModal
        ref="modalRef"
        class="link-unlink-modal"
        data-testid="link-unlink-modal"
        :width="600"
        :title="props.title"
        :subtitle="props.subtitle"
    >
        <LinkUnlinkPicker
            v-if="pickerInitialized"
            :key="linkUnlinkPickerKey"
            ref="linkUnlinkPickerRef"
            :create-modal="createModal"
            :items-label="itemsLabel"
            :link-relation="linkRelation"
            :pagination-relation="paginationRelation"
            @saved="(items) => emit('saved', items)"
            @submit="(items) => emit('submit', items)"
        />
        <template #footer-action>
            <OtButton
                data-testid="link-unlink-modal-submit"
                icon="check"
                :title="saveLabel"
                :disabled="!linkUnlinkPickerRef || !linkUnlinkPickerRef?.hasLocalChanges"
                @click="save"
            >
                {{ saveLabel }}
            </OtButton>
        </template>
    </OtModal>
</template>

<script setup lang="ts">
import { ref, useTemplateRef } from 'vue';
import type {
    CrudNode,
    LinkMultiable,
    Listable,
    Model,
    ModelConfig,
    Parent,
    Pickable,
    UnlinkMultiable,
} from '@openticket/lib-crud';
import { OtModal, useLocalization } from '@openticket/vue-ui';
import LinkUnlinkPicker from './LinkUnlinkPicker.vue';
import type FormModal from '../../form/FormModal.vue';

type Props = {
    createModal?: InstanceType<typeof FormModal>,
    inputDescription?: string,
    inputLabel?: string,
    itemsLabel?: string;
    linkRelation?: Pickable<Model<Parent, ModelConfig>, ModelConfig, Parent>
        & LinkMultiable<Model<CrudNode<Parent>, ModelConfig>, ModelConfig, CrudNode<Parent>, Model<Parent, ModelConfig>>
        & UnlinkMultiable<Model<CrudNode<Parent>, ModelConfig>, ModelConfig, CrudNode<Parent>, Model<Parent, ModelConfig>>,
    notShownLabel?: string,
    paginationRelation: Listable<Model<Parent, ModelConfig>, ModelConfig, Parent>,
    saveText?: string,
    subtitle?: string,
    title: string,
}

type Emits = {
    (e: 'saved', linkedGuids: string[]): void
    (e: 'submit', linkedGuids: string[]): void
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { t } = useLocalization();

const modalRef = ref<InstanceType<typeof OtModal> | null>(null);
// Only render the picker when the modal has been opened for the first time, to prevent a call at initialization.
const pickerInitialized = ref<boolean>(false);
const linkUnlinkPickerKey = ref((Math.random() + 1).toString(36).substring(7));
const linkUnlinkPickerRef = useTemplateRef('linkUnlinkPickerRef');

const saveLabel = props.saveText || t('dashboard.common.action.save.text');

function save() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    linkUnlinkPickerRef.value?.submit();
    close();
}

function open() {
    if (modalRef.value) {
        pickerInitialized.value = true;
        linkUnlinkPickerKey.value = (Math.random() + 1).toString(36).substring(7);

        modalRef.value.open();
    }
}

function close() {
    if (modalRef.value) {
        void modalRef.value.close();
    }
}

defineExpose({
    open,
});
</script>

<style lang="scss" scoped>
.link-unlink-modal {
    min-width: 600px;
    overflow: visible;

    &__content {
        padding-bottom: var(--ot-ui-spacing-2xl) !important;
    }

    @media (max-width: 40rem) {
        min-width: unset;
        width: 100% !important;
    }

    :deep(.ot-ui-card) {
        overflow: visible !important;
    }

    :deep(.ot-ui-card-footer) {
        border-radius: 0 0 var(--ot-ui-input-radius) var(--ot-ui-input-radius);
    }
}
</style>
