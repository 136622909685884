import type { RouteRecordRaw } from 'vue-router';
import ProductPortalView from './views/ProductPortalView.vue';

export function productPortalRoutes(): RouteRecordRaw[] {
    return [
        {
            path: '/product-portal',
            component: ProductPortalView,
            meta: {
                context: 'global',
                title: 'dashboard.document_title.product_portal.title',
                titleFallback: 'Product Portal',
            },
        },
    ];
}
