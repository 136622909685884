<template>
    <OtCard
        class="outstanding-invitation"
        no-padding
    >
        <ModalHeader class="outstanding-invitation__header" />

        <OtCardHeader :title="$t( 'dashboard.header.profile.invitations.title')" />

        <template v-if="user">
            <OtCardContent class="outstanding-invitation__content">
                <!-- TODO: Update DS of invitations | CU-86c0xx206 -->
                <InvitationsList
                    :invitations="invitations"
                    :two-factor-enabled="user.two_factor_enabled"
                    :no-invitation-warning="{
                        title: 'dashboard.header.profile.invitations.no_invitations.title',
                        description: noInvitationsDescription,
                    }"
                    :show-redirect-to-fix-page="true"
                    @accepted="acceptedInvitation"
                />
                <!-- DD-DASHBOARD-2714 - New users with no valid invitation (all are deleted or expired),
                cannot create a new company. All they can do is log out.-->
            </OtCardContent>

            <OtCardFooter>
                <template #right>
                    <OtButton
                        v-if="tfaInvite && !user.two_factor_enabled"
                        icon="call"
                        :title="$t('dashboard.user.tfa.action.enable.title')"
                        variant="secondary"
                        @click="redirectTfa"
                    >
                        {{ $t('dashboard.user.tfa.action.enable.text') }}
                    </OtButton>

                    <OtButton
                        :disabled="!hasACompany"
                        icon-right="arrow-right"
                        :title="$t('dashboard.common.action.next.title')"
                        @click="redirectLogin"
                    >
                        {{ $t('dashboard.common.action.next.text') }}
                    </OtButton>
                </template>
            </OtCardFooter>
        </template>

        <OtSpinner v-else />
    </OtCard>
</template>

<script setup lang="ts">
import type { AuthClient } from '@openticket/lib-auth';
import urlJoin from 'url-join';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import InvitationsList from '../../../components/invitations/InvitationsList.vue';
import { injectOrFail } from '../../../services/util';
import type { OptionalClientConfig, StrippedMinimalUser } from '../types';
import type { RudderStack } from '../../../services/rudderstack';

interface MinimalCompany {
    name: string;
    guid: string;
    force_tfa: boolean;
}

const auth = injectOrFail<AuthClient>('auth');
const user = injectOrFail<StrippedMinimalUser>('minimal_user');
const rudderstack = injectOrFail<RudderStack>('rudderstack');

const tfaInvite = ref<boolean>(false);
const hasACompany = ref<boolean>(false);
const invitations = ref<MinimalCompany[]>([]);
const clientConfig = ref<OptionalClientConfig>();

const noInvitationsDescription = computed((): string => {
    if (user.has_a_company) {
        return 'dashboard.header.profile.invitations.no_invitations.description_with_companies';
    }

    return 'dashboard.header.profile.invitations.no_invitations.description';
});

const route = useRoute();
const router = useRouter();

void (async (): Promise<void> => {
    clientConfig.value = route.query;

    // TODO - @openticket/lib-auth
    const response: { data: { data: MinimalCompany[] } } = await auth.$http.get<{ data: MinimalCompany[] }>(
        urlJoin(auth.$path, 'invitations', 'companies'),
    );

    invitations.value = response.data.data;
    tfaInvite.value = invitations.value.some((invite): boolean => invite.force_tfa);

    hasACompany.value = user.has_a_company;

    rudderstack.track('vue-dashboard fix zero companies init', { invitationsCount: invitations.value.length ?? 0 });
})();

async function redirectTfa(): Promise<void> {
    await router.push({ name: 'fix.tfa', query: { ...clientConfig.value, 'forced-warning': 'hide' } });
}

async function redirectLogin(): Promise<void> {
    rudderstack.track('vue-dashboard fix zero companies redirect login');
    await router.push({ name: 'auth.login', query: clientConfig.value });
}

function acceptedInvitation(): void {
    hasACompany.value = true;
    rudderstack.track('vue-dashboard fix zero companies accepted invitation');
}
</script>
