import Axios, { isAxiosError } from 'axios';
import type { AxiosInstance, AxiosResponse } from 'axios';
import urlJoin from 'url-join';
import type { ShopData } from './types';
import { parseRawShopData } from './parser/parser';
import { InvalidShopDataIdError } from './errors/invalid.shop.data.id.error';
import { InvalidShopDataApiError } from './errors/invalid.shop.data.api.error';

export class ShopDataManager {

    readonly baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    async load(id: string): Promise<ShopData> {
        if (!id) {
            throw new InvalidShopDataIdError();
        }

        const url: string = urlJoin(this.baseUrl, id, 'data');

        const data: unknown = await this.getShopData(url);

        return parseRawShopData(data);
    }

    private async getShopData(url: string): Promise<unknown> {
        try {
            const axios: AxiosInstance = Axios.create();

            const response: AxiosResponse<unknown> = await axios.get(url);

            return response.data;
        } catch (e) {
            if (isAxiosError(e)) {
                throw new InvalidShopDataApiError(url, e);
            }

            throw e;
        }
    }

}
