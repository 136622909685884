<template>
    <OtModal
        ref="invitationsModal"
        :title="$t('dashboard.header.profile.invitations.title')"
        :width="800"
    >
        <div
            v-if="invitations"
            class="profile-invitations__modal"
        >
            <OtCardContent
                class="profile-invitations__modal__content"
                no-padding
            >
                <OtSpinner
                    v-if="invitations.isLoading"
                    class="ot-ui-p[2xs]!"
                />

                <!-- TODO: Update the (nested) components below CU-86c0xx206 -->
                <InvitationsList
                    v-else
                    :invitations="pageValues"
                    :show-redirect-to-fix-page="false"
                    :two-factor-enabled="tfaEnabled"
                />
            </OtCardContent>
        </div>
    </OtModal>
</template>

<script lang="ts" setup>
import type {
    AuthClient, Invitation, UserRaw,
} from '@openticket/lib-auth';
import type { Pagination } from '@openticket/lib-crud';
import { computed, ref, useTemplateRef } from 'vue';
import { OtModal } from '@openticket/vue-ui';
import InvitationsList from '../../../components/invitations/InvitationsList.vue';
import { injectOrFail } from '../../../services/util';

interface MinimalCompany {
    name: string;
    guid: string;
    force_tfa: boolean;
}

type Emits = {
    (e: 'close'): void
}
const emit = defineEmits<Emits>();

const auth = injectOrFail<AuthClient>('auth');

const invitationsModal = useTemplateRef('invitationsModal');
const user = ref<UserRaw | undefined>(undefined);
const invitations = ref<Pagination<Invitation<AuthClient>> | null>(null);

const tfaEnabled = computed((): boolean => !!(user.value && user.value.two_factor_enabled));
const pageValues = computed<MinimalCompany[]>(() => {
    if (!invitations.value) {
        return [];
    }

    // TODO: Cleanup invitation type in map function
    return invitations.value.records.map((invitation: Invitation<AuthClient>) => invitation.$data as MinimalCompany);
});

void (async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    invitationsModal.value?.registerBeforeClose(async () => {
        closeInvitations();
        return Promise.resolve(true);
    });

    const tokenInfo = await auth.$token.$info;
    user.value = tokenInfo ? tokenInfo.user : undefined;

    invitations.value = auth.invitations.list({ deferInitialization: true });

    await invitations.value?.initialization;
})();

async function refreshInvitations(): Promise<void> {
    if (invitations.value) {
        await invitations.value.loadPage();
    }
}

function open(): void {
    void refreshInvitations();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    invitationsModal.value?.open();
}

function closeInvitations(): void {
    void refreshInvitations();
    emit('close');
}

defineExpose({
    open,
});
</script>

<style lang="scss" scoped>
.profile-invitations {
    &__modal {
        &__content {
            margin-bottom: var(--ot-ui-spacing-2xl);
        }
    }
}
</style>
