<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <div
        v-else-if="orders"
        class="module__orders__list"
    >
        <OtDataGrid
            class="module__orders__list__grid"
            type="table"
            :pagination="orders"
            :columns="columns"
            :empty-labels="{
                subtitle: $t('dashboard.orders.list.empty.message'),
                title: $t('dashboard.orders.list.empty.title'),
            }"
            :loading-text="$t('dashboard.orders.list.header.loading')"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="orders"
                    searchable
                    :search-key="'email'"
                    :search-placeholder="$t('dashboard.orders.list.header.search.placeholder')"
                    :title="$t('dashboard.document_title.orders.list')"
                >
                    <!-- TODO: Restore when orders db has been indexed -->
                    <!-- <template #filters>
                        <OtFilters
                            :pagination="orders"
                            :view-filter="{ label: companyViewFilterSlug }"
                            :categories="[
                                {
                                    label: $t('dashboard.orders.list.filters.by_status.label'),
                                    icon: 'alert',
                                    targets: [ 'status' ]
                                },
                            ]"
                            :labels="{
                                status: {
                                    cancelled: $t('dashboard.orders.status.cancelled'),
                                    expired: $t('dashboard.orders.status.expired'),
                                    failed: $t('dashboard.orders.status.failed'),
                                    paid: $t('dashboard.orders.status.paid'),
                                    'paid decide': $t('dashboard.orders.status.paid_decide'),
                                    pending: $t('dashboard.orders.status.pending'),
                                    refunded: $t('dashboard.orders.status.refunded'),
                                    refunding: $t('dashboard.orders.status.refunding'),
                                    unknown: $t('dashboard.orders.status.unknown'),
                                    verify: $t('dashboard.orders.status.verify'),
                                }
                            }"
                            :tags-to-hide="[ 'email' ]"
                            :new-filter-label="$t('dashboard.common.filters.new')"
                            :apply-filter-label="$t('dashboard.common.filters.apply')"
                        />
                    </template> -->
                </OtListHeader>
            </template>

            <template #column__event_name>
                Open+++Ticket Event
            </template>

            <template #column__shop_name>
                Open+++Ticket Shop
            </template>

            <template #column__$data__status="{ value }">
                <OtFlair
                    :variant="orderStatusColors[value] || orderStatusColors['unknown']"
                    inversed
                    centered
                    :text="$t(`models.order.attributes.status.enum.${value}`)"
                />
            </template>

            <template #column__tickets_count>
                Open+++Ticket
            </template>

            <template #column__$data__finn_price="{ value }">
                {{ $l.currency(value, currency) }}
            </template>

            <template #actions="{ record }">
                <OtButton
                    data-testid="order-list-view-edit-order"
                    icon-right="carrot-right"
                    size="small"
                    variant="outline"
                    @on-click="goToOrderDetails(record.id)"
                >
                    {{ $t('dashboard.orders.list.table.edit.text') }}
                </OtButton>
            </template>
        </OtDataGrid>
    </div>

    <OtSpinner v-else />
</template>

<script setup lang="ts">
import type { ManagementClient, Order } from '@openticket/lib-management';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import type { Pagination } from '@openticket/lib-crud';
import { useLocalization, type OtDataGridColumn } from '@openticket/vue-ui';
import ErrorView from '../../../components/ErrorView.vue';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';
import { useGenericErrorHandling } from '../../../composables';

const router = useRouter();
const { error, handleError } = useGenericErrorHandling();
const { t } = useLocalization();

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const columns: Array<OtDataGridColumn> = [
    {
        key: [ '$data', 'created_at' ],
        type: 'date',
        label: t('models.order.attributes.created_at.label'),
        // TODO: Remove when orders db has been indexed
        sortingKey: 'UNKNOWN_PROPERTY',
    },
    {
        key: [ '$data', 'fullname' ],
        type: 'string',
        label: t('models.order.attributes.fullname.label'),
        // sortingKey: 'firstName',
        // TODO: Remove when orders db has been indexed
        sortingKey: 'UNKNOWN_PROPERTY',
    },
    // {
    //     key: [ 'event_name' ], // TODO
    //     type: 'string',
    //     label: 'Event',
    // },
    // {
    //     key: [ 'shop_name' ], // TODO
    //     type: 'string',
    //     label: 'Shop',
    // },
    {
        key: [ '$data', 'status' ],
        type: 'custom',
        label: t('models.order.attributes.status.label'),
        // TODO: Remove when orders db has been indexed
        sortingKey: 'UNKNOWN_PROPERTY',
    },
    // {
    //     key: [ 'tickets_count' ], // TODO
    //     type: 'number',
    //     label: 'Tickets',
    // },
    // {
    //     key: [ '$data', 'finn_price' ],
    //     type: 'number',
    //     label: 'Amount',
    // },
];

const orderStatusColors = {
    cancelled: 'danger',
    expired: 'danger',
    failed: 'danger',
    paid: 'success',
    pending: 'neutral',
    refunded: 'danger',
    refunding: 'warning',
    swapped: 'info',
    unknown: 'warning',
    verify: 'warning',
};

// TODO: Restore when orders db has been indexed
// const { list: orders, setPagination } = useRouterPagination(management.orders, { defaultSort: [ [ 'created_at', 'desc' ] ] });
const orders = ref<Pagination<Order<ManagementClient>> | null>(null);

// TODO Should come from payment??? On global context, we now don't have a currency...
const currency = computed(() => (context.isCompanyContext() ? context.company.model.$data.currency : 'XPD')); // TODO ALTERNATIVE HARDCODED!!!!
// TODO: Restore when orders db has been indexed
// const companyViewFilterSlug = computed<string>(() => (context.isCompanyContext()
//     ? context.company.name
//     : localization.getI18n().t('dashboard.sidebar.context_filter.company.all_companies') as string
// ));

watch(() => context.company, (newValue, oldValue) => {
    if (newValue?.id !== oldValue?.id) {
        // TODO: Restore when orders db has been indexed
        // void setPagination(management.orders);
        void setPagination();
    }
});

// TODO: Remove when orders db has been indexed
async function setPagination() {
    try {
        orders.value = management.orders.list({ deferInitialization: true });
        await orders.value.initialization;
    } catch (e: unknown) {
        void handleError(e);
    }
}
void setPagination();

function goToOrderDetails(order: string): void {
    try {
        const params: {order: string, company?: string} = { order };

        if (context.isCompanyContext()) {
            params.company = context.company.id;
        }

        void router.push({
            name: 'orders.details',
            params,
        });
    } catch (e) {
        void handleError(e);
    }
}
</script>
