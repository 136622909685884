import type { App } from 'vue';
import type { Options } from './types';
import { AdminActionsPlugin } from './adminActions.plugin';
import { AlbatoPlugin } from './albato.plugin';
import { AuthPlugin } from './auth.plugin';
import { UIPlugin } from './ui.plugin';
import { FeatureFlagPlugin } from './featureFlag.plugin';
import { HotkeysPlugin } from './hotkeys.plugin';
import { IntercomPlugin } from './intercom.plugin';
import { LegacyApiPlugin } from './legacyApi.plugin';
import { LogPlugin } from './log.plugin';
import { ManagementPlugin } from './management.plugin';
import { RevisorPlugin } from './revisor.plugin';
import { RouterPlugin } from './router.plugin';
import { RudderStackPlugin } from './rudderstack.plugin';
import { TemplatesPlugin } from './templates.plugin';
import { UploaderPlugin } from './uploader.plugin';
import { WhitelabelPlugin } from './whitelabel.plugin';
import { WaitingListPlugin } from './waitingList.plugin';

export class PluginsManager {

    readonly options: Options;

    readonly adminActions: AdminActionsPlugin;
    readonly albato: AlbatoPlugin;
    readonly auth: AuthPlugin;
    readonly intercom: IntercomPlugin;
    readonly hotkeys: HotkeysPlugin;
    readonly legacyApi: LegacyApiPlugin;
    readonly log: LogPlugin;
    readonly featureFlag: FeatureFlagPlugin;
    readonly management: ManagementPlugin;
    readonly revisor: RevisorPlugin;
    readonly router: RouterPlugin;
    readonly rudderstack: RudderStackPlugin;
    readonly templates: TemplatesPlugin;
    readonly ui: UIPlugin;
    readonly uploader: UploaderPlugin;
    readonly whitelabel: WhitelabelPlugin;
    readonly waitingList: WaitingListPlugin;

    get all(): Promise<void> {
        return Promise.all([
            this.adminActions.loading,
            this.albato.loading,
            this.auth.loading,
            this.featureFlag.loading,
            this.intercom.loading,
            this.hotkeys.loading,
            this.legacyApi.loading,
            this.log.loading,
            this.management.loading,
            this.revisor.loading,
            this.router.loading,
            this.rudderstack.loading,
            this.templates.loading,
            this.ui.loading,
            this.uploader.loading,
            this.whitelabel.loading,
            this.waitingList.loading,
        ]).then(() => { /* No-op */ });
    }

    get errorCount(): number {
        return [
            this.adminActions,
            this.albato,
            this.auth,
            // this.intercom,
            this.featureFlag,
            this.intercom,
            this.hotkeys,
            this.legacyApi,
            this.log,
            this.management,
            this.revisor,
            this.router,
            this.rudderstack,
            this.templates,
            this.ui,
            this.uploader,
            this.whitelabel,
            this.waitingList,
        ].reduce((carry: number, plugin) => carry + plugin.errors.length, 0);
    }

    constructor(options: Options = {}) {
        this.options = options;
        this.adminActions = new AdminActionsPlugin();
        this.albato = new AlbatoPlugin();
        this.auth = new AuthPlugin();
        this.featureFlag = new FeatureFlagPlugin();
        this.intercom = new IntercomPlugin();
        this.hotkeys = new HotkeysPlugin();
        this.legacyApi = new LegacyApiPlugin();
        this.log = new LogPlugin();
        this.management = new ManagementPlugin();
        this.revisor = new RevisorPlugin();
        this.router = new RouterPlugin();
        this.rudderstack = new RudderStackPlugin();
        this.templates = new TemplatesPlugin();
        this.ui = new UIPlugin();
        this.uploader = new UploaderPlugin();
        this.whitelabel = new WhitelabelPlugin();
        this.waitingList = new WaitingListPlugin();
    }

    /**
     * This method starts the installation of all plugins.
     * It is called by the Vue plugin installer (`Vue.use`) before the creation of the app.
     *
     * See: `main.ts`
     */
    install(app: App): void {
        void this.log.install(this);

        // // Explicitly do NOT await install calls here.
        // // The loading property of the plugin can be awaited whenever needed.
        // // Any install method can define and wait for its own dependencies.
        // // Do be careful though to avoid circular dependencies!
        void this.adminActions.install(this, app);
        void this.albato.install(this, app);
        void this.auth.install(this, app);
        void this.featureFlag.install(this, app);
        void this.intercom.install(this, app);
        void this.hotkeys.install(this);
        void this.legacyApi.install(this, app);
        void this.management.install(this, app);
        void this.revisor.install(this, app);
        void this.router.install(this, app);
        void this.rudderstack.install(this, app);
        void this.templates.install(this, app);
        void this.ui.install(this, app);
        void this.uploader.install(this, app);
        void this.whitelabel.install(this, app);
        void this.waitingList.install(this, app);

        app.provide('plugins', this);
    }

}
