<template>
    <div class="confetti">
        <canvas
            ref="canvas"
            class="confetti__canvas"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { create as createConfetti } from 'canvas-confetti';
import type { CreateTypes } from 'canvas-confetti';

const canvas = ref<HTMLCanvasElement>();
const func = ref<CreateTypes>();

const colors = computed<string[]>(() => [
    window.getComputedStyle(document.body).getPropertyValue('--ot-color-core-brand'),
]);

onMounted(() => {
    func.value = createConfetti(canvas.value, {
        resize: true,
        useWorker: true,
    });
});

function fire(): void {
    if (!func.value) {
        return;
    }

    void func.value({
        colors: colors.value,
        particleCount: 200,
        spread: 120,
    });
}

defineExpose({
    fire,
});
</script>

<style lang="scss" scoped>
.confetti {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 999;

    &__canvas {
        width: 100%;
        height: 100%;
    }
}
</style>
