import type { App } from 'vue';
import type { AuthClient } from '@openticket/lib-auth';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { Plugin } from './plugin';
import type { PluginsManager } from './manager';
import { Albato } from '../services/albato/albato.service';

export class AlbatoPlugin extends Plugin<Albato> {

    async install(plugins: PluginsManager, app: App): Promise<void> {
        try {
            const auth: AuthClient = await plugins.auth.loading;
            const whitelabel: Whitelabel = await plugins.whitelabel.loading;

            const baseUrl: string = whitelabel.dashboard.albato_url || 'https://albato.openticket.local/';
            const embedUrl: string = whitelabel.dashboard.albato_embed_url || 'https://albato-embed.openticket.local/';

            const albato = new Albato({ baseUrl, http: auth.$http, embedUrl });

            app.provide('albato', albato);

            this.resolve(albato);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

}
