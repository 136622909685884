import { OtError } from '@openticket/lib-log';
import type { AxiosError } from 'axios';

export class AdminActionsApiError extends OtError {

    readonly url: string;
    readonly axiosError: AxiosError;

    constructor(url: string, axiosError: AxiosError) {
        super('AdminActionsApiError', axiosError.message, 'AdminActions request failed');

        this.url = url;
        this.addContext('url', url);

        this.axiosError = axiosError;
        this.addContext('axios_error', axiosError.message);

        this.captureStackTrace(AdminActionsApiError);
    }

}
