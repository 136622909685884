import { type App, type ComponentOptions } from 'vue';
import {
    createRouter,
    createWebHistory,
    type NavigationGuardNext,
    type RouteLocationNormalized,
    type RouteLocationRaw,
    type Router,
} from 'vue-router';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import type { RouterOptions } from './types';
import ErrorView from '../modules/error/views/ErrorView.vue';

const persistClassicQueryParam = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const fromHasClassicOrLegacyQuery = !!from.query.classic || !!from.query.legacy;
    const toHasClassicOrLegacyQuery = !!to.query.classic || !!to.query.legacy;

    if (fromHasClassicOrLegacyQuery && !toHasClassicOrLegacyQuery) {
        // TODO: check if `name` does not exist on RouteLocationRaw. Removed it temporarily.
        const newRoute: RouteLocationRaw = {
            ...to,
            query: {
                ...to.query,
                classic: from.query.classic ?? undefined,
                legacy: from.query.legacy ?? undefined,
            },
        };

        next(newRoute);
        return;
    }

    next();
};

export class RouterPlugin extends Plugin<Router> {

    install(plugins: PluginsManager, app: App): void {
        try {
            const options: RouterOptions = plugins.options.router || {};

            const router = createRouter({
                history: createWebHistory(),
                routes: [
                    {
                        path: '/error/:log_id?',
                        name: 'error',
                        meta: {
                            disableAuth: true,
                            title: 'dashboard.document_title.error',
                            titleFallback: 'Error',
                        },
                        component: ErrorView,
                        props: (route: RouteLocationNormalized) => ({ reason: route.query.reason }),
                    },

                    ...(options.routes || []),

                    // {
                    //     path: '*',
                    //     redirect: { name: 'home' },
                    // },
                ],
                // TODO: Check if this is still needed, otherwise reimplement
                // scrollBehavior(to: RouteLocationNormalized, from: RouteLocationNormalized): Position | void {
                //     if ((to.meta && to.meta.modal) || (from.meta && from.meta.modal)) {
                //         return savedPosition;
                //     }
                //
                //     return { x: 0, y: 0 };
                // },
            });

            app.use(router);

            router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
                void plugins.auth.routeGuard(to, from, next);
            });

            router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
                persistClassicQueryParam(to, from, next);
            });

            // vue-router initializes the router, when it sees its router options present during component creation.
            // (i.e. in a global beforeCreate hook. As it's global, it's triggered for every component!)
            // It does not care if a router was already initialized. When used in combination with nested (dymamic?) routes.
            // With the below injection of the router options as a global mixin, it will therefore re-init the vue router.
            // When it renders a component with a vue-router component inside. This results in infinite recursion,
            // as it will then try to fill that router-view child with itself (or technically a clone of it)...
            const strats = app.config.optionMergeStrategies as { [key: string]: CallableFunction };
            strats.router = function routerMergeStrategy(
                parent: Router | undefined,
                child: Router | undefined,
                vm: ComponentOptions<App> | undefined,
            ): Router | undefined {
                return vm ? router : undefined;
            };

            // The key needs to be present for the above strategy to work, but the value should not be there.
            app.mixin({ router: undefined });

            router.afterEach(async (to, from) => {
                const intercom = await plugins.intercom.loading;
                intercom.updateSettings();

                const rudderstack = await plugins.rudderstack.loading;
                rudderstack.page('vue-dashboard', String(to.name), {
                    from: from.fullPath,
                    to: to.fullPath,
                });
            });

            this.resolve(router);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

}
