import type { Directive, DirectiveBinding } from 'vue';
import { iframeResizer } from 'iframe-resizer';

interface ResizableHTMLElement extends HTMLElement {
    iFrameResizer?: {
        removeListeners: () => void
    }
}

const resize: Directive = {
    bind(el: HTMLElement, binding: DirectiveBinding<{}>) {
        const options = binding.value || {};

        el.addEventListener('load', () => iframeResizer(options, el));
    },
    unbind(el: HTMLElement) {
        const resizableEl = el as ResizableHTMLElement;

        if (resizableEl.iFrameResizer) {
            resizableEl.iFrameResizer.removeListeners();
        }
    },
};

export {
    resize as iframeResize,
};
