<template>
    <div class="ticket-design">
        <div class="ticket-design__left">
            <div class="ticket-design__left-header">
                <h2 class="ot-ui-text-heading-2">
                    {{ $t('dashboard.tickets.ticket_design.custom_ticket_image.title') }}
                </h2>
                <div class="ot-ui-text-body-intro">
                    {{ $t('dashboard.tickets.ticket_design.custom_ticket_image.description') }}
                </div>
            </div>
            <div class="ticket-design__left-upload">
                <OtButton
                    variant="outline"
                    icon-right="carrot-right"
                >
                    {{ $t('dashboard.tickets.ticket_design.custom_ticket_image.upload.label') }}
                </OtButton>
                <div class="ticket-design__left-upload-value">
                    {{ $t('dashboard.tickets.ticket_design.custom_ticket_image.upload.placeholder') }}
                </div>
            </div>
            <div class="ticket-design__left-footer">
                {{ $t('dashboard.tickets.ticket_design.custom_ticket_image.additional_info') }}
            </div>
        </div>
        <div class="ticket-design__right">
            <OtPreview
                type="iframe"
                :header-label="$t('dashboard.tickets.ticket_design.preview.header')"
                :src="'https://openticket.tech'"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "../../../assets/scss/mixins.scss";

.ticket-design {
    display: flex;
    flex-direction: row;
    gap: var(--ot-ui-spacing-md);

    @include breakpoint(tab) {
        flex-direction: column;
    }

    &__left {
        flex: 1;

        display: flex;
        flex-direction: column;
        gap: var(--ot-ui-spacing-lg);

        &-upload {
            display: flex;
            flex-direction: row;
            gap: var(--ot-ui-spacing-md);
            align-items: center;

            &-value {
                color: var(--ot-ui-color-accent-primary)
            }
        }

        &-footer {
            color: var(--ot-ui-color-accent-primary)
        }
    }

    &__right {
        flex: 1;
    }
}
</style>
