function formatRelativeTimeString(
    deltaSeconds: number,
    lang : string,
    options: Intl.RelativeTimeFormatOptions = { numeric: 'auto' },
    config: { discardPrefix: boolean } = { discardPrefix: false },
): string {
    // Array representing one minute, hour, day, week, month, etc in seconds
    const cutoffs = [ 60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity ];

    // Array equivalent to the above but in the string representation of the units
    const units: Intl.RelativeTimeFormatUnit[] = [ 'second', 'minute', 'hour', 'day', 'week', 'month', 'year' ];

    // Grab the ideal cutoff unit
    const unitIndex = cutoffs.findIndex((cutoff) => cutoff > Math.abs(deltaSeconds));

    // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
    // is one day in seconds, so we can divide our seconds by this to get the # of days
    const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;

    // Intl.RelativeTimeFormat do its magic
    const rtf = new Intl.RelativeTimeFormat(lang, options);

    if (config.discardPrefix) {
        const [ _prefix, value, timeUnit ] = rtf.formatToParts(Math.floor(deltaSeconds / divisor), units[unitIndex]);
        return `${value.value} ${timeUnit.value}`;
    }

    return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);
}

/**
 * Convert a date to a relative time string, such as
 * "now", "a minute ago", "yesterday", "3 months ago", etc.
 * This function will never return a future value such as "in 10 minutes". It will be returned as "now"
 */
export function getPastRelativeTimeString(date: Date | number, lang = navigator.language) {
    // Allow dates or times to be passed
    const timeMs = typeof date === 'number' ? date : date.getTime();

    // Get the amount of seconds between the given date and now with a minimum of 0 because it will always be in the past.
    const deltaSeconds = Math.min(0, Math.round((timeMs - Date.now()) / 1000));

    return formatRelativeTimeString(deltaSeconds, lang);
}

/**
 * Convert a date to a relative time string, such as
 * "a minute ago", "in 2 hours", "yesterday", "3 months ago", etc.
 */
export function getRelativeTimeString(
    date: Date | number,
    lang = navigator.language,
    options: Intl.RelativeTimeFormatOptions = { numeric: 'auto' },
    config: { discardPrefix: boolean } = { discardPrefix: false },
) {
    // Allow dates or times to be passed
    const timeMs = typeof date === 'number' ? date : date.getTime();

    // Get the amount of seconds between the given date and now
    const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);

    return formatRelativeTimeString(deltaSeconds, lang, options, config);
}
