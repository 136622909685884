<template>
    <div
        v-if="user"
        class="user-form ot-ui-stacky[sm]"
    >
        <!-- TODO  :form="form" -->
        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.common.input.name')"
                :error="error"
                :optional="rules.name && !rules.name.includes('required')"
            >
                <OtTextInput
                    v-model="user.$data.name"
                    :disabled="disabled"
                />
            </OtInputField>
        </OtFormRow>

        <OtFormRow>
            <OtInputField
                :label="$t('dashboard.common.input.email')"
                :error="error"
                :optional="rules.email && !rules.email.includes('required')"
            >
                <OtEmailInput
                    :model-value="user.$data.email"
                    :disabled="true"
                />
            </OtInputField>

            <OtInputField
                :label="$t('dashboard.common.input.phone_number')"
                :error="error"
                :optional="rules.phone && !rules.phone.includes('required')"
            >
                <OtPhoneInput
                    v-model="user.$data.phone"
                    :disabled="disabled"
                />
            </OtInputField>
        </OtFormRow>

        <div class="user-form__save">
            <OtButton
                :disabled="disabled || !hasLocalChanges"
                :title="$t('dashboard.common.action.save.title')"
                icon="check"
                @click="save()"
            >
                {{ $t('dashboard.common.action.save.text') }}
            </OtButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { AuthClient, User } from '@openticket/lib-auth';
import { watch } from 'vue';

type Props = {
    user: User<AuthClient>,
    rules: { [p: string]: string[] },
    disabled?: boolean,
    hasLocalChanges?: boolean,
}

const error = undefined; // TODO

const props = withDefaults(defineProps<Props>(), {
    disabled: false,
});

type Emits = {
    (e: 'data-changed', value: boolean): void
    (e: 'save'): void
}
const emit = defineEmits<Emits>();

watch(() => [ props.user.$data.name, props.user.$data.phone ], () => {
    emit('data-changed', true);
});

function save(): void {
    if (!props.disabled) {
        emit('save');
    }
}
</script>

<style lang="scss" scoped>
.user-form {
    &__save {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
