import { computed } from 'vue';
import { injectOrFail } from '../services/util';
import type { Roles } from '../plugins/types';
import type { FeatureFlagPlugin } from '../plugins/featureFlag.plugin';

/**
 * This composable provides access to the roles of the current user.
 * Other than the roles inject, it provides helper variables to for example check if the user is read only.
 */
export function useRoles() {
    const roles = injectOrFail<Roles>('roles');
    const { flags } = injectOrFail<FeatureFlagPlugin>('featureFlags');

    const isReadOnly = computed<boolean>(() => !roles.isManager && flags.app__read_only_views__global);

    return { roles, isReadOnly };
}
