import { OtError, UnknownError } from '@openticket/lib-log';

export class ClipboardUnavailableError extends OtError {

    constructor(cause?: Error) {
        super('ClipboardUnavailableError', 'dashboard.error.module.shops.list.clipboard.unavailable', 'Clipboard unavailable.');

        if (cause) {
            this.causedBy(new UnknownError(cause));
        }

        this.captureStackTrace(ClipboardUnavailableError);
    }

}
