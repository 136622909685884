<template>
    <div class="color-conformation-level-contrast">
        <div class="color-conformation-level-contrast__details">
            <div
                class="color-conformation-level-contrast__details__example"
                :style="{ background: backgroundStyle }"
            >
                <div
                    class="color-conformation-level-contrast__details__example__color"
                    :style="{ background: foregroundStyle }"
                />

                <div
                    class="color-conformation-level-contrast__details__example__value ot-ui-text-body-md-strong"
                    :style="{ color: foregroundStyle }"
                >
                    {{ relatedColor ? relatedColor.formatted : '?' }}
                </div>
            </div>

            <div class="color-conformation-level-contrast__details__description">
                <div class="color-conformation-level-contrast__details__description__title ot-ui-text-body-md-strong">
                    <span>{{ $t(levelInfo) }}</span>

                    <span class="color-conformation-level-contrast__details__description__title__contrast ot-ui-text-body-xs">
                        {{ comparison.result ? $l.number(comparison.result.rawPerceivableValues.contrast) : '?' }} : 1
                    </span>
                </div>

                <div class="ot-ui-text-body-xs">
                    {{ relatedName }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Color } from '@peterdekok/color-compare';
import { computed, onBeforeUnmount, watch } from 'vue';
import type { Comparison, ComparisonRegistration } from './types';
import { injectOrFail } from '../../services/util';
import { convertCssHslaToRgb } from './hslToRgb';

type Props = {
    id: string,
    related: string | null,
    relatedName: string,
    relatedLocation: 'above' | 'below',
}

const props = withDefaults(defineProps<Props>(), { related: null });

const register = injectOrFail<ComparisonRegistration>('color-comparison-registration');

const relatedColor = computed<Color | null>(() => {
    if (!props.related) {
        return null;
    }

    try {
        return new Color(convertCssHslaToRgb(props.related));
    } catch (e) {
        return null;
    }
});

const foregroundStyle = computed<string>(() => {
    const fgColor: Color | null = props.relatedLocation === 'above' ? relatedColor.value : comparison.target;

    return fgColor ? fgColor.formatted : 'var(--ot-ui-color-foreground-primary)';
});

const backgroundStyle = computed<string>(() => {
    const bgColor: Color | null = props.relatedLocation === 'above' ? comparison.target : relatedColor.value;

    return bgColor ? bgColor.formatted : 'var(--ot-ui-color-background-primary)';
});

const levelInfo = computed<string>(() => {
    if (!comparison.result) {
        return 'dashboard.components.color_conformation_level_contrast.level.info.undetermined';
    }

    switch (comparison.result.wcagConformance) {
        case 'aaa':
            return 'dashboard.components.color_conformation_level_contrast.level.info.strong';
        case 'aa':
            return 'dashboard.components.color_conformation_level_contrast.level.info.good';
        case 'a':
            return 'dashboard.components.color_conformation_level_contrast.level.info.poor';
        default:
            return 'dashboard.components.color_conformation_level_contrast.level.info.weak';
    }
});

const comparison: Comparison = register(props.id, relatedColor.value);

onBeforeUnmount(() => comparison.deregister());

watch(() => props.related, () => {
    register(props.id, relatedColor.value);
});
</script>

<style lang="scss" scoped>
.color-conformation-level-contrast {
    padding: var(--ot-ui-spacing-sm) 0 0;

    &__details {
        display: flex;
        gap: var(--ot-ui-spacing-lg);

        &__example {
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            flex-shrink: 0;

            width: 12rem;
            height: 6rem;

            padding: var(--ot-ui-spacing-sm);
            border-radius: var(--ot-ui-radius-lg);

            &__color {
                width: 1.5rem;
                height: 1.5rem;
                margin-right: var(--ot-ui-spacing-sm);
                border-radius: var(--ot-ui-radius-md);
            }

            &__value {
                line-height: 1.5rem;
                text-overflow: ellipsis;
                text-wrap: avoid;
                white-space: nowrap;
            }
        }

        &__description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;

            &__title {
                display: flex;
                justify-content: space-between;

                &__contrast {
                    opacity: 0;
                    line-height: 1.5rem;
                    color: var(--ot-ui-color-foreground-secondary);
                }
            }
        }
    }

    &:hover &__details__description__title__contrast {
        opacity: 1;
    }
}
</style>
