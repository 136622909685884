import { InvalidShopDataTypeError, InvalidShopDataRelationError, InvalidShopDataUniqueError } from '../errors';
import {
    type ShopData,
    type ShopDataCollapse,
    type ShopDataEvent,
    type ShopDataEventItem,
    type ShopDataTicket,
    type ShopDataRaw,
    type ShopDataRawCollapse,
    type ShopDataRawEvent,
    type ShopDataRawEventDate,
    type ShopDataRawPivots,
    type ShopDataRawTicket,
    type ShopDataRawShopsTicketsPivot,
    type ShopDataRawEventLocation,
} from '../types';

function isRawObject<T>(raw: unknown): raw is { [key in keyof T]?: unknown } {
    return !!raw && typeof raw === 'object' && !Array.isArray(raw);
}

type ObjectWith<T extends PropertyKey, V = { [key: string]: unknown } | unknown[]> = {
    [key in T]: V;
} & { [key: string]: unknown };

function hasCollapses(raw: { [key: string]: unknown }): raw is ObjectWith<'collapses'> {
    return !!raw.collapses && typeof raw.collapses === 'object';
}

function hasEventDates(raw: { [key: string]: unknown }): raw is ObjectWith<'eventDates'> {
    return !!raw.eventDates && typeof raw.eventDates === 'object';
}

function hasEvents(raw: { [key: string]: unknown }): raw is ObjectWith<'events'> {
    return !!raw.events && typeof raw.events === 'object';
}

function hasTickets(raw: { [key: string]: unknown }): raw is ObjectWith<'tickets'> {
    return !!raw.tickets && typeof raw.tickets === 'object';
}

function hasPivots(raw: { [key: string]: unknown }): raw is ObjectWith<'pivots', { 'shops-tickets': unknown[] }> {
    if (!isRawObject<ShopDataRawPivots>(raw.pivots)) {
        return false;
    }

    if (!raw.pivots['shops-tickets']
        || typeof raw.pivots['shops-tickets'] !== 'object'
        || !Array.isArray(raw.pivots['shops-tickets'])
    ) {
        throw new InvalidShopDataTypeError('pivots.shops-tickets', typeof raw.pivots['shops-tickets'], 'object');
    }

    return true;
}

function isRawcollapse(rawCollapse: unknown, id: string | number): rawCollapse is ShopDataRawCollapse {
    if (!isRawObject<ShopDataRawCollapse>(rawCollapse)) {
        return false;
    }

    if (!rawCollapse.guid || typeof rawCollapse.guid !== 'string') {
        throw new InvalidShopDataTypeError(`collapses.${id}.guid`, typeof rawCollapse.guid, 'string');
    }

    if (!rawCollapse.title || typeof rawCollapse.title !== 'string') {
        throw new InvalidShopDataTypeError(`collapses.${id}.title`, typeof rawCollapse.title, 'string');
    }

    if (!rawCollapse.ticket_guids || typeof rawCollapse.ticket_guids !== 'object' || !Array.isArray(rawCollapse.ticket_guids)) {
        throw new InvalidShopDataTypeError(`collapses.${id}.ticket_guids`, typeof rawCollapse.ticket_guids, 'object');
    }

    return true;
}

function isRawEventDate(rawEventDate: unknown, id: string | number): rawEventDate is ShopDataRawEventDate {
    if (!isRawObject<ShopDataRawEventDate>(rawEventDate)) {
        return false;
    }

    if (!rawEventDate.end || typeof rawEventDate.end !== 'string') {
        throw new InvalidShopDataTypeError(`eventDates.${id}.end`, typeof rawEventDate.end, 'string');
    }

    if (!rawEventDate.event_id || typeof rawEventDate.event_id !== 'string') {
        throw new InvalidShopDataTypeError(`eventDates.${id}.event_id`, typeof rawEventDate.event_id, 'string');
    }

    if (!rawEventDate.guid || typeof rawEventDate.guid !== 'string') {
        throw new InvalidShopDataTypeError(`eventDates.${id}.guid`, typeof rawEventDate.guid, 'string');
    }

    if (!rawEventDate.start || typeof rawEventDate.start !== 'string') {
        throw new InvalidShopDataTypeError(`eventDates.${id}.start`, typeof rawEventDate.start, 'string');
    }

    return true;
}

function isRawEvent(rawEvent: unknown, id: string | number): rawEvent is ShopDataRawEvent {
    if (!isRawObject<ShopDataRawEvent>(rawEvent)) {
        return false;
    }

    if (!rawEvent.guid || typeof rawEvent.guid !== 'string') {
        throw new InvalidShopDataTypeError(`events.${id}.guid`, typeof rawEvent.guid, 'string');
    }

    if (!rawEvent.name || typeof rawEvent.name !== 'string') {
        throw new InvalidShopDataTypeError(`events.${id}.name`, typeof rawEvent.name, 'string');
    }

    return true;
}

function isRawEventLocation(rawEvent: unknown, id: string | number)
    : rawEvent is ShopDataRaw & { location: ShopDataRawEventLocation } {
    if (!isRawObject<ShopDataRawEvent>(rawEvent)
        || !isRawObject<ShopDataRawEventLocation>(rawEvent.location)) {
        return false;
    }

    if (rawEvent.location.address !== null && typeof rawEvent.location.address !== 'string') {
        throw new InvalidShopDataTypeError(`events.${id}.location.address`, typeof rawEvent.location.address, 'string');
    }

    if (!rawEvent.location.name || typeof rawEvent.location.name !== 'string') {
        throw new InvalidShopDataTypeError(`events.${id}.location.name`, typeof rawEvent.location.name, 'string');
    }

    return true;
}

function isRawTicket(rawTicket: unknown, id: string | number): rawTicket is ShopDataRawTicket {
    if (!isRawObject<ShopDataRawTicket>(rawTicket)) {
        return false;
    }

    if (!rawTicket.event_id || typeof rawTicket.event_id !== 'string') {
        throw new InvalidShopDataTypeError(`tickets.${id}.event_id`, typeof rawTicket.event_id, 'string');
    }

    if (!rawTicket.guid || typeof rawTicket.guid !== 'string') {
        throw new InvalidShopDataTypeError(`tickets.${id}.guid`, typeof rawTicket.guid, 'string');
    }

    if (!rawTicket.name || typeof rawTicket.name !== 'string') {
        throw new InvalidShopDataTypeError(`tickets.${id}.name`, typeof rawTicket.name, 'string');
    }

    return true;
}

function isRawShopsTicketsPivot(
    rawShopsTicketsPivot: unknown,
    id: string | number,
): rawShopsTicketsPivot is ShopDataRawShopsTicketsPivot {
    if (!isRawObject<ShopDataRawShopsTicketsPivot>(rawShopsTicketsPivot)) {
        return false;
    }

    if (!rawShopsTicketsPivot.denominator || typeof rawShopsTicketsPivot.denominator !== 'number') {
        throw new InvalidShopDataTypeError(`pivots.shops-tickets.${id}.denominator`, typeof rawShopsTicketsPivot.denominator, 'number');
    }

    if (!rawShopsTicketsPivot.numerator || typeof rawShopsTicketsPivot.numerator !== 'number') {
        throw new InvalidShopDataTypeError(`pivots.shops-tickets.${id}.numerator`, typeof rawShopsTicketsPivot.numerator, 'number');
    }

    if (!rawShopsTicketsPivot.shop_guid || typeof rawShopsTicketsPivot.shop_guid !== 'string') {
        throw new InvalidShopDataTypeError(`pivots.shops-tickets.${id}.shop_guid`, typeof rawShopsTicketsPivot.shop_guid, 'string');
    }

    if (!rawShopsTicketsPivot.ticket_guid || typeof rawShopsTicketsPivot.ticket_guid !== 'string') {
        throw new InvalidShopDataTypeError(`pivots.shops-tickets.${id}.ticket_guid`, typeof rawShopsTicketsPivot.ticket_guid, 'string');
    }

    return true;
}

function createPropCompareFn<
    K extends PropertyKey,
    T extends { [key in K]: string | null } | { [key in K]: number | null },
>(prop: K): (a: T, b: T) => 1 | -1 | 0 {
    return (a: T, b: T) => {
        const aVal = a[prop];
        const bVal = b[prop];

        if (bVal === null) {
            return aVal === null ? 0 : -1;
        }

        if (aVal === null || aVal > bVal) {
            return 1;
        }

        if (aVal < bVal) {
            return -1;
        }

        return 0;
    };
}

function firstOrCreateUsedCollapse(
    collapses: { [key: string]: ShopDataRawCollapse[] },
    id: string,
    usedCollapses: Map<ShopDataRawCollapse, ShopDataCollapse>,
): ShopDataCollapse {
    for (const collapse of collapses[id]) {
        const usedCollapse: ShopDataCollapse | undefined = usedCollapses.get(collapse);

        if (usedCollapse) {
            return usedCollapse;
        }
    }

    const collapse: ShopDataRawCollapse = collapses[id][0];

    const usedCollapse: ShopDataCollapse = {
        _type_: 'collapse',
        guid: collapse.guid,
        title: collapse.title,
        index: null,
        items: [],
    };

    usedCollapses.set(collapse, usedCollapse);

    return usedCollapse;
}

export function parseRawShopData(raw: unknown): ShopData {
    if (!isRawObject<ShopDataRaw>(raw)) {
        throw new InvalidShopDataTypeError('', typeof raw, 'object');
    }

    if (!hasCollapses(raw)) {
        throw new InvalidShopDataTypeError('collapses', typeof raw.collapses, 'object');
    }

    if (!hasEventDates(raw)) {
        throw new InvalidShopDataTypeError('eventDates', typeof raw.eventDates, 'object');
    }

    if (!hasEvents(raw)) {
        throw new InvalidShopDataTypeError('events', typeof raw.events, 'object');
    }

    if (!hasTickets(raw)) {
        throw new InvalidShopDataTypeError('tickets', typeof raw.tickets, 'object');
    }

    if (!hasPivots(raw)) {
        throw new InvalidShopDataTypeError('pivots', typeof raw.pivots, 'object');
    }

    const eventsMap: { [key: string]: ShopDataEvent } = {};
    const events: ShopDataEvent[] = [];

    for (const [ i, rawEvent ] of Object.entries(raw.events)) {
        if (!isRawEvent(rawEvent, i)) {
            throw new InvalidShopDataTypeError(`events.${i}`, typeof rawEvent, 'object');
        }

        // TODO start & end need to be 'normalized' with the ugly timezone transform from shop/order
        const event: ShopDataEvent = {
            end: null,
            guid: rawEvent.guid,
            name: rawEvent.name,
            start: null,
            items: [],
            location: null,
            created_at: rawEvent.created_at,
            updated_at: rawEvent.updated_at,
        };

        if (isRawEventLocation(rawEvent, i)) {
            event.location = {
                name: rawEvent.location.name,
                address: rawEvent.location.address,
            };
        }

        eventsMap[rawEvent.guid] = event;
        events.push(event);
    }

    for (const [ i, rawEventDate ] of Object.entries(raw.eventDates)) {
        if (!isRawEventDate(rawEventDate, i)) {
            throw new InvalidShopDataTypeError(`eventDates.${i}`, typeof rawEventDate, 'object');
        }

        if (!eventsMap[rawEventDate.event_id]) {
            throw new InvalidShopDataRelationError('eventDate', i, 'event', rawEventDate.event_id);
        }

        const currentStart: string | null = eventsMap[rawEventDate.event_id].start;
        const currentEnd: string | null = eventsMap[rawEventDate.event_id].end;

        if (!currentStart || !currentEnd) {
            eventsMap[rawEventDate.event_id].start = rawEventDate.start;
            eventsMap[rawEventDate.event_id].end = rawEventDate.end;
        } else {
            if (currentStart > rawEventDate.start) {
                eventsMap[rawEventDate.event_id].start = rawEventDate.start;
            }

            if (currentEnd < rawEventDate.end) {
                eventsMap[rawEventDate.event_id].end = rawEventDate.end;
            }
        }
    }

    events.sort(createPropCompareFn('start'));

    const shopsTicketsPivotsByTicket: { [key: string]: ShopDataRawShopsTicketsPivot } = {};

    for (const [ i, rawShopsTicketsPivot ] of Object.entries(raw.pivots['shops-tickets'])) {
        if (!isRawShopsTicketsPivot(rawShopsTicketsPivot, i)) {
            throw new InvalidShopDataTypeError(`pivots.shops-tickets.${i}`, typeof rawShopsTicketsPivot, 'object');
        }

        if (shopsTicketsPivotsByTicket[rawShopsTicketsPivot.ticket_guid]) {
            throw new InvalidShopDataUniqueError(`pivots.shops-tickets.${i}`, 'ticket_guid', rawShopsTicketsPivot.ticket_guid);
        }

        shopsTicketsPivotsByTicket[rawShopsTicketsPivot.ticket_guid] = {
            denominator: rawShopsTicketsPivot.denominator,
            numerator: rawShopsTicketsPivot.numerator,
            shop_guid: rawShopsTicketsPivot.shop_guid,
            ticket_guid: rawShopsTicketsPivot.ticket_guid,
        };
    }

    for (const [ i, rawTicket ] of Object.entries(raw.tickets)) {
        if (!isRawTicket(rawTicket, i)) {
            throw new InvalidShopDataTypeError(`tickets.${i}`, typeof rawTicket, 'object');
        }

        const pivot: ShopDataRawShopsTicketsPivot | null = shopsTicketsPivotsByTicket[rawTicket.guid] || null;

        const ticket: ShopDataTicket = {
            _type_: 'ticket',
            guid: rawTicket.guid,
            eventId: rawTicket.event_id,
            name: rawTicket.name,
            index: pivot ? pivot.numerator / pivot.denominator : null,
        };

        if (!eventsMap[rawTicket.event_id]) {
            throw new InvalidShopDataRelationError('ticket', i, 'event', rawTicket.event_id);
        }

        eventsMap[rawTicket.event_id].items.push(ticket);
    }

    const ticketIdsToCollapses: { [key: string]: ShopDataRawCollapse[] } = {};

    for (const [ i, rawCollapse ] of Object.entries(raw.collapses)) {
        if (!isRawcollapse(rawCollapse, i)) {
            throw new InvalidShopDataTypeError(`collapses.${i}`, typeof rawCollapse, 'object');
        }

        for (const [ j, ticketId ] of Object.entries(rawCollapse.ticket_guids)) {
            if (typeof ticketId !== 'string') {
                throw new InvalidShopDataTypeError(`collapses.${i}.ticket_guids.${j}`, typeof ticketId, 'string');
            }

            ticketIdsToCollapses[ticketId] = ticketIdsToCollapses[ticketId] || [];

            ticketIdsToCollapses[ticketId].push(rawCollapse);
        }
    }

    const itemCompare = createPropCompareFn<'index', ShopDataEventItem>('index');

    for (const event of events) {
        event.items.sort(itemCompare);

        const usedCollapses = new Map<ShopDataRawCollapse, ShopDataCollapse>();
        const toRemoveTickets: number[] = [];

        // TODO Use forEach instead so we have a number index....
        event.items.forEach((item: ShopDataCollapse | ShopDataTicket, i: number) => {
            if (item._type_ !== 'ticket' || !ticketIdsToCollapses[item.guid]) {
                return;
            }

            const collapse: ShopDataCollapse = firstOrCreateUsedCollapse(ticketIdsToCollapses, item.guid, usedCollapses);

            if (!collapse.items.length) {
                collapse.index = item.index;
                event.items[i] = collapse;
            } else {
                toRemoveTickets.unshift(i);
            }

            collapse.items.push(item);
        });

        for (const index of toRemoveTickets) {
            event.items.splice(index, 1);
        }
    }

    return {
        events,
    };
}
