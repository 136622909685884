<template>
    <FormModal
        v-if="modal.isOpen"
        data-testid="shop-create-modal"
        :form="shopForm"
        :modal="modal"
        :title="$t('dashboard.shops.new.title')"
        :subtitle="$t('dashboard.shops.new.subtitle')"
        :submit-button-labels="{
            text: $t('dashboard.shops.list.new.create'),
            title: $t('dashboard.shops.list.new.title')
        }"
    >
        <ShopCreateForm :form="shopForm" />
    </FormModal>
</template>

<script setup lang="ts">
import { reactive, type UnwrapNestedRefs } from 'vue';
import type { CompanyPath, ManagementClient, Shop } from '@openticket/lib-management';
import FormModal from '../../../components/form/FormModal.vue';
import { useCreateForm, type FormModalComposableResult } from '../../../composables/forms';
import ShopCreateForm from './forms/ShopCreateForm.vue';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';

type Props = {
    companyGuid: string;
    modal: UnwrapNestedRefs<FormModalComposableResult>,
}

type Emits = {
    (e: 'saved', guid: string | null): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const companyModel = management.companyPath.$factory({
    ...management.companyPath.new().$data,
    guid: props.companyGuid,
});

const shopForm = reactive(
    useCreateForm<Shop<CompanyPath<ManagementClient>>, CompanyPath<ManagementClient>>(
        companyModel.shops.new(),
        companyModel.shops,
        {
            beforeSubmit() {
                // When in global context, the create call is made using multiple company guids in the company header.
                // This results in a 403. Below temporarily overwrites the company scope to the specific company guid to ensure it
                // is set correctly. Should be removed when the backend is fixed. See CU-86c0h58r8
                if (!context.isCompanyContext()) {
                    // @ts-expect-error Below method is private, but is needed for correct company scope during the call
                    void context._setCompanyScope(props.companyGuid);
                }
            },
            onSubmit(model) {
                if (!context.isCompanyContext()) {
                    // @ts-expect-error Below method is private, but is needed for correct company scope during the call
                    void context._removeCompanyScope();
                }

                emit('saved', model.id);
            },
        },
    ),
);
</script>
