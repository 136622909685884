<template>
    <div class="input-float-unlimited">
        <div
            v-show="unlimited"
            :class="{ 'input-float-unlimited__input--small': size === 'small' }"
            class="input-float-unlimited__input input-float-unlimited__input--unlimited"
            @focusin="onUnlimitedTextFocus"
        >
            <OtTextInput
                :readonly="readonly"
                model-value="∞"
            />
        </div>
        <OtFloatInput
            v-show="!unlimited"
            :class="{ 'input-float-unlimited__input--small': size === 'small' }"
            :min="min"
            :model-value="model"
            :readonly="readonly"
            class="input-float-unlimited__input input-float-unlimited__input--text"
            data-testid="input-float-unlimited-input"
            @input="handleInput"
            @blur="handleBlur"
        />

        <OtToggleInput
            v-if="!readonly"
            :label="$t('dashboard.common.unlimited')"
            class="input-float-unlimited__toggle"
            :model-value="unlimited"
            data-testid="input-float-unlimited-toggle"
            @input="handleToggle"
        />
    </div>
</template>

<script lang="ts" setup>
import { nextTick, ref, watch } from 'vue';

type T = number;

type Props = {
    min?: number,
    size?: 'small',
    readonly?: boolean,
}

type Emits = {
    (e: 'input', value: number): void,
}

withDefaults(defineProps<Props>(), {
    min: undefined,
    size: undefined,
    readonly: false,
});

const emit = defineEmits<Emits>();
const model = defineModel<T>();

const unlimited = ref<boolean>(model.value === 0);

watch(() => model.value, () => {
    if (model.value === 0) {
        unlimited.value = true;
    }
});

async function onUnlimitedTextFocus() {
    unlimited.value = false;

    await nextTick();

    const inputElement = document.querySelector('.input-float-unlimited__input > input');

    if (inputElement && inputElement instanceof HTMLInputElement) {
        inputElement.focus();
        inputElement.select();
    }
}

function handleToggle() {
    unlimited.value = !unlimited.value;

    if (unlimited.value) {
        model.value = 0;
    }

    emit('input', model.value ?? 0);
}

function handleInput(value: number) {
    // Check new and previous value
    if (value === 0 && model.value !== 0) {
        unlimited.value = true;
    } else {
        // Setting unlimited triggers a blur event, triggering the input event again.
        emit('input', value);
    }

    model.value = value;
}

function toggleUnlimited(value: boolean) {
    unlimited.value = value;
    model.value = value ? 0 : model.value;
}

// TODO: Temporary workaround. Floatinput doesn't emit correctly. Should be fixed in Vue-UI | CU-86c15mqdz
async function handleBlur() {
    // Wait to see if the up-arrow of the float input got pressed
    await new Promise((r) => {
        setTimeout(r, 100);
    });

    // If the value is still true, switch back to unlimited
    if (model.value === 0) {
        unlimited.value = true;
    }
}

defineExpose({
    toggleUnlimited,
});
</script>

<style lang="scss" scoped>
@import "../assets/scss/mixins.scss";

.input-float-unlimited {
    display: flex;
    gap: var(--ot-ui-spacing-xs);
    align-items: center;
    width: 100%;

    &__toggle {
        :deep(.ot-ui-toggle-input__text) {
            display: flex;
            align-items: start;
        }
    }

    &__input {

        &--unlimited {
            width: 50%;
        }

        &.ot-ui-float-input {
            width: 50%;
        }

        &--small {
            max-width: 10rem;
        }

        @include breakpoint(mob) {
            &--unlimited, &--text.ot-ui-float-input {
                width: 100%;
            }
        }
    }
}
</style>
