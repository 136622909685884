<template>
    <div class="tfa-totp-confirm">
        <div v-if="model">
            <OtInputField
                :label="$t('dashboard.user.tfa.setup.password')"
                :error="(error.incorrectPassword
                    && $t(error.incorrectPassword, { attribute: $t('dashboard.user.tfa.setup.attributes.password') }))
                    || undefined"
            >
                <OtPasswordInput
                    v-model="model.password"
                    :invalid="!!error.incorrectPassword"
                    :label="$t('dashboard.user.tfa.setup.password')"
                    name="password"
                />
            </OtInputField>

            <OtInputField
                :label="$t('dashboard.user.tfa.setup.totp.code')"
                :error="(error.incorrectTFAInput
                    && $t(error.incorrectTFAInput, { attribute: $t('dashboard.user.tfa.setup.attributes.totp_code') }))
                    || undefined"
            >
                <OtCharacterGroupInput
                    inputmode="numeric"
                    placeholder="0"
                    autocomplete="one-time-code"
                    :blur-on-complete="false"
                    :initial-focus="-1"
                    :invalid="!!error.incorrectTFAInput"
                    @complete="setInput"
                />
            </OtInputField>
        </div>

        <div class="explanation-label ot-ui-text-body-sm">
            {{ $t('dashboard.user.tfa.setup.totp.explanation') }}
        </div>
    </div>
</template>

<script setup lang="ts">
import type { OtTFAError } from '@openticket/vue-ui';
import type { TfaTotpData } from '../types';

type Props = {
    error: OtTFAError
}

withDefaults(
    defineProps<Props>(),
    {
        error: () => ({ incorrectPassword: null, incorrectTFAInput: null }),
    },
);
const model = defineModel<TfaTotpData>();

const setInput = (val: string[] | null): void => {
    if (model.value === undefined) {
        return;
    }

    model.value.input = val ? val.map((n: string) => Number(n).toFixed(0)).join('') : null;
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins.scss";

.tfa-totp-confirm {
    .explanation-label {
        color: var(--ot-ui-color-accent-primary);
        text-align: center;
    }
}
</style>
