<template>
    <FormModal
        v-if="modal.isOpen"
        data-testid="location-create-modal"
        :title="$t('dashboard.events.wizard.steps.details.form.location.modal.title')"
        :subtitle="$t('dashboard.events.wizard.steps.details.form.location.modal.description')"
        :form="locationForm"
        :modal="modal"
    >
        <LocationFieldsVue :form-model="locationForm" />
    </FormModal>
</template>

<script setup lang="ts">
import { reactive, type UnwrapNestedRefs } from 'vue';
import { useCreateForm, type FormModalComposableResult } from '../../../composables/forms';
import FormModal from '../FormModal.vue';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import LocationFieldsVue from './LocationFields.vue';

type Props = {
    modal: UnwrapNestedRefs<FormModalComposableResult>,
}

type Emits = {
    (e: 'saved', guid: string | null): void
}

defineProps<Props>();
const emit = defineEmits<Emits>();

const context = injectOrFail<Context>('context');

if (!context.isCompanyContext()) {
    // TODO: Proper error handling + logging
    throw Error('Cannot create a new location when not in company context');
}

const locationForm = reactive(
    useCreateForm(
        context.company.model.locations.new(),
        context.company.model.locations,
        {
            async onSubmit(model) {
                emit('saved', model.id);
                await Promise.resolve();
            },
            disableLocalChangesDialog: true,
        },
    ),
);

</script>
