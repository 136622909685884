<template>
    <FormModal
        :form="ticketForm"
        :modal="modal"
        :submit-button-labels="{
            text: $t('dashboard.tickets.list.new.create'),
            title: $t('dashboard.tickets.list.new.title')
        }"
        :subtitle="$t('dashboard.events.wizard.steps.tickets.upsert.description')"
        :title="$t(`dashboard.events.wizard.steps.tickets.upsert.title_insert`)"
        add-another-enabled
        data-testid="ticket-create-modal"
    >
        <TicketCreateForm :form="ticketForm" />
    </FormModal>
</template>

<script setup lang="ts">
import { reactive, type UnwrapNestedRefs } from 'vue';
import type {
    Event, ManagementClient, Ticket,
} from '@openticket/lib-management';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import FormModal from '../../../components/form/FormModal.vue';
import { useCreateForm, type FormModalComposableResult } from '../../../composables/forms';
import TicketCreateForm from './forms/TicketCreateForm.vue';

type Props = {
    modal: UnwrapNestedRefs<FormModalComposableResult>,
}

type Emits = {
    (e: 'submit', model: Ticket<ManagementClient>): void
}

defineProps<Props>();
const emit = defineEmits<Emits>();

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const ticketForm = reactive(
    useCreateForm<Ticket<ManagementClient>, Event<ManagementClient>>(
        context.event.model.tickets.new(),
        context.event.model.tickets,
        {
            onSubmit(model) {
                emit('submit', model);
            },
        },
    ),
);
</script>
