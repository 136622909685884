<template>
    <div class="module__profile__tfa-management">
        <div v-if="tfaTypes">
            <OtAside
                v-if="tfaTypes['code'] && tfaTypes['code'].settings <= 3"
                :title="$t('dashboard.user.tfa.few_recovery_codes.title', {number: tfaTypes['code'].settings})"
                :description="$t('dashboard.user.tfa.few_recovery_codes.description')"
                type="warning"
                icon="warning"
            />

            <!-- TODO: Update this to use new DS | CU-86c0xx1zf -->
            <OtFormCollapse
                :title="$t('dashboard.user.tfa.title')"
                :subtitle="$t('dashboard.user.tfa.subtitle')"
                header-icon="call"
                class="module__profile__tfa-management__tfa_types"
                :button-open-label="$t('dashboard.common.action.open.text')"
                :button-close-label="$t('dashboard.common.action.close.text')"
            >
                <!-- DD-DASHBOARD-2719 - Only a user can disable a single tfa methods-->
                <div
                    v-if="tfaTypes['totp']"
                    class="module__profile__tfa-management__tfa_type"
                >
                    <div class="module__profile__tfa-management__tfa_type__info">
                        <div>
                            <h3 class="ot-ui-text-heading-3">
                                {{ $t('dashboard.user.tfa.types.totp.title') }}
                            </h3>
                            <OtFlair
                                v-if="tfaTypes['totp'].settings >= 1"
                                icon="check"
                                :inversed="false"
                                :centered="false"
                                variant="success"
                                :text="$t('dashboard.user.tfa.active')"
                            />
                        </div>
                        <span class="ot-ui-text-body-sm">
                            {{ $t('dashboard.user.tfa.types.totp.description') }}
                        </span>
                    </div>
                    <div class="module__profile__tfa-management__tfa_type__action">
                        <OtButton
                            v-if="tfaTypes['totp'].settings === 0"
                            @on-click="openTfaSetup( 'totp', 'dashboard.user.tfa.setup.totp.success')"
                        >
                            {{ $t('dashboard.user.tfa.action.set_up.text') }}
                        </OtButton>

                        <OtButton
                            v-else
                            variant="destructive"
                            @on-click="disableTfaType('totp')"
                        >
                            {{ $t('dashboard.user.tfa.action.disable.text') }}
                        </OtButton>
                    </div>
                </div>

                <OtSeparator v-if="tfaTypes['code']" />

                <div
                    v-if="tfaTypes['code']"
                    class="module__profile__tfa-management__tfa_type"
                >
                    <div class="module__profile__tfa-management__tfa_type__info">
                        <div>
                            <h5>{{ $t('dashboard.user.tfa.types.code.title') }}</h5>
                            <OtFlair
                                icon="check"
                                :inversed="false"
                                :centered="false"
                                variant="success"
                                :text="$t('dashboard.user.tfa.active')"
                            />
                        </div>
                        <span class="ot-ui-text-body-sm">
                            {{ $t('dashboard.user.tfa.types.code.description') }}
                        </span>
                    </div>
                    <div class="module__profile__tfa-management__tfa_type__action">
                        <!-- recovery codes can be renewed even if no codes are currently available -->
                        <OtButton
                            variant="secondary"
                            @on-click="openTfaSetup('code', 'dashboard.user.tfa.setup.code.success')"
                        >
                            {{ $t('dashboard.user.tfa.action.renew_recovery_codes.text') }}
                        </OtButton>
                    </div>
                </div>
            </OtFormCollapse>
        </div>

        <!-- TODO: Update this to use new DS | CU-86c0xx23g -->
        <OtModal
            ref="modalTfaSetup"
            class="tfa-inline-modal"
        >
            <template #custom>
                <div class="module__profile__tfa-management__modal__tfa-setup">
                    <TfaSetup
                        v-if="tfaTypes"
                        :type="tfaTypeToSetup"
                        :tfa-types="tfaTypes"
                        :success-message="successMessageTfaSetup"
                        hide-logo
                        hide-logout
                        @close="tfaSetupClosed"
                    />
                </div>
            </template>
        </OtModal>

        <OtTFAConfirmation
            ref="modalTfaConfirmation"
            class="module__profile__tfa-management__modal__tfa-confirmation"
            :tfa-types="tfaTypes ?? {}"
            :error="error"
            :submitting="submitDisable"
            :auto-submit="false"
            hide-logo
            hide-logout
            @submit="tryToSubmitDisable"
            @back="tfaDisableClose"
        />
    </div>
</template>

<script lang="ts" setup>
import type { AuthClient } from '@openticket/lib-auth';
import urlJoin from 'url-join';
import { reactive, ref, useTemplateRef } from 'vue';
import type { AxiosError } from 'axios';
import {
    OtModal,
    OtTFAConfirmation,
    type OtTFAError,
    type OtTFATypes,
    useConfirmationModal,
    useLocalization,
    useNotifications,
} from '@openticket/vue-ui';
import TfaSetup from '../../../components/tfa/TfaSetup.vue';
import { injectOrFail } from '../../../services/util';

const auth = injectOrFail<AuthClient>('auth');

const confirmationModal = useConfirmationModal();
const { t } = useLocalization();
const notifications = useNotifications();

const modalTfaConfirmation = useTemplateRef<typeof OtTFAConfirmation>('modalTfaConfirmation');
const modalTfaSetup = ref<InstanceType<typeof OtModal>>();
const submitDisable = ref<boolean>(false);
const successMessageTfaSetup = ref<string>('dashboard.common.state.created');
const tfaEnforced = ref<boolean>(true);
const tfaTypes = ref<OtTFATypes | null>(null);
const tfaTypeToDelete = ref<string | null>(null);
const tfaTypeToSetup = ref<string | null>(null);

const error = reactive<OtTFAError>({
    incorrectPassword: null,
    incorrectTFAInput: null,
});

void (async () => {
    // TODO @Peter fix form component usage, should not be necessary here just for UI (but right now it sort-of is...)

    // TODO @Peter Add try catch/errors here

    // TODO - @openticket/lib-auth
    const tfaTypesPromise = auth.$http.get<OtTFATypes>(urlJoin(auth.$path, 'twofactor'));
    const forcedListPromise = auth.$http.get<unknown[]>(urlJoin(auth.$path, 'twofactor', 'forced'));

    const tfaOptions: OtTFATypes = (await tfaTypesPromise).data;

    for (const key of Object.keys(tfaOptions)) {
        tfaOptions[key].name = t(tfaOptions[key].name);
    }

    tfaTypes.value = tfaOptions;

    tfaEnforced.value = (await forcedListPromise).status !== 204;
})();

async function tfaSetupClosed(): Promise<void> {
    if (!auth) {
        return;
    }

    // TODO - @openticket/lib-auth
    tfaTypes.value = (await auth.$http.get<OtTFATypes>(urlJoin(auth.$path, 'twofactor'))).data;
    submitDisable.value = false;
    successMessageTfaSetup.value = 'dashboard.common.state.created';

    await modalTfaSetup.value?.close();
}

async function disableTfaType(type: string): Promise<void> {
    const confirm = await confirmationModal.confirm({
        title: t(tfaEnforced.value
            ? 'dashboard.user.tfa.action.forced_disable.title'
            : 'dashboard.common.confirm.permanent.title'),
        message: t(tfaEnforced.value
            ? 'dashboard.user.tfa.action.forced_disable.description'
            : 'dashboard.common.confirm.permanent.description'),
        variant: 'destructive',
    });

    if (confirm) {
        tfaTypeToDelete.value = type;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalTfaConfirmation.value?.open();
    }
}

async function tryToSubmitDisable(tfaConfirmed: { input: string, type: string, password: string | null}): Promise<void> {
    if (auth && tfaConfirmed && tfaTypeToDelete) {
        submitDisable.value = true;

        // TODO - @openticket/lib-auth
        await auth.$http.delete(
            urlJoin(auth.$path, 'twofactor', tfaTypeToDelete.value as string),
            {
                data: {
                    tfa_type: tfaConfirmed.type,
                    tfa_input: tfaConfirmed.input,
                    password: tfaConfirmed.password,
                },
            },
        ).then(() => {
            notifications.success(t('dashboard.user.tfa.deletion.success'));
            submitDisable.value = false;

            void tfaDisableClose();
        }).catch((catchError: AxiosError<{error_description: {password: Array<string>, tfa_input: Array<string>} }>) => {
            if (catchError.response && catchError.response.status === 406) {
                if (catchError.response.data.error_description.password
                        && catchError.response.data.error_description.password.length > 0) {
                    [ error.incorrectPassword ] = catchError.response.data.error_description.password;
                    error.incorrectTFAInput = null;
                } else if (catchError.response?.data.error_description.tfa_input
                        && catchError.response.data.error_description.tfa_input.length > 0) {
                    error.incorrectPassword = null;
                    [ error.incorrectTFAInput ] = catchError.response.data.error_description.tfa_input;
                }
            } else {
                notifications.warn(t('dashboard.user.tfa.deletion.warning'));
            }
            submitDisable.value = false;
        });

        // TODO @Peter Proper errors and logging
    }
}

async function tfaDisableClose(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await modalTfaConfirmation.value?.close();
    tfaTypeToDelete.value = null;
    submitDisable.value = false;

    if (!auth) {
        return;
    }

    // TODO - @openticket/lib-auth
    tfaTypes.value = (await auth.$http.get<OtTFATypes>(urlJoin(auth.$path, 'twofactor'))).data;
}

function openTfaSetup(type: string, successMessage: string): void {
    tfaTypeToSetup.value = type;
    successMessageTfaSetup.value = successMessage;

    modalTfaSetup.value?.open();
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins.scss";

.module__profile__tfa-management {
    &__tfa_type {
        display: flex;
        align-items: center;

        &__info {
            flex: 1;
            margin-right: var(--ot-ui-spacing-lg);

            h5 {
                display: inline-flex;
                margin-right: var(--ot-ui-spacing-xs);
            }

            span {
                display: inline-block;
            }

            &__enforced {
                color: var(--ot-ui-color-intent-error-secondary);
            }

            &__label {
                background-color: var(--ot-ui-color-intent-success-secondary);
                color: var(--ot-ui-color-intent-invert-secondary);
                cursor: default;
                padding: 0 var(--ot-ui-spacing-xs);
                border-radius: var(--ot-ui-radius-md);
                height: auto;
                vertical-align: bottom;
            }
        }
    }

    &__modal__tfa-confirmation,
    &__modal__tfa-setup {
        width: 36rem;

        @include breakpoint(mob) {
            width: calc(100dvw - (var(--ot-ui-spacing-lg) * 2));
        }
    }
}
</style>
