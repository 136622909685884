import { OtError } from '@openticket/lib-log';

export class AdminActionsInvalidResponse extends OtError {

    constructor() {
        super('AdminActionsInvalidResponse', 'dashboard.error.plugins.admin_actions.invalid_response', 'AdminActions invalid response.');

        this.captureStackTrace(AdminActionsInvalidResponse);
    }

}
