<template>
    <FormModal
        v-if="modal.isOpen"
        data-testid="location-update-modal"
        :title="$t('dashboard.events.wizard.steps.details.form.location.modal.title')"
        :subtitle="$t('dashboard.events.wizard.steps.details.form.location.modal.description')"
        :form="locationForm"
        :modal="modal"
    >
        <LocationFieldsVue :form-model="locationForm" />
    </FormModal>
</template>

<script setup lang="ts">
import { reactive, type UnwrapNestedRefs } from 'vue';
import type { ManagementClient, Location } from '@openticket/lib-management';
import { useUpdateForm } from '../../../composables/forms';
import type { FormModalComposableResult } from '../../../composables/forms';
import FormModal from '../FormModal.vue';

import { injectOrFail } from '../../../services/util';
import LocationFieldsVue from './LocationFields.vue';

type Props = {
    modal: UnwrapNestedRefs<FormModalComposableResult>,
    model: UnwrapNestedRefs<Location<ManagementClient>>,
}

type Emits = {
    (e: 'saved', guid: string | null): void
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const management = injectOrFail<ManagementClient>('management');

const locationForm = reactive(
    useUpdateForm(
        props.model,
        management.locations,
        {
            async onSubmit(model) {
                emit('saved', model.id);
                await Promise.resolve();
            },
            disableLocalChangesDialog: true,
        },
    ),
);
</script>
