import type { DataInterface, Parent, RawInterface } from '@openticket/lib-crud';
import {
    Create, DataType, Model, ToMany,
} from '@openticket/lib-crud';
import { Finalize, UploadRequest, type UploadRequestConfig } from '@openticket/lib-knox';

const ToggleConfig = {
    background: [ DataType.undefined, DataType.boolean ],
    date_of_event: [ DataType.undefined, DataType.boolean ],
    email: [ DataType.undefined, DataType.boolean ],
    event_name: [ DataType.undefined, DataType.boolean ],
    footer: [ DataType.undefined, DataType.boolean ],
    logos: [ DataType.undefined, DataType.boolean ],
    name: [ DataType.undefined, DataType.boolean ],
    optional_products: [ DataType.undefined, DataType.boolean ],
    optional_products_metadata: [ DataType.undefined, DataType.boolean ],
    order_info: [ DataType.undefined, DataType.boolean ],
    qr_codes: [ DataType.undefined, DataType.boolean ],
    seating_info_row_with_seats: [ DataType.undefined, DataType.boolean ],
    seating_info_table_with_seats: [ DataType.undefined, DataType.boolean ],
    seating_info_whole_table: [ DataType.undefined, DataType.boolean ],
    ticket_info: [ DataType.undefined, DataType.boolean ],
    ticket_name: [ DataType.undefined, DataType.boolean ],
};

const ModelConfig = {
    owned_type: [ DataType.string ],
    owned_id: [ DataType.string ],
    belongs_type: [ DataType.string ],
    belongs_id: [ DataType.string ],
    template_id: [ DataType.string ],
    background: [ DataType.string ],
    hide_toggles: [ DataType.object(ToggleConfig), DataType.undefined ],
    show_toggles: [ DataType.object(ToggleConfig), DataType.undefined ],
};

type Config = typeof ModelConfig;

export type {
    Config as TemplateLinkConfig,
};

export type TemplateLinkRaw = RawInterface<Config>;
export type TemplateLinkData = DataInterface<Config>;

export class TemplateLink<Ancestor extends Parent> extends Model<Ancestor, Config> {

    static $defaultData(): RawInterface<Config> {
        return {
            owned_type: '',
            owned_id: '',
            belongs_type: '',
            belongs_id: '',
            template_id: '',
            background: '',
            hide_toggles: {},
            show_toggles: {},
        };
    }

    get id(): string | null {
        return null;
    }

    get name(): string | null {
        return null;
    }

    $config(): Config {
        return ModelConfig;
    }

    $modelName(): string {
        return 'TemplateLink';
    }

    uploadRequests = new ToMany<UploadRequest<typeof this>, UploadRequestConfig, typeof this, {}>(UploadRequest, this, 'upload_request')
        .with(Create)
        .with(Finalize);

}
